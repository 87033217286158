import React, { useEffect, useRef, useState, useContext } from "react";
import { Row, Col } from "reactstrap";
import { PreloaderWrap } from "Components/Common/Preloader/Preloader";
import { useFormContext } from "../../provider/utils/FormContext";
import { ApplicationDetailsContext } from "pages/Applications/ApplicationDetails/ApplicationDetails";
import { getCustomerPayerDetails } from "helpers/API/core-service/cs_backend_helper";
import { toast_error } from "helpers/toast_helper";
import ApplicationStepNavigation from "../../components/ApplicationStepNavigation/ApplicationStepNavigation";
import PayerForm from "./PayerForm";
import PayerTiles from "./PayerTiles";
import "./PayerDetailsStep.scss";
import { FormattedString, getString } from "Components/Common/FormattedString";

const PayerDetailsStep = () => {
  const { applicationData } = useContext(ApplicationDetailsContext);
  const { formik, handleStepChange } = useFormContext();
  const [loading, setLoading] = useState(true);
  const [existingPayerDetails, setExistingPayerDetails] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const payerFormRef = useRef();

  useEffect(() => {
    const fetchPayerDetails = async () => {
      try {
        const response = await getCustomerPayerDetails(
          applicationData.customerId,
        );
        setExistingPayerDetails(response.payerDetails);
      } catch (error) {
        console.error(error);
        toast_error("fetching_payer_details_error");
      } finally {
        setLoading(false);
      }
    };

    fetchPayerDetails();
  }, [applicationData.customerId]);

  useEffect(() => {
    if (formik.values.selectedPayer) {
      formik.setErrors((prevErrors) => {
        const { selectedPayer, payerDetails, ...rest } = prevErrors;
        return rest;
      });
    }
  }, [formik.values.selectedPayer]);

  const handleBack = () => {
    setIsEditing(false);
    formik.setErrors({});
  };

  const handleNext = () => {
    if (payerFormRef.current) {
      payerFormRef.current.submit();
    } else {
      if (formik.values.selectedPayer) {
        formik
          .setValues({
            ...formik.values,
            payerDetails: formik.values.selectedPayer,
          })
          .then(() => {
            handleStepChange(1);
          });
      } else {
        formik.setErrors({
          ...formik.errors,
          payerDetails: getString("payer_details_required"),
        });
      }
    }
  };

  const handleEditPayer = (payer) => {
    formik.setFieldValue("selectedPayer", payer);
    setIsEditing(true);
  };

  if (loading) {
    return (
      <div className="payer-details-step">
        <PreloaderWrap />
      </div>
    );
  }

  return (
    <div className="payer-details-step">
      <Row className="mb-2">
        <Col>
          <h2 className="text-left text-primary">
            <FormattedString id="payer_details" />
          </h2>
        </Col>
      </Row>

      {isEditing ? (
        <PayerForm
          ref={payerFormRef}
          initialValues={{
            payerDetails: formik.values.selectedPayer || {
              id: null,
              type: "person",
              companyName: "",
              taxNumber: "",
              firstName: "",
              lastName: "",
              address: "",
              zipCode: "",
              city: "",
            },
          }}
          handleBack={handleBack}
        />
      ) : (
        <PayerTiles
          existingPayerDetails={existingPayerDetails}
          formik={formik}
          setIsEditing={setIsEditing}
          onEditPayer={handleEditPayer}
        />
      )}

      {(formik.errors.selectedPayer || formik.errors.payerDetails) && (
        <Row className="mb-2">
          <Col>
            <div
              style={{
                color: "red",
                marginTop: "10px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <i
                className="ri-error-warning-line"
                style={{ marginRight: "5px" }}
              ></i>
              <FormattedString id="payer_details_required" />
            </div>
          </Col>
        </Row>
      )}

      <ApplicationStepNavigation
        handlePrev={isEditing ? () => handleBack() : undefined}
        handleNext={handleNext}
      />
    </div>
  );
};

export default PayerDetailsStep;
