import { getStringSync } from "Components/Common/FormattedString";

export const programStatus = {
  in_preparation: "in_preparation",
  on_sale: "on_sale",
  ongoing: "ongoing",
  settlement: "settlement",
  cancelled: "cancelled",
};

export const statusOptions = Object.keys(programStatus).map((key) => ({
  value: programStatus[key],
  label: getStringSync(programStatus[key]),
}));
