import React, { useEffect, useRef } from "react";
import { useSearchParams, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getPromoCodesUsageList } from "../../store/promoCodesUsage/action";
import TableContainerExternalPagination from "Components/Common/TableContainerExternalPagination";
import { Badge } from "reactstrap";
import "./PromoCodesUsageList.scss";
import { handleCopyToClipboard, splitNumbers } from "helpers/utils";
import { getString } from "Components/Common/FormattedString";

const PromoCodesUsageList = () => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const isInitialRender = useRef(true);

  const {
    promoCodesUsage,
    count,
    currentPage,
    totalPages,
    pageSize,
    loading,
    error,
  } = useSelector((rootState) => rootState.PromoCodesUsage);

  const pageNumber = searchParams.get("page") || "1";
  const globalFilter = searchParams.get("searchTerm") || "";

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
    }
    dispatch(getPromoCodesUsageList(pageNumber, globalFilter));
  }, [searchParams]);

  const columns = [
    {
      id: "promo_code",
      Header: getString("promo_code"),
      accessor: (row) => (
        <div className="promo-code-cell">
          <span
            className="promo-code-text"
            onClick={() => handleCopyToClipboard(row.code)}
          >
            {row.code}
            <i className="ri-clipboard-line clipboard-icon" />
          </span>
        </div>
      ),
      Cell: ({ value }) => value,
    },
    {
      id: "discount_amount",
      Header: getString("discount_applied"),
      accessor: (row) => (
        <div className="cell-discount-applied">
          {row.discountAmount
            ? `${splitNumbers(row.discountAmount)} zł`
            : getString("none")}
        </div>
      ),
    },
    {
      id: "application_id",
      Header: getString("application_id"),
      accessor: (row) => (
        <div className="cell-application-id">
          {row.applicationCustomId ? (
            <Link to={`/pp-application/${row.applicationId}`}>
              #{row.applicationCustomId}
            </Link>
          ) : (
            "-"
          )}
        </div>
      ),
    },
    {
      id: "user",
      Header: getString("user"),
      accessor: (row) => (
        <div
          className="cell-email d-flex align-items-center"
          style={{ gap: "8px", color: "#6c757d" }}
        >
          {row.customerEmail ? (
            <>
              <i
                className="ri-clipboard-line"
                style={{ cursor: "pointer", color: "inherit" }}
                onClick={() => handleCopyToClipboard(row.customerEmail)}
              ></i>
              <span
                style={{ cursor: "pointer", color: "inherit" }}
                onClick={() => handleCopyToClipboard(row.customerEmail)}
              >
                {row.customerEmail}
              </span>
            </>
          ) : (
            <span>-</span>
          )}
        </div>
      ),
    },
    {
      id: "customer_name",
      Header: getString("customer"),
      accessor: (row) => (
        <div className="cell-customer-name">
          {row.customerFirstName || row.customerLastName
            ? `${row.customerFirstName || ""} ${row.customerLastName || ""}`.trim()
            : "-"}
        </div>
      ),
    },
    {
      id: "phone",
      Header: getString("phone"),
      accessor: (row) => (
        <div className="cell-phone">
          {row.customerPhonePrefix && row.customerPhoneNumber
            ? `+${row.customerPhonePrefix} ${row.customerPhoneNumber}`
            : "-"}
        </div>
      ),
    },
    {
      id: "sales_person",
      Header: getString("sales_person"),
      accessor: (row) => (
        <div
          className="cell-sales-person d-flex align-items-center"
          style={{ gap: "8px" }}
        >
          {row.salesPerson && (
            <i className="ri-user-line" style={{ color: "#6c757d" }}></i>
          )}
          {row.salesPerson ? row.salesPerson : "-"}
        </div>
      ),
    },
    {
      id: "status",
      Header: getString("status"),
      accessor: (row) => (
        <div className="cell-status">
          <Badge color={row.isActive ? "success" : "danger"}>
            {row.isActive ? getString("active") : getString("inactive")}
          </Badge>
        </div>
      ),
    },
  ];

  const customSetGlobalFilter = (value) => {
    const newSearchParams = new URLSearchParams(searchParams);
    if (value) {
      newSearchParams.set("searchTerm", value);
    } else {
      newSearchParams.delete("searchTerm");
    }
    newSearchParams.set("page", "1");
    setSearchParams(newSearchParams);
  };

  const handlePageChange = (page) => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set("page", page.toString());
    setSearchParams(newSearchParams);
  };

  return (
    <div className="promo-codes-usage-list">
      <TableContainerExternalPagination
        columns={columns || []}
        count={count}
        currentPage={currentPage}
        totalPages={totalPages}
        pageSize={pageSize}
        loading={loading}
        globalFilter={globalFilter}
        setGlobalFilter={customSetGlobalFilter}
        onPageChange={handlePageChange}
        data={promoCodesUsage || []}
        isBordered={false}
        isGlobalFilter={true}
        className="custom-header-css table align-middle table-nowrap"
        tableClassName="table-centered align-middle table-nowrap mb-0"
        theadClassName="text-muted table-light"
        SearchPlaceholder={getString("promo_codes_usage_search_for")}
        error={error}
        onRetry={() =>
          dispatch(getPromoCodesUsageList(pageNumber, globalFilter))
        }
        preventLoading={false}
      />
    </div>
  );
};

export { PromoCodesUsageList };
