import React from "react";
import { useNavigate } from "react-router-dom";
import { getString } from "../../../../Components/Common/FormattedString";

const QuickActions = () => {
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <div className="flex-grow-1">
        <p className="text-muted text-uppercase mb-2">
          {getString("quick_actions")}
        </p>
      </div>
      <div className="d-flex flex-column h-100">
        <div className="row g-3">
          {[
            {
              icon: "ri-clipboard-line",
              label: getString("applications"),
              route: "/pp-applications",
            },
            {
              icon: "ri-user-line",
              label: getString("customers"),
              route: "/customers",
            },
            {
              icon: "ri-time-line",
              label: getString("overdue"),
              route: "/pp-applications?filter=overdue",
            },
            {
              icon: "ri-global-line",
              label: getString("ip-participants"),
              route: "/ip-participants",
            },
            {
              icon: "ri-space-ship-line",
              label: getString("programs"),
              route: "/programs",
            },
            {
              icon: "ri-building-line",
              label: getString("venues"),
              route: "/venues",
            },
          ].map((action, index) => (
            <div key={index} className="col-6">
              <button
                onClick={() => navigate(action.route)}
                className="btn btn-light w-100 p-3 text-center d-flex flex-column align-items-center justify-content-center"
              >
                <div className="mb-2">
                  <i className={action.icon + ` display-6 text-muted`}></i>
                </div>
                <span className="text-muted text-uppercase fs-13">
                  {action.label}
                </span>
              </button>
            </div>
          ))}
        </div>
      </div>
    </React.Fragment>
  );
};

export default QuickActions;
