// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard .card {
  min-height: 115px;
}
.dashboard .participants-table thead {
  border-top: solid #d3d3d3 1px;
  border-bottom: solid #d3d3d3 1px;
}
.dashboard .participants-table__row:not(.participants-table__row--group-header) td:first-child {
  padding-left: calc(28px + var(--vz-card-spacer-x)) !important;
}
.dashboard .participants-table td {
  border: 0;
}
.dashboard .participants-table th {
  font-weight: 500;
}
.dashboard .participants-table td {
  color: #212529;
  font-size: 13px;
}`, "",{"version":3,"sources":["webpack://./src/pages/DashboardCrm/Dashboard.scss"],"names":[],"mappings":"AACE;EACE,iBAAA;AAAJ;AAII;EACE,6BAAA;EACA,gCAAA;AAFN;AAKM;EACE,6DAAA;AAHR;AAOI;EACE,SAAA;AALN;AAQI;EACE,gBAAA;AANN;AASI;EACE,cAAA;EACA,eAAA;AAPN","sourcesContent":[".dashboard {\n  .card {\n    min-height: 115px;\n  }\n\n  .participants-table {\n    thead {\n      border-top: solid #d3d3d3 1px;\n      border-bottom: solid #d3d3d3 1px;\n    }\n    &__row:not(.participants-table__row--group-header) {\n      td:first-child {\n        padding-left: calc(28px + var(--vz-card-spacer-x)) !important;\n      }\n    }\n\n    td {\n      border: 0;\n    }\n\n    th {\n      font-weight: 500;\n    }\n\n    td {\n      color: #212529;\n      font-size: 13px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
