export const getCurrencyTotals = (programData, programPacketId) => {
  const currencyTotals = {};
  const matchingPrices = programData.programPrices.filter(
    (price) => price.programPacketId === programPacketId,
  );

  const matchingInstallmentPlans = matchingPrices.map((price) => {
    return {
      ...price,
      installmentPlan: programData.installmentPlans.find(
        (installmentPlan) => installmentPlan.id === price.installmentPlanId,
      ),
    };
  });

  const firstMatchingInstallmentPlan = matchingInstallmentPlans.find(
    (installmentPlan) => {
      return installmentPlan?.installmentPlan;
    },
  );

  firstMatchingInstallmentPlan?.prices.forEach((price) => {
    const installment =
      firstMatchingInstallmentPlan.installmentPlan?.installments.find(
        (installment) => installment.id === price.installmentId,
      );

    if (installment) {
      const currency = installment?.currency;

      if (!currencyTotals[currency]) {
        currencyTotals[currency] = 0;
      }

      currencyTotals[currency] += price.price;
    }
  });

  return currencyTotals;
};
