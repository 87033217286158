import {
  API_RESPONSE_SUCCESS,
  API_RESPONSE_ERROR,
  GET_UPSELL_LIST_DATA,
  POST_UPSELL,
  EDIT_UPSELL,
  ARCHIVE_UPSELL,
  RESTORE_UPSELL,
} from "./actionType";

export const INIT_STATE = {
  upsellsData: [],
  formValidationErrors: {},
  error: null,
  loading: false,
  isUpsellAdded: false,
  isUpsellEdited: false,
  isUpsellArchived: false,
  isUpsellRestored: false,
};

const Upsells = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_UPSELL_LIST_DATA:
    case POST_UPSELL:
    case EDIT_UPSELL:
    case ARCHIVE_UPSELL:
    case RESTORE_UPSELL:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case GET_UPSELL_LIST_DATA:
          return {
            ...state,
            upsellsData: action.payload.data,
            isUpsellAdded: false,
            isUpsellEdited: false,
            isUpsellArchived: false,
            isUpsellRestored: false,
            loading: false,
            error: null,
          };
        case POST_UPSELL:
          return {
            ...state,
            loading: false,
            isUpsellAdded: true,
            error: null,
          };
        case EDIT_UPSELL:
          return {
            ...state,
            loading: false,
            isUpsellEdited: true,
            error: null,
          };
        case ARCHIVE_UPSELL:
          return {
            ...state,
            loading: false,
            isUpsellArchived: true,
            error: null,
          };
        case RESTORE_UPSELL:
          return {
            ...state,
            loading: false,
            isUpsellRestored: true,
            error: null,
          };
        default:
          return state;
      }
    case API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case GET_UPSELL_LIST_DATA:
          return {
            ...state,
            errorMsg: action.payload.data,
            error: action.payload.error,
            isUpsellAdded: false,
            isUpsellEdited: false,
            isUpsellArchived: false,
            isUpsellRestored: false,
            loading: false,
          };
        case POST_UPSELL:
          return {
            ...state,
            error: action.payload.error,
            formValidationErrors: action.payload.error.data?.fields ?? null,
            loading: false,
            isUpsellAdded: false,
          };
        case EDIT_UPSELL:
          return {
            ...state,
            error: action.payload.error,
            formValidationErrors: action.payload.error.data?.fields ?? null,
            loading: false,
            isUpsellEdited: false,
          };
        case ARCHIVE_UPSELL:
          return {
            ...state,
            error: action.payload.error,
            loading: false,
            isUpsellArchived: false,
          };
        case RESTORE_UPSELL:
          return {
            ...state,
            error: action.payload.error,
            loading: false,
            isUpsellRestored: false,
          };
        default:
          return state;
      }
    default:
      return state;
  }
};

export default Upsells;
