import { FormattedString } from "Components/Common/FormattedString";
import { CustomerDetailsContext } from "pages/Customers/CustomerDetails/CustomerDetails";
import { useContext } from "react";

export const BillingData = () => {
  const {
    customerData: { payerDetails },
  } = useContext(CustomerDetailsContext);

  const sortedPayerDetails = payerDetails.sort(
    (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt),
  );
  const newestPayerDetail = sortedPayerDetails[0] || {};

  if (!newestPayerDetail) {
    return (
      <div className="billing-data">
        <FormattedString id="billing_data_not_available" />
      </div>
    );
  }

  return (
    <div className="billing-data">
      <ul className="list-unstyled vstack gap-2 fs-13 mb-0">
        {newestPayerDetail.companyName ? (
          <>
            <li className="fw-medium fs-14">
              {newestPayerDetail.companyName || "N/A"}
            </li>
            <li>{newestPayerDetail.taxNumber || "N/A"}</li>
          </>
        ) : (
          <>
            <li className="fw-medium fs-14">
              {newestPayerDetail.firstName || "N/A"}{" "}
              {newestPayerDetail.lastName || "N/A"}
            </li>
          </>
        )}
        <li>{newestPayerDetail.address || "N/A"}</li>
        <li>
          {newestPayerDetail.zipCode || "N/A"} -{" "}
          {newestPayerDetail.city || "N/A"}
        </li>
      </ul>
    </div>
  );
};
