import {
  API_RESPONSE_SUCCESS,
  API_RESPONSE_ERROR,
  GET_IP_PARTICIPANT_LIST_DATA,
  POST_IP_PARTICIPANT,
  EDIT_IP_PARTICIPANT,
  DELETE_IP_PARTICIPANT,
  GET_BUSINESS_UNIT_LIST_DATA,
  TOGGLE_IP_PARTICIPANT_STATUS,
  POST_IP_ATTACHMENT,
} from "./actionType";

// common success
export const ipParticipantsApiSuccess = (actionType, data) => ({
  type: API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

// common error
export const ipParticipantsApiError = (actionType, error) => ({
  type: API_RESPONSE_ERROR,
  payload: { actionType, error },
});

// business units list
export const getBusinessUnitListData = () => ({
  type: GET_BUSINESS_UNIT_LIST_DATA,
  payload: {},
});

// ipParticipants list
export const getIpParticipantListData = () => ({
  type: GET_IP_PARTICIPANT_LIST_DATA,
  payload: {},
});

// ipParticipant add
export const postIpParticipant = (data, onSuccess) => ({
  type: POST_IP_PARTICIPANT,
  payload: { data, onSuccess },
});

// ipParticipant edit
export const editIpParticipant = (id, data, onSuccess) => ({
  type: EDIT_IP_PARTICIPANT,
  payload: { id, data, onSuccess },
});

// ipParticipant delete
export const deleteIpParticipant = (id, onSuccess) => ({
  type: DELETE_IP_PARTICIPANT,
  payload: { id, onSuccess },
});

// ipParticipant block
export const toggleIpParticipantStatus = (id, onSuccess) => ({
  type: TOGGLE_IP_PARTICIPANT_STATUS,
  payload: { id, onSuccess },
});

// ipAttachment add
export const postIpAttachment = (id, data, onSuccess) => ({
  type: POST_IP_ATTACHMENT,
  payload: { id, data, onSuccess },
});
