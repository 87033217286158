import React, { useState, useContext } from "react";
import { Modal, ModalBody } from "reactstrap";
import { Link } from "react-router-dom";
import { FormattedString, getString } from "Components/Common/FormattedString";
import { toast_success } from "helpers/toast_helper";
import { markAsRefund } from "helpers/API/core-service/cs_backend_helper";
import { ApplicationDetailsContext } from "pages/Applications/ApplicationDetails/ApplicationDetails";

export const ConfirmMarkAsRefundModal = ({
  visibility,
  toggle,
  paymentId,
  selectedPayment,
}) => {
  const { applicationData, updateApplicationInstallments } = useContext(
    ApplicationDetailsContext,
  );

  const [loading, setLoading] = useState(false);

  const handleConfirmRefund = () => {
    if (!selectedPayment || !selectedPayment.relatedTransactions?.length) {
      console.error("No related transactions found for the selected payment.");
      return;
    }

    const lastTransaction = selectedPayment.relatedTransactions.slice(-1)[0];
    const transactionCustomId = lastTransaction.transactionCustomId;

    setLoading(true);
    markAsRefund(paymentId, transactionCustomId)
      .then(() => {
        toast_success(getString("refund_success"));

        const prevInstallments = applicationData.applicationInstallments;
        updateApplicationInstallments(
          prevInstallments.map((installment) =>
            installment.id === selectedPayment.id
              ? { ...installment, disabled: true }
              : installment,
          ),
        );

        toggle(false);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Modal
      isOpen={visibility}
      toggle={toggle}
      backdrop="static"
      id="staticBackdrop"
      centered
    >
      <ModalBody className="text-center p-5">
        <div className="error-basic-img move-animation">
          <i
            className="ri-refund-line"
            style={{ fontSize: "100px", color: "red" }}
          ></i>
        </div>

        <div className="mt-4">
          <h4 className="mb-3">
            <FormattedString id="refund_modal_title" />
          </h4>
          <p className="text-muted mb-4">
            <FormattedString id="refund_modal_warning" />
          </p>
          <p className="text-muted mb-4">
            <u>
              <FormattedString id="refund_modal_reminder" />
            </u>
          </p>
          <div className="hstack gap-4 justify-content-center">
            <Link
              to="#"
              className="btn btn-success fw-medium"
              onClick={() => toggle(false)}
              data-testid="cancel-button"
            >
              <FormattedString id="cancel" />
            </Link>
            <button
              type="button"
              className="btn btn-danger"
              onClick={handleConfirmRefund}
              disabled={loading}
              data-testid="refund-button"
            >
              {loading ? (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              ) : (
                <FormattedString id="refund" />
              )}
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ConfirmMarkAsRefundModal;
