export const filterItemsByStatus = (items, status) => {
  return items.filter((item) => item.status === status);
};

export const filterOtherItems = (items, statuses) => {
  return items.filter((item) => !statuses.includes(item.status));
};

export const sumItemCounts = (items) => {
  return items.reduce((sum, item) => sum + item.count, 0);
};

export const getDisplayItems = (items, status) => {
  return items.length ? items : [{ status, count: 0 }];
};

export const calculateTotalParticipantsCount = (participants) => {
  return participants.reduce((total, participant) => {
    const { quantity } = participant.summary;
    const relevantCounts = quantity.filter((item) =>
      ["pre_booking", "partly_paid", "fully_paid"].includes(item.status),
    );
    const sumCounts = relevantCounts.reduce((sum, item) => sum + item.count, 0);
    return total + sumCounts;
  }, 0);
};

export const calculateParticipantsSums = (participants) => {
  let programParticipantsSum = 0;
  let programParticipantsTotalCapacity = 0;

  participants.forEach((packet) => {
    programParticipantsTotalCapacity += packet.summary.totalCapacity;
    programParticipantsSum += packet.summary.quantity.reduce(
      (sum, item) => sum + item.count,
      0,
    );
  });

  return { programParticipantsSum, programParticipantsTotalCapacity };
};
