import React, { useContext, useState, useCallback } from "react";
import { Modal } from "reactstrap";

import { getString } from "Components/Common/FormattedString";
import ConfirmArchiveBody from "Components/Entity/ConfirmArchiveBody";
import { archiveDocumentOfIp } from "helpers/API/core-service/cs_backend_helper";
import { toast_success } from "helpers/toast_helper";
import { IpDetailsContext } from "../../IpParticipantDetails";

const ArchiveDocument = ({
  internationalParticipantId,
  documentId,
  visibility,
  toggle,
}) => {
  const { refreshIpData } = useContext(IpDetailsContext);
  const [loading, setLoading] = useState(false);

  const archiveDocumentEntry = useCallback(() => {
    setLoading(true);
    archiveDocumentOfIp(internationalParticipantId, documentId)
      .then(() => {
        toast_success(getString("document_archived"));
        refreshIpData();
        toggle(false);
      })
      .catch((e) => console.error(e))
      .finally(() => setLoading(false));
  }, [internationalParticipantId, documentId, refreshIpData, toggle]);

  return (
    <Modal
      isOpen={visibility}
      toggle={() => {
        toggle();
      }}
      backdrop={"static"}
      id="staticBackdrop"
      centered
    >
      <ConfirmArchiveBody
        entityName={getString("document_sm")}
        toggle={toggle}
        archiveAction={archiveDocumentEntry}
        loading={loading}
      />
    </Modal>
  );
};

export default ArchiveDocument;
