import React, { useState } from "react";
import { Col, Container, Row, Button, Spinner } from "reactstrap";
import { ApplicationsList } from "./ApplicationsList";
import EntityCardList from "../../Components/Entity/EntityCardList";
import { FormattedString, getString } from "Components/Common/FormattedString";
import {
  generateApplicationAbandonedReport,
  generateApplicationReport,
} from "helpers/API/core-service/cs_backend_helper";
import { downloadBlob } from "helpers/fileUtils";

const Applications = () => {
  document.title = getString("applications_page_title");

  const [allLoading, setAllLoading] = useState(false);

  const [abandonedLoading, setAbandonedLoading] = useState(false);

  const handleGenerateAllReport = () => {
    setAllLoading(true);
    generateApplicationReport()
      .then((response) => {
        const blob = new Blob([response.data], {
          type: "text/csv;charset=utf-8;",
        });
        downloadBlob(blob, response.headers, "application_report.csv");
      })
      .catch((error) => {
        console.error("Error generating report:", error);
      })
      .finally(() => {
        setAllLoading(false);
      });
  };

  const handleGenerateAbandonedReport = () => {
    setAbandonedLoading(true);
    generateApplicationAbandonedReport()
      .then((response) => {
        const blob = new Blob([response.data], {
          type: "text/csv;charset=utf-8;",
        });
        downloadBlob(
          blob,
          response.headers,
          "application_abandoned_report.csv",
        );
      })
      .catch((error) => {
        console.error("Error generating report:", error);
      })
      .finally(() => {
        setAbandonedLoading(false);
      });
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col>
              <div className="h-100">
                <EntityCardList
                  title={getString("applications_list")}
                  headerButtons={
                    <>
                      <Button
                        color="light"
                        className="generate-report-button text-primary"
                        onClick={handleGenerateAllReport}
                        disabled={allLoading}
                      >
                        <FormattedString id="generate_report_all" />
                        {allLoading && (
                          <Spinner size="sm" color="primary" className="ms-2" />
                        )}
                      </Button>

                      <Button
                        color="light"
                        className="generate-report-button text-primary"
                        onClick={handleGenerateAbandonedReport}
                        disabled={abandonedLoading}
                      >
                        <FormattedString id="generate_report_abandoned" />
                        {abandonedLoading && (
                          <Spinner size="sm" color="primary" className="ms-2" />
                        )}
                      </Button>
                    </>
                  }
                >
                  <ApplicationsList />
                </EntityCardList>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Applications;
