import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { FormGroup, Label, FormFeedback } from "reactstrap";
import classNames from "classnames";
import "./FancyFileUploader.scss";
import { FormattedString } from "Components/Common/FormattedString";

const FancyFileUploader = ({ name, label, onFileChange, error }) => {
  const [fileUploaded, setFileUploaded] = useState(false);
  const [fileName, setFileName] = useState("");

  const onDrop = useCallback(
    (acceptedFiles) => {
      if (acceptedFiles.length > 0) {
        const file = acceptedFiles[0];
        onFileChange({
          target: {
            name,
            files: [file],
          },
        });
        setFileUploaded(true);
        setFileName(file.name);
      }
    },
    [onFileChange, name],
  );

  const clearFile = (e) => {
    e.stopPropagation();
    onFileChange({
      target: {
        name,
        files: [],
      },
    });
    setFileUploaded(false);
    setFileName("");
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
  });

  return (
    <FormGroup>
      <Label for={name}>{label}</Label>
      <div
        {...getRootProps()}
        className={classNames("dropzone", {
          active: isDragActive,
          uploaded: fileUploaded,
          "dropzone--error": error,
        })}
      >
        <input {...getInputProps()} />
        <div className="dropzone__content">
          <i
            className={`ri-file-${fileUploaded ? "check" : "upload"}-line icon`}
          ></i>
          {isDragActive ? (
            <p>
              <FormattedString id="dropFileHere" />
            </p>
          ) : fileUploaded ? (
            <div className="file-tile">
              <i className="ri-file-line file-icon"></i>
              <div className="file-name">{fileName}</div>
              <i className="ri-close-line clear-button" onClick={clearFile}></i>
            </div>
          ) : (
            <p>
              <FormattedString id="dragDropFile" />
            </p>
          )}
        </div>
      </div>
      {error && <FormFeedback className="d-block">{error}</FormFeedback>}
    </FormGroup>
  );
};

export default FancyFileUploader;
