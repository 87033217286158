import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import ServicesStatus from "./partials/components/ServicesStatus";
import { getDashboardData } from "../../helpers/API/core-service/cs_backend_helper";
import { PreloaderWrap } from "../../Components/Common/Preloader/Preloader";
import SeasonComparison from "./partials/components/SeasonComparison";
import EntityCardList from "../../Components/Entity/EntityCardList";
import { getString } from "../../Components/Common/FormattedString";
import QuickActions from "./partials/components/QuickActions";
import FillUpRate from "./partials/components/FillUpRate";
import ApplicationStatistics from "./partials/components/ApplicationStatistics";
import UpcomingPrograms from "./partials/components/UpcomingPrograms";
import "./Dashboard.scss";
import { RecentApplications } from "./partials/RecentApplications/RecentApplications";
import { AbandonedApplications } from "./partials/AbandonedApplications/AbandonedApplications";

const DashboardCrm = () => {
  document.title = "Dashboard | Angloville - CRM Panel";

  const [loading, setLoading] = useState(true);
  const [dashboardData, setDashboardData] = useState({
    servicesStatus: [],
    seasonComparison: [],
  });

  useEffect(() => {
    getDashboardData()
      .then((r) => {
        setDashboardData(r);
        setLoading(false);
      })
      .catch((e) => console.error(e));
  }, []);

  if (loading) {
    return <PreloaderWrap h100 />;
  }

  return (
    <div className="page-content dashboard">
      <Container fluid>
        <BreadCrumb title="CRM" pageTitle="Dashboards" />
        <Row>
          <Col className="col-xxl-12 order-xxl-0 order-first">
            <Row>
              {dashboardData.servicesStatus.length > 0 && (
                <ServicesStatus servicesStatus={dashboardData.servicesStatus} />
              )}
            </Row>
            {dashboardData.seasonComparison.length > 0 && (
              <Row>
                <SeasonComparison
                  seasonComparison={dashboardData.seasonComparison}
                />
              </Row>
            )}
            <Row className="mt-md-2">
              <Col className="col-xxl-9 order-xxl-0 order-first">
                <div className="h-100">
                  <EntityCardList title={getString("recent_applications")}>
                    <RecentApplications />
                  </EntityCardList>
                  <EntityCardList
                    title={getString("abandoned_applications_list")}
                  >
                    <AbandonedApplications />
                  </EntityCardList>
                </div>
              </Col>
              <Col className="col-xxl-3">
                <Row>
                  <QuickActions />
                  <FillUpRate fillUpRate={dashboardData.fillUpRate} />
                </Row>
              </Col>
            </Row>

            <Row>
              <ApplicationStatistics
                applicationsStats={dashboardData.applicationsStats}
              />
              <UpcomingPrograms
                upcomingPrograms={dashboardData.upcomingPrograms}
              />
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default DashboardCrm;
