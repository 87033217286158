import React, { useEffect, useState } from "react";
import { Card, CardBody, Form, Row, Col, Spinner } from "reactstrap";
import { useFormik } from "formik";
import { createSelector } from "reselect";
import { useDispatch, useSelector } from "react-redux";
import {
  postPromoCode,
  getPromoCodesListData,
} from "../../store/promoCodes/action";
import { getProgramPacketTemplateListData } from "../../store/programPacketTemplates/action";
import { createPromoCodeSchema } from "../../models/promoCodes";
import {
  handleInputChange,
  setValidationErrors,
} from "../../helpers/validation_helper";
import OffcanvasRight from "../../Components/Entity/OffcanvasRight";
import FormTextField from "../../Components/Entity/FormTextField";
import FormNumberField from "../../Components/Entity/FormNumberField";
import { FormRadioSelect } from "../../Components/Entity/FormRadioSelect";
import FormDateField from "../../Components/Entity/FormDateField";
import FormSwitch from "../../Components/Entity/FormSwitch";
import { getString } from "Components/Common/FormattedString";
import { installmentPositions } from "models/installmentPositions";
import FormPriceConfigurationSelect from "./components/FormPriceConfigurationSelect/FormPriceConfigurationSelect";
import { externalizeDate } from "helpers/utils";
import { toast_success } from "helpers/toast_helper";

const AddPromoCode = ({ visibility, toggle }) => {
  const dispatch = useDispatch();

  const { programPacketTemplatesData, loading: templatesLoading } = useSelector(
    (state) => state.ProgramPacketTemplates,
  );

  const selectPromoCodesState = (state) => state.PromoCodes;
  const selectPromoCodesProperties = createSelector(
    selectPromoCodesState,
    (state) => ({
      isPromoCodeAdded: state.isPromoCodeAdded,
      error: state.error,
      loading: state.loading,
      formValidationErrors: state.formValidationErrors,
    }),
  );
  const { loading, formValidationErrors } = useSelector(
    selectPromoCodesProperties,
  );

  const [prevValidationErrors, setPrevValidationErrors] = useState(null);

  useEffect(() => {
    dispatch(getProgramPacketTemplateListData());
  }, [dispatch]);

  const programPacketTemplateOptions = programPacketTemplatesData.map((pp) => ({
    value: pp.id,
    label: pp.label,
  }));

  const validation = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    initialValues: {
      code: "",
      usageLimit: "",
      isExternal: false,
      startDate: new Date().toISOString().split("T")[0],
      endDate: "",
      email: "",
      installmentPosition: "",
      pricesConfiguration: [],
      isGlobal: true,
      globalDiscount: "",
    },
    validationSchema: createPromoCodeSchema,
    onSubmit: async (values) => {
      const {
        isGlobal,
        pricesConfiguration,
        usageLimit,
        globalDiscount,
        ...data
      } = values;
      const formattedData = {
        ...data,
        ...(usageLimit !== "" && { usageLimit }),
        ...(isGlobal && globalDiscount !== "" && { globalDiscount }),
        startDate: externalizeDate(values.startDate),
        endDate: externalizeDate(values.endDate),
        ...(pricesConfiguration.length > 0 && { pricesConfiguration }),
      };

      dispatch(
        postPromoCode(formattedData, () => {
          toast_success(getString("promo_code_added_successfully"));
          dispatch(getPromoCodesListData());
          toggle(false);
        }),
      );
    },
  });

  useEffect(() => {
    if (formValidationErrors === null) {
      return;
    }
    if (prevValidationErrors !== null) {
      if (prevValidationErrors !== formValidationErrors) {
        setValidationErrors(validation, formValidationErrors);
      }
    }
    setPrevValidationErrors(formValidationErrors);
  }, [formValidationErrors, prevValidationErrors]);

  useEffect(() => {
    if (validation.touched.installmentPosition) {
      validation.validateField("installmentPosition");
    }
  }, [
    validation.values.installmentPosition,
    validation.touched.installmentPosition,
  ]);

  const handleGlobalToggle = (e) => {
    const isGlobal = e.target.checked;
    validation.setFieldValue("isGlobal", isGlobal);

    if (isGlobal) {
      validation.setFieldValue("pricesConfiguration", []);
      validation.setFieldError("pricesConfiguration", undefined);
    } else {
      validation.setFieldValue("globalDiscount", "");
      validation.setFieldError("globalDiscount", undefined);
    }
  };

  console.log(validation.values);

  return (
    <React.Fragment>
      <OffcanvasRight
        isOpen={visibility}
        toggle={toggle}
        title={getString("promo_codes_add_new_promo_code")}
        formId={"add-promo-code"}
        loading={loading}
        validationRule={true}
        buttonLabel={getString("add")}
      >
        <Card>
          <CardBody className="card-body">
            {templatesLoading ? (
              <div className="d-flex justify-content-center align-items-center">
                <Spinner color="primary" />
              </div>
            ) : (
              <Form
                id="add-promo-code"
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
                action="#"
              >
                <FormTextField
                  name="code"
                  label={getString("promo_code")}
                  value={validation.values.code}
                  onChange={(e) => handleInputChange(validation, e, "code")}
                  error={validation.errors["code"]}
                  touched={validation.touched.code}
                />
                <FormNumberField
                  name="usageLimit"
                  label={getString("usage_limit")}
                  value={validation.values.usageLimit}
                  onChange={(e) =>
                    handleInputChange(validation, e, "usageLimit")
                  }
                  error={validation.errors["usageLimit"]}
                  touched={validation.touched.usageLimit}
                />
                <FormSwitch
                  label={getString("is_external")}
                  noPadding
                  name="isExternal"
                  checked={validation.values.isExternal}
                  onChange={(e) =>
                    validation.setFieldValue("isExternal", e.target.checked)
                  }
                  error={validation.errors.isExternal}
                />
                <FormDateField
                  name="startDate"
                  label={getString("start_date")}
                  clearable
                  value={validation.values.startDate}
                  onChange={(e) =>
                    handleInputChange(validation, e, "startDate")
                  }
                  error={validation.errors["startDate"]}
                  touched={validation.touched.startDate}
                />
                <FormDateField
                  name="endDate"
                  label={getString("end_date")}
                  clearable
                  value={validation.values.endDate}
                  onChange={(e) => handleInputChange(validation, e, "endDate")}
                  error={validation.errors["endDate"]}
                  touched={validation.touched.endDate}
                />
                <FormTextField
                  name="email"
                  label={getString("email")}
                  type="email"
                  value={validation.values.email}
                  onChange={(e) => handleInputChange(validation, e, "email")}
                  error={validation.errors["email"]}
                  touched={validation.touched.email}
                />
                <FormRadioSelect
                  name="installmentPosition"
                  id="installmentPosition"
                  label={getString("installment_position")}
                  isMulti={false}
                  options={installmentPositions}
                  value={validation.values.installmentPosition}
                  onChange={(selectedValue) => {
                    validation.setFieldValue(
                      "installmentPosition",
                      selectedValue,
                    );
                  }}
                  error={validation.errors["installmentPosition"]}
                />
                <Row className="mb-3 align-items-center">
                  <Col>
                    <FormSwitch
                      label={getString("apply_globally")}
                      name="isGlobal"
                      noPadding
                      noMargin
                      checked={validation.values.isGlobal}
                      onChange={handleGlobalToggle}
                    />
                  </Col>
                </Row>

                {validation.values.isGlobal && (
                  <FormNumberField
                    name="globalDiscount"
                    label={getString("global_discount")}
                    value={validation.values.globalDiscount}
                    onChange={(e) =>
                      handleInputChange(validation, e, "globalDiscount")
                    }
                    error={validation.errors["globalDiscount"]}
                    touched={validation.touched.globalDiscount}
                  />
                )}

                {!validation.values.isGlobal && (
                  <FormPriceConfigurationSelect
                    name="pricesConfiguration"
                    label={getString("prices_configuration")}
                    value={validation.values.pricesConfiguration}
                    options={programPacketTemplateOptions}
                    onChange={(value) => {
                      validation.setFieldError(
                        "pricesConfiguration",
                        undefined,
                      );
                      validation.setFieldValue("pricesConfiguration", value);
                    }}
                    error={validation.errors.pricesConfiguration}
                  />
                )}
              </Form>
            )}
          </CardBody>
        </Card>
      </OffcanvasRight>
    </React.Fragment>
  );
};

export default AddPromoCode;
