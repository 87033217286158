import React from "react";
import PropTypes from "prop-types";
import "./GradientProgressBar.scss";

const hexToRgb = (hex) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
};

const getContrast = (r, g, b) => {
  return 0.2126 * r + 0.7152 * g + 0.0722 * b;
};

const mixWithWhite = (rgb, opacity) => {
  return {
    r: Math.round(rgb.r * opacity + 255 * (1 - opacity)),
    g: Math.round(rgb.g * opacity + 255 * (1 - opacity)),
    b: Math.round(rgb.b * opacity + 255 * (1 - opacity)),
  };
};

const getColorWithIntensity = (baseColor, percentage) => {
  const rgb = hexToRgb(baseColor);
  if (!rgb) {
    return { backgroundColor: baseColor, color: "#fff" };
  }

  const opacity = 0.2 + (percentage / 100) * 0.8;

  const mixedColor = mixWithWhite(rgb, opacity);

  return {
    backgroundColor: `rgb(${mixedColor.r},${mixedColor.g},${mixedColor.b})`,
    color:
      getContrast(mixedColor.r, mixedColor.g, mixedColor.b) > 128
        ? "#000"
        : "#fff",
  };
};

const GradientProgressBar = ({ percentage, baseColor }) => {
  const badgeStyle = getColorWithIntensity(baseColor, percentage);

  return (
    <span
      className="gradient-progress-bar"
      style={{
        ...badgeStyle,
      }}
      data-testid="gradient-progress-bar"
    >
      {percentage}%
    </span>
  );
};

GradientProgressBar.propTypes = {
  percentage: PropTypes.number.isRequired,
  baseColor: PropTypes.string.isRequired,
};

export default GradientProgressBar;
