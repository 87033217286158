// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.participant-equation {
  margin-bottom: 0;
  font-family: "Inter", sans-serif;
  align-items: center;
  display: flex;
  gap: 4px;
}
.participant-equation__val--sum {
  color: #299cdb;
  font-weight: 700;
}
.participant-equation__val--wait_list {
  font-weight: 700;
}
.participant-equation__sign--bright {
  color: #878a99;
}`, "",{"version":3,"sources":["webpack://./src/Components/Common/ParticipantEquation/ParticipantEquation.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,gCAAA;EACA,mBAAA;EACA,aAAA;EACA,QAAA;AACF;AAEI;EACE,cAAA;EACA,gBAAA;AAAN;AAEI;EACE,gBAAA;AAAN;AAKI;EACE,cAAA;AAHN","sourcesContent":[".participant-equation {\n  margin-bottom: 0;\n  font-family: \"Inter\", sans-serif;\n  align-items: center;\n  display: flex;\n  gap: 4px;\n\n  &__val {\n    &--sum {\n      color: #299cdb;\n      font-weight: 700;\n    }\n    &--wait_list {\n      font-weight: 700;\n    }\n  }\n\n  &__sign {\n    &--bright {\n      color: #878a99;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
