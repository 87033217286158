import * as Yup from "yup";
import { getStringSync } from "Components/Common/FormattedString";

const createApplicationSchema = Yup.object({
  programId: Yup.string().required(getStringSync("select_program")),
  customerId: Yup.string().nullable(),
  phonePrefix: Yup.string().test(
    "phonePrefix-required",
    getStringSync("phone_prefix_required"),
    function (value) {
      const { customerId } = this.parent;
      return customerId || value;
    },
  ),
  phoneNumber: Yup.string().test(
    "phoneNumber-required",
    getStringSync("phone_number_required"),
    function (value) {
      const { customerId } = this.parent;
      return customerId || value;
    },
  ),
  email: Yup.string()
    .email(getStringSync("invalid_email"))
    .test("email-required", getStringSync("email_required"), function (value) {
      const { customerId } = this.parent;
      return customerId || value;
    }),
  firstName: Yup.string().test(
    "firstName-required",
    getStringSync("first_name_required"),
    function (value) {
      const { customerId } = this.parent;
      return customerId || value;
    },
  ),
  lastName: Yup.string().test(
    "lastName-required",
    getStringSync("last_name_required"),
    function (value) {
      const { customerId } = this.parent;
      return customerId || value;
    },
  ),
});

export { createApplicationSchema };
