import {
  API_RESPONSE_SUCCESS,
  API_RESPONSE_ERROR,
  GET_PROGRAM_LIST_DATA,
  POST_PROGRAM,
  EDIT_PROGRAM,
  DELETE_PROGRAM,
  DUPLICATE_PROGRAM,
} from "./actionType";

export const INIT_STATE = {
  programsData: [],
  formValidationErrors: {},
  error: null,
  loading: false,
  isProgramAdded: false,
  isProgramEdited: false,
  isProgramArchived: false,
  isProgramRestored: false,
};

const Programs = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PROGRAM_LIST_DATA:
    case POST_PROGRAM:
    case EDIT_PROGRAM:
    case DELETE_PROGRAM:
    case DUPLICATE_PROGRAM:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case GET_PROGRAM_LIST_DATA:
          return {
            ...state,
            programsData: action.payload.data,
            isProgramAdded: false,
            isProgramEdited: false,
            isProgramArchived: false,
            isProgramRestored: false,
            loading: false,
            error: null,
          };
        case POST_PROGRAM:
          return {
            ...state,
            loading: false,
            isProgramAdded: true,
            error: null,
          };
        case EDIT_PROGRAM:
          return {
            ...state,
            loading: false,
            isProgramEdited: true,
            error: null,
          };
        case DELETE_PROGRAM:
          return {
            ...state,
            loading: false,
            isProgramArchived: true,
            error: null,
          };
        case DUPLICATE_PROGRAM:
          return {
            ...state,
            loading: false,
            isProgramAdded: true,
            error: null,
          };
        default:
          return state;
      }
    case API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case GET_PROGRAM_LIST_DATA:
          return {
            ...state,
            errorMsg: action.payload.data,
            error: action.payload.error,
            isProgramAdded: false,
            isProgramEdited: false,
            isProgramArchived: false,
            isProgramRestored: false,
            loading: false,
          };
        case POST_PROGRAM:
          return {
            ...state,
            error: action.payload.error,
            formValidationErrors: action.payload.error.data?.fields ?? null,
            loading: false,
            isProgramAdded: false,
          };
        case EDIT_PROGRAM:
          return {
            ...state,
            error: action.payload.error,
            formValidationErrors: action.payload.error.data?.fields ?? null,
            loading: false,
            isProgramEdited: false,
          };
        case DELETE_PROGRAM:
          return {
            ...state,
            error: action.payload.error,
            loading: false,
            isProgramArchived: false,
          };
        case DUPLICATE_PROGRAM:
          return {
            ...state,
            error: action.payload.error,
            loading: false,
            isProgramAdded: false,
          };
        default:
          return state;
      }
    default:
      return state;
  }
};

export default Programs;
