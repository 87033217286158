import {
  API_RESPONSE_SUCCESS,
  API_RESPONSE_ERROR,
  GET_ATTACHMENT_LIST_DATA,
  POST_ATTACHMENT,
  EDIT_ATTACHMENT,
  ARCHIVE_ATTACHMENT,
  RESTORE_ATTACHMENT,
} from "./actionType";

export const INIT_STATE = {
  attachmentsData: [],
  formValidationErrors: {},
  error: null,
  loading: false,
  isAttachmentAdded: false,
  isAttachmentEdited: false,
  isAttachmentArchived: false,
  isAttachmentRestored: false,
};

const Attachments = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ATTACHMENT_LIST_DATA:
    case POST_ATTACHMENT:
    case EDIT_ATTACHMENT:
    case ARCHIVE_ATTACHMENT:
    case RESTORE_ATTACHMENT:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case GET_ATTACHMENT_LIST_DATA:
          return {
            ...state,
            attachmentsData: action.payload.data,
            isAttachmentAdded: false,
            isAttachmentEdited: false,
            isAttachmentArchived: false,
            isAttachmentRestored: false,
            loading: false,
            error: null,
          };
        case POST_ATTACHMENT:
          return {
            ...state,
            loading: false,
            isAttachmentAdded: true,
            error: null,
          };
        case EDIT_ATTACHMENT:
          return {
            ...state,
            loading: false,
            isAttachmentEdited: true,
            error: null,
          };
        case ARCHIVE_ATTACHMENT:
          return {
            ...state,
            loading: false,
            isAttachmentArchived: true,
            error: null,
          };
        case RESTORE_ATTACHMENT:
          return {
            ...state,
            loading: false,
            isAttachmentRestored: true,
            error: null,
          };
        default:
          return state;
      }
    case API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case GET_ATTACHMENT_LIST_DATA:
          return {
            ...state,
            errorMsg: action.payload.data,
            error: action.payload.error,
            isAttachmentAdded: false,
            isAttachmentEdited: false,
            isAttachmentArchived: false,
            isAttachmentRestored: false,
            loading: false,
          };
        case POST_ATTACHMENT:
          return {
            ...state,
            error: action.payload.error,
            formValidationErrors: action.payload.error.data?.fields ?? null,
            loading: false,
            isAttachmentAdded: false,
          };
        case EDIT_ATTACHMENT:
          return {
            ...state,
            error: action.payload.error,
            formValidationErrors: action.payload.error.data?.fields ?? null,
            loading: false,
            isAttachmentEdited: false,
          };
        case ARCHIVE_ATTACHMENT:
          return {
            ...state,
            error: action.payload.error,
            loading: false,
            isAttachmentArchived: false,
          };
        case RESTORE_ATTACHMENT:
          return {
            ...state,
            error: action.payload.error,
            loading: false,
            isAttachmentRestored: false,
          };
        default:
          return state;
      }
    default:
      return state;
  }
};

export default Attachments;
