import React, { useContext, useEffect, useState } from "react";
import { Form, Card, CardBody, FormText, FormGroup, Spinner } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import OffcanvasRight from "Components/Entity/OffcanvasRight";
import FormDateField from "Components/Entity/FormDateField";
import { getString } from "Components/Common/FormattedString";
import { toast_success, toast_error } from "helpers/toast_helper";
import { markAsPaid } from "helpers/API/core-service/cs_backend_helper";
import { format } from "date-fns";
import { externalizeDate } from "helpers/utils";
import { FormSelect } from "Components/Entity/FormSelect";
import { useDispatch, useSelector } from "react-redux";
import { getPointOfSellListData } from "store/pointOfSell/action";
import { ApplicationDetailsContext } from "pages/Applications/ApplicationDetails/ApplicationDetails";

const MarkAsPaidOffcanvas = ({
  isOpen,
  toggle,
  selectedInstallment,
  applicationId,
}) => {
  const { applicationData, updateApplicationInstallments } = useContext(
    ApplicationDetailsContext,
  );

  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { pointOfSellData, loading: posLoading } = useSelector(
    (state) => state.PointOfSell,
  );

  useEffect(() => {
    dispatch(getPointOfSellListData());
  }, [dispatch]);

  const today = format(new Date(), "yyyy-MM-dd");

  const validation = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    initialValues: {
      paymentDate: today,
      pointOfSell:
        pointOfSellData.find((pos) => pos.name.includes("Manual transfer"))
          ?.id || "",
    },
    validationSchema: Yup.object({
      paymentDate: Yup.string().required(getString("select_payment_date")),
      pointOfSell: Yup.string().required(getString("select_point_of_sell")),
    }),
    onSubmit: (values) => {
      setLoading(true);
      markAsPaid(applicationId, selectedInstallment.id, {
        paymentDate: externalizeDate(values.paymentDate),
        pointOfSell: values.pointOfSell,
      })
        .then(() => {
          toast_success(getString("payment_marked_as_paid"));
          const prevInstallments = applicationData.applicationInstallments;
          updateApplicationInstallments(
            prevInstallments.map((installment) =>
              installment.id === selectedInstallment.id
                ? { ...installment, disabled: true }
                : installment,
            ),
          );

          toggle();
        })
        .catch((error) => {
          console.error("Error marking payment as paid:", error);
          toast_error(getString("error_marking_payment_as_paid"));
        })
        .finally(() => setLoading(false));
    },
  });

  const pointOfSellOptions = pointOfSellData.map((pos) => ({
    value: pos.id,
    label: `${pos.paymentMethod.name}`,
  }));

  return (
    <OffcanvasRight
      isOpen={isOpen}
      toggle={toggle}
      title={getString("mark_as_paid")}
      formId="mark-as-paid-form"
      validationRule={!!validation.values.paymentDate}
      buttonLabel={getString("confirm")}
      loading={loading}
      onSubmit={validation.handleSubmit}
    >
      <Form
        id="mark-as-paid-form"
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
          return false;
        }}
        action="#"
      >
        <Card>
          <CardBody className="card-body">
            {posLoading ? (
              <div className="d-flex justify-content-center align-items-center">
                <Spinner color="primary" />
              </div>
            ) : (
              <>
                <FormGroup>
                  <FormSelect
                    name="pointOfSell"
                    label={getString("point_of_sell")}
                    value={pointOfSellOptions.find(
                      (option) =>
                        option.value === validation.values.pointOfSell,
                    )}
                    defaultValue={pointOfSellOptions.find(
                      (option) =>
                        option.value === validation.values.pointOfSell,
                    )}
                    onChange={(selectedOption) =>
                      validation.setFieldValue(
                        "pointOfSell",
                        selectedOption.value,
                      )
                    }
                    options={pointOfSellOptions}
                    error={validation.errors.pointOfSell}
                    touched={validation.touched.pointOfSell}
                  />
                </FormGroup>
                <FormDateField
                  label={getString("payment_date")}
                  name="paymentDate"
                  value={validation.values.paymentDate}
                  onChange={validation.handleChange}
                  error={validation.errors.paymentDate}
                  touched={validation.touched.paymentDate}
                />
                <FormText
                  color="muted"
                  className="text-center mt-2"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "4px",
                    justifyContent: "center",
                  }}
                >
                  <i className="ri-error-warning-line"></i>{" "}
                  {getString("make_sure_you_entered_proper_date")}
                </FormText>
              </>
            )}
          </CardBody>
        </Card>
      </Form>
    </OffcanvasRight>
  );
};

export default MarkAsPaidOffcanvas;
