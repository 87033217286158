import React from "react";
import "./ParticipantsTable.scss";

import { getString } from "Components/Common/FormattedString";
import { Progress } from "reactstrap";
import { ParticipantEquation } from "Components/Common/ParticipantEquation/ParticipantEquation";

export const ParticipantsTable = ({ data }) => {
  const columns = [
    {
      header: getString("packet_label"),
      render: (participant) => getString(participant.status),
    },
    {
      header: getString("participants"),
      render: (participant) => (
        <span className={`participants-table__type--${participant.status}`}>
          {participant.count}
        </span>
      ),
    },
    {
      header: getString("progress"),
      style: { width: "25%" },
      render: () => <></>,
    },
  ];

  return (
    <table className="participants-table table table-hover table-nowrap align-middle mb-0">
      <thead className="table-light">
        <tr className="text-muted">
          {columns.map((column, index) => (
            <th key={index} scope="col" style={column.style}>
              {column.header}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.length === 0 ? (
          <tr>
            <td colSpan={columns.length} className="text-start text-muted">
              No participants available
            </td>
          </tr>
        ) : (
          data.map((packet, index) => (
            <React.Fragment key={`${packet.programPacketId}-${index}`}>
              <tr className="participants-table__row participants-table__row--group-header">
                <td>
                  <div className="d-flex align-items-center gap-2">
                    <span className="fw-medium">
                      {packet.programPacketLabel}
                    </span>
                  </div>
                </td>
                <td>
                  <ParticipantEquation summary={packet.summary} />
                </td>
                <td>
                  <Progress
                    value={packet.summary.occupancyFraction}
                    color="danger"
                    className="animated-progress custom-progress progress-label"
                  >
                    <div className="label">
                      {packet.summary.occupancyFraction}%
                    </div>
                  </Progress>
                </td>
              </tr>
            </React.Fragment>
          ))
        )}
      </tbody>
    </table>
  );
};
