// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.attachment-action-buttons {
  display: flex;
  gap: 8px;
  justify-content: flex-end;
  align-items: center;
}
.attachment-action-buttons i {
  height: 15px;
}
.attachment-action-buttons .btn-soft-primary {
  color: #007bff;
  background: rgba(0, 123, 255, 0.15);
}
.attachment-action-buttons .btn-soft-primary:hover {
  color: white;
  background: #007bff;
}
.attachment-action-buttons .btn-soft-secondary {
  color: #6c757d;
  background: rgba(108, 117, 125, 0.15);
}
.attachment-action-buttons .btn-soft-secondary:hover {
  color: white;
  background: #6c757d;
}
.attachment-action-buttons:empty {
  display: none;
}

.file-preview-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.file-preview-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  max-width: 90%;
  max-height: 90%;
  overflow: auto;
  position: relative;
}

.file-preview-content .btn {
  position: absolute;
  top: 10px;
  right: 10px;
}

.ReactModal__Overlay {
  z-index: 5555 !important;
}

.no-overlay {
  overflow: hidden;
}`, "",{"version":3,"sources":["webpack://./src/pages/IpParticipants/components/AttachmentActionButtons/AttachmentActionButtons.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,QAAA;EACA,yBAAA;EACA,mBAAA;AACF;AACE;EACE,YAAA;AACJ;AAEE;EACE,cAAA;EACA,mCAAA;AAAJ;AAEI;EACE,YAAA;EACA,mBAAA;AAAN;AAIE;EACE,cAAA;EACA,qCAAA;AAFJ;AAII;EACE,YAAA;EACA,mBAAA;AAFN;AAME;EACE,aAAA;AAJJ;;AAQA;EACE,eAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;EACA,8BAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,aAAA;AALF;;AAQA;EACE,iBAAA;EACA,aAAA;EACA,kBAAA;EACA,cAAA;EACA,eAAA;EACA,cAAA;EACA,kBAAA;AALF;;AAQA;EACE,kBAAA;EACA,SAAA;EACA,WAAA;AALF;;AAQA;EACE,wBAAA;AALF;;AAQA;EACE,gBAAA;AALF","sourcesContent":[".attachment-action-buttons {\n  display: flex;\n  gap: 8px;\n  justify-content: flex-end;\n  align-items: center;\n\n  i {\n    height: 15px;\n  }\n\n  .btn-soft-primary {\n    color: #007bff;\n    background: rgba(0, 123, 255, 0.15);\n\n    &:hover {\n      color: white;\n      background: #007bff;\n    }\n  }\n\n  .btn-soft-secondary {\n    color: #6c757d;\n    background: rgba(108, 117, 125, 0.15);\n\n    &:hover {\n      color: white;\n      background: #6c757d;\n    }\n  }\n\n  &:empty {\n    display: none;\n  }\n}\n\n.file-preview-modal {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background: rgba(0, 0, 0, 0.5);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  z-index: 1000;\n}\n\n.file-preview-content {\n  background: white;\n  padding: 20px;\n  border-radius: 5px;\n  max-width: 90%;\n  max-height: 90%;\n  overflow: auto;\n  position: relative;\n}\n\n.file-preview-content .btn {\n  position: absolute;\n  top: 10px;\n  right: 10px;\n}\n\n.ReactModal__Overlay {\n  z-index: 5555 !important;\n}\n\n.no-overlay {\n  overflow: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
