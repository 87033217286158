import React, { useState, useCallback, useContext } from "react";
import { Modal } from "reactstrap";
import ConfirmRestoreBody from "Components/Entity/ConfirmRestoreBody";
import { getString } from "Components/Common/FormattedString";
import { restoreDocumentOfIp } from "helpers/API/core-service/cs_backend_helper";
import { toast_success } from "helpers/toast_helper";
import { IpDetailsContext } from "../../IpParticipantDetails";

const RestoreDocument = ({
  internationalParticipantId,
  documentId,
  visibility,
  toggle,
}) => {
  const { refreshIpData } = useContext(IpDetailsContext);
  const [loading, setLoading] = useState(false);

  const restoreDocumentEntry = useCallback(() => {
    setLoading(true);
    restoreDocumentOfIp(internationalParticipantId, documentId)
      .then(() => {
        toast_success(getString("document_restored"));
        refreshIpData();
        toggle(false);
      })
      .catch((e) => console.error(e))
      .finally(() => setLoading(false));
  }, [internationalParticipantId, documentId, refreshIpData, toggle]);

  return (
    <Modal
      isOpen={visibility}
      toggle={() => {
        toggle();
      }}
      backdrop={"static"}
      id="staticBackdrop"
      centered
    >
      <ConfirmRestoreBody
        entityName={getString("document_sm")}
        toggle={toggle}
        restoreAction={restoreDocumentEntry}
        loading={loading}
      />
    </Modal>
  );
};

export default RestoreDocument;
