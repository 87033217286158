import {
  API_RESPONSE_SUCCESS,
  API_RESPONSE_ERROR,
  GET_PROGRAM_PACKET_TEMPLATE_LIST_DATA,
  POST_PROGRAM_PACKET_TEMPLATE,
  EDIT_PROGRAM_PACKET_TEMPLATE,
  ARCHIVE_PROGRAM_PACKET_TEMPLATE,
  RESTORE_PROGRAM_PACKET_TEMPLATE,
} from "./actionType";

export const INIT_STATE = {
  programPacketTemplatesData: [],
  selectedBusinessUnit: null,
  formValidationErrors: {},
  error: null,
  loading: false,
  isProgramPacketTemplateAdded: false,
  isProgramPacketTemplateEdited: false,
  isProgramPacketTemplateArchived: false,
  isProgramPacketTemplateRestored: false,
};

const ProgramPacketTemplates = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PROGRAM_PACKET_TEMPLATE_LIST_DATA:
    case POST_PROGRAM_PACKET_TEMPLATE:
    case EDIT_PROGRAM_PACKET_TEMPLATE:
    case ARCHIVE_PROGRAM_PACKET_TEMPLATE:
    case RESTORE_PROGRAM_PACKET_TEMPLATE:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case GET_PROGRAM_PACKET_TEMPLATE_LIST_DATA:
          return {
            ...state,
            programPacketTemplatesData: action.payload.data,
            isProgramPacketTemplateAdded: false,
            isProgramPacketTemplateEdited: false,
            isProgramPacketTemplateArchived: false,
            isProgramPacketTemplateRestored: false,
            loading: false,
            error: null,
          };
        case POST_PROGRAM_PACKET_TEMPLATE:
          return {
            ...state,
            loading: false,
            isProgramPacketTemplateAdded: true,
            error: null,
          };
        case EDIT_PROGRAM_PACKET_TEMPLATE:
          return {
            ...state,
            loading: false,
            isProgramPacketTemplateEdited: true,
            error: null,
          };
        case ARCHIVE_PROGRAM_PACKET_TEMPLATE:
          return {
            ...state,
            loading: false,
            isProgramPacketTemplateArchived: true,
            error: null,
          };
        case RESTORE_PROGRAM_PACKET_TEMPLATE:
          return {
            ...state,
            loading: false,
            isProgramPacketTemplateRestored: true,
            error: null,
          };
        default:
          return state;
      }
    case API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case GET_PROGRAM_PACKET_TEMPLATE_LIST_DATA:
          return {
            ...state,
            errorMsg: action.payload.data,
            error: action.payload.error,
            isProgramPacketTemplateAdded: false,
            isProgramPacketTemplateEdited: false,
            isProgramPacketTemplateArchived: false,
            isProgramPacketTemplateRestored: false,
            loading: false,
          };
        case POST_PROGRAM_PACKET_TEMPLATE:
          return {
            ...state,
            error: action.payload.error,
            formValidationErrors: action.payload.error.data?.fields ?? null,
            loading: false,
            isProgramPacketTemplateAdded: false,
          };
        case EDIT_PROGRAM_PACKET_TEMPLATE:
          return {
            ...state,
            error: action.payload.error,
            formValidationErrors: action.payload.error.data?.fields ?? null,
            loading: false,
            isProgramPacketTemplateEdited: false,
          };
        case ARCHIVE_PROGRAM_PACKET_TEMPLATE:
          return {
            ...state,
            error: action.payload.error,
            loading: false,
            isProgramPacketTemplateArchived: false,
          };
        case RESTORE_PROGRAM_PACKET_TEMPLATE:
          return {
            ...state,
            error: action.payload.error,
            loading: false,
            isProgramPacketTemplateRestored: false,
          };
        default:
          return state;
      }
    default:
      return state;
  }
};

export default ProgramPacketTemplates;
