// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `th.table-header.pp-summary-header {
  background-color: #bf9002;
  color: #fff;
}

th.table-header.ip-summary-header {
  background-color: #073763;
  color: #fff;
}

th.table-header.ip-ns-summary-header {
  background-color: #3d85c6;
  color: #fff;
}

th.table-header.ip-nns-summary-header {
  background-color: #e69138;
  color: #fff;
}

th.table-header.ip-esl-summary-header {
  background-color: #a64d79;
  color: #fff;
}

td.pp-summary-cell-wrap {
  background-color: rgba(191, 144, 2, 0.05);
  color: inherit;
}

td.ip-ns-summary-cell-wrap {
  background-color: rgba(61, 133, 198, 0.05);
  color: inherit;
}

td.ip-nns-summary-cell-wrap {
  background-color: rgba(230, 145, 56, 0.05);
  color: inherit;
}

td.ip-esl-summary-cell-wrap {
  background-color: rgba(166, 77, 121, 0.05);
  color: inherit;
}

td.ip-summary-cell-wrap {
  background-color: rgba(7, 55, 99, 0.05);
  color: inherit;
}`, "",{"version":3,"sources":["webpack://./src/pages/ProgramPackets/ProgramPackets.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,WAAA;AACF;;AAEA;EACE,yBAAA;EACA,WAAA;AACF;;AAEA;EACE,yBAAA;EACA,WAAA;AACF;;AAEA;EACE,yBAAA;EACA,WAAA;AACF;;AAEA;EACE,yBAAA;EACA,WAAA;AACF;;AAEA;EACE,yCAAA;EACA,cAAA;AACF;;AAEA;EACE,0CAAA;EACA,cAAA;AACF;;AAEA;EACE,0CAAA;EACA,cAAA;AACF;;AAEA;EACE,0CAAA;EACA,cAAA;AACF;;AAEA;EACE,uCAAA;EACA,cAAA;AACF","sourcesContent":["th.table-header.pp-summary-header {\n  background-color: #bf9002;\n  color: #fff;\n}\n\nth.table-header.ip-summary-header {\n  background-color: #073763;\n  color: #fff;\n}\n\nth.table-header.ip-ns-summary-header {\n  background-color: #3d85c6;\n  color: #fff;\n}\n\nth.table-header.ip-nns-summary-header {\n  background-color: #e69138;\n  color: #fff;\n}\n\nth.table-header.ip-esl-summary-header {\n  background-color: #a64d79;\n  color: #fff;\n}\n\ntd.pp-summary-cell-wrap {\n  background-color: rgb(191 144 2 / 5%);\n  color: inherit;\n}\n\ntd.ip-ns-summary-cell-wrap {\n  background-color: rgb(61 133 198 / 5%);\n  color: inherit;\n}\n\ntd.ip-nns-summary-cell-wrap {\n  background-color: rgb(230 145 56 / 5%);\n  color: inherit;\n}\n\ntd.ip-esl-summary-cell-wrap {\n  background-color: rgb(166 77 121 / 5%);\n  color: inherit;\n}\n\ntd.ip-summary-cell-wrap {\n  background-color: rgb(7 55 99 / 5%);\n  color: inherit;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
