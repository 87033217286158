import React, { useContext, useEffect, useState, useRef } from "react";
import "./MailPreviewModal.scss";
import { FormattedString, getString } from "Components/Common/FormattedString";
import { SimplestModal } from "Components/Common/SimplestModal/SimplestModal";
import { getApplicationActivityEmail } from "helpers/API/core-service/cs_backend_helper";
import { ApplicationDetailsContext } from "pages/Applications/ApplicationDetails/ApplicationDetails";
import { PreloaderWrap } from "Components/Common/Preloader/Preloader";

export const MailPreviewModal = ({ emailId, setVisible, visible, width }) => {
  const { applicationData } = useContext(ApplicationDetailsContext);
  const [loading, setLoading] = useState(true);
  const [emailContent, setEmailContent] = useState(null);
  const iframeRef = useRef(null);

  useEffect(() => {
    getApplicationActivityEmail(applicationData.id, emailId)
      .then((r) => {
        setEmailContent(r.emailContent);
        setLoading(false);
      })
      .catch((e) => console.error(e));
  }, [applicationData.id, emailId]);

  useEffect(() => {
    if (iframeRef.current && emailContent) {
      const doc = iframeRef.current.contentDocument;
      doc.open();
      doc.write(emailContent);
      doc.close();
    }
  }, [emailContent]);

  return (
    <SimplestModal
      visible={visible}
      setVisible={setVisible}
      id="mail-preview-modal"
      title={getString("mail_preview")}
      size="lg"
      width={width}
    >
      {loading ? (
        <PreloaderWrap />
      ) : emailContent ? (
        <iframe
          ref={iframeRef}
          style={{ width: "100%", height: "100%", border: "none" }}
          title="Mail Preview"
        />
      ) : (
        <div className="mail-preview-modal__placeholder">
          <FormattedString id="no_mail_preview_available" />
        </div>
      )}
    </SimplestModal>
  );
};
