import React, { useEffect, useMemo, useRef } from "react";
import { useSearchParams, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getIpApplicationsListData } from "../../store/ipApplications/action";
import TableContainerExternalPagination from "Components/Common/TableContainerExternalPagination";
import { getBusinessUnitListData } from "store/actions";
import { format } from "date-fns";
import "./IpApplicationsList.scss";
import { FormattedString, getString } from "Components/Common/FormattedString";
import { getIpStatusBadgeClass } from "models/ipApplications";
import { AssignIpCanvas } from "./modals/AssignIpCanvas";

const IpApplicationsList = ({
  assignIpCanvasVisible,
  setAssignIpCanvasVisible,
}) => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const isInitialRender = useRef(true);

  const {
    ipApplicationsData,
    count,
    currentPage,
    totalPages,
    pageSize,
    loading,
    error,
  } = useSelector((rootState) => rootState.IpApplications);

  const pageNumber = searchParams.get("page") || "1";
  const globalFilter = searchParams.get("searchTerm") || "";

  useEffect(() => {
    if (isInitialRender.current) {
      dispatch(getBusinessUnitListData());
      isInitialRender.current = false;
    }
    dispatch(getIpApplicationsListData(pageNumber, globalFilter));
  }, [searchParams]);

  const columns = useMemo(
    () => [
      {
        Header: getString("name"),
        accessor: (d) => (
          <span className="text-decoration-none">
            {`${d.firstName || ""} ${d.lastName || ""}`.trim() || "-"}
          </span>
        ),
        Cell: ({ row }) => (
          <Link
            to={`/ip-participants/${row.original.internationalParticipantId}`}
            className="text-decoration-none"
          >
            {`${row.original.firstName || ""} ${row.original.lastName || ""}`.trim() ||
              "-"}
          </Link>
        ),
      },
      {
        Header: getString("program"),
        accessor: "programName",
        Cell: ({ row }) => (
          <Link to={`/program/${row.original.programId}`}>
            {row.original.programName || "-"}
          </Link>
        ),
      },
      {
        Header: getString("package_name"),
        accessor: "programPacketName",
        Cell: ({ value }) => <span>{value || "-"}</span>,
      },
      {
        Header: getString("ip_type"),
        accessor: "type",
        Cell: ({ value }) => (
          <span className="badge">
            <FormattedString id={value} />
          </span>
        ),
      },
      {
        Header: getString("status"),
        accessor: "applicationStatus",
        Cell: ({ value }) => (
          <span
            className={`badge ${getIpStatusBadgeClass(value.toLowerCase())}`}
          >
            <FormattedString id={value} />
          </span>
        ),
      },
      {
        Header: getString("hotel"),
        accessor: "hotel.name",
        Cell: ({ value }) => <span>{value || "N/A"}</span>,
      },
      {
        Header: getString("program_dates"),
        accessor: (d) => `${d.programStartDate} - ${d.programEndDate}`,
        Cell: ({ row }) => (
          <div className="text-nowrap">
            {`${format(new Date(row.original.programStartDate), "yyyy-MM-dd")} - ${format(
              new Date(row.original.programEndDate),
              "yyyy-MM-dd",
            )}`}
          </div>
        ),
      },
      {
        Header: getString("program_status"),
        accessor: "programStatus",
        Cell: ({ value }) => (
          <span
            className={`badge ${getIpStatusBadgeClass(value.toLowerCase())}`}
          >
            <FormattedString id={value} />
          </span>
        ),
      },
    ],
    [],
  );

  const customSetGlobalFilter = (value) => {
    const newSearchParams = new URLSearchParams(searchParams);
    if (value) {
      newSearchParams.set("searchTerm", value);
    } else {
      newSearchParams.delete("searchTerm");
    }
    newSearchParams.set("page", "1");
    setSearchParams(newSearchParams);
  };

  const handlePageChange = (page) => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set("page", page.toString());
    setSearchParams(newSearchParams);
  };

  return (
    <div className="ip-applications-list">
      <TableContainerExternalPagination
        columns={columns || []}
        count={count}
        currentPage={currentPage}
        totalPages={totalPages}
        pageSize={pageSize}
        loading={loading}
        globalFilter={globalFilter}
        setGlobalFilter={customSetGlobalFilter}
        onPageChange={handlePageChange}
        data={ipApplicationsData || []}
        isGlobalFilter={true}
        isBordered={false}
        className="custom-header-css table align-middle table-nowrap"
        tableClassName="table-centered align-middle table-nowrap mb-0"
        theadClassName="text-muted table-light"
        SearchPlaceholder={getString("ip_applications_search_for")}
        error={error}
        onRetry={() =>
          dispatch(getIpApplicationsListData(pageNumber, globalFilter))
        }
      />
      {assignIpCanvasVisible && (
        <AssignIpCanvas
          visible={assignIpCanvasVisible}
          setVisible={setAssignIpCanvasVisible}
          refreshData={() =>
            dispatch(getIpApplicationsListData(pageNumber, globalFilter))
          }
        />
      )}
    </div>
  );
};

export { IpApplicationsList };
