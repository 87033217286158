import {
  API_RESPONSE_SUCCESS,
  API_RESPONSE_ERROR,
  GET_COORDINATOR_LIST_DATA,
  POST_COORDINATOR,
  EDIT_COORDINATOR,
  ARCHIVE_COORDINATOR,
  RESTORE_COORDINATOR,
} from "./actionType";

export const INIT_STATE = {
  coordinatorsData: [],
  formValidationErrors: {},
  error: null,
  loading: false,
  isCoordinatorAdded: false,
  isCoordinatorEdited: false,
  isCoordinatorArchived: false,
  isCoordinatorRestored: false,
};

const Coordinators = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_COORDINATOR_LIST_DATA:
    case POST_COORDINATOR:
    case EDIT_COORDINATOR:
    case ARCHIVE_COORDINATOR:
    case RESTORE_COORDINATOR:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case GET_COORDINATOR_LIST_DATA:
          return {
            ...state,
            coordinatorsData: action.payload.data,
            isCoordinatorAdded: false,
            isCoordinatorEdited: false,
            isCoordinatorArchived: false,
            isCoordinatorRestored: false,
            loading: false,
            error: null,
          };
        case POST_COORDINATOR:
          return {
            ...state,
            loading: false,
            isCoordinatorAdded: true,
            error: null,
          };
        case EDIT_COORDINATOR:
          return {
            ...state,
            loading: false,
            isCoordinatorEdited: true,
            error: null,
          };
        case ARCHIVE_COORDINATOR:
          return {
            ...state,
            loading: false,
            isCoordinatorArchived: true,
            error: null,
          };
        case RESTORE_COORDINATOR:
          return {
            ...state,
            loading: false,
            isCoordinatorRestored: true,
            error: null,
          };
        default:
          return state;
      }
    case API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case GET_COORDINATOR_LIST_DATA:
          return {
            ...state,
            errorMsg: action.payload.data,
            error: action.payload.error,
            isCoordinatorAdded: false,
            isCoordinatorEdited: false,
            isCoordinatorArchived: false,
            isCoordinatorRestored: false,
            loading: false,
          };
        case POST_COORDINATOR:
          return {
            ...state,
            error: action.payload.error,
            formValidationErrors: action.payload.error.data?.fields ?? null,
            loading: false,
            isCoordinatorAdded: false,
          };
        case EDIT_COORDINATOR:
          return {
            ...state,
            error: action.payload.error,
            formValidationErrors: action.payload.error.data?.fields ?? null,
            loading: false,
            isCoordinatorEdited: false,
          };
        case ARCHIVE_COORDINATOR:
          return {
            ...state,
            error: action.payload.error,
            loading: false,
            isCoordinatorArchived: false,
          };
        case RESTORE_COORDINATOR:
          return {
            ...state,
            error: action.payload.error,
            loading: false,
            isCoordinatorRestored: false,
          };
        default:
          return state;
      }
    default:
      return state;
  }
};

export default Coordinators;
