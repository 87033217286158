import React from "react";
import { Label } from "reactstrap";
import "./FormSwitch.scss";

const FormSwitch = ({
  label,
  name,
  checked,
  onChange,
  disabled = false,
  noMargin = false,
}) => {
  const handleClick = (e) => {
    if (!disabled) {
      onChange({ target: { checked: !checked, name } });
    }
  };

  return (
    <div
      className={`form-switch ${noMargin ? "" : "mb-3"} ${disabled ? "disabled" : ""}`}
      onClick={handleClick}
      style={{ cursor: disabled ? "not-allowed" : "pointer" }}
    >
      <Label
        htmlFor={name}
        className="form-label d-flex gap-1"
        style={{ pointerEvents: "none", marginBottom: 0 }}
      >
        {label}
      </Label>
      <div className="switch">
        <input
          type="checkbox"
          id={name}
          name={name}
          checked={checked}
          onChange={(e) => onChange(e, name)}
          disabled={disabled}
          style={{ pointerEvents: "none" }}
        />
        <span className="slider round"></span>
      </div>
    </div>
  );
};

export default FormSwitch;
