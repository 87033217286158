import {
  API_RESPONSE_SUCCESS,
  API_RESPONSE_ERROR,
  GET_IP_APPLICATIONS_LIST_DATA,
  SET_CURRENT_PAGE,
  POST_IP_APPLICATION,
} from "./actionType";

export const ipApplicationsApiSuccess = (actionType, data) => ({
  type: API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

export const ipApplicationsApiError = (actionType, error) => ({
  type: API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getIpApplicationsListData = (page = 1, searchTerm = "") => ({
  type: GET_IP_APPLICATIONS_LIST_DATA,
  payload: { page, searchTerm },
});

export const setCurrentPage = (page) => ({
  type: SET_CURRENT_PAGE,
  payload: page,
});

export const postIpApplication = (data, onSuccess) => ({
  type: POST_IP_APPLICATION,
  payload: { data, onSuccess },
});
