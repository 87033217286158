import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  currentLocation,
  getDefaultFilterType,
  getItemUuid,
  getLocation,
  isValidUuid,
  parentLocation,
} from "helpers/utils";
import { useNavigate } from "react-router-dom";
import { getMeetingPointListData } from "../../store/meetingPoints/action";
import ActionButtons from "../../Components/Entity/ActionButtons/ActionButtons";
import TableContainer from "../../Components/Common/TableContainerReactTable";
import EditMeetingPoint from "./EditMeetingPoint";
import EntityListArchive from "../../Components/Entity/EntityListArchive";
import ArchiveMeetingPoint from "./ArchiveMeetingPoint";
import RestoreMeetingPoint from "./RestoreMeetingPoint";
import { consts } from "../../config";
import { filterListByType } from "../../helpers/entity/list_helper";
import { FormattedString, getString } from "Components/Common/FormattedString";
import { MeetingPointDetails } from "./MeetingPointDetails";
import { MapUrlCell } from "Components/Common/Cells/MapUrlCell";

const MeetingPointsList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { meetingPointsData, loading, error } = useSelector(
    (rootState) => rootState.MeetingPoints,
  );

  useEffect(() => {
    dispatch(getMeetingPointListData());
  }, [dispatch]);

  const [editModalVisible, setEditModalVisible] = useState(false);
  const [singleMeetingPointData, setSingleMeetingPointData] = useState();
  const [filterType, setFilterType] = useState(getDefaultFilterType());
  const [filteredData, setFilteredData] = useState(
    filterListByType(meetingPointsData, getDefaultFilterType()),
  );

  const toggleEditModal = (meetingPoint, direction = false) => {
    setSingleMeetingPointData(meetingPoint);
    setEditModalVisible(direction);
  };

  useEffect(() => {
    toggleFilter(filterType);
  }, [meetingPointsData]);

  const toggleFilter = (type) => {
    setFilteredData(filterListByType(meetingPointsData, type));
    setFilterType(type);
  };

  const [itemId, setModalItemId] = useState(getItemUuid());
  const [archiveModalVisible, setArchiveModalVisible] = useState(false);
  const toggleArchiveModal = (id) => {
    setModalItemId(id);
    setArchiveModalVisible((p) => !p);
  };

  const [restoreModalVisible, setRestoreModalVisible] = useState(false);
  const toggleRestoreModal = (id) => {
    setModalItemId(id);
    setRestoreModalVisible((p) => !p);
  };

  const [detailsModalVisible, setDetailsModalVisible] = useState(
    isValidUuid(getLocation(3)) || isValidUuid(getLocation(2)),
  );

  const toggleDetailsModal = (state, id) => {
    setDetailsModalVisible(state);
    state === false
      ? navigate(parentLocation())
      : navigate(`${currentLocation()}/${id}`);
    setModalItemId(id);
  };

  const columns = useMemo(
    () => [
      {
        Header: getString("address"),
        searchValue: (cellProps) => JSON.stringify(cellProps),
        accessor: (cellProps) => `${cellProps.city}, ${cellProps.address}`,
        Cell: (cellProps) => (
          <span>
            <strong>{cellProps.row.original.city}</strong>,{" "}
            {cellProps.row.original.address}
          </span>
        ),
      },
      {
        Header: getString("mapUrl"),
        accessor: (cellProps) => <MapUrlCell mapUrl={cellProps.mapUrl} />,
      },
      {
        id: "Actions",
        Header: () => (
          <div
            style={{
              textAlign: "right",
            }}
          >
            <FormattedString id="actions" />
          </div>
        ),
        disableSortBy: true,
        accessor: (cellProps) => (
          <ActionButtons
            onEdit={() => toggleEditModal(cellProps, true)}
            onArchive={() => toggleArchiveModal(cellProps.id)}
            onShowDetails={() => toggleDetailsModal(true, cellProps.id)}
            isRestorable={cellProps.status === "archived"}
            onRestore={() => toggleRestoreModal(cellProps.id)}
            itemId={cellProps.id}
            permissionPrefix="MEETING_POINT"
          />
        ),
      },
    ],
    [],
  );

  return (
    <>
      <EntityListArchive toggleFilter={toggleFilter} />
      <TableContainer
        columns={columns || []}
        data={filteredData || []}
        isPagination={true}
        isGlobalFilter={true}
        isBordered={false}
        customPageSize={consts.GLOBAL_PAGE_SIZE}
        className="custom-header-css table align-middle table-nowrap"
        tableClassName="table-centered align-middle table-nowrap mb-0"
        theadClassName="text-muted table-light"
        SearchPlaceholder={getString("meeting_points_search_for")}
        error={error}
        loading={loading}
        onRetry={() => dispatch(getMeetingPointListData())}
        preventLoading={
          editModalVisible ||
          archiveModalVisible ||
          restoreModalVisible ||
          detailsModalVisible
        }
      />
      {editModalVisible && (
        <EditMeetingPoint
          meetingPoint={singleMeetingPointData}
          visibility={editModalVisible}
          toggle={toggleEditModal}
        />
      )}
      {archiveModalVisible && (
        <ArchiveMeetingPoint
          itemId={itemId}
          visibility={archiveModalVisible}
          toggle={toggleArchiveModal}
        />
      )}
      {restoreModalVisible && (
        <RestoreMeetingPoint
          itemId={itemId}
          visibility={restoreModalVisible}
          toggle={toggleRestoreModal}
        />
      )}
      {detailsModalVisible && (
        <MeetingPointDetails
          itemId={itemId}
          toggle={toggleDetailsModal}
          visible={detailsModalVisible}
        />
      )}
    </>
  );
};

export { MeetingPointsList };
