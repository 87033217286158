import React, { useContext, useEffect, useState } from "react";
import * as Yup from "yup";
import { FormSelect } from "Components/Entity/FormSelect";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, Form } from "reactstrap";
import {
  handleInputChange,
  handleSelectChange,
} from "helpers/validation_helper";
import { useFormik } from "formik";
import FormNumberField from "Components/Entity/FormNumberField";
import { getString } from "Components/Common/FormattedString";
import { getProgramPacketTemplateListData } from "store/programPacketTemplates/action";
import { updateProgramPacket } from "helpers/API/core-service/cs_backend_helper";
import { ProgramDetailsContext } from "../../ProgramDetails";
import { toast_error, toast_success } from "helpers/toast_helper";
import OffcanvasRight from "Components/Entity/OffcanvasRight";

export const EditProgramPacketCanvas = ({
  visible,
  setVisible,
  programPacketToEdit,
}) => {
  const { programPacketTemplatesData } = useSelector(
    (rootState) => rootState.ProgramPacketTemplates,
  );

  const dispatch = useDispatch();

  const { programData, refreshProgramData } = useContext(ProgramDetailsContext);

  const [loading, setLoading] = useState(false);

  const programPacketOptions = programPacketTemplatesData
    .filter((item) => item?.status !== "archived")
    .map((pp) => {
      return { value: pp.id, label: pp.label };
    });

  const programPacket = programData.programPackets.find(
    (p) => p.id === programPacketToEdit,
  );

  const validation = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    initialValues: {
      ppCapacity: programPacket?.ppCapacity || "",
      ipCapacities: {
        ns: programPacket?.ipCapacities?.ns || "",
        non_ns_ip: programPacket?.ipCapacities?.non_ns_ip || "",
        esl: programPacket?.ipCapacities?.esl || "",
      },
      programPacketTemplateId:
        programPacketOptions.find((r) => r.label === programPacket.label)
          ?.value || "",
      waitlistAvailable: programPacket?.waitlistAvailable || false, // Added waitlistAvailable field
    },
    validationSchema: Yup.object({
      ppCapacity: Yup.string().required(getString("pp_capacity_required")),
      ipCapacities: Yup.object({
        ns: Yup.string().required(getString("ns_capacity_required")),
        non_ns_ip: Yup.string().required(
          getString("non_ns_ip_capacity_required"),
        ),
        esl: Yup.string().required(getString("esl_capacity_required")),
      }),
      programPacketTemplateId: Yup.string().required(
        getString("program_packet_template_required"),
      ),
    }),
    onSubmit: (values) => {
      setLoading(true);
      updateProgramPacket(programData.id, programPacketToEdit, values)
        .then(() => {
          toast_success(getString("program_packet_updated"));
          setVisible(false);
          refreshProgramData();
        })
        .catch((e) => toast_error(e.message))
        .finally(() => setLoading(false));
    },
  });

  useEffect(() => {
    dispatch(getProgramPacketTemplateListData());
  }, [dispatch]);

  return (
    <OffcanvasRight
      isOpen={visible}
      toggle={() => setVisible(false)}
      title={getString("program_packet_edit")}
      formId="edit-program-packet-form"
      validationRule={true}
      buttonLabel={getString("save")}
      loading={loading}
    >
      <Card>
        <CardBody className="card-body">
          <Form
            id="edit-program-packet-form"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
            action="#"
          >
            <FormSelect
              key={validation.values.type}
              name="programPacketTemplateId"
              label={getString("programPacketTemplate")}
              value={validation.values.programPacketTemplateId}
              defaultValue={programPacketOptions.find(
                (r) => r.label === programPacket.label,
              )}
              onChange={(selectedOption) =>
                handleSelectChange(
                  validation,
                  selectedOption,
                  "programPacketTemplateId",
                )
              }
              isMulti={false}
              options={programPacketOptions}
              error={validation.errors["programPacketTemplateId"]}
            />
            <hr />
            <FormNumberField
              label={getString("ppCapacity")}
              name="ppCapacity"
              value={validation.values.ppCapacity}
              onChange={(e) => handleInputChange(validation, e, "ppCapacity")}
              error={validation.errors.ppCapacity}
              touched={validation.touched.ppCapacity}
            />
            <hr />
            <h5>{getString("ipCapacities")}</h5>
            <FormNumberField
              label={getString("nsCapacity")}
              name="ipCapacities.ns"
              value={validation.values.ipCapacities.ns}
              onChange={(e) =>
                handleInputChange(validation, e, "ipCapacities.ns")
              }
              error={validation.errors.ipCapacities?.ns}
              touched={validation.touched.ipCapacities?.ns}
            />
            <FormNumberField
              label={getString("nonNsIpCapacity")}
              name="ipCapacities.non_ns_ip"
              value={validation.values.ipCapacities.non_ns_ip}
              onChange={(e) =>
                handleInputChange(validation, e, "ipCapacities.non_ns_ip")
              }
              error={validation.errors.ipCapacities?.non_ns_ip}
              touched={validation.touched.ipCapacities?.non_ns_ip}
            />
            <FormNumberField
              label={getString("eslCapacity")}
              name="ipCapacities.esl"
              value={validation.values.ipCapacities.esl}
              onChange={(e) =>
                handleInputChange(validation, e, "ipCapacities.esl")
              }
              error={validation.errors.ipCapacities?.esl}
              touched={validation.touched.ipCapacities?.esl}
            />
            <hr />
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="waitlistAvailable"
                name="waitlistAvailable"
                checked={validation.values.waitlistAvailable}
                onChange={(e) =>
                  validation.setFieldValue(
                    "waitlistAvailable",
                    e.target.checked,
                  )
                }
              />
              <label className="form-check-label" htmlFor="waitlistAvailable">
                {getString("waitlistAvailable")}
              </label>
            </div>
          </Form>
        </CardBody>
      </Card>
    </OffcanvasRight>
  );
};
