import React from "react";
import { Row, Col, Progress } from "reactstrap";

const PaymentProgress = ({ statusCounts, total }) => {
  return (
    <Row className="mt-3" key="progress">
      <Col>
        <Progress multi>
          <Progress
            bar
            color="danger"
            value={(statusCounts.overdue / total) * 100}
          />
          <Progress
            bar
            color="warning"
            value={(statusCounts.awaiting / total) * 100}
          />
          <Progress
            bar
            color="success"
            value={(statusCounts.paid / total) * 100}
          />
          <Progress
            bar
            style={{ backgroundColor: "#878a99" }}
            value={(statusCounts.future / total) * 100}
          />
          <Progress
            bar
            color="primary"
            value={(statusCounts.refund / total) * 100}
          />
        </Progress>
      </Col>
    </Row>
  );
};

export default PaymentProgress;
