import React from "react";
import { AngloTooltip } from "Components/Common/AngloTooltip/AngloTooltip";
import "./PpSummaryCell.scss";
import GradientProgressBar from "Components/Common/GradientProgressBar/GradientProgressBar";

const PpSummaryCell = ({ summary }) => {
  const { totalCapacity, quantity, sum } = summary;

  const afCount = quantity.find((q) => q.status === "af_summary")?.count || 0;
  const waitlistCount =
    quantity.find((q) => q.status === "wait_list")?.count || 0;
  const preBookingCount =
    quantity.find((q) => q.status === "pre_booking")?.count || 0;
  const partlyPaidCount =
    quantity.find((q) => q.status === "partly_paid")?.count || 0;
  const fullyPaidCount =
    quantity.find((q) => q.status === "fully_paid")?.count || 0;

  const percentage =
    totalCapacity > 0
      ? Math.min(Math.round((sum / totalCapacity) * 100), 100)
      : 0;

  const ppBaseColor = "#BF9002";

  const isMuted = totalCapacity === 0;

  return (
    <div className="pp-summary-cell">
      <div
        className={`pp-summary-cell__equation ${
          isMuted ? "pp-summary-cell__equation--muted" : ""
        }`}
      >
        <span className="pp-summary-cell__bracket">(</span>
        <AngloTooltip stringId="af_summary">
          <span className="pp-summary-cell__val pp-summary-cell__val--af-summary">
            {afCount}
          </span>
        </AngloTooltip>
        <span className="pp-summary-cell__bracket"> / </span>
        <AngloTooltip stringId="wait_list">
          <span className="pp-summary-cell__val pp-summary-cell__val--wait-list">
            {waitlistCount}
          </span>
        </AngloTooltip>
        <span className="pp-summary-cell__bracket">)</span>
        <span className="pp-summary-cell__separator"> </span>
        <AngloTooltip stringId="pre_booking">
          <span className="pp-summary-cell__val pp-summary-cell__val--pre-booking">
            {preBookingCount}
          </span>
        </AngloTooltip>
        <span className="pp-summary-cell__separator"> + </span>
        <AngloTooltip stringId="partly_paid">
          <span className="pp-summary-cell__val pp-summary-cell__val--partly-paid">
            {partlyPaidCount}
          </span>
        </AngloTooltip>
        <span className="pp-summary-cell__separator"> + </span>
        <AngloTooltip stringId="fully_paid">
          <span className="pp-summary-cell__val pp-summary-cell__val--fully-paid">
            {fullyPaidCount}
          </span>
        </AngloTooltip>
        <span className="pp-summary-cell__separator"> = </span>
        <AngloTooltip stringId="sum">
          <span className="pp-summary-cell__val pp-summary-cell__sum">
            {sum}
          </span>
        </AngloTooltip>
        <span className="pp-summary-cell__separator"> / </span>
        <AngloTooltip stringId="total_capacity">
          <span className="pp-summary-cell__val pp-summary-cell__total">
            {totalCapacity}
          </span>
        </AngloTooltip>
      </div>
      {!isMuted && (
        <GradientProgressBar percentage={percentage} baseColor={ppBaseColor} />
      )}
    </div>
  );
};

export default PpSummaryCell;
