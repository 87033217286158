import { getStringSync } from "Components/Common/FormattedString";

const suggestedDiets = [
  { value: "regular", label: getStringSync("regular") },
  { value: "vegetarian", label: getStringSync("vegetarian") },
  { value: "vegan", label: getStringSync("vegan") },
  // { value: "paleo", label: getStringSync("paleo") },
  // { value: "keto", label: getStringSync("keto") },
  // { value: "gluten_free", label: getStringSync("gluten_free") },
  // { value: "mediterranean", label: getStringSync("mediterranean") },
  // { value: "low_carb", label: getStringSync("low_carb") },
];

export default suggestedDiets;
