import React from "react";
import { Input, Label, FormFeedback, Spinner, Button } from "reactstrap";
import { AngloTooltip } from "Components/Common/AngloTooltip/AngloTooltip";

const FormTextFieldWithApply = ({
  label,
  name,
  value,
  onChange,
  onApply,
  error,
  type = "text",
  tooltip,
  disabled = false,
  applyButtonLabel = "Apply",
  applyLoading = false,
  "data-testid": dataTestId,
}) => {
  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !disabled && !applyLoading && value?.trim()) {
      onApply();
    }
  };

  const handleApplyClick = () => {
    if (value?.trim()) {
      onApply();
    }
  };

  return (
    <div className="mb-3">
      <Label htmlFor={name} className="form-label d-flex gap-1">
        {label}
        {tooltip && (
          <AngloTooltip stringId={tooltip}>
            <i className="ri-information-line"></i>
          </AngloTooltip>
        )}
      </Label>
      <div className="d-flex gap-2">
        <Input
          type={type}
          className="form-control"
          id={name}
          name={name}
          onChange={(e) => onChange(e, name)}
          value={value || ""}
          invalid={!!error}
          disabled={disabled || applyLoading}
          onKeyDown={handleKeyDown}
          data-testid={dataTestId}
        />
        <Button
          color="primary"
          onClick={handleApplyClick}
          disabled={disabled || applyLoading || !value?.trim()}
        >
          {applyLoading ? <Spinner size="sm" /> : applyButtonLabel}
        </Button>
      </div>
      {error && <FormFeedback type="invalid">{error}</FormFeedback>}
    </div>
  );
};

export default FormTextFieldWithApply;
