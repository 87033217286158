// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gradient-progress-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 56px;
  text-align: center;
  border-radius: 4px;
  font-weight: 500;
  padding: 0.25em;
  font-size: 0.8125rem;
  line-height: 1;
  white-space: nowrap;
  vertical-align: baseline;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out;
}`, "",{"version":3,"sources":["webpack://./src/Components/Common/GradientProgressBar/GradientProgressBar.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,WAAA;EACA,kBAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,oBAAA;EACA,cAAA;EACA,mBAAA;EACA,wBAAA;EACA,uEACE;AAAJ","sourcesContent":[".gradient-progress-bar {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 56px;\n  text-align: center;\n  border-radius: 4px;\n  font-weight: 500;\n  padding: 0.25em;\n  font-size: 0.8125rem;\n  line-height: 1;\n  white-space: nowrap;\n  vertical-align: baseline;\n  transition:\n    color 0.15s ease-in-out,\n    background-color 0.15s ease-in-out;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
