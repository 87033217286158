import React, { useContext, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap";
import { getString } from "Components/Common/FormattedString";
import OffcanvasRight from "Components/Entity/OffcanvasRight";
import { IpDetailsContext } from "../../IpParticipantDetails";
import { addDocumentToIP } from "helpers/API/core-service/cs_backend_helper";
import { toast_error, toast_success } from "helpers/toast_helper";
import { convertFileToBase64 } from "helpers/files_helper";
import {
  handleFileChange,
  handleInputChange,
  handleSelectChange,
  setValidationErrors,
} from "helpers/validation_helper";
import FormTextField from "Components/Entity/FormTextField";
import FormDateField from "Components/Entity/FormDateField";
import FancyFileUploader from "Components/Entity/FancyFileUploader/FancyFileUploader";
import { FormSelect } from "Components/Entity/FormSelect";
import { ipDocumentTypeOptions } from "models/ipParticipants";
import { externalizeDate } from "helpers/utils";
import { parseErrorToFormikErrors } from "helpers/parseErrorsToFormik";

export const AddDocumentCanvas = ({ visible, setVisible }) => {
  const { ipData, refreshIpData } = useContext(IpDetailsContext);
  const [loading, setLoading] = useState(false);
  const [noExpirationDate, setNoExpirationDate] = useState(false);

  const validation = useFormik({
    enableReinitialize: true,
    validateOnChange: false,

    initialValues: {
      description: "",
      type: "",
      expirationDate: "",
      file: null,
      noExpirationDate: false,
    },
    validationSchema: Yup.object({
      description: Yup.string().required(getString("description_required")),
      type: Yup.string().required(getString("type_required")),
      noExpirationDate: Yup.boolean(),
      expirationDate: Yup.date()
        .nullable()
        .test(
          "expirationDate",
          getString("expiration_date_required"),
          function (value) {
            const { noExpirationDate } = this.parent;
            return (
              noExpirationDate ||
              (value !== "" && value !== null && value !== undefined)
            );
          },
        ),
      file: Yup.mixed().required(getString("file_required")),
    }),
    onSubmit: (values) => {
      setLoading(true);
      convertFileToBase64(values.file)
        .then((base64) => {
          const documentData = {
            description: values.description,
            type: values.type,
            expirationDate: values.noExpirationDate
              ? null
              : externalizeDate(values.expirationDate),
            fileBase64: base64,
          };

          addDocumentToIP(ipData.id, documentData)
            .then(() => {
              toast_success(getString("document_added"));
              setVisible(false);
              refreshIpData();
              setLoading(false);
            })
            .catch((e) => parseErrorToFormikErrors(e, validation));
        })
        .catch((error) => {
          console.error(error);
          setLoading(false);
        });
    },
  });

  useEffect(() => {
    if (validation.errors == null) {
      return;
    }
    setValidationErrors(validation, validation.errors);
  }, [validation.errors]);

  return (
    <OffcanvasRight
      isOpen={visible}
      toggle={() => setVisible(false)}
      title={getString("add_document")}
      formId="add-document-form"
      validationRule={validation.isValid}
      buttonLabel={getString("save")}
      loading={loading}
    >
      <Card className="add-document">
        <CardBody className="card-body">
          <Form
            id="add-document-form"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
            action="#"
          >
            <FormTextField
              label={getString("description")}
              name="description"
              value={validation.values.description}
              onChange={(e) => handleInputChange(validation, e, "description")}
              error={validation.errors.description}
              touched={validation.touched.description}
            />
            <FormSelect
              label={getString("type")}
              name={"type"}
              value={validation.values.type}
              onChange={(selectedOption) =>
                handleSelectChange(validation, selectedOption, "type")
              }
              isMulti={false}
              options={ipDocumentTypeOptions}
              error={validation.errors.type}
            />
            <FormGroup check className="mb-3">
              <Label check>
                <Input
                  type="checkbox"
                  checked={validation.values.noExpirationDate}
                  onChange={(e) => {
                    setNoExpirationDate(e.target.checked);
                    validation.setFieldValue(
                      "noExpirationDate",
                      e.target.checked,
                    );
                    if (e.target.checked) {
                      validation.setFieldValue("expirationDate", null);
                      validation.setFieldError("expirationDate", undefined);
                    }
                  }}
                />
                {getString("no_expiration_date")}
              </Label>
            </FormGroup>
            <FormDateField
              label={getString("expiration_date")}
              name="expirationDate"
              value={validation.values.expirationDate}
              onChange={(e) =>
                handleInputChange(validation, e, "expirationDate")
              }
              error={validation.errors.expirationDate}
              touched={validation.touched.expirationDate}
              disabled={noExpirationDate}
            />
            <FancyFileUploader
              name="file"
              label={getString("file")}
              error={validation.errors.file}
              onFileChange={(e) => handleFileChange(validation, e, "file")}
            />
          </Form>
        </CardBody>
      </Card>
    </OffcanvasRight>
  );
};
