// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.payer-tiles__disabled-notice {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 12px;
  background-color: #fff8f8;
  border: 1px solid #ffebee;
  border-radius: 4px;
  color: #ff4d4f;
  font-size: 0.9em;
  width: calc(200% + 18px);
}
.payer-tiles__disabled-notice i {
  font-size: 1.2em;
}
.payer-tiles__disabled-notice--editable {
  background-color: #fffbe6;
  border: 1px solid #ffe58f;
  color: #faad14;
}

.payer-tile--disabled {
  opacity: 0.6;
  pointer-events: none;
  cursor: not-allowed;
}

.payer-tile.disabled,
.add-new-tile.disabled {
  opacity: 0.6;
  pointer-events: none;
  cursor: not-allowed;
}`, "",{"version":3,"sources":["webpack://./src/pages/Applications/ApplicationDetails/partials/ApplicationForm/steps/PayerDetailsStep/PayerTiles.scss"],"names":[],"mappings":"AACE;EACE,aAAA;EACA,mBAAA;EACA,QAAA;EACA,iBAAA;EACA,yBAAA;EACA,yBAAA;EACA,kBAAA;EACA,cAAA;EACA,gBAAA;EACA,wBAAA;AAAJ;AAEI;EACE,gBAAA;AAAN;AAGE;EACE,yBAAA;EACA,yBAAA;EACA,cAAA;AADJ;;AAKA;EACE,YAAA;EACA,oBAAA;EACA,mBAAA;AAFF;;AAKA;;EAEE,YAAA;EACA,oBAAA;EACA,mBAAA;AAFF","sourcesContent":[".payer-tiles {\n  &__disabled-notice {\n    display: flex;\n    align-items: center;\n    gap: 8px;\n    padding: 8px 12px;\n    background-color: #fff8f8;\n    border: 1px solid #ffebee;\n    border-radius: 4px;\n    color: #ff4d4f;\n    font-size: 0.9em;\n    width: calc(200% + 18px);\n\n    i {\n      font-size: 1.2em;\n    }\n  }\n  &__disabled-notice--editable {\n    background-color: #fffbe6; // Light yellow background\n    border: 1px solid #ffe58f; // Yellow border\n    color: #faad14; // Yellow text\n  }\n}\n\n.payer-tile--disabled {\n  opacity: 0.6;\n  pointer-events: none;\n  cursor: not-allowed;\n}\n\n.payer-tile.disabled,\n.add-new-tile.disabled {\n  opacity: 0.6;\n  pointer-events: none;\n  cursor: not-allowed;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
