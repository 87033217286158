import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getPointOfSellListData } from "../../store/pointOfSell/action";
import TableContainer from "../../Components/Common/TableContainerReactTable";
import ActionButtons from "../../Components/Entity/ActionButtons/ActionButtons";
import { FormattedString, getString } from "Components/Common/FormattedString";
import {
  currentLocation,
  getItemUuid,
  getLocation,
  isValidUuid,
  parentLocation,
} from "helpers/utils";
import { PointOfSellDetails } from "./PointOfSellDetails";
import { Badges } from "Components/Common/Badges/Badges";
import { getBusinessUnitListData } from "store/actions";

const PointOfSellList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { pointOfSellData, loading, error } = useSelector(
    (rootState) => rootState.PointOfSell,
  );

  useEffect(() => {
    dispatch(getBusinessUnitListData());
    dispatch(getPointOfSellListData());
  }, [dispatch]);

  const [itemId, setModalItemId] = useState(getItemUuid());
  const [detailsModalVisible, setDetailsModalVisible] = useState(
    isValidUuid(getLocation(3)) || isValidUuid(getLocation(2)),
  );

  const toggleDetailsModal = (state, id) => {
    setDetailsModalVisible(state);
    state === false
      ? navigate(parentLocation())
      : navigate(`${currentLocation()}/${id}`);
    setModalItemId(id);
  };

  const columns = useMemo(
    () => [
      {
        Header: getString("name"),
        accessor: "name",
      },
      {
        Header: getString("paymentMethod"),
        accessor: (cellProps) => cellProps.paymentMethod.name,
        Cell: (cellProps) => (
          <Badges feed={[cellProps.row.original.paymentMethod.name]} />
        ),
      },
      {
        id: "Actions",
        Header: () => (
          <div
            style={{
              textAlign: "right",
            }}
          >
            <FormattedString id="actions" />
          </div>
        ),
        disableSortBy: true,
        accessor: (cellProps) => (
          <ActionButtons
            onShowDetails={() => toggleDetailsModal(true, cellProps.id)}
            itemId={cellProps.id}
          />
        ),
      },
    ],
    [],
  );

  return (
    <>
      <TableContainer
        columns={columns || []}
        data={pointOfSellData || []}
        isPagination={true}
        isGlobalFilter={true}
        isBordered={false}
        customPageSize={10}
        className="custom-header-css table align-middle table-nowrap"
        tableClassName="table-centered align-middle table-nowrap mb-0"
        theadClassName="text-muted table-light"
        SearchPlaceholder={getString("point_of_sell_search_for")}
        error={error}
        loading={loading}
        onRetry={() => dispatch(getPointOfSellListData())}
        preventLoading={detailsModalVisible}
      />
      {detailsModalVisible && (
        <PointOfSellDetails
          itemId={itemId}
          toggle={toggleDetailsModal}
          visible={detailsModalVisible}
        />
      )}
    </>
  );
};

export { PointOfSellList };
