import React from "react";
import "./PayerTile.scss";
import { useHasPermission } from "Components/Hooks/useHasPermission";

const PayerTile = ({
  payer,
  isSelected,
  onClick,
  onEdit,
  disabled = false,
}) => {
  const canEditPayer = useHasPermission("PP_APPLICATION_ACCOUNTING");

  const handleClick = () => {
    if (!disabled) {
      onClick(payer);
    }
  };

  const handleEdit = (e) => {
    e.stopPropagation();
    if (!disabled && canEditPayer) {
      onEdit(payer);
    }
  };

  return (
    <div
      className={`payer-tile ${isSelected ? "payer-tile--selected" : ""} ${disabled ? "payer-tile--disabled" : ""}`}
      onClick={handleClick}
    >
      <div className="payer-tile__avatar">
        <i
          className={payer.companyName ? "ri-building-line" : "ri-user-line"}
        ></i>
      </div>
      <div className="payer-tile__content">
        <div className="payer-tile__name">
          {payer.companyName
            ? payer.companyName
            : `${payer.firstName} ${payer.lastName}`}
        </div>
        <div className="payer-tile__address">
          {payer.address}, {payer.zipCode} {payer.city}
        </div>
      </div>
      {canEditPayer && !disabled && (
        <div className="payer-tile__edit" onClick={handleEdit}>
          <i className="ri-edit-line"></i>
        </div>
      )}
    </div>
  );
};

export default PayerTile;
