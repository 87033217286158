export const handleMultiSelectChange = async (
  validation,
  selectedOptions,
  fieldName,
) => {
  await validation.setFieldValue(fieldName, selectedOptions, fieldName);
  await validation.validateField(fieldName);
};

export const handleSelectChange = async (
  validation,
  selectedOption,
  fieldName,
) => {
  const value = selectedOption ? selectedOption.value : "";
  await validation.setFieldValue(fieldName, value);
  await validation.validateField(fieldName);
};

export const handleCheckboxGroupChange = async (
  validation,
  value,
  fieldName,
) => {
  const currentValues = validation.values[fieldName] || [];
  const newValues = currentValues.includes(value)
    ? currentValues.filter((v) => v !== value)
    : [...currentValues, value];

  await validation.setFieldValue(fieldName, newValues);
  await validation.validateField(fieldName);
};

export const handleFileChange = async (validation, e, fieldName) => {
  await validation.setFieldValue(fieldName, e.target.files[0]);
  await validation.validateField(fieldName);
};

export const handleInputChange = async (validation, e, fieldName) => {
  await validation.handleChange(e);
  await validation.validateField(fieldName);
};

export const handlePhoneChange = async (validation, e) => {
  await validation.handleChange(e);
};

export const handleHtmlEditorChange = async (
  validation,
  content,
  fieldName,
) => {
  validation
    .setFieldValue(fieldName, content)
    .then(() => validation.validateField(fieldName));
};

export const handleNumberInputChange = async (validation, e, fieldName) => {
  const newValue = Number(e.target.value) || 0;
  const syntheticEvent = {
    target: {
      name: e.target.name,
      value: newValue,
    },
  };
  await validation.handleChange(syntheticEvent);
  await validation.validateField(fieldName);
};

export const handleNumberInputChange2 = (formik, e, field) => {
  const value = e.target.value;
  formik.setFieldValue(field, value === "" ? "" : Number(value));
};

export const setValidationErrors = (validation, formValidationErrors) => {
  if (Array.isArray(formValidationErrors)) {
    const transformedErrors = formValidationErrors.reduce((errors, field) => {
      errors[field.name] = field.message;
      return errors;
    }, {});
    validation.setErrors(transformedErrors);
  } else if (formValidationErrors && typeof formValidationErrors === "object") {
    validation.setErrors(formValidationErrors);
  }
};

export const isValidationError = (code) => {
  return code === 400;
};

export const isValidNIP = (value) => {
  if (!/^\d{10}$/.test(value)) {
    return false;
  }
  const weights = [6, 5, 7, 2, 3, 4, 5, 6, 7];
  const sum = value
    .split("")
    .slice(0, 9)
    .reduce((acc, digit, index) => acc + digit * weights[index], 0);
  const controlDigit = sum % 11;
  return controlDigit === parseInt(value[9], 10);
};
