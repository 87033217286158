import React, { useEffect, useState } from "react";
import { Card, CardBody, Form, Row, Col } from "reactstrap";
import FormTextField from "Components/Entity/FormTextField";
import { FormRadioSelect } from "Components/Entity/FormRadioSelect";
import FormPhoneField from "Components/Common/FormPhoneField/FormPhoneField";

import { useFormContext } from "../../provider/utils/FormContext";
import { getString } from "Components/Common/FormattedString";
import { genderOptions } from "models/genders";
import {
  handleInputChange,
  handlePhoneChange,
} from "helpers/validation_helper";

import FormDateField from "Components/Entity/FormDateField";
import { getProgramPacketWarning } from "./utils";

const ParticipantForm = ({ participant, index, programPacketOptions }) => {
  const { formik, parentProgram } = useFormContext();
  const [selectedProgramPacket, setSelectedProgramPacket] = useState(
    participant.programPacket,
  );

  useEffect(() => {
    if (programPacketOptions.length === 1) {
      const defaultPacket = programPacketOptions[0];
      formik.setFieldValue(
        `participants[${index}].programPacket`,
        defaultPacket.value,
      );
      setSelectedProgramPacket(defaultPacket.value);
    }
  }, [programPacketOptions, index]);

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        formik.handleSubmit();
        return false;
      }}
    >
      <Card>
        <CardBody>
          <Row>
            <Col md={6}>
              <FormRadioSelect
                name={`participants[${index}].gender`}
                id={`participants[${index}].gender`}
                label={getString("gender")}
                isMulti={false}
                options={genderOptions}
                value={participant.gender}
                onChange={(selectedOption) => {
                  formik.setFieldValue(
                    `participants[${index}].gender`,
                    selectedOption,
                  );
                }}
                error={formik.errors.participants?.[index]?.gender}
                data-testid="gender-select"
              />
            </Col>
            <Col md={6}>
              <FormRadioSelect
                name={`participants[${index}].programPacket`}
                id={`participants[${index}].programPacket`}
                label={getString("program_packet")}
                isMulti={false}
                options={programPacketOptions}
                value={selectedProgramPacket}
                onChange={(selectedOption) => {
                  formik.setFieldValue(
                    `participants[${index}].programPacket`,
                    selectedOption,
                  );
                  setSelectedProgramPacket(selectedOption);
                }}
                error={formik.errors.participants?.[index]?.programPacket}
                warning={getProgramPacketWarning(
                  participant.dateOfBirth,
                  participant.programPacket,
                  programPacketOptions,
                  parentProgram,
                )}
                data-testid="program-packet-select"
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormTextField
                label={getString("first_name")}
                name={`participants[${index}].firstName`}
                value={participant.firstName}
                onChange={(e) =>
                  handleInputChange(
                    formik,
                    e,
                    `participants[${index}].firstName`,
                  )
                }
                error={formik.errors.participants?.[index]?.firstName}
                data-testid="first-name-input"
              />
            </Col>
            <Col md={6}>
              <FormTextField
                label={getString("last_name")}
                name={`participants[${index}].lastName`}
                value={participant.lastName}
                onChange={(e) =>
                  handleInputChange(
                    formik,
                    e,
                    `participants[${index}].lastName`,
                  )
                }
                error={formik.errors.participants?.[index]?.lastName}
                touched={formik.touched.participants?.[index]?.lastName}
                data-testid="last-name-input"
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormTextField
                label={getString("email")}
                name={`participants[${index}].email`}
                type="email"
                value={participant.email}
                onChange={(e) =>
                  handleInputChange(formik, e, `participants[${index}].email`)
                }
                error={formik.errors.participants?.[index]?.email}
                touched={formik.touched.participants?.[index]?.email}
                data-testid="email-input"
              />
            </Col>
            <Col md={6}>
              <FormPhoneField
                label={getString("phone_number")}
                name={`participants[${index}].phoneNumber`}
                phonePrefixName={`participants[${index}].phonePrefix`}
                value={participant.phoneNumber}
                onChange={(e) => handlePhoneChange(formik, e)}
                phonePrefix={participant.phonePrefix}
                onPrefixChange={(e) => handlePhoneChange(formik, e)}
                error={
                  formik.errors.participants?.[index]?.phoneNumber ||
                  formik.errors.participants?.[index]?.phonePrefix
                }
                setError={(error) =>
                  formik.setFieldError(
                    `participants[${index}].phoneNumber`,
                    error,
                  )
                }
                touched={
                  formik.touched.participants?.[index]?.phoneNumber ||
                  formik.touched.participants?.[index]?.phonePrefix
                }
                data-testid="phone-input"
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormDateField
                label={getString("date_of_birth")}
                name={`participants[${index}].dateOfBirth`}
                value={participant.dateOfBirth}
                onChange={(e) => {
                  handleInputChange(
                    formik,
                    e,
                    `participants[${index}].dateOfBirth`,
                  );
                }}
                error={formik.errors.participants?.[index]?.dateOfBirth}
                touched={formik.touched.participants?.[index]?.dateOfBirth}
                data-testid="date-of-birth-input"
              />
            </Col>
            <Col md={6}>
              <FormTextField
                label={getString("comment")}
                name={`participants[${index}].comment`}
                value={participant.comment}
                onChange={(e) =>
                  handleInputChange(formik, e, `participants[${index}].comment`)
                }
                error={formik.errors.participants?.[index]?.comment}
                touched={formik.touched.participants?.[index]?.comment}
                data-testid="comment-input"
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Form>
  );
};

export default ParticipantForm;
