// Actions
export const API_RESPONSE_SUCCESS = "API_RESPONSE_SUCCESS";
export const API_RESPONSE_ERROR = "API_RESPONSE_ERROR";
export const GET_PROMO_CODE_LIST = "GET_PROMO_CODE_LIST";
export const SET_CURRENT_PAGE = "SET_CURRENT_PAGE";
export const POST_PROMO_CODE = "POST_PROMO_CODE";
export const POST_PROMO_CODE_SUCCESS = "POST_PROMO_CODE_SUCCESS";
export const POST_PROMO_CODE_ERROR = "POST_PROMO_CODE_ERROR";
export const UPDATE_PROMO_CODE = "UPDATE_PROMO_CODE";
export const UPDATE_PROMO_CODE_SUCCESS = "UPDATE_PROMO_CODE_SUCCESS";
export const UPDATE_PROMO_CODE_ERROR = "UPDATE_PROMO_CODE_ERROR";
export const DELETE_PROMO_CODE = "DELETE_PROMO_CODE";
export const DELETE_PROMO_CODE_SUCCESS = "DELETE_PROMO_CODE_SUCCESS";
export const DELETE_PROMO_CODE_ERROR = "DELETE_PROMO_CODE_ERROR";
