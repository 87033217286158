// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.add-application-button {
  background-color: #ebf6fb;
  color: #3fa7d6;
  border: none;
  display: flex;
  align-items: center;
  gap: 4px;
  transition: background-color 0.3s, color 0.3s;
}
.add-application-button:hover {
  background-color: #3fa7d6;
  color: #ffffff;
}
.add-application-button i {
  font-size: 14px;
}`, "",{"version":3,"sources":["webpack://./src/pages/IpParticipants/IpParticipantDetails/partials/Tabs/IpParticipantOverviewTab/IpParticipantOverviewTab.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,cAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,QAAA;EACA,6CACE;AAAJ;AAGE;EACE,yBAAA;EACA,cAAA;AADJ;AAIE;EACE,eAAA;AAFJ","sourcesContent":[".add-application-button {\n  background-color: #ebf6fb;\n  color: #3fa7d6;\n  border: none;\n  display: flex;\n  align-items: center;\n  gap: 4px;\n  transition:\n    background-color 0.3s,\n    color 0.3s;\n\n  &:hover {\n    background-color: #3fa7d6;\n    color: #ffffff;\n  }\n\n  i {\n    font-size: 14px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
