import React, { useState } from "react";
import { Col, Container, Row, Button } from "reactstrap";
import { IpApplicationsList } from "./IpApplicationsList";
import EntityCardList from "../../Components/Entity/EntityCardList";
import { FormattedString, getString } from "Components/Common/FormattedString";

const IpApplications = () => {
  document.title = getString("ip_applications_page_title");

  const [assignIpCanvasVisible, setAssignIpCanvasVisible] = useState(false);

  const handleAssignIpClick = () => {
    setAssignIpCanvasVisible(true);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col>
              <div className="h-100">
                <EntityCardList
                  title={getString("ip_applications_list")}
                  headerButtons={
                    <Button
                      color="light"
                      className="generate-report-button text-primary"
                      onClick={handleAssignIpClick}
                    >
                      <FormattedString id="assignIpParticipant" />
                    </Button>
                  }
                >
                  <IpApplicationsList
                    assignIpCanvasVisible={assignIpCanvasVisible}
                    setAssignIpCanvasVisible={setAssignIpCanvasVisible}
                  />
                </EntityCardList>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default IpApplications;
