import React, { useContext, useState } from "react";
import {
  Card,
  CardBody,
  CardTitle,
  CardText,
  Row,
  Col,
  Spinner,
} from "reactstrap";
import "./SummaryDetails.scss";
import { useSelector } from "react-redux";
import { ApplicationDetailsContext } from "pages/Applications/ApplicationDetails/ApplicationDetails";
import { FormattedString, getString } from "Components/Common/FormattedString";
import { useFormContext } from "../../provider/utils/FormContext";
import { splitNumbers } from "helpers/utils";
import FormTextFieldWithApply from "Components/Entity/FormTextFieldWithApply";

const SummaryDetails = ({
  summary = [],
  discounts = [],
  promoCodeDiscounts = [],
  formik,
  fetchInstallmentData,
}) => {
  const { applicationData } = useContext(ApplicationDetailsContext);
  const { activeBusinessUnit } = useSelector((rootState) => rootState.Profile);
  const { maxInstallmentSum } = useFormContext();
  const currency = activeBusinessUnit.currency || "";

  const [applyLoading, setApplyLoading] = useState(false);
  const [removalLoading, setRemovalLoading] = useState(false);

  const handleApply = async () => {
    setApplyLoading(true);
    try {
      const promoCode = formik.values.promoCode;
      await fetchInstallmentData(promoCode);
    } catch (error) {
      console.error("Error applying promo code:", error);
    } finally {
      setApplyLoading(false);
    }
  };

  const handleRemovePromoCode = async () => {
    setRemovalLoading(true);
    try {
      formik.setFieldValue("promoCode", "");
      formik.setFieldError("promoCode", null);
      await fetchInstallmentData("");
    } catch (error) {
      console.error("Error removing promo code:", error);
    } finally {
      setRemovalLoading(false);
    }
  };

  const convertedPromoCodeDiscounts = promoCodeDiscounts.map((discount) => ({
    type: "promoCode",
    amount: discount.amount,
    count: 1,
    sum: discount.amount,
    code: discount.code,
  }));

  const finalDiscounts = [
    ...(discounts.length > 0
      ? discounts
      : applicationData.discounts.map((discount, index) => ({
          id: `${discount.type}-${index}`,
          type: discount.type,
          amount: discount.amount,
          sum: discount.sum,
          count: discount.count,
        }))),
    ...convertedPromoCodeDiscounts,
  ];

  const finalSummary =
    summary.length > 0
      ? summary
      : applicationData.participants.map((participant) => ({
          tempId: participant.participantId,
          participantName: `${participant.firstName} ${participant.lastName}`,
          programPacketName: participant.programPacketName,
          programPacketPrice: participant.programPacketPrice,
          programMeetingPrice: participant.transportPrice,
          upsells: [
            {
              name: participant.dietName,
              type: "diet",
              price: participant.dietPrice,
            },
            {
              name: participant.insuranceName,
              type: "insurance",
              price: participant.insurancePrice,
            },
          ],
        }));

  return (
    <Card className="summary-card mb-4">
      <CardBody>
        <Row>
          <Col md={5}>
            <CardTitle tag="h5" className="summary-title text-primary">
              <FormattedString id="summary" />
            </CardTitle>
            {finalSummary.map((item, index) => (
              <div
                key={`${item.tempId}-${index}`}
                className="summary-item mb-3"
              >
                <CardText tag="h6" className="participant-name">
                  {item.participantName || getString("no_participant_name")}
                </CardText>
                <CardText>
                  <strong className="custom-muted">
                    <FormattedString id="program_packet" />:
                  </strong>{" "}
                  {item.programPacketName || getString("no_program_packet")} -{" "}
                  {splitNumbers(item.programPacketPrice) ||
                    getString("no_price")}{" "}
                  {currency}
                </CardText>
                <CardText>
                  <strong className="custom-muted">
                    <FormattedString id="meeting_price" />:
                  </strong>{" "}
                  {splitNumbers(item.programMeetingPrice) ||
                    getString("no_meeting_price")}{" "}
                  {currency}
                </CardText>
                <div>
                  <strong className="custom-muted">
                    <FormattedString id="upsells" />:
                  </strong>
                  <ul className="upsells-list">
                    {item.upsells.length > 0 ? (
                      item.upsells.map((upsell, index) => (
                        <li key={`${item.tempId}-upsell-${index}`}>
                          <span className="upsell-name">
                            {upsell.name || getString("no_upsell_name")}
                          </span>{" "}
                          ({upsell.type || getString("no_type")}) -{" "}
                          {splitNumbers(upsell.price) || getString("no_price")}{" "}
                          {currency}
                        </li>
                      ))
                    ) : (
                      <li>
                        <FormattedString id="no_upsells" />
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            ))}
          </Col>
          <Col md={1} className="separator">
            <div className="vertical-line"></div>
          </Col>
          <Col md={6}>
            <CardTitle tag="h5" className="discounts-title text-primary">
              <FormattedString id="discounts" />
            </CardTitle>
            {finalDiscounts.length > 0 ? (
              finalDiscounts.map((discount, index) => (
                <CardText
                  key={`${discount.type}-${index}`}
                  className="d-flex align-items-center gap-2"
                >
                  <strong className="custom-muted">
                    <FormattedString id={discount.type || "no_discount_type"} />
                    :
                  </strong>{" "}
                  {discount.type === "promoCode" ? (
                    <>
                      <span className="fw-medium">{discount.code}</span> -{" "}
                      {splitNumbers(discount.amount) || getString("no_amount")}{" "}
                      {currency}
                    </>
                  ) : (
                    <>
                      {discount.count || getString("no_count")} x{" "}
                      {splitNumbers(discount.amount) || getString("no_amount")}{" "}
                      {currency} (Total:{" "}
                      {splitNumbers(discount.sum) || getString("no_total")}{" "}
                      {currency})
                    </>
                  )}
                  {discount.type === "promoCode" &&
                    (removalLoading ? (
                      <Spinner size="sm" color="danger" />
                    ) : (
                      <i
                        className="ri-close-circle-line ml-2 text-danger"
                        style={{ cursor: "pointer" }}
                        onClick={() => handleRemovePromoCode()}
                      ></i>
                    ))}
                </CardText>
              ))
            ) : (
              <CardText>
                <FormattedString id="no_discounts_available" />
              </CardText>
            )}
            {promoCodeDiscounts.length === 0 && (
              <div className="mt-3">
                <FormTextFieldWithApply
                  label={getString("promoCode")}
                  name="promoCode"
                  value={formik.values.promoCode}
                  onChange={(e, name) =>
                    formik.setFieldValue(name, e.target.value)
                  }
                  applyLoading={applyLoading}
                  onApply={handleApply}
                  error={formik.errors.promoCode}
                  className="form-summary__promo-code"
                />
              </div>
            )}

            <CardTitle tag="h5" className="total-price-title text-primary mt-4">
              <FormattedString id="total_price" />
            </CardTitle>
            <CardText>
              <strong className="custom-muted">
                <FormattedString id="total_price" />:
              </strong>{" "}
              {splitNumbers(maxInstallmentSum)} {currency}
            </CardText>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default SummaryDetails;
