import { externalizeDate } from "helpers/utils";

const removeEmptyValues = (obj) => {
  if (Array.isArray(obj)) {
    return obj
      .map((v) => (v && typeof v === "object" ? removeEmptyValues(v) : v))
      .filter((v) => v !== null && v !== "");
  } else if (obj !== null && typeof obj === "object") {
    return Object.entries(obj)
      .map(([k, v]) => [
        k,
        v && typeof v === "object" ? removeEmptyValues(v) : v,
      ])
      .reduce(
        (acc, [k, v]) => (v === null || v === "" ? acc : { ...acc, [k]: v }),
        {},
      );
  }
  return obj;
};

const mapFormikValuesToDTO = (
  values,
  _currentStep,
  dontInstallments = false,
  parentProgram,
) => {
  const dto = {
    program: parentProgram.customId,
    programMeeting: values.programMeeting,
    participants: values.participants.map((participant) => {
      const participantDTO = {
        gender: participant.gender,
        firstName: participant.firstName,
        lastName: participant.lastName,
        comment: participant.comment,
        email: participant.email,
        phonePrefix: participant.phonePrefix,
        phoneNumber: participant.phoneNumber,
        dateOfBirth: externalizeDate(participant.dateOfBirth),
        programPacket: participant.programPacket,
      };

      if (participant.upsells) {
        const upsells = [
          participant.upsells.diet,
          participant.upsells.insurance,
          ...(participant.upsells.upsells || []),
        ].filter(Boolean);

        if (upsells.length > 0) {
          participantDTO.upsells = upsells;
        }
      }

      return participantDTO;
    }),
    promoCode: values.promoCode,
  };

  if (values.payerDetails && Object.keys(values.payerDetails).length > 0) {
    const {
      firstName,
      lastName,
      taxNumber,
      companyName,
      address,
      zipCode,
      city,
    } = values.payerDetails;
    const payerDetails = {
      firstName,
      lastName,
      taxNumber,
      companyName,
      address,
      zipCode,
      city,
    };

    if (Object.values(payerDetails).some((value) => value)) {
      dto.payerDetails = payerDetails;
    }
  }

  if (!dontInstallments) {
    const installments = values.installments?.map((installment) => ({
      price: installment.price,
      dueDate: externalizeDate(installment.dueDate),
      currency: installment.currency,
      type: installment.type,
      pointOfSell: installment.pointOfSell,
    }));

    if (installments && installments.length > 0) {
      dto.installments = installments;
    }
  }

  return removeEmptyValues(dto);
};

export default mapFormikValuesToDTO;
