import { useSelector } from "react-redux";

export const WithRole = ({ children, roleName }) => {
  const roles = useSelector((state) => state.Profile.user.roles);

  const hasRole = roles?.includes(roleName);

  if (hasRole || !roleName) {
    return children;
  }

  return null;
};
