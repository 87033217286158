import { ApplicationDetailsContext } from "pages/Applications/ApplicationDetails/ApplicationDetails";
import { useContext } from "react";

export const SalesPerson = () => {
  const {
    applicationData: { salesPerson },
  } = useContext(ApplicationDetailsContext);

  return (
    <div className="d-flex align-items-center">
      <div className="flex-shrink-0">
        <i className="ri-user-line me-2 align-middle text-muted fs-16"></i>
      </div>
      <div className="flex-grow-1">{salesPerson}</div>
    </div>
  );

  //
};
