import { useContext } from "react";
import "./FormSummary.scss";
import { ApplicationDetailsContext } from "pages/Applications/ApplicationDetails/ApplicationDetails";
import ParticipantShort from "./components/ParticipantShort/ParticipantShort";
import ProgramShort from "./components/ProgramShort/ProgramShort";
import { splitNumbers } from "helpers/utils";
import DiscountsShort from "./components/DiscountsShort/DiscountsShort";
import { FormattedString } from "Components/Common/FormattedString";

export const FormSummary = ({
  setUpsellsOverlayVisible,
  setEditedParticipant,
}) => {
  const { applicationData } = useContext(ApplicationDetailsContext);
  const summary = applicationData;

  const totalSummaryPrice = summary?.participants.reduce(
    (acc, participant) => acc + (participant.summaryPrice || 0),
    0,
  );

  const totalDiscounts = summary?.discounts.reduce(
    (acc, discount) => acc + (discount.sum || 0),
    0,
  );

  const finalTotalPrice = totalSummaryPrice - totalDiscounts;

  return (
    <div className="form-summary">
      <div className="form-summary__section">
        <div className="form-summary__section__header">
          <FormattedString id="program" />
        </div>
        <ProgramShort
          hotelName={summary.hotelName}
          meetingPoint={summary.meetingPoint}
          programStartDate={summary.startDate}
          programEndDate={summary.endDate}
        />
      </div>
      <div className="form-summary__section">
        <div className="form-summary__section__header">
          <FormattedString id="participants" />
        </div>
        {summary?.participants.length > 0 ? (
          summary.participants.map((participant, index) => (
            <ParticipantShort key={index} participant={participant} />
          ))
        ) : (
          <div className="form-summary__no-participants">
            <FormattedString id="noParticipants" />
          </div>
        )}
      </div>

      <div className="form-summary__section">
        <div className="form-summary__section__header">
          <FormattedString id="discounts" />
        </div>
        {summary?.discounts?.length > 0 ? (
          <DiscountsShort discounts={summary?.discounts ?? []} />
        ) : (
          <div className="form-summary__no-discounts">
            <FormattedString id="noDiscounts" />
          </div>
        )}
      </div>

      <div className="form-summary__footer">
        <div className="form-summary__footer-label">
          <FormattedString id="sum" />:
        </div>
        <div className="form-summary__footer-amount">
          {finalTotalPrice > 0 ? (
            `${splitNumbers(finalTotalPrice)} zł`
          ) : (
            <FormattedString id="na" />
          )}
        </div>
      </div>
    </div>
  );
};
