import React, { useEffect, useState, useCallback, useMemo } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { Card, CardBody, CardHeader, Spinner } from "reactstrap";
import TableContainerExternalPagination from "Components/Common/TableContainerExternalPagination";
import { getIpApplicationsListPromise } from "helpers/API/core-service/cs_backend_helper";
import { debounce } from "lodash";
import { FormattedString, getString } from "Components/Common/FormattedString";
import { format } from "date-fns";
import InternalCommentCell from "pages/IpApplications/cells/InternalCommentCell/InternalCommentCell";
import { getIpStatusBadgeClass } from "models/ipApplications";

const IpApplicationsList = () => {
  const [applications, setApplications] = useState([]);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchParams] = useSearchParams();
  const maxResults = 10;

  const searchQuery = searchParams.get("searchTerm");

  const fetchApplications = useCallback(
    debounce((page, searchValue) => {
      setLoading(true);
      getIpApplicationsListPromise(page, maxResults, searchValue)
        .then((response) => {
          setApplications(response.data);
          setCount(response.count);
          setCurrentPage(response.currentPage);
          setTotalPages(response.totalPages);
          setPageSize(response.pageSize);
          setLoading(false);
        })
        .catch((error) => {
          console.error(getString("error_fetching_applications"), error);
          setLoading(false);
        });
    }, 300),
    [],
  );

  useEffect(() => {
    fetchApplications(currentPage, searchQuery);
  }, [currentPage, searchQuery, fetchApplications]);

  const handlePageChange = (page) => {
    setLoading(true);
    setCurrentPage(page);
  };

  const columns = useMemo(
    () => [
      {
        Header: getString("name"),
        accessor: (d) => (
          <span className="text-decoration-none">
            {`${d.firstName || ""} ${d.lastName || ""}`.trim() || "-"}
          </span>
        ),
        Cell: ({ row }) => (
          <Link
            to={`/ip-participants/${row.original.internationalParticipantId}`}
            className="text-decoration-none"
          >
            {`${row.original.firstName || ""} ${row.original.lastName || ""}`.trim() ||
              "-"}
          </Link>
        ),
      },
      {
        Header: getString("program"),
        accessor: "programName",
        Cell: ({ row }) => (
          <Link to={`/program/${row.original.programId}`}>
            {row.original.programName || "-"}
          </Link>
        ),
      },
      {
        Header: getString("package_name"),
        accessor: "programPacketName",
        Cell: ({ value }) => <span>{value || "-"}</span>,
      },
      {
        Header: getString("ip_type"),
        accessor: "type",
        Cell: ({ value }) => (
          <span className="badge">
            <FormattedString id={value} />
          </span>
        ),
      },
      {
        Header: getString("status"),
        accessor: "applicationStatus",
        Cell: ({ value }) => (
          <span
            className={`badge ${getIpStatusBadgeClass(value.toLowerCase())}`}
          >
            <FormattedString id={value} />
          </span>
        ),
      },
      {
        Header: getString("hotel"),
        accessor: "hotel.name",
        Cell: ({ value }) => <span>{value || "N/A"}</span>,
      },
      {
        Header: getString("program_dates"),
        accessor: (d) => `${d.programStartDate} - ${d.programEndDate}`,
        Cell: ({ row }) => (
          <div className="text-nowrap">
            {`${format(new Date(row.original.programStartDate), "yyyy-MM-dd")} - ${format(
              new Date(row.original.programEndDate),
              "yyyy-MM-dd",
            )}`}
          </div>
        ),
      },
      {
        Header: getString("program_status"),
        accessor: "programStatus",
        Cell: ({ value }) => (
          <ProgramStatusBadge label={getString(value)} status={value} />
        ),
      },
      {
        id: "internal_comment",
        Header: getString("internal_comment"),
        accessor: (d) => (
          <InternalCommentCell
            internalComment={d.internalComment}
            entityId={d.id}
            status={d.applicationStatus}
          />
        ),
        disableSortBy: true,
      },
    ],
    [],
  );

  return (
    <Card>
      <CardHeader>
        <h5 style={{ marginBottom: 0 }}>
          <FormattedString id="ip_applications" />
        </h5>
      </CardHeader>
      <CardBody>
        {loading ? (
          <div className="text-center my-3">
            <Spinner color="primary" />
          </div>
        ) : (
          <TableContainerExternalPagination
            columns={columns || []}
            count={count}
            currentPage={currentPage}
            totalPages={totalPages}
            pageSize={pageSize}
            loading={loading}
            globalFilter={searchQuery}
            setGlobalFilter={() => {}}
            onPageChange={handlePageChange}
            data={applications || []}
            isBordered={false}
            isGlobalFilter={true}
            className="custom-header-css table align-middle table-nowrap"
            tableClassName="table-centered align-middle table-nowrap mb-0"
            theadClassName="text-muted table-light"
            SearchPlaceholder={getString("applications_search_for")}
            error={null}
            onRetry={() => fetchApplications(currentPage, searchQuery)}
            preventLoading={false}
          />
        )}
      </CardBody>
    </Card>
  );
};

export default IpApplicationsList;
