import React, { useContext, useEffect, useMemo } from "react";
import "./Payments.scss";
import { ApplicationDetailsContext } from "pages/Applications/ApplicationDetails/ApplicationDetails";
import { useDispatch } from "react-redux";
import PaymentsTable from "./partials/PaymentsTable";
import StatusCounts from "./partials/StatusCounts";
import PaymentProgress from "./partials/PaymentProgress";
import { getPointOfSellListData } from "store/pointOfSell/action";
import { getBusinessUnitListData } from "store/actions";

export const Payments = () => {
  const {
    applicationData: { applicationInstallments, transactions = [] },
  } = useContext(ApplicationDetailsContext);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getBusinessUnitListData());
    dispatch(getPointOfSellListData());
  }, [dispatch]);

  const data = useMemo(() => {
    const combinedData = [];
    applicationInstallments.forEach((installment, index) => {
      const relatedTransactions = transactions.filter(
        (transaction) => transaction.installmentId === installment.id,
      );

      const isRefund =
        relatedTransactions.length > 0 &&
        relatedTransactions[relatedTransactions.length - 1].amount < 0;

      const status = installment.isPaid
        ? "paid"
        : installment.isOverDue
          ? "overdue"
          : "future";

      combinedData.push({
        ...installment,
        isTransaction: false,
        index: index + 1,
        status: isRefund ? "refund" : status,
      });

      relatedTransactions.forEach((transaction, transactionIndex) => {
        combinedData.push({
          ...transaction,
          isTransaction: true,
          index: `${index + 1}${String.fromCharCode(97 + transactionIndex)}`,
        });
      });
    });

    return combinedData;
  }, [applicationInstallments, transactions]);

  const statusCounts = data.reduce(
    (acc, { status }) => {
      acc[status] = (acc[status] || 0) + 1;
      return acc;
    },
    { paid: 0, overdue: 0, future: 0, awaiting: 0, refund: 0 },
  );

  const total =
    statusCounts.paid +
    statusCounts.overdue +
    statusCounts.future +
    statusCounts.awaiting +
    statusCounts.refund;

  return (
    <div className="payments">
      <div className="table-responsive mt-4 mt-xl-0">
        <PaymentsTable data={data} />
      </div>
      <StatusCounts statusCounts={statusCounts} />
      <PaymentProgress statusCounts={statusCounts} total={total} />
    </div>
  );
};
