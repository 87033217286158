import React from "react";
import { Row, Col } from "reactstrap";
import { FormattedString, getString } from "Components/Common/FormattedString";
import FormTextField from "Components/Entity/FormTextField";
import FormNumberField from "Components/Entity/FormNumberField";
import { FormSelect } from "Components/Entity/FormSelect";
import FormDateField from "Components/Entity/FormDateField";
import {
  handleInputChange,
  handleNumberInputChange2,
  handleSelectChange,
} from "helpers/validation_helper";
import { useFormContext } from "../../provider/utils/FormContext";
import "./InstallmentFormFields.scss";
import { AngloTooltip } from "Components/Common/AngloTooltip/AngloTooltip";

const InstallmentFormFields = ({
  installment,
  index,
  formik,
  installmentTypes,
  pointOfSellOptions,
  removeInstallment,
  isDisabled,
  advancedManagement,
}) => {
  const { maxInstallmentSum, setInstallmentsError } = useFormContext();

  const remainingPrice =
    maxInstallmentSum !== 0
      ? maxInstallmentSum -
        formik.values.installments.reduce((sum, inst, i) => {
          return i !== index ? sum + inst.price : sum;
        }, 0)
      : undefined;

  const hasErrors = formik.errors.installments?.[index];

  const today = new Date();
  const oneYearBefore = new Date(
    today.getFullYear() - 1,
    today.getMonth(),
    today.getDate(),
  );
  const oneYearAfter = new Date(
    today.getFullYear() + 1,
    today.getMonth(),
    today.getDate(),
  );

  const priceError =
    formik.errors.installments?.[index]?.price ||
    (remainingPrice !== 0 &&
      remainingPrice !== formik.values.installments[index].price &&
      !isDisabled)
      ? `${getString("prices_sum_error")} ${maxInstallmentSum}`
      : "";

  const dueDateError = formik.errors.installments?.[index]?.dueDate || "";

  const currencyError = formik.errors.installments?.[index]?.currency || "";

  const typeError = formik.errors.installments?.[index]?.type || "";

  const pointOfSellError =
    formik.errors.installments?.[index]?.pointOfSell || "";

  return (
    <div className="installment-form-fields">
      <Row className="mb-2">
        <Col>
          <h5
            className={`text-left ${hasErrors ? "red-header" : "blue-header"}`}
          >
            {getString("installment")} {index + 1}
          </h5>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col md={2}>
          <FormNumberField
            label={
              priceError !== "" ? (
                <AngloTooltip text={priceError.toString()}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "4px",
                      color: "red",
                    }}
                  >
                    <i className="ri-information-line"></i>
                    <FormattedString id="price" />
                  </div>
                </AngloTooltip>
              ) : (
                <FormattedString id="price" />
              )
            }
            name={`installments[${index}].price`}
            value={formik.values.installments[index].price}
            onChange={(e) =>
              handleNumberInputChange2(
                formik,
                e,
                `installments[${index}].price`,
              )
            }
            error={
              (remainingPrice !== 0 &&
                remainingPrice !== formik.values.installments[index].price &&
                !isDisabled) ||
              formik.errors.installments?.[index]?.price
                ? " "
                : ""
            }
            disabled={isDisabled}
            min={0}
            max={remainingPrice === 0 ? undefined : remainingPrice}
          />
        </Col>
        <Col md={2}>
          <FormDateField
            label={
              dueDateError !== "" ? (
                <AngloTooltip text={dueDateError.toString()}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "4px",
                      color: "red",
                    }}
                  >
                    <i className="ri-information-line"></i>
                    <FormattedString id="dueDate" />
                  </div>
                </AngloTooltip>
              ) : (
                <FormattedString id="dueDate" />
              )
            }
            name={`installments[${index}].dueDate`}
            value={formik.values.installments[index].dueDate}
            onChange={(e) =>
              handleInputChange(formik, e, `installments[${index}].dueDate`)
            }
            error={formik.errors.installments?.[index]?.dueDate ? " " : ""}
            touched={formik.touched.installments?.[index]?.dueDate}
            disabled={isDisabled}
            min={oneYearBefore.toISOString().split("T")[0]}
            max={oneYearAfter.toISOString().split("T")[0]}
          />
        </Col>
        {advancedManagement && (
          <>
            <Col md={2}>
              <FormTextField
                label={
                  currencyError !== "" ? (
                    <AngloTooltip text={currencyError.toString()}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "4px",
                          color: "red",
                        }}
                      >
                        <i className="ri-information-line"></i>
                        <FormattedString id="currency" />
                      </div>
                    </AngloTooltip>
                  ) : (
                    <FormattedString id="currency" />
                  )
                }
                name={`installments[${index}].currency`}
                value={formik.values.installments[index].currency}
                onChange={(e) =>
                  handleInputChange(
                    formik,
                    e,
                    `installments[${index}].currency`,
                  )
                }
                error={formik.errors.installments?.[index]?.currency ? " " : ""}
                disabled={isDisabled}
              />
            </Col>
            <Col md={2}>
              <FormSelect
                label={
                  typeError !== "" ? (
                    <AngloTooltip text={typeError.toString()}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "4px",
                          color: "red",
                        }}
                      >
                        <i className="ri-information-line"></i>
                        <FormattedString id="type" />
                      </div>
                    </AngloTooltip>
                  ) : (
                    <FormattedString id="type" />
                  )
                }
                name={`installments[${index}].type`}
                value={formik.values.installments[index].type}
                defaultValue={installmentTypes.find(
                  (r) => r.value === installment.type,
                )}
                onChange={(selectedOption) =>
                  handleSelectChange(
                    formik,
                    selectedOption,
                    `installments[${index}].type`,
                  )
                }
                isMulti={false}
                options={installmentTypes}
                error={formik.errors.installments?.[index]?.type ? " " : ""}
                isDisabled={isDisabled}
              />
            </Col>
            <Col md={2}>
              <FormSelect
                label={
                  pointOfSellError !== "" ? (
                    <AngloTooltip text={pointOfSellError.toString()}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "4px",
                          color: "red",
                        }}
                      >
                        <i className="ri-information-line"></i>
                        <FormattedString id="pointOfSell" />
                      </div>
                    </AngloTooltip>
                  ) : (
                    <FormattedString id="pointOfSell" />
                  )
                }
                name={`installments[${index}].pointOfSell`}
                value={formik.values.installments[index].pointOfSell}
                defaultValue={pointOfSellOptions.find(
                  (r) => r.value === installment.pointOfSell,
                )}
                onChange={(selectedOption) =>
                  handleSelectChange(
                    formik,
                    selectedOption,
                    `installments[${index}].pointOfSell`,
                  )
                }
                isMulti={false}
                options={pointOfSellOptions}
                error={
                  formik.errors.installments?.[index]?.pointOfSell ? " " : ""
                }
                isDisabled={isDisabled}
              />
            </Col>
          </>
        )}
        {!isDisabled && (
          <Col
            md={2}
            className="d-flex align-items-center mb-3"
            style={{ marginTop: "calc(19px + 0.5rem)" }}
          >
            <div
              className="fancy-remove-icon"
              onClick={() => {
                removeInstallment(index);
                setInstallmentsError(null);
                formik.setFieldError("installments", undefined);
              }}
              disabled={formik.values.installments.length === 1}
            >
              <i className="ri-delete-bin-line"></i>
            </div>
          </Col>
        )}
      </Row>
    </div>
  );
};

export default InstallmentFormFields;
