import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { toast_error, toast_success } from "../../helpers/toast_helper";

import {
  GET_IP_APPLICATIONS_LIST_DATA,
  POST_IP_APPLICATION,
} from "./actionType";
import { ipApplicationsApiSuccess, ipApplicationsApiError } from "./action";

import {
  getIpApplicationsList,
  postIpApplication,
} from "../../helpers/API/core-service/cs_backend_helper";

function* getIpApplicationsListData({ payload: data }) {
  try {
    const { page, searchTerm } = data;
    const response = yield call(getIpApplicationsList, page, searchTerm);

    yield put(
      ipApplicationsApiSuccess(GET_IP_APPLICATIONS_LIST_DATA, response),
    );
  } catch (error) {
    yield put(ipApplicationsApiError(GET_IP_APPLICATIONS_LIST_DATA, error));
  }
}

function* createIpApplication({ payload: application }) {
  try {
    const response = yield call(
      postIpApplication,
      application.data,
      application.onSuccess,
    );
    yield put(ipApplicationsApiSuccess(POST_IP_APPLICATION, response));
    toast_success("IP Application added successfully");
  } catch (error) {
    yield put(ipApplicationsApiError(POST_IP_APPLICATION, error));
    toast_error(error.data.message);
  }
}

export function* watchGetIpApplicationsListData() {
  yield takeEvery(GET_IP_APPLICATIONS_LIST_DATA, getIpApplicationsListData);
}

export function* watchPostIpApplication() {
  yield takeEvery(POST_IP_APPLICATION, createIpApplication);
}

function* ipApplicationsSaga() {
  yield all([
    fork(watchGetIpApplicationsListData),
    fork(watchPostIpApplication),
  ]);
}

export default ipApplicationsSaga;
