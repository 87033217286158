import React from "react";
import { Collapse } from "reactstrap";
import { format } from "date-fns";
import { LinkButton } from "Components/Common/LinkButton/LinkButton";
import ReactJsonViewCompare from "react-json-view-compare";
import classNames from "classnames";
import { generateChangesList } from "./utils";
import { FormattedString } from "Components/Common/FormattedString";
import { WithRole } from "Components/Common/WithRole/WithRole";
import { AngloTooltip } from "Components/Common/AngloTooltip/AngloTooltip";

const ActivityLogItem = ({
  log,
  index,
  expandedLogIndex,
  comparisonLogIndex,
  previousLog,
  toggleCollapse,
  toggleComparison,
  handleEmailPreview,
}) => {
  const getIconByEventName = (eventName) => {
    switch (eventName) {
      case "application.email_sent":
        return "ri-mail-open-line";
      case "application.updated":
        return "ri-edit-line";
      case "application.installment_paid":
        return "ri-money-dollar-circle-line";
      case "application.created":
        return "ri-add-circle-line";
      default:
        return "mdi mdi-account-circle";
    }
  };

  const eventName =
    index === 0 && log.eventName === "application.updated"
      ? "application.created"
      : log.eventName;

  const isStatusChangedToFullyPaid =
    log.eventMessage ===
    "Application status changed from partly_paid to fully_paid";

  const isAdminAction = log.adminName !== null;

  return (
    <div className="d-flex mb-3 application-log" key={index}>
      <div className="icon-wrap">
        <i
          className={classNames(
            isStatusChangedToFullyPaid
              ? "ri-checkbox-circle-line text-success"
              : getIconByEventName(eventName),
            "icon",
          )}
        ></i>
      </div>
      <div className="flex-grow-1 ms-3">
        <div className="d-flex align-items-center gap-2">
          <div
            className={classNames("activity-title", {
              "text-success": isStatusChangedToFullyPaid,
            })}
          >
            <FormattedString
              id={isStatusChangedToFullyPaid ? "application.paid" : eventName}
            />
          </div>
          {log.eventName === "application.updated" && previousLog && (
            <>
              <LinkButton
                onClick={() => toggleCollapse(index)}
                className="application-activity-tab__view-email"
              >
                <i className="ri-edit-line"></i>{" "}
                {expandedLogIndex === index ? (
                  <FormattedString id="hide_details" />
                ) : (
                  <FormattedString id="show_details" />
                )}
              </LinkButton>
              <WithRole roleName="ROLE_IT">
                <LinkButton
                  onClick={() => toggleComparison(index)}
                  className="application-activity-tab__view-email"
                >
                  <i className="ri-file-list-line"></i>{" "}
                  {comparisonLogIndex === index ? (
                    <FormattedString id="hide_comparison" />
                  ) : (
                    <FormattedString id="show_comparison" />
                  )}
                </LinkButton>
              </WithRole>
            </>
          )}
          {log.emailId && (
            <LinkButton
              onClick={() => handleEmailPreview(log.emailId)}
              className="application-activity-tab__view-email"
            >
              <i className="ri-mail-open-line"></i>{" "}
              <FormattedString id="view_email" />
            </LinkButton>
          )}
        </div>
        <div className="d-flex gap-2 align-items-center">
          <small
            className={classNames("text-muted", {
              "text-success": isStatusChangedToFullyPaid,
            })}
          >
            {log.eventMessage} -{" "}
            {format(new Date(log.occurredOn), "yyyy-MM-dd HH:mm:ss")}
          </small>
          {log.eventName !== "application.email_sent" && isAdminAction && (
            <span className="activity-author">
              <AngloTooltip text={`${log.adminEmail}`} nowrap>
                <small className="text-muted">
                  <div className="d-flex align-items-center">
                    <i
                      className="ri-user-3-line"
                      data-toggle="tooltip"
                      style={{ marginRight: "2px" }}
                    ></i>
                    {log.adminName}
                  </div>
                </small>
              </AngloTooltip>
            </span>
          )}
          {log.eventName !== "application.email_sent" &&
            !isAdminAction &&
            log.newApplication?.salesPerson && (
              <span className="activity-author">
                <AngloTooltip
                  text={
                    <FormattedString id="change_toggled_by_sales_team_member" />
                  }
                >
                  <i className="ri-user-line" data-toggle="tooltip"></i>
                </AngloTooltip>
              </span>
            )}
        </div>
        {log.eventName === "application.updated" && log.newApplication && (
          <>
            <Collapse isOpen={expandedLogIndex === index}>
              <ul className="list-group list-group-flush">
                {previousLog &&
                  generateChangesList(
                    previousLog.newApplication || {},
                    log.newApplication,
                  )}
              </ul>
            </Collapse>
            <Collapse isOpen={comparisonLogIndex === index}>
              {previousLog && (
                <ReactJsonViewCompare
                  oldData={previousLog.newApplication || {}}
                  newData={log.newApplication}
                />
              )}
            </Collapse>
          </>
        )}
      </div>
    </div>
  );
};

export default ActivityLogItem;
