// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.application-overview-tab .expandable-tile {
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out;
}
.application-overview-tab .expandable-tile.open {
  max-height: 5000px;
}
.application-overview-tab__close {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  color: black;
  transition: color 0.3s;
  font-size: 16px;
}
.application-overview-tab__close:hover {
  color: darkgray;
}`, "",{"version":3,"sources":["webpack://./src/pages/Applications/ApplicationDetails/partials/Tabs/ApplicationOverviewTab/ApplicationOverviewTab.scss"],"names":[],"mappings":"AACE;EACE,gBAAA;EACA,aAAA;EAEA,iEACE;AAFN;AAME;EACE,kBAAA;AAJJ;AAOE;EACE,gBAAA;EACA,YAAA;EACA,UAAA;EACA,eAAA;EACA,YAAA;EACA,sBAAA;EACA,eAAA;AALJ;AAOI;EACE,eAAA;AALN","sourcesContent":[".application-overview-tab {\n  .expandable-tile {\n    overflow: hidden;\n    max-height: 0;\n\n    transition:\n      max-height 0.3s ease-in-out,\n      opacity 0.3s ease-in-out;\n  }\n\n  .expandable-tile.open {\n    max-height: 5000px;\n  }\n\n  &__close {\n    background: none;\n    border: none;\n    padding: 0;\n    cursor: pointer;\n    color: black;\n    transition: color 0.3s;\n    font-size: 16px;\n\n    &:hover {\n      color: darkgray;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
