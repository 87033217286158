import React, { useState } from "react";
import { Col, Container, Row } from "reactstrap";
import EntityCardList from "../../Components/Entity/EntityCardList";
import { getString } from "Components/Common/FormattedString";
import { PromoCodesList } from "./PromoCodesList";
import AddPromoCode from "./AddPromoCode";

const PromoCodes = () => {
  document.title = getString("promo_codes_page_title");
  const [promoCodeAddVisible, setPromoCodeAddVisible] = useState(false);
  const togglePromoCodeAdd = () => {
    setPromoCodeAddVisible((prev) => !prev);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col>
              <div className="h-100">
                <EntityCardList
                  title={getString("promo_codes_list")}
                  actionBtnLabel={getString("promo_code_sm")}
                  actionBtn={togglePromoCodeAdd}
                  actionBtnPermission={"PROMO_CODE_CREATE"}
                >
                  <PromoCodesList />
                </EntityCardList>
              </div>
            </Col>
            {promoCodeAddVisible && (
              <AddPromoCode
                visibility={promoCodeAddVisible}
                toggle={togglePromoCodeAdd}
              />
            )}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default PromoCodes;
