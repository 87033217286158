import React, { useContext, useState } from "react";
import { Col, Row, TabPane } from "reactstrap";
import { IpDetailsContext } from "../../../IpParticipantDetails";
import { Tile } from "Components/Common/Tile/Tile";
import { FormattedString, getString } from "Components/Common/FormattedString";
import FormikTextArea from "Components/Entity/FormikTextArea";
import "./IpParticipantOverviewTab.scss";
import { IpParticipantDetails } from "../../components/IpParticipantDetails/IpParticipantDetails";
import { IpDocumentList } from "../../components/IpDocumentList/IpDocumentList";
import { IpApplications } from "../../components/IpApplications/IpApplications";
import { addEntityNote } from "helpers/API/core-service/cs_backend_helper";
import { toast_error, toast_success } from "helpers/toast_helper";
import { OutlineButton } from "Components/Common/OutlineButton/OutlineButton";
import { AddDocumentCanvas } from "../../modals/AddDocumentCanvas";

export const IpParticipantOverviewTab = () => {
  const { ipData, refreshIpData } = useContext(IpDetailsContext);
  const [addDocumentCanvasVisible, setAddDocumentCanvasVisible] =
    useState(false);

  const handleSubmitNote = (entityId, type, content) => {
    return addEntityNote(entityId, type, content)
      .then(() => {
        toast_success(getString("comment_added"));
        refreshIpData();
      })
      .catch((e) => {
        toast_error(e.message);
        throw e;
      });
  };

  const entityNotes = ipData.entityNotes || [];

  const specialNeedsNote = entityNotes.find(
    (note) => note.type === "ip_special_needs",
  ) || { content: "" };
  const allergiesNote = entityNotes.find(
    (note) => note.type === "ip_allergies",
  ) || { content: "" };

  return (
    <TabPane tabId="overview">
      <Row>
        <Col xxl={9}>
          <Tile title={getString("recent_applications")} gap="14px">
            <IpApplications />
          </Tile>
          <Tile
            title={getString("documents")}
            gap="14px"
            titleDropdown={
              <OutlineButton onClick={() => setAddDocumentCanvasVisible(true)}>
                <FormattedString id="add_document" />
              </OutlineButton>
            }
          >
            {ipData.documents && ipData.documents.length > 0 ? (
              <IpDocumentList
                documents={ipData.documents}
                internationalParticipantId={ipData.id}
              />
            ) : (
              <div className="text-center text-muted">
                <FormattedString id="no_documents_to_show" />
              </div>
            )}
          </Tile>
        </Col>
        <Col xxl={3}>
          <Tile title={getString("ip_participant_details")} gap="14px">
            <IpParticipantDetails />
          </Tile>

          <Tile title={getString("special_needs")} gap="14px">
            <FormikTextArea
              name="specialNeedsNote"
              value={specialNeedsNote.content}
              handleSubmit={(value) =>
                handleSubmitNote(ipData.id, "ip_special_needs", value)
              }
            />
          </Tile>

          <Tile title={getString("allergies")} gap="14px">
            <FormikTextArea
              name="allergiesNote"
              value={allergiesNote.content}
              handleSubmit={(value) =>
                handleSubmitNote(ipData.id, "ip_allergies", value)
              }
            />
          </Tile>
        </Col>
      </Row>

      {addDocumentCanvasVisible && (
        <AddDocumentCanvas
          visible={addDocumentCanvasVisible}
          setVisible={setAddDocumentCanvasVisible}
        />
      )}
    </TabPane>
  );
};
