import React from "react";
import { Card, CardBody, Col } from "reactstrap";
import PropTypes from "prop-types";
import * as dateNow from "date-fns";

const ServicesStatus = ({ servicesStatus }) => {
  return (
    <React.Fragment>
      {(servicesStatus || []).map((item, key) => {
        const {
          serviceName,
          serviceUrl,
          lastDeploymentTriggeredAt,
          activeDeploymentTriggerUrl,
          activeDeploymentTrigger,
          lastDeploymentStatus,
          deploymentsUrl,
        } = item;

        let deployThreshold = new Date(new Date().getTime() - 3 * 60 * 1000);
        let lastDeploymentDateTime = new Date(
          lastDeploymentTriggeredAt,
        ).getTime();
        let isDeployInProgress = lastDeploymentDateTime > deployThreshold;

        return (
          <Col lg={4} md={6} key={key}>
            <Card>
              <CardBody>
                <div className="d-flex align-items-center">
                  {/* Service Info */}
                  <div className="flex-grow-1">
                    <p className="text-uppercase fw-semibold fs-13 text-muted mb-1">
                      {serviceName}
                    </p>
                    <p className="mb-0 fs-10">{serviceUrl}</p>
                    <p className="mb-2 fs-10">
                      Last deploy at:{" "}
                      <span className="fw-semibold">
                        {lastDeploymentTriggeredAt}
                      </span>
                    </p>
                    <p className="mb-0 fs-10">
                      <span className="fw-semibold">
                        <a
                          href={activeDeploymentTriggerUrl}
                          target="_blank"
                          rel="noreferrer"
                          className="text-decoration-underline"
                        >
                          {activeDeploymentTrigger}
                        </a>
                      </span>
                    </p>
                  </div>

                  {/* Status and Logs */}
                  <div className="flex-shrink-0 text-center ms-3">
                    <span
                      className={`badge bg-${lastDeploymentStatus ? "success" : isDeployInProgress ? "warning" : "danger"}-subtle text-${lastDeploymentStatus ? "success" : isDeployInProgress ? "warning" : "danger"}`}
                    >
                      {lastDeploymentStatus
                        ? "Active"
                        : isDeployInProgress
                          ? "In Progress"
                          : "Failed"}
                    </span>
                    <p className="mt-4 fs-10">
                      <a
                        href={deploymentsUrl}
                        target="_blank"
                        rel="noreferrer"
                        className="text-decoration-underline"
                      >
                        Deployment logs
                      </a>
                    </p>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        );
      })}
    </React.Fragment>
  );
};

ServicesStatus.propTypes = {
  servicesStatus: PropTypes.arrayOf(
    PropTypes.shape({
      serviceName: PropTypes.string.isRequired,
      serviceUrl: PropTypes.string,
      lastDeploymentTriggeredAt: PropTypes.string.isRequired,
      activeDeploymentTriggerUrl: PropTypes.string.isRequired,
      activeDeploymentTrigger: PropTypes.string.isRequired,
      lastDeploymentStatus: PropTypes.bool.isRequired,
      deploymentsUrl: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default ServicesStatus;
