import React from "react";
import ReactDOM from "react-dom/client";
import { configureStore } from "./store";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { Popup400 } from "pages/AuthenticationInner/Errors/Popup400";
import { PopupProvider } from "context/PopupContext";
import { ToastContainer } from "react-toastify";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={configureStore({})}>
    <PopupProvider>
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <App />
        <Popup400 />
      </BrowserRouter>
    </PopupProvider>
    <ToastContainer />
  </Provider>,
);

reportWebVitals();
