import React, { useCallback, useState } from "react";
import { Modal } from "reactstrap";
import { useDispatch } from "react-redux";

import { getString } from "Components/Common/FormattedString";
import {
  getIpParticipantListData,
  toggleIpParticipantStatus,
} from "store/ipParticipants/action";
import ConfirmUnblockBody from "Components/Entity/ConfirmUnblockBody";

const UnblockIpModal = ({ itemId, visibility, toggle }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const unblockIpEntry = useCallback(() => {
    setLoading(true);
    dispatch(
      toggleIpParticipantStatus(itemId, () => {
        dispatch(getIpParticipantListData());
        setLoading(false);
        toggle(false);
      }),
    );
  }, [dispatch, itemId]);

  return (
    <Modal
      isOpen={visibility}
      toggle={() => {
        toggle();
      }}
      backdrop={"static"}
      id="staticBackdrop"
      centered
    >
      <ConfirmUnblockBody
        entityName={getString("ip_participant_sm")}
        toggle={toggle}
        unblockAction={unblockIpEntry}
        loading={loading}
      />
    </Modal>
  );
};

export default UnblockIpModal;
