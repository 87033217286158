// Actions
export const API_RESPONSE_SUCCESS = "API_RESPONSE_SUCCESS";
export const API_RESPONSE_ERROR = "API_RESPONSE_ERROR";
export const GET_IP_PARTICIPANT_LIST_DATA = "GET_IP_PARTICIPANT_LIST_DATA";
export const GET_BUSINESS_UNIT_LIST_DATA = "GET_BUSINESS_UNIT_LIST_DATA";
export const POST_IP_PARTICIPANT = "POST_IP_PARTICIPANT";
export const EDIT_IP_PARTICIPANT = "EDIT_IP_PARTICIPANT";
export const DELETE_IP_PARTICIPANT = "DELETE_IP_PARTICIPANT";
export const TOGGLE_IP_PARTICIPANT_STATUS = "TOGGLE_IP_PARTICIPANT_STATUS";
export const POST_IP_ATTACHMENT = "POST_IP_ATTACHMENT";
