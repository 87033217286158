import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { FormSelect } from "Components/Entity/FormSelect";
import { Card, CardBody, Form, Spinner } from "reactstrap";
import { handleSelectChange } from "helpers/validation_helper";
import { useFormik } from "formik";
import { getString } from "Components/Common/FormattedString";
import OffcanvasRight from "Components/Entity/OffcanvasRight";
import { toast_error, toast_success } from "helpers/toast_helper";
import { assignIpToProgramPacket } from "helpers/API/core-service/cs_backend_helper";
import { ipTypeOptions } from "models/ipParticipants";
import { getProgramListData } from "store/programs/action";
import { getIpParticipantListData } from "store/ipParticipants/action";
import { getProgram } from "helpers/API/core-service/cs_backend_helper";
import { parseErrorToFormikErrors } from "helpers/parseErrorsToFormik";

export const AssignIpCanvas = ({
  visible,
  setVisible,
  defaultPacket,
  refreshData,
}) => {
  const dispatch = useDispatch();
  const { programsData } = useSelector((rootState) => rootState.Programs);
  const { ipParticipantsData } = useSelector(
    (rootState) => rootState.IpParticipants,
  );
  const [loading, setLoading] = useState(false);
  const [programPackets, setProgramPackets] = useState([]);
  const [fetchingPackets, setFetchingPackets] = useState(false);
  const [packetsFetched, setPacketsFetched] = useState(false);

  const fetchProgramPackets = async (programId) => {
    setFetchingPackets(true);
    try {
      const program = await getProgram(programId);
      const packets = program.programPackets.map((packet) => ({
        value: packet.id,
        label: packet.name,
      }));
      setProgramPackets(packets);
      setPacketsFetched(true);
    } catch (error) {
      toast_error(getString("error_fetching_program_packets"));
      setPacketsFetched(false);
    } finally {
      setFetchingPackets(false);
    }
  };

  useEffect(() => {
    dispatch(getProgramListData());
    dispatch(getIpParticipantListData());
  }, [dispatch]);

  const validation = useFormik({
    enableReinitialize: true,
    validateOnChange: false,

    initialValues: {
      internationalParticipantId: "",
      programPacketId: defaultPacket || "",
      type: "",
      selectedProgram: "",
    },
    validationSchema: Yup.object({
      internationalParticipantId: Yup.string().required(
        getString("ip_required"),
      ),
      programPacketId: Yup.string().required(
        getString("program_packet_required"),
      ),
      type: Yup.string().required(getString("participant_type_required")),
      selectedProgram: Yup.string().required(getString("program_required")),
    }),
    onSubmit: (values) => {
      setLoading(true);
      assignIpToProgramPacket(values.programPacketId, values)
        .then(() => {
          toast_success(getString("ip_assigned"));
          setVisible(false);
          refreshData();
        })
        .catch((e) => parseErrorToFormikErrors(e))
        .finally(() => setLoading(false));
    },
  });

  const ipsOptions = ipParticipantsData
    .filter((item) => item?.status !== "archived")
    .map((item) => ({
      value: item.id,
      label: `${item.firstName} ${item.lastName}`,
    }));

  const programPacketOptions = programPackets;

  const programOptions = programsData
    .filter((program) => program.status !== "archived")
    .map((program) => ({
      value: program.id,
      label: program.name,
    }));

  const handleProgramChange = (selectedOption) => {
    if (selectedOption.value !== validation.values.selectedProgram) {
      validation.setFieldValue("selectedProgram", selectedOption.value);
      setPacketsFetched(false);
      validation.setFieldValue("programPacketId", "");
      validation.setFieldValue("type", "");
      validation.setFieldValue("internationalParticipantId", "");
      validation.setErrors({});
    }
  };

  useEffect(() => {
    if (validation.values.selectedProgram) {
      fetchProgramPackets(validation.values.selectedProgram);
    }
  }, [validation.values.selectedProgram]);

  return (
    <OffcanvasRight
      isOpen={visible}
      toggle={() => setVisible(false)}
      title={getString("ip_assign")}
      formId="assign-ip-form"
      validationRule={true}
      buttonLabel={getString("save")}
      loading={loading}
    >
      <Card>
        <CardBody className="card-body">
          <Form
            id="assign-ip-form"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
            action="#"
          >
            <FormSelect
              name="selectedProgram"
              label={getString("program")}
              value={validation.values.selectedProgram}
              onChange={handleProgramChange}
              isMulti={false}
              options={programOptions}
              error={validation.errors["selectedProgram"]}
            />
            {fetchingPackets ? (
              <div className="text-center my-4">
                <Spinner color="primary" className="mt-4" />
              </div>
            ) : (
              <>
                <FormSelect
                  name="programPacketId"
                  label={getString("program_packet")}
                  value={validation.values.programPacketId}
                  onChange={(selectedOption) =>
                    handleSelectChange(
                      validation,
                      selectedOption,
                      "programPacketId",
                    )
                  }
                  defaultValue={programPacketOptions.find(
                    (r) => r.value === validation.values.programPacketId,
                  )}
                  isMulti={false}
                  options={programPacketOptions}
                  error={validation.errors["programPacketId"]}
                  isDisabled={
                    !validation.values.selectedProgram ||
                    !!defaultPacket ||
                    !packetsFetched
                  }
                />
                <FormSelect
                  name="participantType"
                  label={getString("participant_type")}
                  value={validation.values.type}
                  onChange={(selectedOption) =>
                    handleSelectChange(validation, selectedOption, "type")
                  }
                  isMulti={false}
                  options={ipTypeOptions}
                  error={validation.errors["type"]}
                  isDisabled={
                    !validation.values.selectedProgram || !packetsFetched
                  }
                />
                <FormSelect
                  name="internationalParticipantId"
                  label={getString("international_participant")}
                  value={validation.values.internationalParticipantId}
                  onChange={(selectedOption) =>
                    handleSelectChange(
                      validation,
                      selectedOption,
                      "internationalParticipantId",
                    )
                  }
                  isMulti={false}
                  options={ipsOptions}
                  error={validation.errors["internationalParticipantId"]}
                  isDisabled={
                    !validation.values.selectedProgram || !packetsFetched
                  }
                />
              </>
            )}
          </Form>
        </CardBody>
      </Card>
    </OffcanvasRight>
  );
};
