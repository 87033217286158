import React, { useContext, useEffect } from "react";
import { Col, Row, TabPane } from "reactstrap";
import { CustomerDetailsContext } from "../../../CustomerDetails";
import { Tile } from "Components/Common/Tile/Tile";
import { getString } from "Components/Common/FormattedString";

import { CustomerParticipants } from "../../components/CustomerParticipants/CustomerParticipants";
import CustomerDetailsRight from "../../components/CustomerDetails/CustomerDetailsRight";

export const CustomerParticipantsTab = () => {
  const { refreshCustomerParticipants } = useContext(CustomerDetailsContext);

  useEffect(() => {
    refreshCustomerParticipants();
  }, []);

  return (
    <TabPane tabId="participants">
      <Row>
        <Col xxl={9}>
          <Tile title={getString("participants")} gap="14px">
            <CustomerParticipants />
          </Tile>
        </Col>
        <CustomerDetailsRight />
      </Row>
    </TabPane>
  );
};
