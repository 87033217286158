import React, { useEffect, useMemo, useState, useRef, useContext } from "react";
import { useSearchParams, Link, useNavigate } from "react-router-dom";
import TableContainerExternalPagination from "Components/Common/TableContainerExternalPagination";
import { PreloaderWrap } from "Components/Common/Preloader/Preloader";
import { format } from "date-fns";
import { FormattedString, getString } from "Components/Common/FormattedString";
import { getIpStatusBadgeClass } from "models/ipApplications";
import { AngloTooltip } from "Components/Common/AngloTooltip/AngloTooltip";
import { WithPermission } from "Components/Common/WithPermission/WithPermission";
import { ChangeIpApplicationStatusCanvas } from "pages/IpApplications/modals/ChangeIpApplicationStatusCanvas/ChangeIpApplicationStatusCanvas";
import InternalCommentCell from "pages/IpApplications/cells/InternalCommentCell/InternalCommentCell";
import { IpApplicationDetailsContext } from "../../IpApplicationDetails";

export const IpApplications = () => {
  const {
    applicationData,
    selectedApplication,
    setSelectedApplication,
    ipApplications,
    ipApplicationsLoading,
    refreshApplicationData,
    refreshIpApplications,
  } = useContext(IpApplicationDetailsContext);

  const [searchParams, setSearchParams] = useSearchParams();
  const pageNumber = searchParams.get("page") || "1";
  const initialSearchTerm = searchParams.get("searchTerm") || "";
  const [globalFilter] = useState(initialSearchTerm);
  const isInitialRender = useRef(true);
  const [changeStatusCanvasVisible, setChangeStatusCanvasVisible] =
    useState(false);

  const [chosenApplication, setChosenApplication] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }

    const params = {};
    if (globalFilter) {
      params.searchTerm = globalFilter;
    }
    if (pageNumber > 1) {
      params.page = pageNumber.toString();
    }
    setSearchParams(params);
  }, [pageNumber, globalFilter]);

  const toggleChangeStatusModal = (application) => {
    setChosenApplication(application);
    setChangeStatusCanvasVisible(true);
  };

  const columns = useMemo(
    () => [
      {
        Header: "",
        accessor: "select",
        Cell: ({ row }) => (
          <input
            type="radio"
            name="selectedApplication"
            checked={selectedApplication?.id === row.original.id}
            onChange={() => navigate(`/ip-applications/${row.original.id}`)}
          />
        ),
      },
      {
        Header: getString("program"),
        accessor: "programName",
        Cell: ({ row }) => (
          <Link to={`/program/${row.original.programId}`}>
            {row.original.programName || "-"}
          </Link>
        ),
      },
      {
        Header: getString("package_name"),
        accessor: "programPacketName",
        Cell: ({ value }) => <span>{value || "-"}</span>,
      },
      {
        Header: getString("ip_type"),
        accessor: "type",
        Cell: ({ value }) => (
          <span className="badge">
            <FormattedString id={value} />
          </span>
        ),
      },
      {
        Header: getString("status"),
        accessor: "applicationStatus",
        Cell: ({ value }) => (
          <span
            className={`badge ${getIpStatusBadgeClass(value.toLowerCase())}`}
          >
            <FormattedString id={value} />
          </span>
        ),
      },
      {
        Header: getString("hotel"),
        accessor: "hotel.name",
        Cell: ({ value }) => <span>{value || "N/A"}</span>,
      },
      {
        Header: getString("program_dates"),
        accessor: (d) => `${d.programStartDate} - ${d.programEndDate}`,
        Cell: ({ row }) => (
          <div className="text-nowrap">
            {`${format(new Date(row.original.programStartDate), "yyyy-MM-dd")} - ${format(
              new Date(row.original.programEndDate),
              "yyyy-MM-dd",
            )}`}
          </div>
        ),
      },
      {
        Header: getString("program_status"),
        accessor: "programStatus",
        Cell: ({ value }) => (
          <ProgramStatusBadge label={getString(value)} status={value} />
        ),
      },
      {
        id: "internal_comment",
        Header: getString("internal_comment"),
        accessor: (d) => (
          <InternalCommentCell
            internalComment={d.internalComment}
            entityId={d.id}
            status={d.applicationStatus}
            onSuccess={() => refreshApplicationData()}
          />
        ),
        disableSortBy: true,
      },
      {
        id: "Actions",
        Header: () => (
          <div style={{ textAlign: "right" }}>
            <FormattedString id="actions" />
          </div>
        ),
        disableSortBy: true,
        Cell: ({ row }) => (
          <WithPermission permissionName="IP_APPLICATION_UPDATE">
            <div className="col-auto d-flex justify-content-end">
              <AngloTooltip stringId="change_status">
                <button
                  type="button"
                  className="btn btn-sm btn-soft-secondary btn-icon"
                  onClick={() => toggleChangeStatusModal(row.original)}
                >
                  <i className="ri-exchange-line"></i>
                </button>
              </AngloTooltip>
            </div>
          </WithPermission>
        ),
      },
    ],
    [selectedApplication, setSelectedApplication, navigate],
  );

  const customSetGlobalFilter = (value) => {
    const newSearchParams = new URLSearchParams(searchParams);
    if (value) {
      newSearchParams.set("searchTerm", value);
    } else {
      newSearchParams.delete("searchTerm");
    }
    newSearchParams.set("page", "1");
    setSearchParams(newSearchParams);
  };

  return ipApplications ? (
    <div className="ip-applications-list">
      <TableContainerExternalPagination
        columns={columns || []}
        count={ipApplications.count || 0}
        currentPage={ipApplications.currentPage || 1}
        totalPages={ipApplications.totalPages || 1}
        globalFilter={globalFilter}
        setGlobalFilter={customSetGlobalFilter}
        onPageChange={(page) =>
          refreshIpApplications(
            applicationData.internationalParticipant.id,
            page,
          )
        }
        loading={ipApplicationsLoading}
        data={ipApplications.data || []}
        isGlobalFilter={false}
        isGlobalSearch={false}
        isBordered={false}
        className="custom-header-css table align-middle table-nowrap"
        tableClassName="table-centered align-middle table-nowrap mb-0"
        theadClassName="text-muted table-light"
        SearchPlaceholder={getString("ip_applications_search_for")}
      />
      {changeStatusCanvasVisible && (
        <ChangeIpApplicationStatusCanvas
          visible={changeStatusCanvasVisible}
          setVisible={setChangeStatusCanvasVisible}
          application={chosenApplication}
          refreshData={() => refreshApplicationData()}
        />
      )}
    </div>
  ) : (
    <PreloaderWrap />
  );
};
