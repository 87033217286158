import React, { useCallback, useEffect, useState } from "react";
import { Label, FormFeedback } from "reactstrap";
import { Editor } from "@tinymce/tinymce-react";
import { AngloTooltip } from "Components/Common/AngloTooltip/AngloTooltip";
import debounce from "lodash/debounce";

const FormHtmlEditor = ({
  label,
  name,
  height = 500,
  value,
  onChange,
  error,
  tooltip,
}) => {
  const [editorContent, setEditorContent] = useState(value);

  const stableOnChange = useCallback(onChange, []);

  const debouncedOnChange = useCallback(debounce(stableOnChange, 1000), [
    stableOnChange,
  ]);

  useEffect(() => {
    return () => {
      debouncedOnChange.cancel();
    };
  }, [debouncedOnChange]);

  const handleEditorChange = (content, editor) => {
    setEditorContent(content);
    debouncedOnChange(content, editor);
  };

  useEffect(() => {
    setEditorContent(value);
  }, [value]);

  return (
    <div className="mb-3">
      <Label htmlFor={name} className="form-label d-flex gap-1">
        {label}
        {tooltip && (
          <AngloTooltip stringId={tooltip}>
            <i className="ri-information-line"></i>
          </AngloTooltip>
        )}
      </Label>
      <Editor
        apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
        value={editorContent}
        onEditorChange={handleEditorChange}
        init={{
          height,
          menubar: false,
          plugins: [
            "advlist",
            "autolink",
            "lists",
            "link",
            "image",
            "charmap",
            "preview",
            "anchor",
            "searchreplace",
            "visualblocks",
            "code",
            "fullscreen",
            "insertdatetime",
            "media",
            "table",
            "code",
            "help",
            "wordcount",
          ],
          toolbar:
            "code blocks | undo redo | " +
            "bold italic forecolor | alignleft aligncenter " +
            "alignright alignjustify | bullist numlist outdent indent | " +
            "removeformat | charmap |",
        }}
      />
      {error && <FormFeedback type="invalid">{error}</FormFeedback>}
    </div>
  );
};

export default FormHtmlEditor;
