import React, { useContext, useMemo, useState } from "react";
import { useTable } from "react-table";
import { Table } from "reactstrap";
import { format } from "date-fns";
import { FormattedString, getString } from "Components/Common/FormattedString";
import { splitNumbers } from "helpers/utils";
import { AngloTooltip } from "Components/Common/AngloTooltip/AngloTooltip";
import { toast_success } from "helpers/toast_helper";
import { ApplicationDetailsContext } from "pages/Applications/ApplicationDetails/ApplicationDetails";
import { useHasPermission } from "Components/Hooks/useHasPermission";
import ConfirmMarkAsRefundModal from "./ConfirmMarkAsRefundModal";
import classNames from "classnames";
import MarkAsPaidOffcanvas from "./MarkAsPaidOffCanvas";
import { useSelector } from "react-redux";

const PaymentsTable = ({ data }) => {
  const {
    applicationData: { id, customId, transactions = [] },
  } = useContext(ApplicationDetailsContext);

  const { pointOfSellData } = useSelector((rootState) => rootState.PointOfSell);

  const canModifyPayments = useHasPermission("PP_APPLICATION_ACCOUNTING");

  const [refundModalVisible, setRefundModalVisible] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState(null);
  const [offcanvasVisible, setOffcanvasVisible] = useState(false);
  const [selectedInstallment, setSelectedInstallment] = useState(null);

  const openOffcanvas = () => setOffcanvasVisible(true);
  const closeOffcanvas = () => setOffcanvasVisible(false);

  const handleMarkAsPaid = (installment) => {
    setSelectedInstallment(installment);
    openOffcanvas();
  };

  const handleRefundClick = (payment) => {
    const relatedTransactions = transactions.filter(
      (transaction) => transaction.installmentId === payment.id,
    );
    setSelectedPayment({ ...payment, relatedTransactions });
    setRefundModalVisible(true);
  };

  const getPaymentMethodName = (pointOfSellId) => {
    const pointOfSell = pointOfSellData.find((pos) => pos.id === pointOfSellId);
    return pointOfSell?.paymentMethod?.name || "-";
  };

  const renderStatus = (status) => {
    if (!status) {
      return "-";
    }
    const statusIcons = {
      paid: "ri-checkbox-circle-line",
      future: "ri-time-line",
      overdue: "ri-close-circle-line",
      awaiting: "ri-time-line",
      refund: "ri-arrow-go-back-line",
    };

    const statusClass = status.toLowerCase();
    const iconClass = statusIcons[status] || "ri-time-line";

    return (
      <div
        className={`d-flex align-items-center gap-1 status-text ${statusClass}`}
      >
        <i
          className={`${iconClass} align-middle`}
          style={{ color: status === "refund" ? "blue" : "" }}
        ></i>
        <FormattedString id={status} />
      </div>
    );
  };

  const columns = useMemo(
    () => [
      {
        Header: "#",
        accessor: (_row, i) => i + 1,
        Cell: ({ row }) => row.original.index,
        className: "index-column",
      },
      {
        Header: getString("paymentDate"),
        accessor: "dueDate",
        Cell: ({ value, row }) =>
          row.original.isTransaction
            ? format(new Date(row.original.createdAt), "yyyy-MM-dd")
            : format(new Date(value), "yyyy-MM-dd"),
      },
      {
        Header: getString("amount"),
        accessor: "price",
        Cell: ({ row }) => {
          const amount = row.original.isTransaction
            ? row.original.amount
            : row.original.price;
          const currency = row.original.currency;
          const formattedAmount = `${splitNumbers(amount)} ${currency}`;

          const color = row.original.isTransaction
            ? amount >= 0
              ? "green"
              : "blue"
            : undefined;

          return <span style={{ color }}>{formattedAmount}</span>;
        },
      },
      {
        Header: getString("type"),
        accessor: "type",
        Cell: ({ value, row }) => {
          if (row.original.isTransaction) {
            return "-";
          } else {
            return value === "educational"
              ? "EDU"
              : value === "touristic"
                ? "TOUR"
                : value;
          }
        },
      },
      {
        Header: getString("source"),
        accessor: "pointOfSellId",
        Cell: ({ row }) => getPaymentMethodName(row.original.pointOfSellId),
      },
      {
        Header: getString("status"),
        accessor: "status",
        Cell: ({ row }) => {
          if (row.original.isTransaction) {
            return "-";
          } else {
            const status = row.original.status
              ? row.original.status
              : row.original.amount < 0
                ? "refund"
                : "paid";
            return renderStatus(status);
          }
        },
      },
      {
        Header: getString("action"),
        accessor: "action",
        Cell: ({ row }) => {
          if (row.original.isTransaction) {
            return "-";
          }

          const { isTransaction, disabled, status } = row.original;

          const [copied, setCopied] = useState(false);

          const baseUrl = process.env.REACT_APP_CUSTOMER_PANEL_URL;
          const paymentUrl = `${baseUrl}application/${customId}/payment-url`;
          const handleCopy = () => {
            if (!copied) {
              navigator.clipboard.writeText(paymentUrl).then(() => {
                setCopied(true);
                toast_success(getString("link_copied"));
                setTimeout(() => setCopied(false), 4000);
              });
            }
          };

          const actions = [];

          if (!isTransaction && canModifyPayments && status !== "paid") {
            actions.push(
              <AngloTooltip stringId="mark_as_paid" key="mark_as_paid">
                <div
                  className={classNames("payments__mark_as_paid__icon", {
                    disabled: disabled,
                  })}
                  onClick={
                    !disabled ? () => handleMarkAsPaid(row.original) : null
                  }
                  style={{ cursor: disabled ? "not-allowed" : "pointer" }}
                >
                  $
                </div>
              </AngloTooltip>,
            );
          }

          if (status !== "paid") {
            actions.push(
              <AngloTooltip
                stringId={copied ? "link_copied" : "copy_link"}
                key="copy_link"
              >
                <div
                  className={classNames("payments__copy__icon", {
                    disabled: disabled,
                  })}
                  onClick={!disabled ? () => handleCopy() : null}
                  style={{ pointerEvents: copied ? "none" : "auto" }}
                >
                  <i
                    className={copied ? "ri-check-line" : "ri-file-copy-line"}
                  ></i>
                </div>
              </AngloTooltip>,
            );
          }

          if (status === "paid" && canModifyPayments) {
            actions.push(
              <AngloTooltip stringId="mark_as_refund" key="mark_as_refund">
                <div
                  className={classNames("payments__mark_as_refund__icon", {
                    disabled: disabled,
                  })}
                  onClick={
                    !disabled ? () => handleRefundClick(row.original) : null
                  }
                  style={{ cursor: disabled ? "not-allowed" : "pointer" }}
                >
                  <i className="ri-arrow-go-back-line"></i>
                </div>
              </AngloTooltip>,
            );
          }

          return actions.length > 0 ? (
            <div className="payments__copy d-flex gap-2">{actions}</div>
          ) : (
            "-"
          );
        },
        headerClassName: "text-end",
        className: "text-end",
      },
    ],
    [canModifyPayments, pointOfSellData],
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });

  return (
    <>
      {data && data.length > 0 ? (
        <Table
          {...getTableProps()}
          className="table-striped table-nowrap align-middle mb-0 table-hover"
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps()}
                    className={column.headerClassName}
                  >
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              const { status } = row.original;
              const rowClassName = row.original.isTransaction
                ? ""
                : status === "refund"
                  ? "table-info"
                  : status === "paid"
                    ? "table-success"
                    : status === "overdue"
                      ? "table-danger"
                      : status === "awaiting"
                        ? "table-warning"
                        : "";

              return (
                <tr {...row.getRowProps()} className={rowClassName}>
                  {row.cells.map((cell) => (
                    <td
                      {...cell.getCellProps()}
                      className={cell.column.className}
                    >
                      {cell.render("Cell")}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </Table>
      ) : (
        <div className="text-center mt-4">
          <p
            className="text-uppercase fs-5"
            style={{ fontWeight: 700, color: "#b8b8b8" }}
          >
            {getString("no_installments_available")}
          </p>
        </div>
      )}
      {offcanvasVisible && (
        <MarkAsPaidOffcanvas
          isOpen={offcanvasVisible}
          toggle={closeOffcanvas}
          selectedInstallment={selectedInstallment}
          applicationId={id}
        />
      )}
      {refundModalVisible && (
        <ConfirmMarkAsRefundModal
          visibility={refundModalVisible}
          toggle={() => setRefundModalVisible(false)}
          paymentId={id}
          selectedPayment={selectedPayment}
        />
      )}
    </>
  );
};

export default PaymentsTable;
