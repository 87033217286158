import React, { useContext } from "react";
import { Button, Row, Col, Spinner } from "reactstrap";
import "./ApplicationStepNavigation.scss";
import { FormattedString } from "Components/Common/FormattedString";
import { useFormContext } from "../../provider/utils/FormContext";
import {
  STEP_INSTALLMENTS,
  STEP_PARTICIPANTS,
} from "../../provider/utils/stepsConfig";
import { ApplicationDetailsContext } from "pages/Applications/ApplicationDetails/ApplicationDetails";
import { applicationStatus } from "models/applicationStatuses";

const ApplicationStepNavigation = () => {
  const { applicationData } = useContext(ApplicationDetailsContext);
  const { currentStep, handleStepChange, handleSave, submitting } =
    useFormContext();

  const isSaveButtonVisible = ![
    applicationStatus.AF_Participants_Data,
    applicationStatus.AF_Participants_Upsells,
    applicationStatus.AF_Billing_Data,
    applicationStatus.AF_Summary,
  ].includes(applicationData.status);

  return (
    <Row
      className="justify-content-center mt-4 application-step-nav"
      style={{ width: "100%" }}
    >
      <Col xs="auto" className="d-flex gap-2">
        <Button
          color="secondary"
          onClick={() => handleStepChange(-1)}
          disabled={currentStep === STEP_PARTICIPANTS || submitting}
        >
          <FormattedString id="previous" />
        </Button>
        {currentStep < STEP_INSTALLMENTS && (
          <Button
            color="primary"
            onClick={() => handleStepChange(1)}
            disabled={submitting}
          >
            {submitting ? <Spinner size="sm" /> : <FormattedString id="next" />}
          </Button>
        )}
        {isSaveButtonVisible && currentStep !== STEP_INSTALLMENTS && (
          <Button
            color="success"
            onClick={handleSave}
            disabled={submitting}
            style={{ marginLeft: "52px" }}
          >
            {submitting ? <Spinner size="sm" /> : <FormattedString id="save" />}
          </Button>
        )}
        {currentStep === STEP_INSTALLMENTS && (
          <Button color="success" onClick={handleSave} disabled={submitting}>
            {submitting ? (
              <Spinner size="sm" />
            ) : (
              <FormattedString id="submit" />
            )}
          </Button>
        )}
      </Col>
    </Row>
  );
};

export default ApplicationStepNavigation;
