import React, { useContext, useState } from "react";
import { useFormContext } from "../../provider/utils/FormContext";
import { PreloaderWrap } from "Components/Common/Preloader/Preloader";
import { useSelector } from "react-redux";
import { internalizeDate } from "helpers/utils";
import mapFormikValuesToDTO from "../../provider/utils/mapFormikValuesToDTO";
import { moveApplication } from "helpers/API/core-service/cs_backend_helper";
import { ApplicationDetailsContext } from "pages/Applications/ApplicationDetails/ApplicationDetails";
import { parseApplicationErrorToFormikErrors } from "../../provider/utils/parseApplicationErrorToFormikErrors";
import "./SummaryStep.scss";
import { installmentTypes } from "models/installmentPlans";
import InstallmentAlert from "./components/InstallmentAlert";
import InstallmentHeader from "./components/InstallmentHeader";
import InstallmentContent from "./components/InstallmentContent";
import { Button, Alert } from "reactstrap";
import ComparisonContainer from "./components/ComparisonContainer";
import { getString, FormattedString } from "Components/Common/FormattedString";

const SummaryStep = () => {
  const { applicationData } = useContext(ApplicationDetailsContext);
  const PointOfSell = useSelector((rootState) => rootState.PointOfSell);
  const { activeBusinessUnit } = useSelector((rootState) => rootState.Profile);

  const pointOfSellOptions = PointOfSell.pointOfSellData.map((item) => ({
    value: item.id,
    label: item.name,
  }));

  const {
    formik,
    installmentsError,
    setInstallmentsError,
    setMaxInstallmentSum,
    targetProgram,
    validateStep,
  } = useFormContext();
  const [loading, setLoading] = useState(true);
  const [summaryData, setSummaryData] = useState(null);
  const [advancedManagement, setAdvancedManagement] = useState(false);
  const [installmentPlans, setInstallmentPlans] = useState([]);
  const [showInstallmentForm, setShowInstallmentForm] = useState(
    formik.values.installments.length > 0,
  );
  const [alertVisible, setAlertVisible] = useState(false);
  const [showComparison, setShowComparison] = useState(true);

  const addInstallment = () => {
    const currentInstallmentCount = formik.values.installments.length;
    const nextPlan = installmentPlans.find(
      (plan) => plan.installmentCount === currentInstallmentCount + 1,
    );

    if (nextPlan) {
      handleSelectPlan(nextPlan);
    } else {
      const lastInstallment =
        formik.values.installments[formik.values.installments.length - 1];
      const newInstallment = {
        price: "",
        dueDate: "",
        currency: activeBusinessUnit.currency || "",
        type: lastInstallment ? lastInstallment.type : "",
        pointOfSell: lastInstallment ? lastInstallment.pointOfSell : "",
      };

      formik.setFieldValue("installments", [
        ...formik.values.installments,
        newInstallment,
      ]);
    }
  };

  const removeInstallment = (index) => {
    const currentInstallmentCount = formik.values.installments.length;
    const previousPlan = installmentPlans.find(
      (plan) => plan.installmentCount === currentInstallmentCount - 1,
    );

    if (previousPlan) {
      handleSelectPlan(previousPlan);
    } else {
      if (formik.values.installments.length > 1) {
        const updatedInstallments = formik.values.installments.filter(
          (_, i) => i !== index,
        );
        formik.setFieldValue("installments", updatedInstallments);
      }
    }
  };

  const handleSelectPlan = (plan) => {
    const finalInstallments = plan.installments.map((installment, index) => {
      const currentInstallment = formik.values.installments[index];
      return {
        price: currentInstallment?.isPaid
          ? currentInstallment.price
          : installment.price,
        dueDate: currentInstallment?.isPaid
          ? currentInstallment.dueDate
          : internalizeDate(installment.dueDate),
        currency: currentInstallment?.isPaid
          ? currentInstallment.currency
          : installment?.currency || activeBusinessUnit.currency,
        type: currentInstallment?.isPaid
          ? currentInstallment.type
          : installment.type,
        pointOfSell: currentInstallment?.isPaid
          ? currentInstallment.pointOfSell
          : installment?.pointOfSellId || pointOfSellOptions[0].value,
        isPaid: currentInstallment?.isPaid || installment.isPaid,
        isOverDue: installment.isOverDue,
      };
    });

    formik.setFieldValue("installments", finalInstallments);
    setMaxInstallmentSum(plan.sum);
    setShowInstallmentForm(true);
  };

  const fetchInstallmentData = async (customPromoCode) => {
    const promoCodeToUse =
      customPromoCode === ""
        ? undefined
        : customPromoCode || formik.values.promoCode;

    try {
      const dto = mapFormikValuesToDTO(
        { ...formik.values, promoCode: promoCodeToUse },
        4,
        true,
        targetProgram,
      );
      const response = await moveApplication(applicationData.id, dto);
      const {
        installmentPlans,
        summary,
        discounts,
        promoCodeDiscounts,
        recalculated,
      } = response;

      const availablePlans =
        recalculated || installmentPlans.length > 0
          ? installmentPlans
          : targetProgram.installmentPlans;

      const sortedInstallmentPlans = [...availablePlans].sort(
        (a, b) => a.installmentCount - b.installmentCount,
      );

      const hasExistingInstallments =
        applicationData.applicationInstallments?.length > 0;
      const currentInstallments =
        formik.values.installments.length > 0 ? formik.values.installments : [];

      if (!hasExistingInstallments) {
        handleNewInstallments(
          sortedInstallmentPlans,
          currentInstallments,
          { summary, discounts, promoCodeDiscounts },
          recalculated,
        );
      } else {
        handleExistingInstallments(
          sortedInstallmentPlans,
          { summary, discounts, promoCodeDiscounts },
          recalculated,
        );
      }

      setAlertVisible(recalculated);
    } catch (error) {
      parseApplicationErrorToFormikErrors(error.data, formik);
      setLoading(false);
    }
  };

  const handleNewInstallments = (
    plans,
    currentInstallments,
    summaryData,
    recalculated,
  ) => {
    setInstallmentPlans(plans);
    setSummaryData(summaryData);
    setLoading(false);

    if (recalculated) {
      setAlertVisible(true);
      const matchedPlan = findMatchingPlan(plans, currentInstallments.length);
      const selectedPlan = matchedPlan || plans[0];

      const updatedInstallments = mapInstallments(selectedPlan.installments);
      formik.setFieldValue("installments", updatedInstallments);
      setMaxInstallmentSum(selectedPlan.sum);
    }
  };

  const handleExistingInstallments = (plans, summaryData, recalculated) => {
    const matchingPlan = plans.find(
      (plan) =>
        plan.installmentCount ===
        applicationData.applicationInstallments.length,
    );

    const updatedInstallments = applicationData.applicationInstallments.map(
      (installment, index) => {
        const matchingInstallment = matchingPlan?.installments[index];
        const parentInstallment =
          targetProgram.installmentPlans[0].installments[index];

        return {
          price: installment.price,
          dueDate: internalizeDate(installment.dueDate),
          currency: getInstallmentCurrency(
            installment,
            matchingInstallment,
            parentInstallment,
          ),
          type: installment.type,
          pointOfSell: getPointOfSell(
            installment,
            matchingInstallment,
            parentInstallment,
          ),
          isPaid: installment.isPaid,
          isOverDue: installment.isOverDue,
        };
      },
    );

    if (recalculated) {
      setAlertVisible(true);
      const currentCount = applicationData.applicationInstallments.length;
      const nextPlan = plans.find(
        (plan) => plan.installmentCount === currentCount,
      );
      nextPlan ? handleSelectPlan(nextPlan) : setShowInstallmentForm(false);
    } else {
      formik.setFieldValue("installments", updatedInstallments);
    }

    setShowInstallmentForm(updatedInstallments.length > 0);
    setInstallmentPlans(plans);
    setSummaryData(summaryData);
    setLoading(false);

    if (plans[0]?.sum) {
      setMaxInstallmentSum(plans[0].sum);
    }
  };

  const findMatchingPlan = (plans, count) => {
    return plans.find((plan) => plan.installments.length === count);
  };

  const mapInstallments = (installments) => {
    return installments.map((installment) => ({
      price: installment.price,
      dueDate: internalizeDate(installment.dueDate),
      currency: installment.currency || activeBusinessUnit.currency,
      type: installment.type,
      pointOfSell: installment.pointOfSellId || pointOfSellOptions[0]?.value,
      isPaid: installment.isPaid,
      isOverDue: installment.isOverDue,
    }));
  };

  const getInstallmentCurrency = (
    installment,
    matchingInstallment,
    parentInstallment,
  ) => {
    return (
      installment.currency ||
      matchingInstallment?.currency ||
      parentInstallment?.currency ||
      activeBusinessUnit.currency
    );
  };

  const getPointOfSell = (
    installment,
    matchingInstallment,
    parentInstallment,
  ) => {
    return (
      installment.pointOfSellId ||
      matchingInstallment?.pointOfSellId ||
      parentInstallment?.pointOfSellId
    );
  };

  const toggleAdvancedManagement = () => {
    setAdvancedManagement(!advancedManagement);
  };

  const handleAccept = () => {
    validateStep().then((errors) => {
      if (Object.keys(errors).length > 0) {
        formik.setErrors(errors);
      } else {
        setShowComparison(false);
        fetchInstallmentData();
      }
    });
  };

  return (
    <div className="installments-step">
      {showComparison ? (
        <div>
          <h5
            className="comparison-header"
            style={{
              color: "#a9a9a9",
              display: "flex",
              gap: "4px",
              alignItems: "center",
              fontWeight: 400,
            }}
          >
            <i className="ri-information-line"></i>
            <FormattedString id={getString("comparisonHeader")} />
          </h5>
          <ComparisonContainer />
          {formik.errors?.program && (
            <Alert
              color="danger"
              style={{
                display: "flex",
                gap: "4px",
                alignItems: "center",
              }}
            >
              <i className="ri-error-warning-line"></i>
              {formik.errors?.program}
            </Alert>
          )}
          <div className="button-container">
            <Button color="secondary" onClick={handleAccept}>
              <FormattedString id={getString("accept")} />
            </Button>
          </div>
        </div>
      ) : (
        <>
          <InstallmentAlert alertVisible={alertVisible} />
          <InstallmentHeader
            advancedManagement={advancedManagement}
            toggleAdvancedManagement={toggleAdvancedManagement}
          />
          {loading ? (
            <PreloaderWrap />
          ) : (
            <InstallmentContent
              summaryData={summaryData}
              showInstallmentForm={showInstallmentForm}
              installmentPlans={installmentPlans}
              setShowInstallmentForm={setShowInstallmentForm}
              setInstallmentsError={setInstallmentsError}
              formik={formik}
              installmentTypes={installmentTypes}
              pointOfSellOptions={pointOfSellOptions}
              removeInstallment={removeInstallment}
              advancedManagement={advancedManagement}
              installmentsError={installmentsError}
              addInstallment={addInstallment}
              handleSelectPlan={handleSelectPlan}
              fetchInstallmentData={fetchInstallmentData}
            />
          )}
        </>
      )}
    </div>
  );
};

export default SummaryStep;
