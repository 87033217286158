import { useContext } from "react";
import { ProgramDetailsContext } from "../../ProgramDetails";
import { formatNumber } from "helpers/utils";
import { getCurrencyTotals } from "../../packetUtils";

export const PacketTotalPrice = ({ programPacket }) => {
  const { programData } = useContext(ProgramDetailsContext);

  const currencyTotals = getCurrencyTotals(programData, programPacket.id);

  return (
    <div className="packet-total-price">
      {Object.keys(currencyTotals).length === 0 && "-"}
      {Object.entries(currencyTotals).map(([currency, total]) => (
        <div key={currency}>
          <strong>{formatNumber(total)}</strong> {currency}
        </div>
      ))}
    </div>
  );
};
