import {
  API_RESPONSE_SUCCESS,
  API_RESPONSE_ERROR,
  GET_INSTALLMENT_PLAN_LIST_DATA,
  POST_INSTALLMENT_PLAN,
  DELETE_INSTALLMENT_PLAN,
} from "./actionType";

export const INIT_STATE = {
  installmentPlansData: [],
  selectedBusinessUnit: null,
  formValidationErrors: {},
  error: null,
  loading: false,
  isInstallmentPlanAdded: false,
  isInstallmentPlanEdited: false,
  isInstallmentPlanDeleted: false,
  isInstallmentPlanRestored: false,
};

const InstallmentPlans = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_INSTALLMENT_PLAN_LIST_DATA:
    case POST_INSTALLMENT_PLAN:
    case DELETE_INSTALLMENT_PLAN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case GET_INSTALLMENT_PLAN_LIST_DATA:
          return {
            ...state,
            installmentPlansData: action.payload.data,
            isInstallmentPlanAdded: false,
            isInstallmentPlanEdited: false,
            isInstallmentPlanDeleted: false,
            isInstallmentPlanRestored: false,
            loading: false,
            error: null,
          };
        case POST_INSTALLMENT_PLAN:
          return {
            ...state,
            loading: false,
            isInstallmentPlanAdded: true,
            error: null,
          };
        case DELETE_INSTALLMENT_PLAN:
          return {
            ...state,
            loading: false,
            isInstallmentPlanDeleted: true,
            error: null,
          };
        default:
          return state;
      }
    case API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case GET_INSTALLMENT_PLAN_LIST_DATA:
          return {
            ...state,
            errorMsg: action.payload.data,
            error: action.payload.error,
            isInstallmentPlanAdded: false,
            isInstallmentPlanEdited: false,
            isInstallmentPlanDeleted: false,
            isInstallmentPlanRestored: false,
            loading: false,
          };
        case POST_INSTALLMENT_PLAN:
          return {
            ...state,
            error: action.payload.error,
            formValidationErrors: action.payload.error.data?.fields ?? null,
            loading: false,
            isInstallmentPlanAdded: false,
          };
        case DELETE_INSTALLMENT_PLAN:
          return {
            ...state,
            error: action.payload.error,
            loading: false,
            isInstallmentPlanDeleted: false,
          };
        default:
          return state;
      }
    default:
      return state;
  }
};

export default InstallmentPlans;
