import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getApplicationsListData } from "../../store/applications/action";
import TableContainerExternalPagination from "Components/Common/TableContainerExternalPagination";
import { getBusinessUnitListData } from "store/actions";
import CustomIdCell from "./cells/CustomIdCell/CustomIdCell";
import InstallmentsCell from "./cells/InstallmentsCell/InstallmentsCell";
import { format } from "date-fns";
import "./ApplicationsList.scss";
import PackageCell from "./cells/PackageCell/PackageCell";
import GenderCell from "./cells/GenderCell/GenderCell";
import ParticipantNameCell from "./cells/ParticipantNameCell/ParticipantNameCell";
import ParticipantAgeCell from "./cells/ParticipantAgeCell/ParticipantAgeCell";
import InternalCommentCell from "./cells/InternalCommentCell/InternalCommentCell";
import ActionButtons from "../../Components/Entity/ActionButtons/ActionButtons";
import { FormattedString, getString } from "Components/Common/FormattedString";

const ApplicationsList = ({
  recordsLimit,
  excludeCols = [],
  disablePagination = false,
  disableSearchBar = false,
}) => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const isInitialRender = useRef(true);

  const {
    applicationsData,
    count,
    currentPage,
    totalPages,
    pageSize,
    loading,
    error,
  } = useSelector((rootState) => rootState.Applications);

  const pageNumber = searchParams.get("page") || "1";
  const globalFilter = searchParams.get("searchTerm") || "";

  // Fetch initial data and update when searchParams change
  useEffect(() => {
    if (isInitialRender.current) {
      dispatch(getBusinessUnitListData());
      isInitialRender.current = false;
    }
    dispatch(
      getApplicationsListData(
        pageNumber,
        recordsLimit ? recordsLimit : pageSize,
        globalFilter,
      ),
    );
  }, [searchParams]);

  const columns = useMemo(
    () =>
      [
        {
          id: "id",
          Header: "ID",
          accessor: (row) => (
            <CustomIdCell
              customId={row.customId}
              to={`/pp-application/${row.id}`}
            />
          ),
        },
        {
          id: "status",
          Header: getString("status"),
          accessor: "applicationStatus",
          Cell: ({ value }) => (
            <div className="text-nowrap">
              <FormattedString id={value} />
            </div>
          ),
        },
        {
          id: "installments_short",
          Header: getString("installments_short"),
          accessor: (d) => (
            <InstallmentsCell
              installmentsPaid={d.installmentsPaid}
              installmentsCount={d.installmentsCount}
            />
          ),
        },
        {
          id: "name",
          Header: getString("name"),
          accessor: (d) =>
            `${d.customerFirstName || ""} ${d.customerLastName || ""}`.trim() ||
            "-",
        },
        {
          id: "program",
          Header: getString("program"),
          accessor: "programName",
        },
        {
          id: "week",
          Header: getString("week"),
          accessor: "week",
        },
        {
          id: "date",
          Header: getString("date"),
          accessor: (d) => (
            <div>
              <div>{format(new Date(d.dateStart), "yyyy-MM-dd")} - </div>
              <div>{format(new Date(d.dateEnd), "yyyy-MM-dd")}</div>
            </div>
          ),
        },
        {
          id: "package",
          Header: getString("package"),
          accessor: (d) => (
            <PackageCell
              packages={d.participants?.map((p) => p.programPacketName)}
            />
          ),
        },
        {
          id: "age",
          Header: getString("age"),
          accessor: (d) => (
            <ParticipantAgeCell ages={d.participants?.map((p) => p.age)} />
          ),
        },
        {
          id: "gender",
          Header: getString("gender"),
          accessor: (d) => (
            <GenderCell genders={d.participants?.map((p, index) => p.gender)} />
          ),
        },
        {
          id: "participant_name",
          Header: getString("participant_name"),
          accessor: (d) => (
            <ParticipantNameCell
              names={d.participants?.map((p) => p.firstName + " " + p.lastName)}
            />
          ),
        },
        {
          id: "internal_comment",
          Header: getString("internal_comment"),
          accessor: (d) => (
            <InternalCommentCell
              internalComment={d.internalComment}
              entityId={d.id}
            />
          ),
        },
        {
          id: "Actions",
          Header: () => (
            <div
              style={{
                textAlign: "right",
              }}
            >
              <FormattedString id="actions" />
            </div>
          ),
          disableSortBy: true,
          accessor: (cellProps) => (
            <ActionButtons
              customShowDetailsLink={`/pp-application/${cellProps.id}`}
              itemId={cellProps.id}
            />
          ),
        },
      ].filter((column) => !excludeCols.includes(column.id)),
    [],
  );

  const customSetGlobalFilter = (value) => {
    const newSearchParams = new URLSearchParams(searchParams);
    if (value) {
      newSearchParams.set("searchTerm", value);
    } else {
      newSearchParams.delete("searchTerm");
    }
    newSearchParams.set("page", "1");
    setSearchParams(newSearchParams);
  };

  const handlePageChange = (page) => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set("page", page.toString());
    setSearchParams(newSearchParams);
  };

  return (
    <div className="applications-list">
      <TableContainerExternalPagination
        columns={columns || []}
        count={count}
        currentPage={currentPage}
        totalPages={totalPages}
        pageSize={recordsLimit ? recordsLimit : pageSize}
        isPaginationActive={!disablePagination}
        loading={loading}
        globalFilter={globalFilter}
        setGlobalFilter={customSetGlobalFilter}
        onPageChange={handlePageChange}
        data={applicationsData || []}
        isGlobalFilter={!disableSearchBar}
        isBordered={false}
        className="custom-header-css table align-middle table-nowrap"
        tableClassName="table-centered align-middle table-nowrap mb-0"
        theadClassName="text-muted table-light"
        SearchPlaceholder={getString("applications_search_for")}
        error={error}
        onRetry={() =>
          dispatch(
            getApplicationsListData(
              pageNumber,
              recordsLimit ? recordsLimit : pageSize,
              globalFilter,
            ),
          )
        }
      />
    </div>
  );
};

export { ApplicationsList };
