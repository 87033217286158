import { call, put, all, fork, takeLatest } from "redux-saga/effects";

import { GET_PROMO_CODES_USAGE_LIST } from "./actionType";
import { promoCodesUsageApiSuccess, promoCodesUsageApiError } from "./action";

import { getPromoCodesUsageList } from "../../helpers/API/core-service/cs_backend_helper";

function* getPromoCodesUsageListData({ payload: data }) {
  try {
    const { page, searchTerm, pageSize } = data;
    const response = yield call(
      getPromoCodesUsageList,
      page,
      searchTerm,
      pageSize,
    );

    yield put(promoCodesUsageApiSuccess(GET_PROMO_CODES_USAGE_LIST, response));
  } catch (error) {
    yield put(promoCodesUsageApiError(GET_PROMO_CODES_USAGE_LIST, error));
  }
}

export function* watchGetPromoCodesUsageListData() {
  yield takeLatest(GET_PROMO_CODES_USAGE_LIST, getPromoCodesUsageListData);
}

function* promoCodesUsageSaga() {
  yield all([fork(watchGetPromoCodesUsageListData)]);
}

export default promoCodesUsageSaga;
