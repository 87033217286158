// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.program-status-badge {
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 6px;
  background: #2196f3;
  color: #ffffff;
  font-weight: 500;
  font-size: 0.8125rem;
  min-width: 90px;
}
.program-status-badge__container {
  display: flex;
}
.program-status-badge--in_preparation {
  background: #ffc107;
  color: #494949;
}
.program-status-badge--on_sale {
  background: #4caf50;
}
.program-status-badge--ongoing {
  background: #2196f3;
}
.program-status-badge--settlement {
  background: #f44336;
}
.program-status-badge--cancelled {
  background: #9e9e9e;
}`, "",{"version":3,"sources":["webpack://./src/Components/Common/ProgramStatusBadge/ProgramStatusBadge.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,cAAA;EACA,mBAAA;EACA,cAAA;EACA,gBAAA;EACA,oBAAA;EACA,eAAA;AACF;AACE;EACE,aAAA;AACJ;AAEE;EACE,mBAAA;EACA,cAAA;AAAJ;AAGE;EACE,mBAAA;AADJ;AAIE;EACE,mBAAA;AAFJ;AAKE;EACE,mBAAA;AAHJ;AAME;EACE,mBAAA;AAJJ","sourcesContent":[".program-status-badge {\n  border-radius: 4px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  padding: 0 6px;\n  background: #2196f3;\n  color: #ffffff;\n  font-weight: 500;\n  font-size: 0.8125rem;\n  min-width: 90px;\n\n  &__container {\n    display: flex;\n  }\n\n  &--in_preparation {\n    background: #ffc107;\n    color: #494949;\n  }\n\n  &--on_sale {\n    background: #4caf50;\n  }\n\n  &--ongoing {\n    background: #2196f3;\n  }\n\n  &--settlement {\n    background: #f44336;\n  }\n\n  &--cancelled {\n    background: #9e9e9e;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
