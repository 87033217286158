import React, { useState } from "react";
import { Col, Container, Row, Button, Spinner } from "reactstrap";
import EntityCardList from "../../Components/Entity/EntityCardList";
import { getString, FormattedString } from "Components/Common/FormattedString";
import { ProgramsList } from "./ListPrograms";
import AddProgram from "./AddProgram";
import { generateProgramReport } from "helpers/API/core-service/cs_backend_helper";
import { downloadBlob } from "helpers/fileUtils";

const Programs = () => {
  document.title = getString("programs_page_title");
  const [programAddVisible, setProgramAddVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const toggleProgramAdd = () => {
    setProgramAddVisible((p) => !p);
  };

  const handleGenerateReport = () => {
    setLoading(true);
    generateProgramReport()
      .then((response) => {
        const blob = new Blob([response.data], {
          type: "text/csv;charset=utf-8;",
        });
        downloadBlob(blob, response.headers, "program_report.csv");
      })
      .catch((error) => {
        console.error("Error generating report:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col>
              <div className="h-100">
                <EntityCardList
                  title={getString("programs_list")}
                  actionBtnPermission={"PROGRAM_CREATE"}
                  actionBtnLabel={getString("program_sm")}
                  actionBtn={toggleProgramAdd}
                  headerButtons={
                    <Button
                      color="light"
                      className="generate-report-button text-primary"
                      onClick={handleGenerateReport}
                      disabled={loading}
                    >
                      <FormattedString id="generate_report" />
                      {loading && (
                        <Spinner size="sm" color="primary" className="ms-2" />
                      )}
                    </Button>
                  }
                >
                  <ProgramsList />
                </EntityCardList>
              </div>
            </Col>
            {programAddVisible && (
              <AddProgram
                visibility={programAddVisible}
                toggle={toggleProgramAdd}
              />
            )}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Programs;
