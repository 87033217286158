// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.package-cell {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-right: 32px;
}
.package-cell__item {
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 6px;
}
.package-cell__item--adult {
  background: #f1c232;
  color: #494949;
}
.package-cell__item--family {
  background: #a64d79;
  color: #ffffff;
}
.package-cell__item--junior {
  background: #6aa84f;
  color: #ffffff;
}
.package-cell__item--kids {
  background: #93c47d;
  color: rgb(255, 255, 255);
}
.package-cell__item--junior_18 {
  background: #38761d;
  color: #ffffff;
}
.package-cell__item--junior_plus {
  background: #0b5394;
  color: #ffffff;
}
.package-cell__item--junior_international {
  background: #3d85c6;
  color: #ffffff;
}
.package-cell__item--company_or_school {
  background: #ff33a6;
  color: #ffffff;
}
.package-cell__item--usa {
  background: #85200c;
  color: #ffffff;
}`, "",{"version":3,"sources":["webpack://./src/pages/Applications/cells/PackageCell/PackageCell.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,QAAA;EACA,kBAAA;AACF;AACE;EACE,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,cAAA;AACJ;AACI;EACE,mBAAA;EACA,cAAA;AACN;AAEI;EACE,mBAAA;EACA,cAAA;AAAN;AAGI;EACE,mBAAA;EACA,cAAA;AADN;AAII;EACE,mBAAA;EACA,yBAAA;AAFN;AAKI;EACE,mBAAA;EACA,cAAA;AAHN;AAMI;EACE,mBAAA;EACA,cAAA;AAJN;AAOI;EACE,mBAAA;EACA,cAAA;AALN;AAQI;EACE,mBAAA;EACA,cAAA;AANN;AASI;EACE,mBAAA;EACA,cAAA;AAPN","sourcesContent":[".package-cell {\n  display: flex;\n  flex-direction: column;\n  gap: 5px;\n  margin-right: 32px;\n\n  &__item {\n    border-radius: 4px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    padding: 0 6px;\n\n    &--adult {\n      background: #f1c232;\n      color: #494949;\n    }\n\n    &--family {\n      background: #a64d79;\n      color: #ffffff;\n    }\n\n    &--junior {\n      background: #6aa84f;\n      color: #ffffff;\n    }\n\n    &--kids {\n      background: #93c47d;\n      color: rgb(255, 255, 255);\n    }\n\n    &--junior_18 {\n      background: #38761d;\n      color: #ffffff;\n    }\n\n    &--junior_plus {\n      background: #0b5394;\n      color: #ffffff;\n    }\n\n    &--junior_international {\n      background: #3d85c6;\n      color: #ffffff;\n    }\n\n    &--company_or_school {\n      background: #ff33a6;\n      color: #ffffff;\n    }\n\n    &--usa {\n      background: #85200c;\n      color: #ffffff;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
