import { FormattedString, getString } from "Components/Common/FormattedString";
import { Tile } from "Components/Common/Tile/Tile";
import React, { useContext, useState } from "react";
import { Col, Row, TabPane } from "reactstrap";
import { ApplicationDetailsContext } from "../../../ApplicationDetails";
import { FormSummary } from "./partials/FormSummary/FormSummary";
import FormikTextArea from "Components/Entity/FormikTextArea";
import { LinkButton } from "Components/Common/LinkButton/LinkButton";
import { Payments } from "../../components/Payments/Payments";
import NewNoteForm from "./partials/NewNoteForm";
import { addEntityNote } from "helpers/API/core-service/cs_backend_helper";
import { toast_error, toast_success } from "helpers/toast_helper";
import AdditionalNote from "./partials/AdditionalNote";
import ParticipantNote from "./ParticipantNote/ParticipantNote";
import { DeleteEntityNoteModal } from "./partials/DeleteEntityNoteModal";
import { WithPermission } from "Components/Common/WithPermission/WithPermission";
import { useHasPermission } from "Components/Hooks/useHasPermission";
import { useTransition, animated } from "react-spring";
import "./ApplicationOverviewTab.scss";
import ApplicationForm from "../../ApplicationForm/ApplicationForm";
import ApplicationDetailsRight from "../ApplicationActivityTab/partials/ApplicationDetailsRight/ApplicationDetailsRight";

export const ApplicationOverviewTab = () => {
  const {
    applicationData,
    refreshApplicationData,
    editTileOpen,
    setEditTileOpen,
  } = useContext(ApplicationDetailsContext);
  const [showNewNoteForm, setShowNewNoteForm] = useState(false);
  const [deleteEntityNoteModalVisible, setDeleteEntityNoteModalVisible] =
    useState(false);
  const [entityNoteToDelete, setEntityNoteToDelete] = useState(null);

  const handleAddNewNote = () => {
    setShowNewNoteForm(true);
  };

  const handleSubmitNote = (entityId, type, content, name) => {
    return addEntityNote(entityId, type, content, name)
      .then(() => {
        toast_success(getString("comment_added"));
        refreshApplicationData();
      })
      .catch((e) => {
        toast_error(e.message);
        throw e;
      });
  };

  const handleDeleteNote = (noteId) => {
    setEntityNoteToDelete(noteId);
    setDeleteEntityNoteModalVisible(true);
  };

  const entityNotes = applicationData.entityNotes;

  const applicationNote = entityNotes.find(
    (note) => note.type === "application",
  );

  const clientNote = entityNotes.find((note) => note.type === "customer");
  const additionalNotes = entityNotes.filter(
    (note) => note.type === "application_additional",
  );

  const canEditApplication = useHasPermission("PP_APPLICATION_UPDATE");

  const transitions = useTransition(editTileOpen, {
    from: { opacity: 0, transform: "translateY(-20px)" },
    enter: { opacity: 1, transform: "translateY(0)" },
    leave: { opacity: 0, transform: "translateY(-20px)" },
  });

  const handleCloseTile = () => {
    setEditTileOpen(false);
  };

  return (
    <TabPane tabId="overview" className="application-overview-tab">
      <Row>
        <Col xxl={3}>
          <FormSummary />
        </Col>
        <Col xxl={9}>
          {transitions((style, item) =>
            item ? (
              <animated.div style={style}>
                <Row
                  className={`expandable-tile ${editTileOpen ? "open" : ""}`}
                >
                  <Col xxl={12}>
                    <Tile
                      title={getString("editing_application")}
                      gap="14px"
                      titleDropdown={
                        <button
                          type="button"
                          className="btn-icon application-overview-tab__close"
                          onClick={handleCloseTile}
                        >
                          <i className="ri-close-line"></i>
                        </button>
                      }
                    >
                      <ApplicationForm />
                    </Tile>
                  </Col>
                </Row>
              </animated.div>
            ) : null,
          )}
          <Row>
            <Col xxl={6}>
              <Tile title={getString("payments")} gap="14px">
                <Payments />
              </Tile>

              <Tile
                title={getString("application_note")}
                gap="14px"
                titleDropdown={
                  applicationNote &&
                  canEditApplication && (
                    <button
                      type="button"
                      className="btn btn-sm btn-soft-danger btn-icon"
                      onClick={() => handleDeleteNote(applicationNote.id)}
                    >
                      <i className="ri-delete-bin-line"></i>
                    </button>
                  )
                }
              >
                <FormikTextArea
                  name="applicationNote"
                  value={applicationNote ? applicationNote.content : ""}
                  key={applicationNote?.content || "applicationNote"}
                  disabled={!canEditApplication}
                  handleSubmit={(value) =>
                    handleSubmitNote(applicationData.id, "application", value)
                  }
                />
              </Tile>

              <Tile title={getString("client_details")} gap="14px">
                <h6>{getString("comment")}</h6>
                <FormikTextArea
                  name="clientComment"
                  disabled
                  value={applicationData.clientComment}
                  handleSubmit={(value) =>
                    handleSubmitNote(applicationData.id, "clientComment", value)
                  }
                />
                <h6>{getString("note")}</h6>
                <FormikTextArea
                  name="clientNote"
                  value={clientNote ? clientNote.content : ""}
                  disabled={!canEditApplication}
                  handleSubmit={(value) =>
                    handleSubmitNote(
                      applicationData.payerDetails.customerId,
                      "customer",
                      value,
                    )
                  }
                />
              </Tile>

              {applicationData.participants.map((participant, index) => {
                const additionalNote = applicationData.entityNotes.find(
                  (note) => note.entityId === participant.participantId,
                );

                return (
                  <Tile
                    key={index}
                    title={`${participant.firstName} ${participant.lastName} ${getString("details")}`}
                    gap="14px"
                  >
                    <h6>{getString("comment")}</h6>
                    <FormikTextArea
                      name={`participantComment_${index}`}
                      value={participant.comment}
                      disabled
                      handleSubmit={(value) =>
                        handleSubmitNote(
                          participant.participantId,
                          "participantComment",
                          value,
                        )
                      }
                    />
                    <h6>{getString("note")}</h6>
                    {additionalNote ? (
                      <ParticipantNote
                        initialValues={additionalNote}
                        key={additionalNote?.content || "additionalNote"}
                        disabled={!canEditApplication}
                        handleSubmitNote={(value) =>
                          handleSubmitNote(
                            additionalNote.entityId,
                            "participant",
                            value.content,
                          )
                        }
                      />
                    ) : (
                      <FormikTextArea
                        name={`participantNote_${index}`}
                        value={participant.note}
                        disabled={!canEditApplication}
                        handleSubmit={(value) =>
                          handleSubmitNote(
                            participant.participantId,
                            "participant",
                            value,
                          )
                        }
                      />
                    )}
                  </Tile>
                );
              })}

              {additionalNotes.map((note, index) => (
                <Tile
                  title={getString(note.name) + " " + getString("note")}
                  gap="14px"
                  key={note.content + note.id || index}
                  titleDropdown={
                    canEditApplication && (
                      <button
                        type="button"
                        className="btn btn-sm btn-soft-danger btn-icon"
                        onClick={() => handleDeleteNote(note.id)}
                      >
                        <i className="ri-delete-bin-line"></i>
                      </button>
                    )
                  }
                >
                  <AdditionalNote
                    initialValues={note}
                    disabled={!canEditApplication}
                    handleSubmitNote={(values) =>
                      handleSubmitNote(
                        applicationData.id,
                        "application_additional",
                        values.content,
                        values.name,
                      )
                    }
                  />
                </Tile>
              ))}

              {!showNewNoteForm && (
                <WithPermission permissionName="PP_APPLICATION_UPDATE">
                  <LinkButton onClick={handleAddNewNote}>
                    <i
                      className="ri-add-line"
                      style={{ marginRight: "4px" }}
                    ></i>
                    <FormattedString id="add_new_note" />
                  </LinkButton>
                </WithPermission>
              )}

              {showNewNoteForm && (
                <Tile title={getString("new_note")} gap="14px">
                  <NewNoteForm
                    handleSubmitNote={(values) =>
                      handleSubmitNote(
                        applicationData.id,
                        "application_additional",
                        values.content,
                        values.name,
                      )
                    }
                    toggle={() => setShowNewNoteForm(false)}
                  />
                </Tile>
              )}
            </Col>

            <Col xxl={6}>
              <ApplicationDetailsRight />
            </Col>
          </Row>
        </Col>
      </Row>

      {deleteEntityNoteModalVisible && (
        <DeleteEntityNoteModal
          noteId={entityNoteToDelete}
          visibility={true}
          toggle={() => setDeleteEntityNoteModalVisible(false)}
        />
      )}
    </TabPane>
  );
};
