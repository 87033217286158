export const excludedUrls = [
  "/program", // GET_PROGRAM_LIST_DATA
  "/point-of-sell", // GET_POINT_OF_SELL_LIST_DATA
  "/meeting-point", // GET_MEETING_POINT_LIST_DATA
  "/upsell", // GET_UPSELL_LIST_DATA
  "/international-participant", // GET_IP_PARTICIPANT_LIST_DATA
  "/business-unit", // GET_BUSINESS_UNIT_LIST_DATA
  "/program-packet-template", // GET_PROGRAM_PACKET_TEMPLATE_LIST_DATA
  "/coordinator", // GET_COORDINATOR_LIST_DATA
  "/user", // GET_USER_LIST_DATA
  "/attachment", // GET_ATTACHMENT_LIST_DATA
  "/application", // GET_APPLICATIONS_LIST_DATA
  "/payment-method", // GET_PAYMENT_METHOD_LIST_DATA
  "/crm/customer", // GET_CUSTOMERS_LIST_DATA
  "/installment-plan", // GET_INSTALLMENT_PLAN_LIST_DATA
  "/hotel", // GET_HOTEL_LIST_DATA
];

export const shouldRedirect = (config) => {
  return !excludedUrls.includes(config.url);
};
