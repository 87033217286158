import {
  API_RESPONSE_SUCCESS,
  API_RESPONSE_ERROR,
  GET_HOTEL_LIST_DATA,
  POST_HOTEL,
  EDIT_HOTEL,
  ARCHIVE_HOTEL,
  RESTORE_HOTEL,
} from "./actionType";

export const INIT_STATE = {
  hotelsData: [],
  formValidationErrors: {},
  error: null,
  loading: false,
  isHotelAdded: false,
  isHotelEdited: false,
  isHotelArchived: false,
  isHotelRestored: false,
};

const Hotels = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_HOTEL_LIST_DATA:
    case POST_HOTEL:
    case EDIT_HOTEL:
    case ARCHIVE_HOTEL:
    case RESTORE_HOTEL:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case GET_HOTEL_LIST_DATA:
          return {
            ...state,
            hotelsData: action.payload.data,
            isHotelAdded: false,
            isHotelEdited: false,
            isHotelArchived: false,
            isHotelRestored: false,
            loading: false,
            error: null,
          };
        case POST_HOTEL:
          return {
            ...state,
            loading: false,
            isHotelAdded: true,
            error: null,
          };
        case EDIT_HOTEL:
          return {
            ...state,
            loading: false,
            isHotelEdited: true,
            error: null,
          };
        case ARCHIVE_HOTEL:
          return {
            ...state,
            loading: false,
            isHotelArchived: true,
            error: null,
          };
        case RESTORE_HOTEL:
          return {
            ...state,
            loading: false,
            isHotelRestored: true,
            error: null,
          };
        default:
          return state;
      }
    case API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case GET_HOTEL_LIST_DATA:
          return {
            ...state,
            errorMsg: action.payload.data,
            error: action.payload.error,
            isHotelAdded: false,
            isHotelEdited: false,
            isHotelArchived: false,
            isHotelRestored: false,
            loading: false,
          };
        case POST_HOTEL:
          return {
            ...state,
            error: action.payload.error,
            formValidationErrors: action.payload.error.data?.fields ?? null,
            loading: false,
            isHotelAdded: false,
          };
        case EDIT_HOTEL:
          return {
            ...state,
            error: action.payload.error,
            formValidationErrors: action.payload.error.data?.fields ?? null,
            loading: false,
            isHotelEdited: false,
          };
        case ARCHIVE_HOTEL:
          return {
            ...state,
            error: action.payload.error,
            loading: false,
            isHotelArchived: false,
          };
        case RESTORE_HOTEL:
          return {
            ...state,
            error: action.payload.error,
            loading: false,
            isHotelRestored: false,
          };
        default:
          return state;
      }
    default:
      return state;
  }
};

export default Hotels;
