import React from "react";
import { Alert, Button } from "reactstrap";
import { getString } from "Components/Common/FormattedString";
import errorImage from "assets/images/error.svg";
import { PreloaderWrap } from "../Preloader/Preloader";

const DataLoadError = ({ errorMessage, onRetry, loading }) => {
  return (
    <div
      className="data-load-error"
      style={{
        height: "calc(100vh - 290px)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {loading ? (
        <PreloaderWrap />
      ) : (
        <>
          <img
            src={errorImage}
            alt="Error"
            style={{ maxWidth: "250px", marginBottom: "20px" }}
          />
          <Alert
            color="primary"
            style={{ width: "100%", maxWidth: "500px", textAlign: "center" }}
          >
            <h4 className="alert-heading">{getString("data_load_error")}</h4>
            <p>{errorMessage || getString("unexpected_error_occurred")}</p>
            {onRetry && (
              <Button color="primary" onClick={onRetry}>
                {getString("retry")}
              </Button>
            )}
          </Alert>
        </>
      )}
    </div>
  );
};

export default DataLoadError;
