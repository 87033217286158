module.exports = {
  paths: {
    GOOGLE_LOGIN_URL: process.env.REACT_APP_ACCOUNT_API_URL + "crm/auth/google",
  },
  apiErrorCodes: {
    INTERNAL_SERVER_ERROR: 500,
    SEE_OTHER: 303,
    FORM_VALIDATION: 400,
    UNAUTHORIZED: 401,
    FORBIDDEN: 403,
    NOT_FOUND: 404,
    CONFLICT: 409,
    TOKEN_EXPIRED: 410,
  },
  apiErrorMessages: {
    INTERNAL_SERVER_ERROR: "api_internal_server_error",
    SEE_OTHER: "api_see_other",
    FORM_VALIDATION: "api_form_validation",
    UNAUTHORIZED: "api_unauthorized",
    FORBIDDEN: "api_forbidden",
    NOT_FOUND: "api_not_found",
    CONFLICT: "api_conflict",
  },
  consts: {
    GLOBAL_PAGE_SIZE: 10,
    ARCHIVED_STATUS: "archived",
  },
};
