import React from "react";
import { Row, Col } from "reactstrap";
import { FormattedString } from "Components/Common/FormattedString";

const StatusCounts = ({ statusCounts }) => {
  return (
    <Row className="mt-3 justify-content-center">
      <Col
        className="d-flex align-items-center justify-content-center"
        key="overdue"
      >
        <div className="dot bg-danger me-2"></div>
        <span className="text-muted">
          <FormattedString id="overdue" />: {statusCounts.overdue}
        </span>
      </Col>
      <Col
        className="d-flex align-items-center justify-content-center"
        key="awaiting"
      >
        <div className="dot bg-warning me-2"></div>
        <span className="text-muted">
          <FormattedString id="awaiting" />: {statusCounts.awaiting}
        </span>
      </Col>
      <Col
        className="d-flex align-items-center justify-content-center"
        key="paid"
      >
        <div className="dot bg-success me-2"></div>
        <span className="text-muted">
          <FormattedString id="paid" />: {statusCounts.paid}
        </span>
      </Col>
      <Col
        className="d-flex align-items-center justify-content-center"
        key="future"
      >
        <div className="dot me-2" style={{ backgroundColor: "#878a99" }}></div>
        <span className="text-muted">
          <FormattedString id="future" />: {statusCounts.future}
        </span>
      </Col>
      <Col
        className="d-flex align-items-center justify-content-center"
        key="refund"
      >
        <div className="dot bg-primary me-2"></div>
        <span className="text-muted">
          <FormattedString id="refund" />: {statusCounts.refund}
        </span>
      </Col>
    </Row>
  );
};

export default StatusCounts;
