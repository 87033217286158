// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-summary {
  background: #428bca;
  border-radius: 18px;
  padding: 21px;
  box-shadow: 0 3px 3px rgba(56, 65, 74, 0.34);
  margin-bottom: 20px;
  color: white;
}
.form-summary--moved {
  background: #6c757d;
}
.form-summary--moved .program-short__hotel a {
  color: #6c757d !important;
}
.form-summary--moved .participant-short__sum::before {
  background: white;
}
.form-summary--moved .discounts-short__section__item__name {
  color: #6c757d;
}
.form-summary--moved .discounts-short__sum::before {
  background: #6c757d;
}
.form-summary--moved .discounts-short__sum {
  color: #6c757d;
}
.form-summary__section__header {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 10px;
}
.form-summary__no-participants, .form-summary__no-discounts {
  margin-bottom: 10px;
}
.form-summary__footer {
  display: flex;
  justify-content: space-between;
  font-size: 24px;
  font-weight: 700;
  text-align: left;
}`, "",{"version":3,"sources":["webpack://./src/pages/Applications/ApplicationDetails/partials/Tabs/ApplicationOverviewTab/partials/FormSummary/FormSummary.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,mBAAA;EACA,aAAA;EACA,4CAAA;EACA,mBAAA;EACA,YAAA;AACF;AACE;EACE,mBAAA;AACJ;AAAI;EACE,yBAAA;AAEN;AACI;EACE,iBAAA;AACN;AAEI;EACE,cAAA;AAAN;AAGI;EACE,mBAAA;AADN;AAII;EACE,cAAA;AAFN;AAOI;EACE,eAAA;EACA,gBAAA;EACA,mBAAA;AALN;AASE;EAEE,mBAAA;AARJ;AAWE;EACE,aAAA;EACA,8BAAA;EACA,eAAA;EACA,gBAAA;EACA,gBAAA;AATJ","sourcesContent":[".form-summary {\n  background: #428bca;\n  border-radius: 18px;\n  padding: 21px;\n  box-shadow: 0 3px 3px rgb(56 65 74 / 34%);\n  margin-bottom: 20px;\n  color: white;\n\n  &--moved {\n    background: #6c757d;\n    .program-short__hotel a {\n      color: #6c757d !important;\n    }\n\n    .participant-short__sum::before {\n      background: white;\n    }\n\n    .discounts-short__section__item__name {\n      color: #6c757d;\n    }\n\n    .discounts-short__sum::before {\n      background: #6c757d;\n    }\n\n    .discounts-short__sum {\n      color: #6c757d;\n    }\n  }\n\n  &__section {\n    &__header {\n      font-size: 20px;\n      font-weight: 700;\n      margin-bottom: 10px;\n    }\n  }\n\n  &__no-participants,\n  &__no-discounts {\n    margin-bottom: 10px;\n  }\n\n  &__footer {\n    display: flex;\n    justify-content: space-between;\n    font-size: 24px;\n    font-weight: 700;\n    text-align: left;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
