import React from "react";
import { ModalBody, Spinner } from "reactstrap";
import confirm_img from "../../assets/imagesn/confirm_delete.gif";
import { Link } from "react-router-dom";
import { FormattedString } from "Components/Common/FormattedString";

const ConfirmDetachBody = ({ entityName, toggle, deleteAction, loading }) => {
  return (
    <ModalBody className="text-center p-5">
      <div className="">
        <img
          src={confirm_img}
          alt=""
          height={250}
          className="error-basic-img move-animation"
        />
      </div>

      <div className="mt-4">
        <h4 className="mb-3">
          <FormattedString id="detach_modal_title" /> {entityName}{" "}
          <FormattedString id="detach_modal_title_2" />
        </h4>
        <p className="text-muted mb-4">
          <FormattedString id="detach_modal_warning" />
        </p>
        <p className="text-muted mb-4">
          <u>
            <FormattedString id="detach_modal_reminder" />
          </u>
        </p>
        <div className="hstack gap-4 justify-content-center">
          <Link
            to="#"
            className="btn btn-success fw-medium"
            onClick={() => toggle(false)}
          >
            <FormattedString id="cancel" />
          </Link>
          <button
            type="button"
            className="btn btn-danger"
            onClick={deleteAction}
            disabled={loading}
          >
            {loading ? <Spinner size="sm" /> : <FormattedString id="remove" />}
          </button>
        </div>
      </div>
    </ModalBody>
  );
};

export default ConfirmDetachBody;
