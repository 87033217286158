import logoBg from "assets/images/logo.svg";
import { Col, Row } from "reactstrap";
import { ApplicationDetailsContext } from "../ApplicationDetails";
import { useContext } from "react";
import { formatDate } from "helpers/utils";
import { FormattedString } from "Components/Common/FormattedString";

export const ApplicationHeader = () => {
  const { applicationData } = useContext(ApplicationDetailsContext);

  return (
    <div className="application-details__header">
      <div className="application-details__header__foreground">
        <div className="application-details__header__background">
          <img
            src={logoBg}
            alt=""
            className="application-details__header__background__image"
          />
        </div>
      </div>
      <div className="pt-4 mb-4 mb-lg-3 pb-lg-4">
        <Row className="g-4">
          <div className="col-auto d-flex align-items-center">
            <div className="application-details__header__avatar">
              <i className="application-details__header__thumb ri-file-list-line"></i>
            </div>
          </div>

          <Col>
            <div className="p-2">
              <div className="application-details__header__top">
                <div className="application-details__header__top__left">
                  <h3 className="application-details__header__top__left__name">
                    {applicationData.customId}
                  </h3>
                  <p className="application-details__header__top__left__paragraph">
                    {applicationData.programName}
                  </p>
                </div>
              </div>
              <Row>
                <Col>
                  <div className="hstack text-white gap-1">
                    {applicationData.hotelName && (
                      <div className="me-2">
                        <i className="ri-building-line me-1 text-white fs-16 align-middle"></i>
                        {applicationData.hotelName}
                      </div>
                    )}
                    <div>
                      <i className="ri-calendar-2-line me-1 text-white fs-16 align-middle"></i>
                      {formatDate(applicationData.startDate)} -{" "}
                      {formatDate(applicationData.endDate)}
                    </div>
                  </div>
                  {applicationData.meetingPoint && (
                    <div className="mt-2 text-white">
                      <i className="ri-map-pin-line me-1 text-white fs-16 align-middle"></i>
                      {applicationData.meetingPoint.city},{" "}
                      {applicationData.meetingPoint.address}
                    </div>
                  )}
                </Col>
              </Row>
            </div>
          </Col>

          <Col xs={12} className="col-lg-auto order-last order-lg-0">
            <div className="application-status">
              <FormattedString id={applicationData.status} />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};
