// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.payments .dot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
}
.payments__copy {
  display: flex;
  justify-content: flex-end;
}
.payments__copy__icon {
  color: #007bff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  width: 24px;
  height: 27px;
  font-size: 10px;
  border-radius: 0.2rem;
  background-color: rgba(0, 123, 255, 0.1);
  cursor: pointer;
  transition: background-color 0.15s ease-in-out, color 0.15s ease-in-out;
}
.payments__copy__icon:hover {
  background-color: rgba(0, 123, 255, 0.2);
  color: #0056b3;
}
.payments__copy__icon.copied {
  background-color: rgba(40, 167, 69, 0.1); /* Soft success background */
  color: #28a745; /* Success color */
  font-size: 13px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Applications/ApplicationDetails/partials/components/Payments/Payments.scss"],"names":[],"mappings":"AACE;EACE,WAAA;EACA,YAAA;EACA,kBAAA;AAAJ;AAGE;EACE,aAAA;EACA,yBAAA;AADJ;AAGI;EACE,cAAA;EACA,oBAAA;EACA,mBAAA;EACA,uBAAA;EACA,YAAA;EACA,WAAA;EACA,YAAA;EACA,eAAA;EACA,qBAAA;EACA,wCAAA;EACA,eAAA;EACA,uEACE;AAFR;AAKM;EACE,wCAAA;EACA,cAAA;AAHR;AAMM;EACE,wCAAA,EAAA,4BAAA;EACA,cAAA,EAAA,kBAAA;EACA,eAAA;AAJR","sourcesContent":[".payments {\n  .dot {\n    width: 12px;\n    height: 12px;\n    border-radius: 50%;\n  }\n\n  &__copy {\n    display: flex;\n    justify-content: flex-end;\n\n    &__icon {\n      color: #007bff;\n      display: inline-flex;\n      align-items: center;\n      justify-content: center;\n      padding: 4px;\n      width: 24px;\n      height: 27px;\n      font-size: 10px;\n      border-radius: 0.2rem;\n      background-color: rgba(0, 123, 255, 0.1);\n      cursor: pointer;\n      transition:\n        background-color 0.15s ease-in-out,\n        color 0.15s ease-in-out;\n\n      &:hover {\n        background-color: rgba(0, 123, 255, 0.2);\n        color: #0056b3;\n      }\n\n      &.copied {\n        background-color: rgba(40, 167, 69, 0.1); /* Soft success background */\n        color: #28a745; /* Success color */\n        font-size: 13px;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
