import React, { useEffect, useMemo, useState } from "react";
import {
  currentLocation,
  getDefaultFilterType,
  getItemUuid,
  getLocation,
  isValidUuid,
  parentLocation,
} from "helpers/utils";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAttachmentListData } from "../../store/attachments/action";
import TableContainer from "../../Components/Common/TableContainerReactTable";
import ArchiveAttachment from "./ArchiveAttachment";
import EditAttachment from "./EditAttachment";
import ActionButtons from "../../Components/Entity/ActionButtons/ActionButtons";
import EntityListArchive from "../../Components/Entity/EntityListArchive";
import RestoreAttachment from "./RestoreAttachment";
import {
  FormattedString,
  getString,
  getStringSync,
} from "Components/Common/FormattedString";
import { AttachmentLink } from "Components/Common/AttachmentLink/AttachmentLink";
import { AttachmentDetails } from "./AttachmentDetails";
import { Badges } from "Components/Common/Badges/Badges";

const AttachmentsList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { attachmentsData, loading, error } = useSelector(
    (rootState) => rootState.Attachments,
  );
  const { activeBusinessUnit, user } = useSelector(
    (rootState) => rootState.Profile,
  );

  const businessUnits = user.businessUnits;

  const filterListByTypeAndUnit = (attachmentsData, type) => {
    return attachmentsData.filter(
      (entry) =>
        entry.status === type &&
        (activeBusinessUnit.id === "all" ||
          entry.businessUnitId === activeBusinessUnit.id),
    );
  };

  useEffect(() => {
    dispatch(getAttachmentListData());
  }, [dispatch]);

  const [editModalVisible, setEditModalVisible] = useState(false);
  const [attachmentData, setAttachmentData] = useState();
  const [filterType, setFilterType] = useState(getDefaultFilterType());
  const [filteredData, setFilteredData] = useState(
    filterListByTypeAndUnit(attachmentsData, getDefaultFilterType()),
  );

  const toggleEditModal = (attachment, direction = false) => {
    setAttachmentData(attachment);
    setEditModalVisible(direction);
  };

  useEffect(() => {
    toggleFilter(filterType, activeBusinessUnit);
  }, [attachmentsData, activeBusinessUnit]);

  const toggleFilter = (type) => {
    setFilteredData(
      filterListByTypeAndUnit(attachmentsData, type, activeBusinessUnit),
    );
    setFilterType(type);
  };

  const [itemId, setModalItemId] = useState(getItemUuid());
  const [archiveModalVisible, setArchiveModalVisible] = useState(false);
  const toggleArchiveModal = (id) => {
    setModalItemId(id);
    setArchiveModalVisible((p) => !p);
  };

  const [restoreModalVisible, setRestoreModalVisible] = useState(false);
  const toggleRestoreModal = (id) => {
    setModalItemId(id);
    setRestoreModalVisible((p) => !p);
  };

  const [detailsModalVisible, setDetailsModalVisible] = useState(
    isValidUuid(getLocation(3)) || isValidUuid(getLocation(2)),
  );

  const toggleDetailsModal = (state, id) => {
    setDetailsModalVisible(state);
    state === false
      ? navigate(parentLocation())
      : navigate(`${currentLocation()}/${id}`);
    setModalItemId(id);
  };

  const columns = useMemo(
    () => [
      {
        Header: getString("file_name"),
        Cell: (cellProps, ...rest) => (
          <AttachmentLink
            fileName={cellProps.row.original.name}
            href={cellProps.row.original.filePath}
          />
        ),
        accessor: (cellProps) => cellProps.name,
      },
      ...(activeBusinessUnit.id === "all"
        ? [
            {
              Header: getString("business_unit"),
              Cell: (cellProps) => {
                const businessUnit = businessUnits.find(
                  (bu) => bu.id === cellProps.row.original.businessUnitId,
                );
                return (
                  <Badges
                    feed={[
                      businessUnit
                        ? businessUnit.name
                        : getStringSync("all_business_units"),
                    ]}
                  />
                );
              },
              accessor: (cellProps) => {
                const businessUnit = businessUnits.find(
                  (bu) => bu.id === cellProps.businessUnitId,
                );
                return businessUnit ? businessUnit.name : "";
              },
            },
          ]
        : []),
      {
        id: "Actions",
        Header: () => (
          <div
            style={{
              textAlign: "right",
            }}
          >
            <FormattedString id="actions" />
          </div>
        ),
        disableSortBy: true,
        accessor: (cellProps) => (
          <ActionButtons
            onArchive={() => toggleArchiveModal(cellProps.id)}
            isRestorable={cellProps.status === "archived"}
            onEdit={() => toggleEditModal(cellProps, true)}
            onShowDetails={() => toggleDetailsModal(true, cellProps.id)}
            onRestore={() => toggleRestoreModal(cellProps.id)}
            itemId={cellProps.id}
            permissionPrefix="ATTACHMENT"
          />
        ),
      },
    ],
    [activeBusinessUnit],
  );

  return (
    <>
      <EntityListArchive toggleFilter={toggleFilter} />
      <TableContainer
        columns={columns || []}
        data={filteredData || []}
        isPagination={true}
        isGlobalFilter={true}
        isBordered={false}
        customPageSize={10}
        className="custom-header-css table align-middle table-nowrap"
        tableClassName="table-centered align-middle table-nowrap mb-0"
        theadClassName="text-muted table-light"
        SearchPlaceholder={getString("attachments_search_for")}
        error={error}
        loading={loading}
        onRetry={() => dispatch(getAttachmentListData())}
        preventLoading={
          editModalVisible ||
          archiveModalVisible ||
          restoreModalVisible ||
          detailsModalVisible
        }
      />
      {editModalVisible && (
        <EditAttachment
          itemId={itemId}
          visibility={editModalVisible}
          toggle={toggleEditModal}
          attachment={attachmentData}
        />
      )}
      {archiveModalVisible && (
        <ArchiveAttachment
          itemId={itemId}
          visibility={archiveModalVisible}
          toggle={toggleArchiveModal}
        />
      )}
      {restoreModalVisible && (
        <RestoreAttachment
          itemId={itemId}
          visibility={restoreModalVisible}
          toggle={toggleRestoreModal}
        />
      )}
      {detailsModalVisible && (
        <AttachmentDetails
          itemId={itemId}
          toggle={toggleDetailsModal}
          visible={detailsModalVisible}
        />
      )}
    </>
  );
};

export { AttachmentsList };
