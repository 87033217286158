// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tooltip {
  pointer-events: none;
}

.tooltip-arrow {
  pointer-events: none;
}`, "",{"version":3,"sources":["webpack://./src/Components/Common/AngloTooltip/AngloTooltip.scss"],"names":[],"mappings":"AAAA;EACE,oBAAA;AACF;;AAEA;EACE,oBAAA;AACF","sourcesContent":[".tooltip {\n  pointer-events: none;\n}\n\n.tooltip-arrow {\n  pointer-events: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
