import React from "react";
import { Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import { FormattedString, getString } from "Components/Common/FormattedString";
import { useFormContext } from "../../provider/utils/FormContext";

const ParticipantTabs = ({
  activeTab,
  setActiveTab,
  handleAddParticipant,
  handleRemoveParticipant,
}) => {
  const { formik } = useFormContext();

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <Nav tabs>
      {formik.values.participants.map((participant, index) => {
        const participantErrors = formik.errors.participants?.[index] || {};
        const { upsells, ...restErrors } = participantErrors;
        const hasErrors = Object.keys(restErrors).length > 0;

        return (
          <NavItem key={participant.id || index}>
            <NavLink
              className={classnames({
                active: activeTab === index,
                "text-danger": hasErrors,
              })}
              onClick={() => {
                toggle(index);
              }}
              style={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
              }}
            >
              {participant.firstName && participant.lastName
                ? `${participant.firstName} ${participant.lastName}`
                : `${getString("participant")} ${index + 1}`}
              {formik.values.participants.length > 1 && (
                <i
                  className="ri-close-line ml-2"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleRemoveParticipant(index);
                  }}
                  style={{ cursor: "pointer", marginLeft: "10px" }}
                />
              )}
            </NavLink>
          </NavItem>
        );
      })}
      <NavItem>
        <NavLink onClick={handleAddParticipant} style={{ cursor: "pointer" }}>
          <i className="ri-add-line" /> <FormattedString id="add_participant" />
        </NavLink>
      </NavItem>
    </Nav>
  );
};

export default ParticipantTabs;
