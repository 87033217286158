import React, { useRef, useState } from "react";
import "./AngloTooltip.scss";
import { Tooltip } from "reactstrap";
import { FormattedString } from "../FormattedString";

export const AngloTooltip = ({
  children,
  className,
  text,
  placement = "top",
  stringId,
  enabled = true,
  nowrap = false,
}) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggleRef = useRef(null);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <>
      <div className="tooltip-toggler" ref={toggleRef}>
        {children}
      </div>
      {enabled && (
        <Tooltip
          placement={placement}
          className={className}
          isOpen={tooltipOpen}
          target={toggleRef}
          toggle={toggle}
        >
          <div className={nowrap ? "text-nowrap" : ""}>
            {stringId ? <FormattedString id={stringId} /> : text}
          </div>
        </Tooltip>
      )}
    </>
  );
};
