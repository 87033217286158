export const handleMultiSelectChange = async (
  validation,
  selectedOptions,
  fieldName,
) => {
  await validation.setFieldValue(fieldName, selectedOptions, fieldName);
  await validation.validateField(fieldName);
};

export const handleSelectChange = async (
  validation,
  selectedOption,
  fieldName,
) => {
  const value = selectedOption ? selectedOption.value : "";
  await validation.setFieldValue(fieldName, value);
  await validation.validateField(fieldName);
};

export const handleCheckboxGroupChange = async (
  validation,
  value,
  fieldName,
) => {
  const currentValues = validation.values[fieldName] || [];
  const newValues = currentValues.includes(value)
    ? currentValues.filter((v) => v !== value)
    : [...currentValues, value];

  await validation.setFieldValue(fieldName, newValues);
  await validation.validateField(fieldName);
};

export const handleFileChange = async (validation, e, fieldName) => {
  await validation.setFieldValue(fieldName, e.target.files[0]);
  await validation.validateField(fieldName);
};

export const handleInputChange = async (validation, e, fieldName) => {
  await validation.handleChange(e);
  await validation.validateField(fieldName);
};

export const handleHtmlEditorChange = async (
  validation,
  content,
  fieldName,
) => {
  validation
    .setFieldValue(fieldName, content)
    .then(() => validation.validateField(fieldName));
};

export const handleNumberInputChange = async (validation, e, fieldName) => {
  const newValue = Number(e.target.value) || 0;
  const syntheticEvent = {
    target: {
      name: e.target.name,
      value: newValue,
    },
  };
  await validation.handleChange(syntheticEvent);
  await validation.validateField(fieldName);
};

export const handleNumberInputChange2 = (formik, e, field) => {
  const value = e.target.value;
  formik.setFieldValue(field, value === "" ? "" : Number(value));
};

export const setValidationErrors = (validation, formValidationErrors) => {
  if (Array.isArray(formValidationErrors)) {
    const transformedErrors = formValidationErrors.reduce((errors, field) => {
      errors[field.name] = field.message;
      return errors;
    }, {});
    validation.setErrors(transformedErrors);
  } else if (formValidationErrors && typeof formValidationErrors === "object") {
    // Handle the case where formValidationErrors is an object
    validation.setErrors(formValidationErrors);
  }
};

export const isValidationError = (code) => {
  return code === 400;
};
