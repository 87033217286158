import { FormattedString } from "Components/Common/FormattedString";
import "./ProgramShort.scss";
import { format } from "date-fns";
import { pl } from "date-fns/locale";
import { useContext } from "react";
import { Link } from "react-router-dom";
import { ApplicationDetailsContext } from "pages/Applications/ApplicationDetails/ApplicationDetails";

const ProgramShort = ({
  hotelName,
  meetingPoint,
  programStartDate,
  programEndDate,
}) => {
  const { applicationData } = useContext(ApplicationDetailsContext);

  const startDate = programStartDate
    ? format(new Date(programStartDate), "dd.MM.yyyy", { locale: pl })
    : null;

  const endDate = programStartDate
    ? format(new Date(programEndDate), "dd.MM.yyyy", { locale: pl })
    : null;

  return (
    <div className="program-short">
      <div className="program-short__hotel">
        <Link to={`/program/${applicationData.programId}`}>
          {hotelName || <FormattedString id="na" />}
        </Link>
      </div>
      <div className="program-short__dates">
        <FormattedString id="from" />{" "}
        <span className="program-short__dates--bold">
          {startDate || <FormattedString id="na" />}
        </span>{" "}
        <FormattedString id="to" />{" "}
        <span className="program-short__dates--bold">
          {endDate || <FormattedString id="na" />}
        </span>
      </div>
      <div className="program-short__meeting">
        <span className="program-short__meeting--from">
          <FormattedString id="transport" />:{" "}
        </span>
        {meetingPoint?.city || <FormattedString id="na" />}
      </div>
    </div>
  );
};

export default ProgramShort;
