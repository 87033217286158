import React, { useContext, useEffect, useState, useRef } from "react";
import { FormSelect } from "Components/Entity/FormSelect";
import { Card, CardBody, Form } from "reactstrap";
import {
  handleSelectChange,
  handleInputChange,
} from "helpers/validation_helper";
import { useFormik } from "formik";
import { getString } from "Components/Common/FormattedString";
import OffcanvasRight from "Components/Entity/OffcanvasRight";
import * as Yup from "yup";
import { ProgramDetailsContext } from "../../ProgramDetails";
import { toast_success } from "helpers/toast_helper";
import { updateDiscountInProgram } from "helpers/API/core-service/cs_backend_helper";
import FormNumberField from "Components/Entity/FormNumberField";
import FormDateTimeField from "Components/Entity/FormDateTimeField";
import { programDiscountOptions } from "models/programDiscounts";
import FormTextField from "Components/Entity/FormTextField";
import { parseErrorToFormikErrors } from "helpers/parseErrorsToFormik";

const convertToLocalDateTime = (dateString) => {
  const date = new Date(dateString);
  const pad = (num) => (num < 10 ? "0" + num : num);

  const year = date.getFullYear();
  const month = pad(date.getMonth() + 1);
  const day = pad(date.getDate());
  const hours = pad(date.getHours());
  const minutes = pad(date.getMinutes());

  return `${year}-${month}-${day}T${hours}:${minutes}`;
};

export const EditDiscountCanvas = ({
  visible,
  setVisible,
  programDiscountToEdit,
}) => {
  const { programData, refreshProgramData } = useContext(ProgramDetailsContext);
  const [loading, setLoading] = useState(false);
  const isMounted = useRef(false);

  const discountToEdit = programData.programDiscounts.find(
    (discount) => discount.id === programDiscountToEdit,
  );

  const validation = useFormik({
    enableReinitialize: true,
    validateOnChange: false,

    initialValues: {
      type: discountToEdit?.type || "",
      name: discountToEdit?.name || "",
      activeAmount: discountToEdit?.activeAmount || "",
      startDate: discountToEdit?.startDate
        ? convertToLocalDateTime(discountToEdit.startDate)
        : "",
      endDate: discountToEdit?.endDate
        ? convertToLocalDateTime(discountToEdit.endDate)
        : "",
      passiveAmount: discountToEdit?.passiveAmount || "",
    },
    validationSchema: Yup.object({
      type: Yup.string().required(getString("discount_type_required")),
      name: Yup.string().test(
        "required-if-time-related",
        getString("discount_name_required"),
        function (value) {
          return this.parent.type !== "time_related" || !!value;
        },
      ),
      activeAmount: Yup.number().required(getString("active_amount_required")),
      startDate: Yup.string().test(
        "required-if-time-related",
        getString("start_date_required"),
        function (value) {
          return this.parent.type !== "time_related" || !!value;
        },
      ),
      endDate: Yup.string().test(
        "required-if-time-related",
        getString("end_date_required"),
        function (value) {
          return this.parent.type !== "time_related" || !!value;
        },
      ),
      passiveAmount: Yup.number().test(
        "required-if-invitation",
        getString("passive_amount_required"),
        function (value) {
          return this.parent.type !== "invitation" || !!value;
        },
      ),
    }),
    onSubmit: (values) => {
      setLoading(true);

      const convertToISOWithOffset = (dateString) => {
        const date = new Date(dateString);
        const pad = (num) => (num < 10 ? "0" + num : num);

        const year = date.getUTCFullYear();
        const month = pad(date.getUTCMonth() + 1);
        const day = pad(date.getUTCDate());
        const hours = pad(date.getUTCHours());
        const minutes = pad(date.getUTCMinutes());
        const seconds = pad(date.getUTCSeconds());

        return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}+00:00`;
      };

      // Create a new object with only non-empty values
      const filteredValues = Object.keys(values).reduce((acc, key) => {
        if (values[key] !== "") {
          acc[key] = values[key];
        }
        return acc;
      }, {});

      // Convert startDate and endDate if they exist
      if (filteredValues.startDate) {
        filteredValues.startDate = convertToISOWithOffset(
          filteredValues.startDate,
        );
      }
      if (filteredValues.endDate) {
        filteredValues.endDate = convertToISOWithOffset(filteredValues.endDate);
      }

      updateDiscountInProgram(
        programData.id,
        programDiscountToEdit,
        filteredValues,
      )
        .then(() => {
          toast_success(getString("discount_updated"));
          setVisible(false);
          refreshProgramData();
        })
        .catch((e) => {
          parseErrorToFormikErrors(e, validation);
        })
        .finally(() => setLoading(false));
    },
  });

  const clearValuesExceptType = () => {
    validation.setValues({
      type: validation.values.type,
      name: "",
      activeAmount: "",
      startDate: "",
      endDate: "",
      passiveAmount: "",
    });
  };

  useEffect(() => {
    if (isMounted.current) {
      clearValuesExceptType();
    } else {
      isMounted.current = true;
    }
  }, [validation.values.type]);

  return (
    <OffcanvasRight
      isOpen={visible}
      toggle={() => setVisible(false)}
      title={getString("discount_edit")}
      formId="edit-discount-form"
      validationRule={true}
      buttonLabel={getString("save")}
      loading={loading}
    >
      <Card className="edit-discount">
        <CardBody className="card-body">
          <Form
            id="edit-discount-form"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
            action="#"
          >
            <FormSelect
              name="type"
              label={getString("discount_type_select")}
              defaultValue={programDiscountOptions.find(
                (o) => o.value === validation.values.type,
              )}
              value={validation.values.type}
              onChange={(selectedOption) => {
                handleSelectChange(validation, selectedOption, "type");
              }}
              isMulti={false}
              options={programDiscountOptions}
              error={validation.errors["type"]}
            />
            {validation.values.type === "time_related" && (
              <>
                <FormTextField
                  label={getString("discount_name")}
                  name={"name"}
                  value={validation.values["name"]}
                  onChange={(e) => handleInputChange(validation, e, "name")}
                  error={validation.errors["name"]}
                  touched={validation.touched["name"]}
                />
                <FormDateTimeField
                  label={getString("start_date")}
                  name="startDate"
                  value={validation.values.startDate}
                  onChange={(e) =>
                    handleInputChange(validation, e, "startDate")
                  }
                  error={validation.errors.startDate}
                  touched={validation.touched.startDate}
                />
                <FormDateTimeField
                  label={getString("end_date")}
                  name="endDate"
                  value={validation.values.endDate}
                  onChange={(e) => handleInputChange(validation, e, "endDate")}
                  error={validation.errors.endDate}
                  touched={validation.touched.endDate}
                />
              </>
            )}
            <FormNumberField
              label={getString("active_amount")}
              name="activeAmount"
              value={validation.values.activeAmount}
              onChange={(e) => handleInputChange(validation, e, "activeAmount")}
              error={validation.errors.activeAmount}
              touched={validation.touched.activeAmount}
            />
            {validation.values.type === "invitation" && (
              <FormNumberField
                label={getString("passive_amount")}
                name="passiveAmount"
                value={validation.values.passiveAmount}
                onChange={(e) =>
                  handleInputChange(validation, e, "passiveAmount")
                }
                error={validation.errors.passiveAmount}
                touched={validation.touched.passiveAmount}
              />
            )}
          </Form>
        </CardBody>
      </Card>
    </OffcanvasRight>
  );
};
