import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { FormFeedback, Label } from "reactstrap";
import { FormattedString } from "Components/Common/FormattedString";
import "./FormRadioSelect.scss";

export const FormRadioSelect = ({
  label = "",
  name,
  value = "",
  options,
  error = "",
  warning = "",
  isMulti = false,
  "data-testid": dataTestId,
  onChange,
  ...rest
}) => {
  const handleChange = (selectedValue) => {
    if (isMulti) {
      const newValues = value.includes(selectedValue)
        ? value.filter((val) => val !== selectedValue)
        : [...value, selectedValue];
      onChange(newValues);
    } else {
      onChange(selectedValue);
    }
  };

  return (
    <div
      className={classNames("form-radio-select", {
        "form-radio-select--error": error && !warning,
        "form-radio-select--warning": warning,
      })}
      data-testid={dataTestId || `radio-select-${name}`}
    >
      {(label && (
        <Label htmlFor={name} className="form-radio-select__label">
          {label}
        </Label>
      )) ||
        (name && (
          <Label htmlFor={name} className="form-radio-select__label">
            {label || <FormattedString id={name} />}
          </Label>
        ))}
      <div className="form-radio-select__options">
        {options.map((option) => (
          <label
            key={option.value}
            htmlFor={`${name}-${option.value}`}
            className={classNames("form-radio-select__option", {
              "form-radio-select__option--selected": isMulti
                ? value.includes(option.value)
                : value === option.value,
            })}
            onClick={() => handleChange(option.value)}
          >
            <input
              type={isMulti ? "checkbox" : "radio"}
              id={`${name}-${option.value}`}
              name={name}
              value={option.value}
              checked={
                isMulti ? value.includes(option.value) : value === option.value
              }
              onChange={() => handleChange(option.value)}
              className={classNames("form-radio-select__input", {
                "form-radio-select__input--invalid": !warning && error,
                "form-radio-select__input--warning": warning,
              })}
              {...rest}
            />
            <span className="form-radio-select__option-label">
              {option.label}
            </span>
          </label>
        ))}
      </div>
      {warning && (
        <FormFeedback className="form-radio-select__feedback form-radio-select__feedback--warning">
          <i className="ri-error-warning-line"></i>
          {warning}
        </FormFeedback>
      )}
      {!warning && error && (
        <FormFeedback className="form-radio-select__feedback form-radio-select__feedback--invalid">
          {error}
        </FormFeedback>
      )}
    </div>
  );
};

FormRadioSelect.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  error: PropTypes.string,
  warning: PropTypes.string,
  isMulti: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  "data-testid": PropTypes.string,
};

export default FormRadioSelect;
