// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.application-form .steps-control .btn {
  margin-bottom: 10px;
  border: none;
  border-radius: 0.25rem;
  color: white;
  font-weight: bold;
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.application-form .steps-control .btn.btn-primary {
  background-color: #2c6ba0;
}
.application-form .steps-control .btn.btn-primary:hover {
  background-color: #1f4f78;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}
.application-form .steps-control .btn.btn-secondary {
  background-color: #3a9fbf;
}
.application-form .steps-control .btn.btn-secondary:hover {
  background-color: #2a7f99;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}
.application-form .steps-control .btn.bg-danger {
  background-color: #d9534f;
}
.application-form .step-body {
  padding: 20px;
  padding-top: 0;
  border-left: 1px solid #ddd;
}`, "",{"version":3,"sources":["webpack://./src/pages/Applications/ApplicationDetails/partials/ApplicationForm/ApplicationForm.scss"],"names":[],"mappings":"AAEI;EACE,mBAAA;EACA,YAAA;EACA,sBAAA;EACA,YAAA;EACA,iBAAA;EACA,yBAAA;EACA,wCAAA;AADN;AAGM;EACE,yBAAA;AADR;AAGQ;EACE,yBAAA;EACA,yCAAA;AADV;AAKM;EACE,yBAAA;AAHR;AAKQ;EACE,yBAAA;EACA,yCAAA;AAHV;AAOM;EACE,yBAAA;AALR;AAUE;EACE,aAAA;EACA,cAAA;EACA,2BAAA;AARJ","sourcesContent":[".application-form {\n  .steps-control {\n    .btn {\n      margin-bottom: 10px;\n      border: none;\n      border-radius: 0.25rem;\n      color: white;\n      font-weight: bold;\n      transition: all 0.3s ease;\n      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\n\n      &.btn-primary {\n        background-color: #2c6ba0;\n\n        &:hover {\n          background-color: #1f4f78;\n          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);\n        }\n      }\n\n      &.btn-secondary {\n        background-color: #3a9fbf;\n\n        &:hover {\n          background-color: #2a7f99;\n          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);\n        }\n      }\n\n      &.bg-danger {\n        background-color: #d9534f;\n      }\n    }\n  }\n\n  .step-body {\n    padding: 20px;\n    padding-top: 0;\n    border-left: 1px solid #ddd;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
