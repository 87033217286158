import {
  FormattedString,
  getString,
  getStringSync,
} from "Components/Common/FormattedString";
import "./ApplicationStatusChanger.scss";
import { FormSelect } from "Components/Entity/FormSelect";
import { updateApplicationStatus } from "helpers/API/core-service/cs_backend_helper";
import { toast_error, toast_success } from "helpers/toast_helper";
import { statusOptions } from "models/applicationStatuses";
import { ApplicationDetailsContext } from "pages/Applications/ApplicationDetails/ApplicationDetails";
import React, { useContext, useEffect, useState } from "react";
import { consts } from "config";

export const ApplicationStatusChanger = ({ disabled = false }) => {
  const { applicationData, setApplicationErrors, refreshApplicationData } =
    useContext(ApplicationDetailsContext);

  const applicationId = applicationData.id;

  const [status, setStatus] = useState(
    statusOptions.find((option) => option.value === applicationData.status),
  );

  const handleChange = (selectedOption) => {
    updateApplicationStatus(applicationId, { status: selectedOption.value })
      .then(() => {
        toast_success(getString("application_status_updated"));
        setStatus(selectedOption);
        refreshApplicationData();
      })
      .catch((e) => {
        toast_error(
          e?.data?.message || getString("application_status_update_failed"),
        );

        if (e?.data?.fields) {
          const mappedErrors = e.data.fields.reduce((acc, field) => {
            acc[field.name] = field.message;
            return acc;
          }, {});
          setApplicationErrors(mappedErrors);
        }
      });
  };

  useEffect(() => {
    setApplicationErrors({});
  }, [applicationData]);

  useEffect(() => {
    const foundOption = statusOptions.find(
      (option) => option.value === applicationData.status,
    );
    setStatus(
      foundOption || {
        value: applicationData.status,
        label: getStringSync(applicationData.status),
      },
    );
  }, [applicationData.status]);

  const currentOption = statusOptions.find(
    (option) => option.value === applicationData.status,
  );

  const customOptions = currentOption
    ? statusOptions
    : [
        ...statusOptions,
        {
          value: applicationData.status,
          label: getStringSync(applicationData.status),
        },
      ];

  const archivedOption = {
    value: "archived",
    label: getStringSync("archived"),
  };

  return (
    <div className="application-status-changer">
      <FormSelect
        label={<FormattedString id="application_status" />}
        className="application-status-changer__select"
        name="applicationStatus"
        value={status}
        defaultValue={
          applicationData?.status === consts.ARCHIVED_STATUS
            ? archivedOption
            : status
              ? status
              : {
                  value: applicationData.status,
                  label: getStringSync(applicationData.status),
                }
        }
        options={customOptions}
        onChange={handleChange}
        isDisabled={disabled}
      />
    </div>
  );
};
