import React, { useState, useEffect } from "react";
import { Input, Label, FormFeedback, Button } from "reactstrap";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { FormattedString, getString } from "Components/Common/FormattedString";

const DescriptionList = ({ label, name, value, onChange, error, touched }) => {
  const delimiter = "\x1F";

  const parseValue = (value) => {
    return value
      .map((item) => {
        const [index, ...rest] = item.split(delimiter);
        return { index: parseInt(index, 10), text: rest.join(delimiter) };
      })
      .sort((a, b) => a.index - b.index)
      .map((item) => item.text);
  };

  const reindexItems = (items) => {
    return items.map((item, index) => `${index}${delimiter}${item}`);
  };

  const [items, setItems] = useState(parseValue(value));
  const [newItem, setNewItem] = useState("");

  useEffect(() => {
    setItems(parseValue(value));
  }, [value]);

  const handleAddItem = () => {
    if (newItem.trim() !== "") {
      const updatedItems = [...items, newItem];
      setItems(updatedItems);
      setNewItem("");
      onChange(reindexItems(updatedItems));
    }
  };

  const handleRemoveItem = (index) => {
    const updatedItems = items.filter((_, i) => i !== index);
    setItems(updatedItems);
    onChange(reindexItems(updatedItems));
  };

  const handleInputChange = (e) => {
    setNewItem(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleAddItem();
    }
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;
    const { source, destination } = result;
    const reorderedItems = Array.from(items);
    const [removed] = reorderedItems.splice(source.index, 1);
    reorderedItems.splice(destination.index, 0, removed);
    setItems(reorderedItems);
    onChange(reindexItems(reorderedItems));
  };

  return (
    <div className="mb-3">
      {label && (
        <Label htmlFor={name} className="form-label">
          {label}
        </Label>
      )}
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className={`list-group ${items.length > 0 ? "mb-3" : ""}`}
            >
              {items.map((item, index) => (
                <Draggable
                  key={index}
                  draggableId={String(index)}
                  index={index}
                >
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className="list-group-item d-flex gap-3 justify-content-between align-items-center text-break"
                    >
                      <div
                        className="drag-handle"
                        {...provided.dragHandleProps}
                      >
                        &#x2630;
                      </div>
                      {item}
                      <i
                        className="ri-close-line text-danger"
                        onClick={() => handleRemoveItem(index)}
                        style={{
                          cursor: "pointer",
                          fontSize: "1.5rem",
                          transition: "color 0.3s",
                        }}
                        onMouseEnter={(e) => (e.target.style.color = "darkred")}
                        onMouseLeave={(e) => (e.target.style.color = "red")}
                      ></i>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <div className="d-flex">
        <Input
          type="text"
          value={newItem}
          onChange={handleInputChange}
          onKeyPress={handleKeyPress}
          placeholder={getString("addNewItem")}
        />
        <Button color="primary" onClick={handleAddItem} className="ms-2">
          <FormattedString id="add" />
        </Button>
      </div>
      {error && touched && <FormFeedback type="invalid">{error}</FormFeedback>}
    </div>
  );
};

export default DescriptionList;
