// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-checkbox-group .checkbox-group {
  margin-bottom: 1.5rem;
}
.form-checkbox-group .checkbox-group .checkbox-group-label {
  font-weight: bold;
  margin-bottom: 0.5rem;
  color: #2d2d2d;
}
.form-checkbox-group .checkbox-group .form-check {
  margin-bottom: 0.5rem;
}
.form-checkbox-group .checkbox-group .checkbox-label {
  display: flex;
  align-items: center;
}
.form-checkbox-group .checkbox-group .checked-label {
  color: #007bff;
}
.form-checkbox-group .checkbox-group .custom-checkbox {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}`, "",{"version":3,"sources":["webpack://./src/Components/Entity/FormCheckboxGroup.scss"],"names":[],"mappings":"AACE;EACE,qBAAA;AAAJ;AAEI;EACE,iBAAA;EACA,qBAAA;EACA,cAAA;AAAN;AAGI;EACE,qBAAA;AADN;AAII;EACE,aAAA;EACA,mBAAA;AAFN;AAKI;EACE,cAAA;AAHN;AAMI;EACE,WAAA;EACA,YAAA;EACA,kBAAA;AAJN","sourcesContent":[".form-checkbox-group {\n  .checkbox-group {\n    margin-bottom: 1.5rem;\n\n    .checkbox-group-label {\n      font-weight: bold;\n      margin-bottom: 0.5rem;\n      color: #2d2d2d;\n    }\n\n    .form-check {\n      margin-bottom: 0.5rem;\n    }\n\n    .checkbox-label {\n      display: flex;\n      align-items: center;\n    }\n\n    .checked-label {\n      color: #007bff;\n    }\n\n    .custom-checkbox {\n      width: 20px;\n      height: 20px;\n      margin-right: 10px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
