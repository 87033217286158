import React, { useEffect, useRef, useState } from "react";
import { Table } from "reactstrap";
import DataLoadError from "Components/Common/DataLoadError/DataLoadError";
import { usePresetTableContext } from "../PresetTableContext";
import { FormattedString } from "Components/Common/FormattedString";

const TableContent = ({ data, loading, error, onRetry }) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    usePresetTableContext();

  const tableContainerRef = useRef(null);
  const [containerHeight, setContainerHeight] = useState(0);

  useEffect(() => {
    if (tableContainerRef.current) {
      setContainerHeight(tableContainerRef.current.clientHeight);
    }
  }, [data, loading]);

  const generateSortingIndicator = (column) => {
    return column.isSorted ? (
      column.isSortedDesc ? (
        <span>&#8593;</span>
      ) : (
        <span>&#8595;</span>
      )
    ) : (
      ""
    );
  };

  return (
    <div
      className="custom-header-css table align-middle table-nowrap"
      ref={tableContainerRef}
      style={{ minHeight: loading && containerHeight }}
      data-testid="table-content"
    >
      {error || loading ? (
        <DataLoadError
          errorMessage={error}
          loading={loading}
          onRetry={onRetry}
        />
      ) : data.length === 0 ? (
        <div className="text-center text-muted mb-3">
          <FormattedString id="no_data_to_show" />
        </div>
      ) : (
        <Table
          hover
          {...getTableProps()}
          className="table-centered align-middle table-nowrap mb-0"
        >
          <thead className="text-muted table-light">
            {headerGroups.map((headerGroup) => (
              <tr
                className="table-row"
                {...headerGroup.getHeaderGroupProps()}
                key={headerGroup.id}
              >
                {headerGroup.headers.map((column) => (
                  <th
                    key={column.id}
                    className={`table-header ${column.headerClassName || ""}`}
                    {...column.getSortByToggleProps()}
                  >
                    {column.render("Header")}
                    {generateSortingIndicator(column)}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr data-testid={row.original.id} key={row.getRowProps().key}>
                  {row.cells.map((cell) => (
                    <td
                      {...cell.getCellProps()}
                      key={cell.id}
                      className={cell.column.className || ""}
                    >
                      {cell.render("Cell")}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </Table>
      )}
    </div>
  );
};

export default TableContent;
