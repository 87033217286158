import {
  API_RESPONSE_SUCCESS,
  API_RESPONSE_ERROR,
  SET_CURRENT_PAGE,
  GET_PROMO_CODES_USAGE_LIST,
} from "./actionType";

export const promoCodesUsageApiSuccess = (actionType, data) => ({
  type: API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

export const promoCodesUsageApiError = (actionType, error) => ({
  type: API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getPromoCodesUsageList = (
  page = 1,
  searchTerm = "",
  pageSize = null,
) => ({
  type: GET_PROMO_CODES_USAGE_LIST,
  payload: { page, searchTerm, pageSize },
});

export const setCurrentPage = (page) => ({
  type: SET_CURRENT_PAGE,
  payload: page,
});
