import React from "react";
import "./ApplicationForm.scss";
import { ApplicationFormProvider } from "./provider/ApplicationFormProvider";
import ApplicationFormContent from "./ApplicationFormContent";

const ApplicationForm = () => (
  <ApplicationFormProvider>
    <ApplicationFormContent />
  </ApplicationFormProvider>
);

export default ApplicationForm;
