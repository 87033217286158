import React from "react";
import { AngloTooltip } from "Components/Common/AngloTooltip/AngloTooltip";
import { IpParticipantStatus } from "models/ipParticipants";
import GradientProgressBar from "Components/Common/GradientProgressBar/GradientProgressBar";
import "./IpEslSummaryCell.scss";

const IpEslSummaryCell = ({ summary }) => {
  const { capacityPerType, quantity } = summary;
  const eslTotalCapacity = capacityPerType?.esl || 0;

  const eslQuantity = quantity.find((q) => q.type === "esl")?.statusCount || [];

  const getEslStatusCount = (status) =>
    eslQuantity.find((s) => s.status === status)?.count || 0;

  const invitedCount = getEslStatusCount(IpParticipantStatus.INVITED);
  const awaitingTravelDetailsCount = getEslStatusCount(
    IpParticipantStatus.AWAITING_TRAVEL_DETAILS,
  );
  const partlyConfirmedCount = getEslStatusCount(
    IpParticipantStatus.PARTLY_CONFIRMED,
  );
  const fullyConfirmedCount = getEslStatusCount(IpParticipantStatus.CONFIRMED);

  const sum =
    invitedCount +
    awaitingTravelDetailsCount +
    partlyConfirmedCount +
    fullyConfirmedCount;

  const percentage =
    eslTotalCapacity > 0
      ? Math.min(Math.round((sum / eslTotalCapacity) * 100), 100)
      : 0;

  const ipBaseColor = "#a64d79";

  const isMuted = eslTotalCapacity === 0;

  return (
    <div className="ip-esl-summary-cell">
      <div
        className={`ip-esl-summary-cell__equation ${
          isMuted ? "ip-esl-summary-cell__equation--muted" : ""
        }`}
      >
        <span className="ip-esl-summary-cell__bracket">(</span>
        <AngloTooltip stringId="invited">
          <span className="ip-esl-summary-cell__val ip-esl-summary-cell__val--invited">
            {invitedCount}
          </span>
        </AngloTooltip>
        <span className="ip-esl-summary-cell__separator"> + </span>
        <AngloTooltip stringId="awaiting_travel_details">
          <span className="ip-esl-summary-cell__val ip-esl-summary-cell__val--awaiting-travel-details">
            {awaitingTravelDetailsCount}
          </span>
        </AngloTooltip>
        <span className="ip-esl-summary-cell__bracket">)</span>
        <span className="ip-esl-summary-cell__separator"> </span>
        <AngloTooltip stringId="partly_confirmed">
          <span className="ip-esl-summary-cell__val ip-esl-summary-cell__val--partly-confirmed">
            {partlyConfirmedCount}
          </span>
        </AngloTooltip>
        <span className="ip-esl-summary-cell__separator"> + </span>
        <AngloTooltip stringId="fully_confirmed">
          <span className="ip-esl-summary-cell__val ip-esl-summary-cell__val--fully-confirmed">
            {fullyConfirmedCount}
          </span>
        </AngloTooltip>
        <span className="ip-esl-summary-cell__separator"> = </span>
        <AngloTooltip stringId="sum">
          <span className="ip-esl-summary-cell__val ip-esl-summary-cell__sum">
            {sum}
          </span>
        </AngloTooltip>
        <span className="ip-esl-summary-cell__separator"> / </span>
        <AngloTooltip stringId="total_capacity">
          <span className="ip-esl-summary-cell__val ip-esl-summary-cell__total">
            {eslTotalCapacity}
          </span>
        </AngloTooltip>
      </div>
      {!isMuted && (
        <GradientProgressBar percentage={percentage} baseColor={ipBaseColor} />
      )}
    </div>
  );
};

export default IpEslSummaryCell;
