function parseErrorToFormikErrors(error, formik) {
  const errors = {};

  if (error.data && error.data.fields) {
    error.data.fields.forEach((field) => {
      errors[field.name] = field.message;
    });
  }
  formik.setErrors(errors);
}

export { parseErrorToFormikErrors };
