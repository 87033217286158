// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ip-ns-summary-cell {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
}
.ip-ns-summary-cell__equation {
  display: flex;
}
.ip-ns-summary-cell__separator {
  color: #6c757d;
  margin: 0 2px;
}
.ip-ns-summary-cell__bracket {
  color: black;
  margin: 0 2px;
}
.ip-ns-summary-cell__val {
  font-weight: 400;
}
.ip-ns-summary-cell__val--highlight {
  font-weight: 700;
}
.ip-ns-summary-cell__val--invited {
  color: #808080;
}
.ip-ns-summary-cell__val--awaiting-travel-details {
  color: red;
}
.ip-ns-summary-cell__val--partly-confirmed {
  color: #ffa500;
}
.ip-ns-summary-cell__val--fully-confirmed {
  color: #2ca02c;
}
.ip-ns-summary-cell__sum {
  color: #299cdb;
  font-weight: 700;
}
.ip-ns-summary-cell__total {
  color: black;
}

.ip-ns-summary-cell__equation--muted {
  color: #6c757d;
  filter: grayscale(1);
  opacity: 0.6;
}`, "",{"version":3,"sources":["webpack://./src/pages/ProgramPackets/cells/IpNsSummaryCell/IpNsSummaryCell.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,yBAAA;EACA,QAAA;AACF;AACE;EACE,aAAA;AACJ;AAEE;EACE,cAAA;EACA,aAAA;AAAJ;AAGE;EACE,YAAA;EACA,aAAA;AADJ;AAIE;EACE,gBAAA;AAFJ;AAII;EACE,gBAAA;AAFN;AAKI;EACE,cAAA;AAHN;AAMI;EACE,UAAA;AAJN;AAOI;EACE,cAAA;AALN;AAQI;EACE,cAAA;AANN;AAUE;EACE,cAAA;EACA,gBAAA;AARJ;AAWE;EACE,YAAA;AATJ;;AAaA;EACE,cAAA;EACA,oBAAA;EACA,YAAA;AAVF","sourcesContent":[".ip-ns-summary-cell {\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n  gap: 8px;\n\n  &__equation {\n    display: flex;\n  }\n\n  &__separator {\n    color: #6c757d;\n    margin: 0 2px;\n  }\n\n  &__bracket {\n    color: black;\n    margin: 0 2px;\n  }\n\n  &__val {\n    font-weight: 400;\n\n    &--highlight {\n      font-weight: 700;\n    }\n\n    &--invited {\n      color: #808080;\n    }\n\n    &--awaiting-travel-details {\n      color: red;\n    }\n\n    &--partly-confirmed {\n      color: #ffa500;\n    }\n\n    &--fully-confirmed {\n      color: #2ca02c;\n    }\n  }\n\n  &__sum {\n    color: #299cdb;\n    font-weight: 700;\n  }\n\n  &__total {\n    color: black;\n  }\n}\n\n.ip-ns-summary-cell__equation--muted {\n  color: #6c757d;\n  filter: grayscale(1);\n  opacity: 0.6;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
