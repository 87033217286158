import React, { useContext, useEffect, useState } from "react";
import { Col } from "reactstrap";
import { Tile } from "Components/Common/Tile/Tile";
import { CustomerDetails } from "../../components/CustomerDetails/CustomerDetails";
import { BillingData } from "../../components/BillingData/BillingData";
import FormikTextArea from "Components/Entity/FormikTextArea";
import { FormattedString, getString } from "Components/Common/FormattedString";
import { CustomerDetailsContext } from "pages/Customers/CustomerDetails/CustomerDetails";
import { OutlineButton } from "Components/Common/OutlineButton/OutlineButton";
import EditCustomerDetailsCanvas from "../EditCustomerDetailsCanvas/EditCustomerDetailsCanvas";
import EditCustomerBillingCanvas from "../EditCustomerBillingCanvas/EditCustomerBillingCanvas";
import { useLocation, useNavigate } from "react-router-dom";

const CustomerDetailsRight = () => {
  const { customerData } = useContext(CustomerDetailsContext);
  const [editCustomerDetailsVisible, setEditCustomerDetailsVisible] =
    useState(false);
  const [editCustomerBillingDataVisible, setEditCustomerBillingDataVisible] =
    useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    setEditCustomerDetailsVisible(location.pathname.includes("/edit"));
  }, [location.pathname]);

  const handleEditTileOpen = () => {
    setEditCustomerDetailsVisible(true);
  };

  const handleEditTileClose = () => {
    setEditCustomerDetailsVisible(false);
    if (location.pathname.includes("/edit")) {
      navigate(location.pathname.replace("/edit", ""));
    }
  };

  return (
    <Col xxl={3}>
      <Tile
        title={getString("customer_details")}
        gap="14px"
        titleDropdown={
          <OutlineButton onClick={handleEditTileOpen}>
            <i className="ri-edit-line" style={{ marginRight: "4px" }}></i>
            <FormattedString id="edit" />
          </OutlineButton>
        }
      >
        <CustomerDetails />
      </Tile>

      <Tile
        title={getString("billing_data")}
        gap="14px"
        titleIcon={<i className="ri-map-pin-line align-middle text-muted"></i>}
        titleDropdown={
          <OutlineButton
            onClick={() => setEditCustomerBillingDataVisible(true)}
          >
            <i className="ri-edit-line" style={{ marginRight: "4px" }}></i>
            <FormattedString id="edit" />
          </OutlineButton>
        }
      >
        <BillingData />
      </Tile>

      <Tile title={getString("customer_note")} gap="14px">
        <FormikTextArea
          name="clientComment"
          disabled
          value={customerData.customerNote}
        />
      </Tile>

      <EditCustomerDetailsCanvas
        visible={editCustomerDetailsVisible}
        setVisible={handleEditTileClose}
      />

      <EditCustomerBillingCanvas
        visible={editCustomerBillingDataVisible}
        setVisible={setEditCustomerBillingDataVisible}
      />
    </Col>
  );
};

export default CustomerDetailsRight;
