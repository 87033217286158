import logoBg from "assets/images/logo.svg";
import { Col, Row, Button } from "reactstrap";
import { IpDetailsContext } from "../IpParticipantDetails";
import { useContext, useState } from "react";
import { formatDate, splitNumbers } from "helpers/utils";
import { FormattedString } from "Components/Common/FormattedString";
import BlockIpModal from "pages/IpParticipants/components/BlockIpModal/BlockIpModal";
import UnblockIpModal from "pages/IpParticipants/components/UnblockIpModal/UnblockIpModal";

import classNames from "classnames";
import { AngloTooltip } from "Components/Common/AngloTooltip/AngloTooltip";
import { OutlineButton } from "Components/Common/OutlineButton/OutlineButton";
import { WithPermission } from "Components/Common/WithPermission/WithPermission";
import EditIpParticipant from "pages/IpParticipants/EditIpParticipant";

export const IpParticipantHeader = () => {
  const { ipData, refreshIpData } = useContext(IpDetailsContext);
  const [blockModalVisible, setBlockModalVisible] = useState(false);
  const [unblockModalVisible, setUnblockModalVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);

  const toggleBlockModal = () => {
    setBlockModalVisible(!blockModalVisible);
    refreshIpData();
  };

  const toggleUnblockModal = () => {
    setUnblockModalVisible(!unblockModalVisible);
    refreshIpData();
  };

  const toggleEditModal = () => {
    setEditModalVisible(!editModalVisible);
  };

  const handleEditButtonClick = () => {
    toggleEditModal();
  };

  const headerClass = classNames("ip-participant-details__header", {
    "ip-participant-details__header--blocked": ipData.status === "blocked",
  });

  return (
    <div className={headerClass}>
      <div className="ip-participant-details__header__foreground">
        <div className="ip-participant-details__header__background">
          <img
            src={logoBg}
            alt=""
            className="ip-participant-details__header__background__image"
          />
        </div>
      </div>
      <div className="pt-4 mb-4 mb-lg-3 pb-lg-4">
        <Row className="g-4">
          <div className="col-auto d-flex align-items-center">
            <div className="ip-participant-details__header__avatar">
              <i className="ip-participant-details__header__thumb ri-user-line"></i>
            </div>
          </div>

          <Col>
            <div className="p-2">
              <div className="ip-participant-details__header__top">
                <div className="ip-participant-details__header__top__left">
                  <h3 className="ip-participant-details__header__top__left__name">
                    {ipData.firstName} {ipData.lastName}
                  </h3>
                  <p className="ip-participant-details__header__top__left__paragraph">
                    <i className="ri-mail-line me-1 text-white text-opacity-75 fs-16 align-middle"></i>
                    <a
                      href={`mailto:${ipData.email}`}
                      target="_blank"
                      className="text-white"
                      rel="noopener noreferrer"
                    >
                      {ipData.email}
                    </a>
                  </p>
                  <p className="ip-participant-details__header__top__left__paragraph">
                    <i className="ri-phone-line me-1 text-white text-opacity-75 fs-16 align-middle"></i>
                    +{ipData.phonePrefix} {splitNumbers(ipData.phoneNumber)}
                  </p>
                </div>
              </div>
              {ipData.lastProgramName && (
                <Row>
                  <Col>
                    <div className="text-white-50">
                      <p className="ip-participant-details__header__top__left__paragraph">
                        <i className="ri-bookmark-line me-1 text-white text-opacity-75 fs-16 align-middle"></i>
                        Last program: {ipData.lastProgramName}
                      </p>
                      {ipData.lastProgramStartDate && (
                        <div>
                          <i className="ri-calendar-2-line me-1 text-white text-opacity-75 fs-16 align-middle"></i>
                          {formatDate(ipData.lastProgramStartDate)} -{" "}
                          {formatDate(ipData.lastProgramEndDate)}
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>
              )}
            </div>
          </Col>

          <Col xs={12} className="col-lg-auto order-last order-lg-0">
            <Row className="text text-white-50 text-center align-items-center">
              <Col>
                <h4 className="text-white mb-1">
                  <FormattedString id={ipData.status} />
                </h4>
              </Col>
              <Col style={{ paddingLeft: 0 }}>
                <div className="d-flex justify-content-center">
                  {ipData.status === "blocked" ? (
                    <AngloTooltip text="Unblock">
                      <Button
                        color="success"
                        size="sm"
                        onClick={toggleUnblockModal}
                        className="pl-0"
                      >
                        <i className="ri-lock-unlock-line fs-5"></i>
                      </Button>
                    </AngloTooltip>
                  ) : (
                    <AngloTooltip text="Block">
                      <Button
                        color="danger"
                        size="sm"
                        onClick={toggleBlockModal}
                        className="pl-0"
                      >
                        <i className="ri-lock-line fs-5"></i>
                      </Button>
                    </AngloTooltip>
                  )}
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col className="d-flex justify-content-end">
            <WithPermission permissionName="IP_PARTICIPANT_UPDATE">
              <OutlineButton onClick={handleEditButtonClick}>
                <i className="ri-pencil-line"></i>
                <FormattedString id="editIpParticipant" />
              </OutlineButton>
            </WithPermission>
          </Col>
        </Row>
      </div>
      {blockModalVisible && (
        <BlockIpModal
          itemId={ipData.id}
          visibility={blockModalVisible}
          toggle={toggleBlockModal}
        />
      )}
      {unblockModalVisible && (
        <UnblockIpModal
          itemId={ipData.id}
          visibility={unblockModalVisible}
          toggle={toggleUnblockModal}
        />
      )}
      {editModalVisible && (
        <EditIpParticipant
          ipParticipant={ipData}
          visibility={editModalVisible}
          toggle={toggleEditModal}
          onSuccess={() => refreshIpData()}
        />
      )}
    </div>
  );
};
