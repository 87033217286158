import React, { useState } from "react";
import { AngloTooltip } from "../../Common/AngloTooltip/AngloTooltip";
import { getString } from "Components/Common/FormattedString";
import { toast_success } from "helpers/toast_helper";

const CopyToClipboardButton = ({ onCopyToClipboard, itemId }) => {
  const [isCopied, setIsCopied] = useState(false);
  const [isCopyDisabled, setIsCopyDisabled] = useState(false);

  const handleCopyToClipboard = () => {
    if (onCopyToClipboard && !isCopyDisabled) {
      const textToCopy = onCopyToClipboard();
      navigator.clipboard.writeText(textToCopy).then(() => {
        setIsCopied(true);
        setIsCopyDisabled(true);
        toast_success(getString("link_copied"));
        setTimeout(() => {
          setIsCopied(false);
          setIsCopyDisabled(false);
        }, 4000);
      });
    }
  };

  const copyToClipboardButtonId = `copyToClipboardButton-${itemId}`;

  return (
    <AngloTooltip
      key={isCopied ? "copied" : "copy_to_clipboard"}
      text={isCopied ? getString("copied") : getString("copy_to_clipboard")}
    >
      <button
        id={itemId && copyToClipboardButtonId}
        type="button"
        className={`btn btn-sm btn-icon ${isCopied ? "btn-soft-success" : "btn-soft-secondary"}`}
        onClick={handleCopyToClipboard}
        disabled={isCopyDisabled}
        style={{ marginRight: 8 }}
      >
        <i className={isCopied ? "ri-check-line" : "ri-clipboard-line"}></i>
      </button>
    </AngloTooltip>
  );
};

export default CopyToClipboardButton;
