import React from "react";
import { Input, Label, FormFeedback, InputGroup, Button } from "reactstrap";

const FormDateField = ({
  label,
  name,
  value,
  onChange,
  error,
  disabled,
  min,
  max,
  clearable = false,
}) => {
  const handleClear = () => {
    const event = {
      target: {
        name: name,
        id: name,
        value: "",
      },
      currentTarget: {
        name: name,
        id: name,
        value: "",
      },
    };
    onChange(event, name);
  };

  return (
    <div className="mb-3">
      <Label htmlFor={name} className="form-label">
        {label}
      </Label>
      <InputGroup>
        <Input
          type="date"
          className="form-control"
          id={name}
          name={name}
          onChange={(e) => onChange(e, name)}
          value={value || ""}
          invalid={!!error}
          disabled={disabled}
          min={min}
          max={max}
        />
        {clearable && value && !disabled && (
          <Button
            color="danger"
            outline
            onClick={handleClear}
            style={{
              borderColor: error ? "#dc3545" : "#ced4da",
              color: "#dc3545",
              borderLeft: "none",
            }}
            type="button"
            className="clear-btn"
          >
            <i className="ri-close-line"></i>
          </Button>
        )}
      </InputGroup>
      {error && <FormFeedback type="invalid">{error}</FormFeedback>}
    </div>
  );
};

export default FormDateField;
