import React, { useEffect, useMemo, useRef, useState } from "react";
import { Link, useSearchParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getCustomersListData } from "../../store/customers/action";
import TableContainerExternalPagination from "Components/Common/TableContainerExternalPagination";
import { getBusinessUnitListData } from "store/actions";
import { format } from "date-fns";
import "./CustomersList.scss";
import ActionButtons from "../../Components/Entity/ActionButtons/ActionButtons";
import { FormattedString, getString } from "Components/Common/FormattedString";
import { splitNumbers } from "helpers/utils";
import AddApplication from "pages/Applications/AddApplication";
import { WithPermission } from "Components/Common/WithPermission/WithPermission";
import { AngloTooltip } from "Components/Common/AngloTooltip/AngloTooltip";

const CustomersList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const isInitialRender = useRef(true);
  const [applicationAddVisible, setApplicationAddVisible] = useState(false);
  const [defaultCustomer, setDefaultCustomer] = useState(null);
  const [editCustomerDetailsVisible, setEditCustomerDetailsVisible] =
    useState(false);

  const {
    customersData,
    count,
    currentPage,
    totalPages,
    pageSize,
    loading,
    error,
  } = useSelector((rootState) => rootState.Customers);

  const pageNumber = searchParams.get("page") || "1";
  const globalFilter = searchParams.get("searchTerm") || "";

  useEffect(() => {
    if (isInitialRender.current) {
      dispatch(getBusinessUnitListData());
      isInitialRender.current = false;
    }
    dispatch(getCustomersListData(pageNumber, globalFilter));
  }, [searchParams]);

  const toggleApplicationAdd = (customer) => {
    setDefaultCustomer(customer);
    setApplicationAddVisible(!applicationAddVisible);
  };

  const toggleEditCustomerDetails = (customer) => {
    setDefaultCustomer(customer);
    setEditCustomerDetailsVisible(!editCustomerDetailsVisible);
    navigate(`/customer/${customer.id}/edit`);
  };

  const columns = useMemo(
    () => [
      {
        Header: getString("customer_name"),
        accessor: (row) => (
          <div className="cell-customer-name">
            <Link to={`/customer/${row.id}`}>
              {row.firstName} {row.lastName}
            </Link>
          </div>
        ),
      },
      {
        Header: getString("phone_number"),
        accessor: (d) => (
          <div className="cell-phone-number">
            +{d.phonePrefix} {splitNumbers(d.phoneNumber)}
          </div>
        ),
      },
      {
        Header: getString("email"),
        accessor: (d) => (
          <Link to={`mailto:${d.email}`} target="_blank">
            {d.email}
          </Link>
        ),
      },
      {
        Header: getString("ap_count"),
        accessor: (d) => (
          <div className="cell-ap-count">{d.applicationsCount}</div>
        ),
      },
      {
        Header: getString("pp_count"),
        accessor: (d) => (
          <div className="cell-pp-count">{d.participantsCount}</div>
        ),
      },
      {
        Header: getString("last_program"),
        accessor: (d) => (
          <div className="cell-last-program">
            <div>{d.lastProgramName}</div>
            {d.lastProgramStartDate && d.lastProgramEndDate ? (
              <div>
                {format(new Date(d.lastProgramStartDate), "yyyy-MM-dd")} -{" "}
                {format(new Date(d.lastProgramEndDate), "yyyy-MM-dd")}
              </div>
            ) : (
              <div>-</div>
            )}
          </div>
        ),
      },
      {
        Header: getString("note"),
        accessor: (d) => (
          <div className="cell-note">
            {d.customerNote ? d.customerNote : "-"}
          </div>
        ),
      },
      {
        id: "Actions",
        Header: () => (
          <div
            style={{
              textAlign: "right",
            }}
          >
            <FormattedString id="actions" />
          </div>
        ),
        disableSortBy: true,
        accessor: (cellProps) => (
          <div
            style={{
              display: "flex",
              gap: "8px",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <WithPermission permissionName="PP_APPLICATION_CREATE">
              <div className="col-auto">
                <AngloTooltip stringId="add_application">
                  <button
                    className="btn btn-sm btn-soft-success btn-icon"
                    onClick={() => toggleApplicationAdd(cellProps)}
                  >
                    <i className="ri-file-add-line"></i>
                  </button>
                </AngloTooltip>
              </div>
            </WithPermission>
            <ActionButtons
              customShowDetailsLink={`/customer/${cellProps.id}`}
              itemId={cellProps.id}
              onEdit={() => toggleEditCustomerDetails(cellProps)}
            />
          </div>
        ),
      },
    ],
    [],
  );

  const customSetGlobalFilter = (value) => {
    const newSearchParams = new URLSearchParams(searchParams);
    if (value) {
      newSearchParams.set("searchTerm", value);
    } else {
      newSearchParams.delete("searchTerm");
    }
    newSearchParams.set("page", "1");
    setSearchParams(newSearchParams);
  };

  const handlePageChange = (page) => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set("page", page.toString());
    setSearchParams(newSearchParams);
  };

  return (
    <div className="customers-list">
      <TableContainerExternalPagination
        columns={columns || []}
        count={count}
        currentPage={currentPage}
        totalPages={totalPages}
        pageSize={pageSize}
        loading={loading}
        globalFilter={globalFilter}
        setGlobalFilter={customSetGlobalFilter}
        onPageChange={handlePageChange}
        data={customersData || []}
        isBordered={false}
        isGlobalFilter={true}
        className="custom-header-css table align-middle table-nowrap"
        tableClassName="table-centered align-middle table-nowrap mb-0"
        theadClassName="text-muted table-light"
        SearchPlaceholder={getString("customers_search_for")}
        error={error}
        onRetry={() => dispatch(getCustomersListData(pageNumber, globalFilter))}
        preventLoading={false}
      />
      {applicationAddVisible && (
        <AddApplication
          visibility={applicationAddVisible}
          toggle={toggleApplicationAdd}
          defaultCustomer={defaultCustomer}
        />
      )}
    </div>
  );
};

export { CustomersList };
