import { Link } from "react-router-dom";
import "./SingleMeetingPointGroup.scss";
import ActionButtons from "Components/Entity/ActionButtons/ActionButtons";
import { AngloTooltip } from "Components/Common/AngloTooltip/AngloTooltip";
import { useContext, useState } from "react";
import { ProgramDetailsContext } from "pages/Programs/ProgramDetails/ProgramDetails";
import { calculateDateShift } from "helpers/utils";
import { FormattedString, getString } from "Components/Common/FormattedString";
import { NewValuePlaceholder } from "Components/Common/NewValuePlaceholder/NewValuePlaceholder";
import { AssignMeetingCanvas } from "../../../modals/AssignMeetingCanvas";
import { DeleteMeetingModal } from "../../../modals/DeleteMeetingModal";
import { EditMeetingCanvas } from "../../../modals/EditMeetingCanvas";

export const SingleMeetingPointGroup = ({ meetingPoints, groupType }) => {
  const { programData, canUpdateProgram } = useContext(ProgramDetailsContext);

  const [assignVisible, setAssignVisible] = useState(false);
  const [deleteVisible, setDeleteVisible] = useState(false);
  const [editVisible, setEditVisible] = useState(false);
  const [meetingToDelete, setMeetingToDelete] = useState(null);
  const [meetingToEdit, setMeetingToEdit] = useState(null);

  const orderedMeetingPoints = meetingPoints.sort((a, b) => {
    if (a.startHour === b.startHour) {
      return a.dateModifier - b.dateModifier;
    }
    if (typeof a.startHour === "string" && typeof b.startHour === "string") {
      return a.startHour.localeCompare(b.startHour);
    }
    return 0;
  });

  return (
    <div className="single-meeting-point-group">
      <div className="single-meeting-point-group__header">
        <div className="single-meeting-point__title">
          <div className="single-meeting-point__title--left">
            <FormattedString id={groupType} />
          </div>
        </div>
      </div>

      {orderedMeetingPoints.map((meetingPoint) => (
        <div className="single-meeting-point" key={meetingPoint.id}>
          <div className="single-meeting-point__left">
            <div className="single-meeting-point__header">
              <div className="single-meeting-point__header--left">
                <div className="single-meeting-point__header__start-hour">
                  <AngloTooltip stringId="program_start_hour">
                    {meetingPoint.startHour}
                  </AngloTooltip>
                </div>
                <div className="single-meeting-point__header__date">
                  <AngloTooltip stringId="program_date_modified">
                    (
                    {calculateDateShift(
                      programData.startDate,
                      meetingPoint.dateModifier,
                    )}
                    )
                  </AngloTooltip>
                </div>
              </div>
              <div className="single-meeting-point__header--right"></div>
            </div>
            <div className="single-meeting-point__details">
              <div className="single-meeting-point__details__city">
                {meetingPoint.meetingPoint.city}
              </div>
              <div className="single-meeting-point__details__address">
                <Link
                  to={meetingPoint.meetingPoint.mapUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {meetingPoint.meetingPoint.address}
                  <i className="ri-external-link-line"></i>
                </Link>
              </div>
            </div>
          </div>

          {canUpdateProgram && (
            <ActionButtons
              onEdit={() => {
                setMeetingToEdit(meetingPoint);
                setEditVisible(true);
              }}
              onDelete={() => {
                setDeleteVisible(true);
                setMeetingToDelete(meetingPoint.id);
              }}
              itemId={meetingPoint.id}
            />
          )}
        </div>
      ))}
      <AngloTooltip
        text={
          !canUpdateProgram
            ? getString("you_dont_have_access")
            : getString("assign_hotel_to_program_first")
        }
        enabled={!canUpdateProgram || !programData.hotel}
        position="start"
      >
        <NewValuePlaceholder
          addStringId={`add_${groupType}_meeting_point`}
          onClick={() => setAssignVisible(true)}
          disabled={!canUpdateProgram || !programData.hotel}
        />
      </AngloTooltip>
      {assignVisible && (
        <AssignMeetingCanvas
          groupType={groupType}
          visible={assignVisible}
          setVisible={setAssignVisible}
        />
      )}
      {editVisible && (
        <EditMeetingCanvas
          groupType={groupType}
          visible={editVisible}
          setVisible={setEditVisible}
          meetingToEdit={meetingToEdit}
        />
      )}
      {deleteVisible && (
        <DeleteMeetingModal
          groupType={groupType}
          visible={deleteVisible}
          setVisible={setDeleteVisible}
          programMeetingId={meetingToDelete}
        />
      )}
    </div>
  );
};
