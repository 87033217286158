import React, { useContext, useEffect } from "react";

import { FormSelect } from "Components/Entity/FormSelect";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, Form } from "reactstrap";
import { handleSelectChange } from "helpers/validation_helper";
import { useFormik } from "formik";
import { getString, getStringSync } from "Components/Common/FormattedString";
import OffcanvasRight from "Components/Entity/OffcanvasRight";
import { coordinatorTypes } from "models/coordinators";
import { getCoordinatorListData } from "store/coordinators/action";
import { getUserListData } from "store/actions";
import { ProgramDetailsContext } from "../../ProgramDetails";
import * as Yup from "yup";
import {
  assignCoordinatorToProgram,
  assignHealthAndSafetyToProgram,
} from "helpers/API/core-service/cs_backend_helper";
import { toast_error, toast_success } from "helpers/toast_helper";

export const AssignCoordinatorCanvas = ({ visible, setVisible }) => {
  const { programData, refreshProgramData } = useContext(ProgramDetailsContext);

  const validation = useFormik({
    enableReinitialize: true,
    validateOnChange: false,

    initialValues: {
      type: "",
      programPacketId: "",
      coordinatorId: "",
    },
    validationSchema: Yup.object({
      coordinatorId: Yup.string().required(getStringSync("select_user")),
      type: Yup.string().required(getStringSync("select_type")),
      programPacketId: Yup.string().when("type", {
        is: (value) => value === "coordinator",
        then: (schema) =>
          schema.required(getStringSync("select_program_packet")),
        otherwise: (schema) => schema,
      }),
    }),
    onSubmit: (values) => {
      switch (values.type) {
        case "coordinator":
          assignCoordinatorToProgram(programData.id, values.programPacketId, {
            coordinatorId: values.coordinatorId,
          })
            .then(() => {
              toast_success(getString("coordinator_assigned"));
              setVisible(false);
              refreshProgramData();
            })
            .catch((e) => toast_error(e.message));
          break;
        case "health_and_safety":
          assignHealthAndSafetyToProgram(programData.id, {
            healthAndSafetyId: values.coordinatorId,
          })
            .then(() => {
              toast_success(getString("health_and_safety_assigned"));
              setVisible(false);
              refreshProgramData();
            })
            .catch((e) => toast_error(e.message));
          break;
        default:
          console.error("Wrong coordinator type");
          break;
      }
    },
  });

  const { coordinatorsData } = useSelector(
    (rootState) => rootState.Coordinators,
  );
  const coordinatorOptions = coordinatorsData
    .filter((item) => item?.status !== "archived")
    .filter((c) => c.type === validation.values.type)
    .map((u) => {
      return { value: u.id, label: `${u.firstName} ${u.lastName}` };
    });

  const programPacketOptions = programData.programPackets
    .filter((item) => item?.status !== "archived")
    .map((p) => {
      return { value: p.id, label: p.name };
    });
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCoordinatorListData());
    dispatch(getUserListData());
  }, []);

  return (
    <OffcanvasRight
      isOpen={visible}
      toggle={() => setVisible(false)}
      title={getString("coordinator_assign")}
      formId="assign-coordinator-form"
      validationRule={true}
      buttonLabel={getString("save")}
    >
      <Card className="add-installment-plan">
        <CardBody className="card-body">
          <Form
            id="assign-coordinator-form"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
            action="#"
          >
            <FormSelect
              name="type"
              label={getString("type")}
              value={validation.values.type}
              onChange={(selectedOption) => {
                handleSelectChange(validation, selectedOption, "type");
                validation.setFieldValue("coordinatorId", "");
                validation.setFieldValue("programPacketId", "");
              }}
              isMulti={false}
              options={coordinatorTypes}
              error={validation.errors["type"]}
            />
            {validation.values.type === "coordinator" && (
              <FormSelect
                name="programPacketId"
                label={getString("program_packet")}
                value={validation.values.type}
                onChange={(selectedOption) => {
                  handleSelectChange(
                    validation,
                    selectedOption,
                    "programPacketId",
                  );
                }}
                isMulti={false}
                options={programPacketOptions}
                error={validation.errors["programPacketId"]}
              />
            )}
            <FormSelect
              key={`${validation.values.type}`}
              isDisabled={validation.values.type === ""}
              name="coordinatorId"
              label={getString("user_selected")}
              value={validation.values.coordinatorId}
              onChange={(selectedOption) =>
                handleSelectChange(validation, selectedOption, "coordinatorId")
              }
              isMulti={false}
              options={coordinatorOptions}
              error={validation.errors["coordinatorId"]}
            />
          </Form>
        </CardBody>
      </Card>
    </OffcanvasRight>
  );
};
