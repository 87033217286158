import React from "react";
import { AngloTooltip } from "Components/Common/AngloTooltip/AngloTooltip";
import { IpParticipantStatus } from "models/ipParticipants";
import GradientProgressBar from "Components/Common/GradientProgressBar/GradientProgressBar";
import "./IpSummaryCell.scss";

const IpSummaryCell = ({ summary }) => {
  const { quantity, capacityPerType } = summary;

  const combinedCapacity =
    (capacityPerType?.ns || 0) +
    (capacityPerType?.non_ns_ip || 0) +
    (capacityPerType?.esl || 0);

  const getCombinedStatusCount = (status) => {
    return quantity.reduce((total, typeData) => {
      const statusData = typeData.statusCount.find((s) => s.status === status);
      return total + (statusData?.count || 0);
    }, 0);
  };

  const invitedCount = getCombinedStatusCount(IpParticipantStatus.INVITED);
  const awaitingTravelDetailsCount = getCombinedStatusCount(
    IpParticipantStatus.AWAITING_TRAVEL_DETAILS,
  );
  const partlyConfirmedCount = getCombinedStatusCount(
    IpParticipantStatus.PARTLY_CONFIRMED,
  );
  const fullyConfirmedCount = getCombinedStatusCount(
    IpParticipantStatus.CONFIRMED,
  );

  const sum =
    invitedCount +
    awaitingTravelDetailsCount +
    partlyConfirmedCount +
    fullyConfirmedCount;

  const percentage =
    combinedCapacity > 0
      ? Math.min(Math.round((sum / combinedCapacity) * 100), 100)
      : 0;

  const ipBaseColor = "#073763";
  const isMuted = combinedCapacity === 0;

  return (
    <div className="ip-summary-cell">
      <div
        className={`ip-summary-cell__equation ${
          isMuted ? "ip-summary-cell__equation--muted" : ""
        }`}
      >
        <span className="ip-summary-cell__bracket">(</span>
        <AngloTooltip stringId="invited">
          <span className="ip-summary-cell__val ip-summary-cell__val--invited">
            {invitedCount}
          </span>
        </AngloTooltip>
        <span className="ip-summary-cell__separator"> + </span>
        <AngloTooltip stringId="awaiting_travel_details">
          <span className="ip-summary-cell__val ip-summary-cell__val--awaiting-travel-details">
            {awaitingTravelDetailsCount}
          </span>
        </AngloTooltip>
        <span className="ip-summary-cell__bracket">)</span>
        <span className="ip-summary-cell__separator"> </span>
        <AngloTooltip stringId="partly_confirmed">
          <span className="ip-summary-cell__val ip-summary-cell__val--partly-confirmed">
            {partlyConfirmedCount}
          </span>
        </AngloTooltip>
        <span className="ip-summary-cell__separator"> + </span>
        <AngloTooltip stringId="fully_confirmed">
          <span className="ip-summary-cell__val ip-summary-cell__val--fully-confirmed">
            {fullyConfirmedCount}
          </span>
        </AngloTooltip>
        <span className="ip-summary-cell__separator"> = </span>
        <AngloTooltip stringId="sum">
          <span className="ip-summary-cell__val ip-summary-cell__sum">
            {sum}
          </span>
        </AngloTooltip>
        <span className="ip-summary-cell__separator"> / </span>
        <AngloTooltip stringId="total_capacity">
          <span className="ip-summary-cell__val ip-summary-cell__total">
            {combinedCapacity}
          </span>
        </AngloTooltip>
      </div>
      {!isMuted && (
        <GradientProgressBar percentage={percentage} baseColor={ipBaseColor} />
      )}
    </div>
  );
};

export default IpSummaryCell;
