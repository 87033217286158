import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { ApplicationDetailsContext } from "pages/Applications/ApplicationDetails/ApplicationDetails";
import { getProgram } from "helpers/API/core-service/cs_backend_helper";
import { internalizeDate, isObjectEmpty, splitNumbers } from "helpers/utils";
import FormContext from "./utils/FormContext";
import { validate } from "./utils/validation";
import { handleSave } from "./utils/utils";
import { getProgramListData } from "store/programs/action";
import { STEP_SELECT_PROGRAM, STEP_SUMMARY } from "./utils/stepsConfig";
import { PayerTypes } from "models/payerTypes";
import { getString } from "Components/Common/FormattedString";
import { Spinner } from "reactstrap";

export const ApplicationMoveFormProvider = ({ children }) => {
  const dispatch = useDispatch();
  const { applicationData, refreshApplicationData, setMoveTileOpen } =
    useContext(ApplicationDetailsContext);
  const [targetProgram, setTargetProgram] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [maxInstallmentSum, setMaxInstallmentSum] = useState(
    applicationData.applicationInstallments.reduce(
      (sum, installment) => sum + installment.price,
      0,
    ),
  );
  const [installmentsError, setInstallmentsError] = useState(null);

  useEffect(() => {
    getProgram(applicationData.programId).then((res) => {
      if (res) {
        setTargetProgram(res);
        formik.setFieldValue("program", res.id);

        const matchingMeeting = res.programMeetingsPP.find(
          (meeting) =>
            meeting.meetingPoint.id === applicationData?.meetingPoint?.id,
        );
        if (matchingMeeting) {
          formik.setFieldValue("programMeeting", matchingMeeting.id);
        }
      } else {
        setTargetProgram(null);
      }
    });
  }, [applicationData.programId]);

  useEffect(() => {
    dispatch(getProgramListData());
  }, [dispatch]);

  const initialValues = {
    program: "",
    programMeeting: "",
    participants:
      applicationData.participants?.length > 0
        ? applicationData.participants.map((participant) => ({
            gender: participant.gender || "",
            programPacket: participant.programPacketId || "",
            firstName: participant.firstName || "",
            lastName: participant.lastName || "",
            email: participant.email || "",
            phonePrefix: participant.phonePrefix || "",
            phoneNumber: participant.phoneNumber || "",
            dateOfBirth: internalizeDate(participant.dateOfBirth) || "",
            comment: participant.comment || "",
            upsells: {
              diet: participant.dietId || "",
              insurance: participant.insuranceId || "",
              upsells: participant.upsells?.map((upsell) => upsell.id) || [],
            },
          }))
        : [],
    payerDetails: {
      type:
        applicationData.payerDetails?.companyName &&
        applicationData.payerDetails?.taxNumber
          ? PayerTypes.COMPANY
          : PayerTypes.PERSON,
      companyName: applicationData.payerDetails?.companyName || "",
      taxNumber: applicationData.payerDetails?.taxNumber || "",
      firstName: applicationData.payerDetails?.firstName || "",
      lastName: applicationData.payerDetails?.lastName || "",
      city: applicationData.payerDetails?.city || "",
      zipCode: applicationData.payerDetails?.zipCode || "",
      address: applicationData.payerDetails?.address || "",
    },
    installments: [],
    promoCode: applicationData.promoCodeDiscounts?.[0]?.code || "",
  };

  const [currentStep, setCurrentStep] = useState(STEP_SELECT_PROGRAM);

  const formik = useFormik({
    validateOnChange: true,
    initialValues,
    validate,
  });

  const handlePayerTypeChange = (e) => {
    const { value } = e.target;
    formik.setFieldValue("payerDetails.type", value);
    formik.setFieldValue("payerDetails.firstName", "");
    formik.setFieldValue("payerDetails.lastName", "");
    formik.setFieldValue("payerDetails.companyName", "");
    formik.setFieldValue("payerDetails.taxNumber", "");
    formik.validateForm();
  };

  const validateStep = (step = currentStep) => {
    return formik.validateForm().then((errors) => {
      const formattedErrors = {};
      Object.keys(errors).forEach((key) => {
        const keys = key.split(".");
        keys.reduce((acc, part, index) => {
          const arrayMatch = part.match(/^(\w+)\[(\d+)\]$/);
          if (arrayMatch) {
            const [_, arrayName, arrayIndex] = arrayMatch;
            acc[arrayName] = acc[arrayName] || [];
            acc[arrayName][arrayIndex] = acc[arrayName][arrayIndex] || {};
            return acc[arrayName][arrayIndex];
          } else {
            if (index === keys.length - 1) {
              acc[part] = errors[key];
            } else {
              acc[part] = acc[part] || {};
            }
            return acc[part];
          }
        }, formattedErrors);
      });

      switch (step) {
        case STEP_SELECT_PROGRAM: {
          let participantsErrors = [];

          if (typeof formattedErrors.participants === "string") {
            participantsErrors = formattedErrors.participants;
          } else {
            participantsErrors = formik.values.participants.map((_, index) => {
              const { upsells, ...rest } =
                formattedErrors.participants?.[index] || {};
              return rest;
            });
          }

          const hasErrors = Array.isArray(participantsErrors)
            ? participantsErrors.some((error) => Object.keys(error).length > 0)
            : participantsErrors.length > 0;

          formik.setErrors({ participants: participantsErrors });

          const participantsErrorsStep2 = formik.values.participants.map(
            (_, index) => formattedErrors.participants?.[index] || {},
          );

          const hasErrorsStep2 = participantsErrorsStep2.some(
            (error) => Object.keys(error).length > 0,
          );

          formik.setErrors({
            participants: participantsErrorsStep2,
            programMeeting: formattedErrors.programMeeting,
          });

          return hasErrors || hasErrorsStep2 || formattedErrors.programMeeting
            ? {
                participants: participantsErrorsStep2,
                programMeeting: formattedErrors.programMeeting,
              }
            : {};
        }
        case STEP_SUMMARY: {
          const installmentsErrors = formattedErrors.installments || {};
          if (isObjectEmpty(installmentsErrors)) {
            const sumOfPrices = formik.values.installments.reduce(
              (sum, installment) => sum + installment.price,
              0,
            );
            if (sumOfPrices !== maxInstallmentSum && maxInstallmentSum !== 0) {
              const currency = formik.values.installments[0]?.currency || "PLN";

              const errorString = `${getString("sum_of_prices")} ${splitNumbers(sumOfPrices)} ${currency}. ${getString("desired_installment_sum")} ${splitNumbers(maxInstallmentSum)} ${currency}.`;

              setInstallmentsError(errorString);
              installmentsErrors.sum = errorString;
            } else {
              setInstallmentsError(null);
            }
          }
          formik.setErrors({ installments: installmentsErrors });
          return installmentsErrors;
        }
        default:
          return Promise.resolve({});
      }
    });
  };

  const handleStepChange = (direction) => {
    if (direction > 0) {
      const targetStep = currentStep + direction;
      const validateSteps = async () => {
        for (let step = currentStep; step < targetStep; step++) {
          const errors = await validateStep(step);
          if (Object.keys(errors).length > 0) {
            formik.setErrors(errors);
            return;
          }
        }
        setCurrentStep(targetStep);
      };
      validateSteps();
    } else {
      setCurrentStep((prevStep) => prevStep + direction);
    }
  };

  return (
    <FormContext.Provider
      value={{
        formik,
        currentStep,
        setCurrentStep,
        handleStepChange,
        handlePayerTypeChange,
        validateStep,
        targetProgram,
        setTargetProgram,
        handleSave: () =>
          handleSave({
            currentStep,
            formik,
            applicationData,
            setSubmitting,
            refreshApplicationData,
            setMoveTileOpen,
            validateStep,
            targetProgram,
          }),
        submitting,
        maxInstallmentSum,
        setMaxInstallmentSum,
        installmentsError,
        setInstallmentsError,
      }}
    >
      {targetProgram ? (
        children
      ) : (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "100vh" }}
        >
          <Spinner color="primary" />
        </div>
      )}
    </FormContext.Provider>
  );
};
