// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.participants-step .summary-card {
  margin-bottom: 20px;
}
.participants-step .summary-title {
  margin-bottom: 0 !important;
}
.participants-step .installment-list {
  margin-top: 20px;
}
.participants-step .add-installment-btn {
  background-color: transparent;
  border: none;
  color: rgb(56, 151, 221);
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
  padding: 10px;
  transition: background-color 0.3s, color 0.3s;
}
.participants-step .add-installment-btn__icon {
  border: 1px solid rgb(56, 151, 221);
  border-radius: 50%;
  padding: 0px 3px;
  transition: background-color 0.3s, color 0.3s;
}
.participants-step .add-installment-btn:hover {
  background-color: #f0f0f0;
}
.participants-step .add-installment-btn:hover .add-installment-btn__icon {
  background-color: rgb(56, 151, 221);
  color: #fff;
}
.participants-step .create-new-participant {
  background-color: transparent;
  color: #505050;
  border-top: 1px solid #ddd;
  padding-top: 10px;
}
.participants-step .create-new-participant .add-installment-btn__icon {
  border-color: #505050;
  color: #505050;
  height: 21px;
  width: 21px;
}
.participants-step .create-new-participant:hover {
  background-color: #f0f0f0;
}
.participants-step .create-new-participant:hover .add-installment-btn__icon {
  background-color: #505050;
  color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/pages/Applications/ApplicationDetails/partials/ApplicationForm/steps/ParticipantsStep/ParticipantsStep.scss"],"names":[],"mappings":"AACE;EACE,mBAAA;AAAJ;AAGE;EACE,2BAAA;AADJ;AAIE;EACE,gBAAA;AAFJ;AAKE;EACE,6BAAA;EACA,YAAA;EACA,wBAAA;EACA,aAAA;EACA,mBAAA;EACA,QAAA;EACA,eAAA;EACA,yBAAA;UAAA,iBAAA;EACA,aAAA;EACA,6CACE;AAJN;AAOI;EACE,mCAAA;EACA,kBAAA;EACA,gBAAA;EACA,6CACE;AANR;AAUI;EACE,yBAAA;AARN;AAUM;EACE,mCAAA;EACA,WAAA;AARR;AAaE;EACE,6BAAA;EACA,cAAA;EACA,0BAAA;EACA,iBAAA;AAXJ;AAaI;EACE,qBAAA;EACA,cAAA;EACA,YAAA;EACA,WAAA;AAXN;AAcI;EACE,yBAAA;AAZN;AAcM;EACE,yBAAA;EACA,WAAA;AAZR","sourcesContent":[".participants-step {\n  .summary-card {\n    margin-bottom: 20px;\n  }\n\n  .summary-title {\n    margin-bottom: 0 !important;\n  }\n\n  .installment-list {\n    margin-top: 20px;\n  }\n\n  .add-installment-btn {\n    background-color: transparent;\n    border: none;\n    color: rgb(56, 151, 221);\n    display: flex;\n    align-items: center;\n    gap: 4px;\n    cursor: pointer;\n    user-select: none;\n    padding: 10px;\n    transition:\n      background-color 0.3s,\n      color 0.3s;\n\n    &__icon {\n      border: 1px solid rgb(56, 151, 221);\n      border-radius: 50%;\n      padding: 0px 3px;\n      transition:\n        background-color 0.3s,\n        color 0.3s;\n    }\n\n    &:hover {\n      background-color: #f0f0f0;\n\n      .add-installment-btn__icon {\n        background-color: rgb(56, 151, 221);\n        color: #fff;\n      }\n    }\n  }\n\n  .create-new-participant {\n    background-color: transparent;\n    color: #505050;\n    border-top: 1px solid #ddd;\n    padding-top: 10px;\n\n    .add-installment-btn__icon {\n      border-color: #505050;\n      color: #505050;\n      height: 21px;\n      width: 21px;\n    }\n\n    &:hover {\n      background-color: #f0f0f0;\n\n      .add-installment-btn__icon {\n        background-color: #505050;\n        color: #fff;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
