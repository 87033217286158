import React, { useContext } from "react";
import { Modal } from "reactstrap";

import { getString } from "Components/Common/FormattedString";
import ConfirmDetachBody from "Components/Entity/ConfirmDetachBody";
import { detachInstallmentPlan } from "helpers/API/core-service/cs_backend_helper";
import { ProgramDetailsContext } from "../../ProgramDetails";
import { toast_success } from "helpers/toast_helper";

export const DeleteInstallmentPlanModal = ({ itemId, visibility, toggle }) => {
  const { programData, refreshProgramData } = useContext(ProgramDetailsContext);

  const deleteInstallmentPlanEntry = () => {
    detachInstallmentPlan(programData.id, itemId)
      .then(() => {
        toast_success(getString("installment_plan_detached"));
        toggle(false);
        refreshProgramData();
      })
      .catch((e) => console.error(e));
  };

  return (
    <Modal
      isOpen={visibility}
      toggle={() => {
        toggle();
      }}
      backdrop={"static"}
      id="staticBackdrop"
      centered
    >
      <ConfirmDetachBody
        entityName={getString("installment_plan_sm")}
        toggle={toggle}
        deleteAction={() => deleteInstallmentPlanEntry()}
      />
    </Modal>
  );
};
