import React, { useContext, useEffect, useState } from "react";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, Form } from "reactstrap";
import { handleCheckboxGroupChange } from "helpers/validation_helper";
import { useFormik } from "formik";
import { getString } from "Components/Common/FormattedString";
import OffcanvasRight from "Components/Entity/OffcanvasRight";
import { getUpsellListData } from "store/upsells/action";
import { assignUpsellToProgram } from "helpers/API/core-service/cs_backend_helper";
import { ProgramDetailsContext } from "../../ProgramDetails";
import { toast_error, toast_success } from "helpers/toast_helper";
import { FormCheckboxGroup } from "Components/Entity/FormCheckboxGroup";

export const AssignIpUpsellCanvas = ({ visible, setVisible }) => {
  const { programData, refreshProgramData } = useContext(ProgramDetailsContext);
  const [loading, setLoading] = useState(false);

  const { upsellsData } = useSelector((rootState) => rootState.Upsells);

  const internationalParticipantUpsellIds =
    programData.internationalParticipantUpsells.map((upsell) => upsell.id);

  const filteredUpsellsData = upsellsData
    .filter((item) => item?.status !== "archived")
    .filter((option) => !internationalParticipantUpsellIds.includes(option.id));

  const groupedUpsellsOptions = filteredUpsellsData.reduce((acc, upsell) => {
    const group = acc.find((g) => g.label === upsell.type);
    const option = { value: upsell.id, label: upsell.name };

    if (group) {
      group.options.push(option);
    } else {
      acc.push({ label: upsell.type, options: [option] });
    }

    return acc;
  }, []);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUpsellListData());
  }, [dispatch]);

  const validation = useFormik({
    enableReinitialize: true,
    validateOnChange: false,

    initialValues: {
      upsells: [],
    },
    validationSchema: Yup.object({
      upsells: Yup.array()
        .of(Yup.string().required(getString("upsell_required")))
        .min(1, getString("at_least_one_upsell_required")),
    }),
    onSubmit: (values) => {
      setLoading(true);
      assignUpsellToProgram(programData.id, {
        upsells: values.upsells,
        participantGroup: "international_participants",
      })
        .then(() => {
          toast_success(getString("upsell_assigned"));
          setVisible(false);
          refreshProgramData();
        })
        .catch((e) => toast_error(e.message))
        .finally(() => setLoading(false));
    },
  });

  return (
    <OffcanvasRight
      isOpen={visible}
      toggle={() => setVisible(false)}
      title={getString("ip_upsells_assign")}
      formId="assign-upsell-form"
      validationRule={true}
      buttonLabel={getString("save")}
      loading={loading}
    >
      <Card>
        <CardBody className="card-body">
          <Form
            id="assign-upsell-form"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
            action="#"
          >
            <FormCheckboxGroup
              values={validation.values.upsells}
              onChange={(selectedOption) =>
                handleCheckboxGroupChange(validation, selectedOption, "upsells")
              }
              options={groupedUpsellsOptions}
              error={validation.errors["upsells"]}
            />
          </Form>
        </CardBody>
      </Card>
    </OffcanvasRight>
  );
};
