import React, { useContext, useState } from "react";
import { Modal } from "reactstrap";
import { getString } from "Components/Common/FormattedString";
import ConfirmDetachBody from "Components/Entity/ConfirmDetachBody";
import { ProgramDetailsContext } from "../../ProgramDetails";
import { toast_error, toast_success } from "helpers/toast_helper";
import { removeIpFromProgramPacket } from "helpers/API/core-service/cs_backend_helper";

export const DeleteIpModal = ({
  itemId,
  programPacketId,
  visible,
  setVisible,
}) => {
  const [loading, setLoading] = useState(false);
  const toggle = () => {
    setVisible(!visible);
  };
  const { refreshProgramData } = useContext(ProgramDetailsContext);

  const deleteIpEntry = () => {
    setLoading(true);
    removeIpFromProgramPacket(programPacketId, itemId)
      .then(() => {
        toast_success(getString("program_ip_removed"));
        toggle(false);
        refreshProgramData();
      })
      .catch((e) => toast_error(e.message))
      .finally(() => setLoading(false));
  };

  return (
    <Modal
      isOpen={visible}
      toggle={() => {
        toggle();
      }}
      backdrop={"static"}
      id="staticBackdrop"
      centered
    >
      <ConfirmDetachBody
        entityName={getString("international_participant")}
        toggle={toggle}
        deleteAction={deleteIpEntry}
        loading={loading}
      />
    </Modal>
  );
};
