import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getIpParticipantListData,
  postIpParticipant,
} from "../../store/ipParticipants/action";

import { useFormik } from "formik";
import { createSelector } from "reselect";
import {
  handleInputChange,
  handleSelectChange,
  setValidationErrors,
} from "../../helpers/validation_helper";

import { Card, CardBody, Form } from "reactstrap";

import { createIpParticipantSchema } from "../../models/ipParticipants";
import OffcanvasRight from "../../Components/Entity/OffcanvasRight";
import FormTextField from "../../Components/Entity/FormTextField";
import { FormSelect } from "../../Components/Entity/FormSelect";
import { getString } from "Components/Common/FormattedString";
import { genderOptions } from "models/genders";
import suggestedDiets from "models/diets";

import countries from "models/countries";

import { format } from "date-fns";
import FormDateField from "Components/Entity/FormDateField";
import FormPhoneField from "Components/Common/FormPhoneField/FormPhoneField";

const AddIpParticipant = ({ toggle, visibility }) => {
  const dispatch = useDispatch();
  const selectIpParticipantsState = (state) => state.IpParticipants;
  const selectIpParticipantsProperties = createSelector(
    selectIpParticipantsState,
    (state) => ({
      businessUnitsData: state.businessUnitsData,
      isIpParticipantAdded: state.isIpParticipantAdded,
      error: state.error,
      loading: state.loading,
      formValidationErrors: state.formValidationErrors,
    }),
  );

  const { loading, formValidationErrors } = useSelector(
    selectIpParticipantsProperties,
  );

  // Form validation (local)
  const validation = useFormik({
    enableReinitialize: true,
    validateOnChange: false,

    initialValues: {
      gender: "",
      firstName: "",
      lastName: "",
      email: "",
      phonePrefix: "48",
      phoneNumber: "",
      dateOfBirth: "",
      countryOfOrigin: "",
      preferredDiet: "",
    },
    validationSchema: createIpParticipantSchema,
    onSubmit: (values) => {
      const newValues = {
        ...values,
      };
      newValues.dateOfBirth = format(
        new Date(values.dateOfBirth),
        "yyyy-MM-dd'T'HH:mm:ssxxx",
      );

      dispatch(
        postIpParticipant(newValues, () => {
          dispatch(getIpParticipantListData());
          toggle(false);
        }),
      );
    },
  });

  // Form validation (api)
  const [prevValidationErrors, setPrevValidationErrors] = useState(null);
  useEffect(() => {
    if (formValidationErrors == null) {
      return;
    }
    if (prevValidationErrors !== null) {
      if (prevValidationErrors !== formValidationErrors) {
        setValidationErrors(validation, formValidationErrors);
      }
    }
    setPrevValidationErrors(formValidationErrors);
  }, [formValidationErrors, prevValidationErrors]);

  return (
    <React.Fragment>
      <OffcanvasRight
        isOpen={visibility}
        toggle={toggle}
        title={getString("ip_participants_add_new")}
        formId={"add-ipParticipant"}
        validationRule={validation.isValid}
        loading={loading}
        buttonLabel={getString("add")}
      >
        <Form
          id="add-ipParticipant"
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
          action="#"
        >
          <Card>
            <CardBody className="card-body">
              <FormSelect
                label={getString("gender")}
                name="gender"
                options={genderOptions}
                value={validation.values.gender}
                onChange={(selectedOption) =>
                  handleSelectChange(validation, selectedOption, "gender")
                }
                error={validation.errors.gender}
                touched={validation.touched.gender}
              />
              <FormTextField
                label={getString("firstName")}
                name="firstName"
                value={validation.values.firstName}
                onChange={(e) => handleInputChange(validation, e, "firstName")}
                error={validation.errors.firstName}
                touched={validation.touched.firstName}
              />
              <FormTextField
                label={getString("lastName")}
                name="lastName"
                value={validation.values.lastName}
                onChange={(e) => handleInputChange(validation, e, "lastName")}
                error={validation.errors.lastName}
                touched={validation.touched.lastName}
              />
              <FormTextField
                label={getString("email")}
                name="email"
                type="email"
                value={validation.values.email}
                onChange={(e) => handleInputChange(validation, e, "email")}
                error={validation.errors.email}
                touched={validation.touched.email}
              />
              <FormPhoneField
                label={getString("phoneNumber")}
                name="phoneNumber"
                phonePrefixName="phonePrefix"
                value={validation.values["phoneNumber"] || ""}
                onChange={(e) =>
                  handleInputChange(validation, e, "phoneNumber")
                }
                phonePrefix={validation.values.phonePrefix || ""}
                onPrefixChange={(e) =>
                  handleInputChange(validation, e, "phonePrefix")
                }
                error={
                  validation.errors["phoneNumber"] ||
                  validation.errors["phonePrefix"]
                }
              />
              <FormDateField
                label={getString("dateOfBirth")}
                name="dateOfBirth"
                value={validation.values.dateOfBirth}
                onChange={(e) =>
                  handleInputChange(validation, e, "dateOfBirth")
                }
                error={validation.errors.dateOfBirth}
                touched={validation.touched.dateOfBirth}
              />
              <FormSelect
                label={getString("countryOfOrigin")}
                name="countryOfOrigin"
                options={countries}
                value={validation.values.countryOfOrigin}
                onChange={(selectedOption) =>
                  handleSelectChange(
                    validation,
                    selectedOption,
                    "countryOfOrigin",
                  )
                }
                error={validation.errors.countryOfOrigin}
                touched={validation.touched.countryOfOrigin}
              />
              <FormSelect
                label={getString("preferredDiet")}
                name="preferredDiet"
                options={suggestedDiets}
                value={validation.values.preferredDiet}
                onChange={(selectedOption) =>
                  handleSelectChange(
                    validation,
                    selectedOption,
                    "preferredDiet",
                  )
                }
                error={validation.errors.preferredDiet}
                touched={validation.touched.preferredDiet}
              />
            </CardBody>
          </Card>
        </Form>
      </OffcanvasRight>
    </React.Fragment>
  );
};

export default AddIpParticipant;
