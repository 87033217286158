import React from "react";
import "./ApplicationStatusBadge.scss";
import classNames from "classnames";

const ApplicationStatusBadge = ({ statusLabel, statusText }) => {
  const className =
    "application-status-badge--" +
    statusLabel
      .toLowerCase()
      .replace(/[\s()]/g, "_")
      .replace(/[^a-z0-9_]/g, "")
      .replace(/__+/g, "_")
      .replace(/_+$/, "");

  return (
    <div className="application-status-badge__container">
      <div className={classNames("application-status-badge", className)}>
        {statusText || statusLabel}
      </div>
    </div>
  );
};

export default ApplicationStatusBadge;
