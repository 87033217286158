import { FormattedString } from "Components/Common/FormattedString";
import { splitNumbers } from "helpers/utils";
import { IpDetailsContext } from "pages/IpParticipants/IpParticipantDetails/IpParticipantDetails";
import { useContext } from "react";

export const IpParticipantDetails = () => {
  const {
    ipData: {
      firstName,
      lastName,
      phonePrefix,
      phoneNumber,
      email,
      gender,
      preferredDiet,
      countryOfOrigin,
      dateOfBirth,
    },
  } = useContext(IpDetailsContext);

  return (
    <div className="ip-participant-details">
      <ul className="list-unstyled mb-0 vstack gap-3">
        <li>
          <div className="d-flex align-items-center">
            <div className="flex-shrink-0">
              <i className="ri-user-line me-2 align-middle text-muted fs-16"></i>
            </div>
            <div className="flex-grow-1">
              <h6 className="fs-14 mb-1">
                {firstName || "N/A"} {lastName || "N/A"}
              </h6>
              <p className="text-muted mb-0">
                <FormattedString id="participant" />
              </p>
            </div>
          </div>
        </li>
        <li>
          <i className="ri-mail-line me-2 align-middle text-muted fs-16"></i>
          <a href={`mailto:${email}`} target="_blank" rel="noopener noreferrer">
            {email || "N/A"}
          </a>
        </li>
        <li>
          <i className="ri-phone-line me-2 align-middle text-muted fs-16"></i>
          <a href={`tel:+${phonePrefix}${phoneNumber}`}>
            +{phonePrefix || "N/A"} {splitNumbers(phoneNumber) || "N/A"}
          </a>
        </li>
        <li>
          <i className="ri-user-line me-2 align-middle text-muted fs-16"></i>
          <span>
            <FormattedString id={gender || "N/A"} />
          </span>
        </li>
        <li>
          <i className="ri-restaurant-line me-2 align-middle text-muted fs-16"></i>
          <span>
            <FormattedString id={preferredDiet || "N/A"} />
          </span>
        </li>
        <li>
          <i className="ri-map-pin-line me-2 align-middle text-muted fs-16"></i>
          <span>{countryOfOrigin || "N/A"}</span>
        </li>
        <li>
          <i className="ri-calendar-line me-2 align-middle text-muted fs-16"></i>
          <span>
            {dateOfBirth ? new Date(dateOfBirth).toLocaleDateString() : "N/A"}
          </span>
        </li>
      </ul>
    </div>
  );
};
