import { Nav, NavItem, NavLink } from "reactstrap";
import { ProgramDetailsContext } from "../ProgramDetails";
import { useContext } from "react";
import classNames from "classnames";
import { ProgramStatusChanger } from "./components/ProgramStatusChanger/ProgramStatusChanger";
import { OutlineButton } from "Components/Common/OutlineButton/OutlineButton";
import { useNavigate, useParams } from "react-router-dom";
import { FormattedString } from "Components/Common/FormattedString";
import { WithPermission } from "Components/Common/WithPermission/WithPermission";
import { useHasPermission } from "Components/Hooks/useHasPermission";

export const ProgramTabSwitcher = () => {
  const { activeTab, setActiveTab, programData } = useContext(
    ProgramDetailsContext,
  );

  const { id } = useParams();

  const navigate = useNavigate();

  const toggleTab = (tab) => {
    setActiveTab(tab);
  };

  const canEditProgram =
    useHasPermission("PROGRAM_UPDATE") && programData?.status !== "archived";

  return (
    <div className="program-details__nav">
      <Nav
        pills
        className="animation-nav profile-nav gap-2 gap-lg-3 flex-grow-1"
        role="tablist"
      >
        <NavItem>
          <NavLink
            href="#overview"
            className={classNames(
              { active: activeTab === "overview" },
              "fs-14",
            )}
            onClick={() => {
              toggleTab("overview");
            }}
          >
            <i className="ri-airplay-fill d-inline-block d-md-none"></i>{" "}
            <span className="d-none d-md-inline-block">
              <FormattedString id="overview" />
            </span>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            href="#participants"
            className={classNames(
              { active: activeTab === "participants" },
              "fs-14",
            )}
            onClick={() => {
              toggleTab("participants");
            }}
          >
            <i className="ri-list-unordered d-inline-block d-md-none"></i>{" "}
            <span className="d-none d-md-inline-block">
              <FormattedString id="participants" />
            </span>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            href="#international-participants"
            className={classNames(
              { active: activeTab === "international-participants" },
              "fs-14",
            )}
            onClick={() => {
              toggleTab("international-participants");
            }}
          >
            <i className="ri-price-tag-line d-inline-block d-md-none"></i>{" "}
            <span className="d-none d-md-inline-block">
              <FormattedString id="internationalParticipants" />
            </span>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            href="#coordinators"
            className={classNames(
              { active: activeTab === "coordinators" },
              "fs-14",
            )}
            onClick={() => {
              toggleTab("coordinators");
            }}
          >
            <i className="ri-folder-4-line d-inline-block d-md-none"></i>{" "}
            <span className="d-none d-md-inline-block">
              <FormattedString id="coordinators" />
            </span>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            href="#log"
            className={classNames({ active: activeTab === "log" }, "fs-14")}
            onClick={() => {
              toggleTab("log");
            }}
          >
            <i className="ri-folder-4-line d-inline-block d-md-none"></i>{" "}
            <span className="d-none d-md-inline-block">
              <FormattedString id="log" />
            </span>
          </NavLink>
        </NavItem>
      </Nav>
      <div className="program-details__nav__right">
        <WithPermission permissionName="PROGRAM_UPDATE">
          <OutlineButton
            onClick={() => {
              navigate(`/program/${id}/edit`);
            }}
            disabled={!canEditProgram}
          >
            <i className="ri-pencil-line"></i>
            <FormattedString id="editProgram" />
          </OutlineButton>
        </WithPermission>
        <ProgramStatusChanger disabled={!canEditProgram} />
      </div>
    </div>
  );
};
