import React, { useState } from "react";
import {
  Card,
  CardBody,
  Form,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";
import { FormSelect } from "Components/Entity/FormSelect";
import ApplicationStepNavigation from "../../components/ApplicationStepNavigation/ApplicationStepNavigation";
import { useFormContext } from "../../provider/utils/FormContext";
import { handleSelectChange } from "helpers/validation_helper";
import { FormattedString, getString } from "Components/Common/FormattedString";

const UpsellsStep = () => {
  const { formik, parentProgram } = useFormContext();
  const [activeTab, setActiveTab] = useState(0);

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const dietOptions = parentProgram.participantUpsells
    .filter((upsell) => upsell.type === "diet")
    ?.map((upsell) => ({ value: upsell.id, label: upsell.name }));

  const insuranceOptions = parentProgram.participantUpsells
    .filter((upsell) => upsell.type === "insurance")
    ?.map((upsell) => ({ value: upsell.id, label: upsell.name }));

  const upsellOptions = parentProgram.participantUpsells
    .filter((upsell) => upsell.type !== "diet" && upsell.type !== "insurance")
    ?.map((upsell) => ({ value: upsell.id, label: upsell.name }));

  const programMeetingOptions = parentProgram.programMeetingsPP?.map(
    (meeting) => ({
      value: meeting.id,
      label: `${meeting.meetingPoint.address}, ${meeting.meetingPoint.city} (${meeting.startHour} - ${meeting.endHour})`,
    }),
  );

  return (
    <div className="upsells-step">
      <Row className="mb-4">
        <Col>
          <h2 className="text-left text-primary">
            <FormattedString id="upsells" />
          </h2>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col md={6}>
          <FormSelect
            name="programMeeting"
            id="programMeeting"
            label={getString("program_meeting")}
            isMulti={false}
            options={programMeetingOptions}
            defaultValue={programMeetingOptions.find(
              (r) => r.value === formik.values.programMeeting,
            )}
            value={formik.values.programMeeting}
            onChange={(selectedOption) => {
              handleSelectChange(formik, selectedOption, "programMeeting");
            }}
            error={formik.errors.programMeeting}
          />
        </Col>
      </Row>
      <Nav tabs>
        {formik.values.participants?.map((participant, index) => {
          const participantErrors = formik.errors.participants?.[index] || {};
          const hasErrors = Object.keys(participantErrors).length > 0;

          return (
            <NavItem key={index}>
              <NavLink
                className={classnames({
                  active: activeTab === index,
                  "text-danger": hasErrors,
                })}
                onClick={() => {
                  toggle(index);
                }}
                style={{ cursor: "pointer" }}
              >
                {participant.firstName && participant.lastName
                  ? `${participant.firstName} ${participant.lastName}`
                  : `${getString("participant")} ${index + 1}`}
              </NavLink>
            </NavItem>
          );
        })}
      </Nav>
      <TabContent activeTab={activeTab}>
        {formik.values.participants?.map((participant, index) => {
          const upsells = participant.upsells || {
            diet: "",
            insurance: "",
            upsells: [],
          };

          return (
            <TabPane tabId={index} key={index}>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  formik.handleSubmit();
                  return false;
                }}
              >
                <Card>
                  <CardBody>
                    <Row>
                      <Col md={6}>
                        <FormSelect
                          name={`participants[${index}].upsells.diet`}
                          id={`participants[${index}].upsells.diet`}
                          label={getString("diet")}
                          isMulti={false}
                          options={dietOptions}
                          defaultValue={dietOptions.find(
                            (r) => r.value === upsells.diet,
                          )}
                          value={upsells.diet}
                          onChange={(selectedOption) => {
                            handleSelectChange(
                              formik,
                              selectedOption,
                              `participants[${index}].upsells.diet`,
                            );
                          }}
                          error={
                            formik.errors.participants?.[index]?.upsells?.diet
                          }
                        />
                      </Col>
                      <Col md={6}>
                        <FormSelect
                          name={`participants[${index}].upsells.insurance`}
                          id={`participants[${index}].upsells.insurance`}
                          label={getString("insurance")}
                          isMulti={false}
                          options={insuranceOptions}
                          defaultValue={insuranceOptions.find(
                            (r) => r.value === upsells.insurance,
                          )}
                          value={upsells.insurance}
                          onChange={(selectedOption) => {
                            handleSelectChange(
                              formik,
                              selectedOption,
                              `participants[${index}].upsells.insurance`,
                            );
                          }}
                          error={
                            formik.errors.participants?.[index]?.upsells
                              ?.insurance
                          }
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <FormSelect
                          name={`participants[${index}].upsells.upsells`}
                          id={`participants[${index}].upsells.upsells`}
                          label={getString("upsells")}
                          isMulti={true}
                          options={upsellOptions}
                          value={upsells.upsells}
                          defaultValue={upsells.upsells?.map((upsellValue) =>
                            upsellOptions.find((r) => r.value === upsellValue),
                          )}
                          onChange={(selectedOptions) => {
                            const selectedValues = selectedOptions?.map(
                              (option) => option.value,
                            );
                            formik.setFieldValue(
                              `participants[${index}].upsells.upsells`,
                              selectedValues,
                            );
                          }}
                          error={
                            formik.errors.participants?.[index]?.upsells
                              ?.upsells
                          }
                        />
                      </Col>
                    </Row>
                    {formik.errors.participants?.[index]?.upsells &&
                      typeof formik.errors.participants[index].upsells ===
                        "string" && (
                        <div className="text-danger">
                          {formik.errors.participants[index].upsells}
                        </div>
                      )}
                  </CardBody>
                </Card>
              </Form>
            </TabPane>
          );
        })}
      </TabContent>
      <ApplicationStepNavigation />
    </div>
  );
};

export default UpsellsStep;
