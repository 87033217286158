import { useContext } from "react";
import { SingleProgramInstallmentDot } from "../SingleProgramInstallmentDot";
import "./ProgramInstallments.scss";
import { ProgramDetailsContext } from "pages/Programs/ProgramDetails/ProgramDetails";
import { getCurrencyTotals } from "pages/Programs/ProgramDetails/packetUtils";

export const ProgramInstallments = ({
  installmentPlans,
  programPrices,
  programPacket,
}) => {
  const { programData } = useContext(ProgramDetailsContext);

  const sortedInstallmentPlans = installmentPlans?.sort(
    (a, b) => a.installmentCount - b.installmentCount,
  );

  const currencyTotals = getCurrencyTotals(programData, programPacket.id);

  const targetPrice = Object.values(currencyTotals).reduce(
    (sum, value) => sum + value,
    0,
  );

  return (
    <div className="program-installments">
      {sortedInstallmentPlans?.map((installmentPlan, index) => (
        <SingleProgramInstallmentDot
          key={index}
          index={index + 1}
          installmentPlan={installmentPlan}
          programPrices={programPrices}
          programPacket={programPacket}
          targetPrice={targetPrice}
        />
      ))}
    </div>
  );
};
