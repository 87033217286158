import React, { useContext, useState } from "react";
import { Button, Col, Row, TabPane } from "reactstrap";
import { CustomerDetailsContext } from "../../../CustomerDetails";
import { Tile } from "Components/Common/Tile/Tile";
import { FormattedString, getString } from "Components/Common/FormattedString";
import { CustomerApplications } from "../../components/CustomerApplications/CustomerApplications";
import AddApplication from "pages/Applications/AddApplication";
import "./CustomerOverviewTab.scss";
import CustomerDetailsRight from "../../components/CustomerDetails/CustomerDetailsRight";

export const CustomerOverviewTab = () => {
  const { customerData } = useContext(CustomerDetailsContext);
  const [applicationAddVisible, setApplicationAddVisible] = useState(false);

  const toggleApplicationAdd = () => {
    setApplicationAddVisible(!applicationAddVisible);
  };

  return (
    <TabPane tabId="overview">
      <Row>
        <Col xxl={9}>
          <Tile
            title={getString("recent_applications")}
            gap="14px"
            titleDropdown={
              <Button
                className="add-application-button"
                color="primary"
                onClick={toggleApplicationAdd}
              >
                <i className="ri-add-line"></i>
                <FormattedString id="add_application" />
              </Button>
            }
          >
            <CustomerApplications />
          </Tile>
        </Col>
        <CustomerDetailsRight />
      </Row>
      {applicationAddVisible && (
        <AddApplication
          visibility={applicationAddVisible}
          toggle={toggleApplicationAdd}
          defaultCustomer={customerData}
        />
      )}
    </TabPane>
  );
};
