import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { LinkButton } from "Components/Common/LinkButton/LinkButton";
import {
  FormattedString,
  getString,
  getStringSync,
} from "Components/Common/FormattedString";
import FormTextField from "../../../../../../../Components/Entity/FormTextField";

const NewInvitationForm = ({
  handleSubmitInvitation,
  toggle,
  existingInvites,
}) => {
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email(getStringSync("invalid_email"))
        .test(
          "unique-email",
          getStringSync("email_already_invited"),
          (value) => !existingInvites.includes(value),
        ),
    }),
    onSubmit: (values) => {
      handleSubmitInvitation(values);
      formik.resetForm();
      toggle();
    },
  });

  const handleCancel = () => {
    formik.resetForm();
    toggle();
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <FormTextField
        name="email"
        label={getString("email")}
        value={formik.values.email}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.errors.email}
      />
      <div className="d-flex justify-content-center mt-3 gap-5">
        <LinkButton color="secondary" type="button" onClick={handleCancel}>
          <i className="ri-close-line" style={{ marginRight: "4px" }}></i>
          <FormattedString id="cancel" />
        </LinkButton>
        <LinkButton color="primary" onClick={() => formik.handleSubmit()}>
          <i className="ri-add-line" style={{ marginRight: "4px" }}></i>
          <FormattedString id="invite" />
        </LinkButton>
      </div>
    </form>
  );
};

export default NewInvitationForm;
