import { getStringSync } from "Components/Common/FormattedString";

const countries = [
  { value: "us", label: getStringSync("united_states"), highlighted: true },
  { value: "gb", label: getStringSync("great_britain"), highlighted: true },
  { value: "ca", label: getStringSync("canada") },
  { value: "au", label: getStringSync("australia") },
  { value: "de", label: getStringSync("germany") },
  { value: "fr", label: getStringSync("france") },
  { value: "it", label: getStringSync("italy") },
  { value: "es", label: getStringSync("spain") },
  { value: "jp", label: getStringSync("japan") },
  { value: "cn", label: getStringSync("china") },
  { value: "in", label: getStringSync("india") },
  { value: "br", label: getStringSync("brazil") },
  { value: "ru", label: getStringSync("russia") },
  { value: "za", label: getStringSync("south_africa") },
  { value: "ng", label: getStringSync("nigeria") },
  { value: "mx", label: getStringSync("mexico") },
  { value: "kr", label: getStringSync("south_korea") },
  { value: "ar", label: getStringSync("argentina") },
  { value: "sa", label: getStringSync("saudi_arabia") },
  { value: "tr", label: getStringSync("turkey") },
  { value: "se", label: getStringSync("sweden") },
  { value: "no", label: getStringSync("norway") },
  { value: "fi", label: getStringSync("finland") },
  { value: "dk", label: getStringSync("denmark") },
  { value: "nl", label: getStringSync("netherlands") },
  { value: "be", label: getStringSync("belgium") },
  { value: "ch", label: getStringSync("switzerland") },
  { value: "at", label: getStringSync("austria") },
  { value: "pl", label: getStringSync("poland") },
  { value: "cz", label: getStringSync("czech_republic") },
  { value: "hu", label: getStringSync("hungary") },
  { value: "pt", label: getStringSync("portugal") },
  { value: "gr", label: getStringSync("greece") },
  { value: "ie", label: getStringSync("ireland") },
  { value: "ro", label: getStringSync("romania") },
  { value: "bg", label: getStringSync("bulgaria") },
  { value: "hr", label: getStringSync("croatia") },
  { value: "rs", label: getStringSync("serbia") },
  { value: "ua", label: getStringSync("ukraine") },
  { value: "by", label: getStringSync("belarus") },
  { value: "il", label: getStringSync("israel") },
  { value: "eg", label: getStringSync("egypt") },
  { value: "pk", label: getStringSync("pakistan") },
  { value: "id", label: getStringSync("indonesia") },
  { value: "my", label: getStringSync("malaysia") },
  { value: "sg", label: getStringSync("singapore") },
  { value: "th", label: getStringSync("thailand") },
  { value: "vn", label: getStringSync("vietnam") },
  { value: "ph", label: getStringSync("philippines") },
  { value: "bd", label: getStringSync("bangladesh") },
  { value: "ir", label: getStringSync("iran") },
  { value: "iq", label: getStringSync("iraq") },
  { value: "sy", label: getStringSync("syria") },
  { value: "af", label: getStringSync("afghanistan") },
  { value: "cl", label: getStringSync("chile") },
  { value: "co", label: getStringSync("colombia") },
  { value: "ve", label: getStringSync("venezuela") },
  { value: "pe", label: getStringSync("peru") },
  { value: "uy", label: getStringSync("uruguay") },
  { value: "ec", label: getStringSync("ecuador") },
  { value: "bo", label: getStringSync("bolivia") },
  { value: "py", label: getStringSync("paraguay") },
  { value: "cu", label: getStringSync("cuba") },
  { value: "do", label: getStringSync("dominican_republic") },
  { value: "jm", label: getStringSync("jamaica") },
  { value: "ht", label: getStringSync("haiti") },
  { value: "gt", label: getStringSync("guatemala") },
  { value: "hn", label: getStringSync("honduras") },
  { value: "sv", label: getStringSync("el_salvador") },
  { value: "ni", label: getStringSync("nicaragua") },
  { value: "cr", label: getStringSync("costa_rica") },
  { value: "pa", label: getStringSync("panama") },
  { value: "tt", label: getStringSync("trinidad_and_tobago") },
  { value: "bb", label: getStringSync("barbados") },
  { value: "bz", label: getStringSync("belize") },
  { value: "gy", label: getStringSync("guyana") },
  { value: "sr", label: getStringSync("suriname") },
  { value: "nz", label: getStringSync("new_zealand") },
  { value: "lk", label: getStringSync("sri_lanka") },
  { value: "mm", label: getStringSync("myanmar") },
  { value: "ss", label: getStringSync("south_sudan") },
  { value: "ma", label: getStringSync("morocco") },
  { value: "dz", label: getStringSync("algeria") },
  { value: "tn", label: getStringSync("tunisia") },
  { value: "ly", label: getStringSync("libya") },
  { value: "gm", label: getStringSync("gambia") },
  { value: "sn", label: getStringSync("senegal") },
  { value: "mr", label: getStringSync("mauritania") },
  { value: "ml", label: getStringSync("mali") },
  { value: "gn", label: getStringSync("guinea") },
  { value: "ci", label: getStringSync("ivory_coast") },
  { value: "bf", label: getStringSync("burkina_faso") },
  { value: "ne", label: getStringSync("niger") },
  { value: "tg", label: getStringSync("togo") },
  { value: "bj", label: getStringSync("benin") },
  { value: "mu", label: getStringSync("mauritius") },
  { value: "lr", label: getStringSync("liberia") },
  { value: "sl", label: getStringSync("sierra_leone") },
  { value: "gh", label: getStringSync("ghana") },
  { value: "td", label: getStringSync("chad") },
  { value: "cf", label: getStringSync("central_african_republic") },
  { value: "cm", label: getStringSync("cameroon") },
  { value: "cv", label: getStringSync("cape_verde") },
  { value: "st", label: getStringSync("sao_tome_and_principe") },
  { value: "gq", label: getStringSync("equatorial_guinea") },
  { value: "ga", label: getStringSync("gabon") },
  { value: "cg", label: getStringSync("congo") },
  { value: "cd", label: getStringSync("democratic_republic_of_the_congo") },
  { value: "ao", label: getStringSync("angola") },
  { value: "gw", label: getStringSync("guinea_bissau") },
  { value: "io", label: getStringSync("british_indian_ocean_territory") },
  { value: "ac", label: getStringSync("ascension_island") },
  { value: "sc", label: getStringSync("seychelles") },
  { value: "sd", label: getStringSync("sudan") },
  { value: "rw", label: getStringSync("rwanda") },
  { value: "et", label: getStringSync("ethiopia") },
  { value: "so", label: getStringSync("somalia") },
  { value: "dj", label: getStringSync("djibouti") },
  { value: "ke", label: getStringSync("kenya") },
  { value: "tz", label: getStringSync("tanzania") },
  { value: "ug", label: getStringSync("uganda") },
  { value: "bi", label: getStringSync("burundi") },
  { value: "mz", label: getStringSync("mozambique") },
  { value: "zm", label: getStringSync("zambia") },
  { value: "mg", label: getStringSync("madagascar") },
  { value: "re", label: getStringSync("reunion") },
  { value: "zw", label: getStringSync("zimbabwe") },
  { value: "na", label: getStringSync("namibia") },
  { value: "mw", label: getStringSync("malawi") },
  { value: "ls", label: getStringSync("lesotho") },
  { value: "bw", label: getStringSync("botswana") },
  { value: "sz", label: getStringSync("eswatini") },
  { value: "km", label: getStringSync("comoros") },
  { value: "sh", label: getStringSync("saint_helena") },
  { value: "er", label: getStringSync("eritrea") },
  { value: "aw", label: getStringSync("aruba") },
  { value: "fo", label: getStringSync("faroe_islands") },
  { value: "gl", label: getStringSync("greenland") },
  { value: "gi", label: getStringSync("gibraltar") },
  { value: "lu", label: getStringSync("luxembourg") },
  { value: "is", label: getStringSync("iceland") },
  { value: "al", label: getStringSync("albania") },
  { value: "mt", label: getStringSync("malta") },
  { value: "cy", label: getStringSync("cyprus") },
  { value: "lt", label: getStringSync("lithuania") },
  { value: "lv", label: getStringSync("latvia") },
  { value: "ee", label: getStringSync("estonia") },
  { value: "md", label: getStringSync("moldova") },
  { value: "am", label: getStringSync("armenia") },
  { value: "ad", label: getStringSync("andorra") },
  { value: "mc", label: getStringSync("monaco") },
  { value: "sm", label: getStringSync("san_marino") },
  { value: "va", label: getStringSync("vatican_city") },
  { value: "me", label: getStringSync("montenegro") },
  { value: "xk", label: getStringSync("kosovo") },
  { value: "si", label: getStringSync("slovenia") },
  { value: "ba", label: getStringSync("bosnia_and_herzegovina") },
  { value: "mk", label: getStringSync("north_macedonia") },
  { value: "sk", label: getStringSync("slovakia") },
  { value: "li", label: getStringSync("liechtenstein") },
  { value: "fk", label: getStringSync("falkland_islands") },
  { value: "pm", label: getStringSync("saint_pierre_and_miquelon") },
  { value: "gp", label: getStringSync("guadeloupe") },
  { value: "gf", label: getStringSync("french_guiana") },
  { value: "mq", label: getStringSync("martinique") },
  { value: "cw", label: getStringSync("curacao") },
  { value: "tl", label: getStringSync("timor_leste") },
  { value: "nf", label: getStringSync("norfolk_island") },
  { value: "bn", label: getStringSync("brunei") },
  { value: "nr", label: getStringSync("nauru") },
  { value: "pg", label: getStringSync("papua_new_guinea") },
  { value: "to", label: getStringSync("tonga") },
  { value: "sb", label: getStringSync("solomon_islands") },
  { value: "vu", label: getStringSync("vanuatu") },
  { value: "fj", label: getStringSync("fiji") },
  { value: "pw", label: getStringSync("palau") },
  { value: "wf", label: getStringSync("wallis_and_futuna") },
  { value: "ck", label: getStringSync("cook_islands") },
  { value: "nu", label: getStringSync("niue") },
  { value: "ws", label: getStringSync("samoa") },
  { value: "ki", label: getStringSync("kiribati") },
  { value: "nc", label: getStringSync("new_caledonia") },
  { value: "tv", label: getStringSync("tuvalu") },
  { value: "pf", label: getStringSync("french_polynesia") },
  { value: "tk", label: getStringSync("tokelau") },
  { value: "fm", label: getStringSync("micronesia") },
  { value: "mh", label: getStringSync("marshall_islands") },
  { value: "kp", label: getStringSync("north_korea") },
  { value: "hk", label: getStringSync("hong_kong") },
  { value: "mo", label: getStringSync("macau") },
  { value: "kh", label: getStringSync("cambodia") },
  { value: "la", label: getStringSync("laos") },
  { value: "tw", label: getStringSync("taiwan") },
  { value: "mv", label: getStringSync("maldives") },
  { value: "lb", label: getStringSync("lebanon") },
  { value: "jo", label: getStringSync("jordan") },
  { value: "kw", label: getStringSync("kuwait") },
  { value: "ye", label: getStringSync("yemen") },
  { value: "om", label: getStringSync("oman") },
  { value: "ps", label: getStringSync("palestine") },
  { value: "ae", label: getStringSync("united_arab_emirates") },
  { value: "bh", label: getStringSync("bahrain") },
  { value: "qa", label: getStringSync("qatar") },
  { value: "bt", label: getStringSync("bhutan") },
  { value: "mn", label: getStringSync("mongolia") },
  { value: "np", label: getStringSync("nepal") },
  { value: "tj", label: getStringSync("tajikistan") },
  { value: "tm", label: getStringSync("turkmenistan") },
  { value: "az", label: getStringSync("azerbaijan") },
  { value: "ge", label: getStringSync("georgia") },
  { value: "kg", label: getStringSync("kyrgyzstan") },
  { value: "uz", label: getStringSync("uzbekistan") },
];

export default countries;
