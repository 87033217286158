import { PlanInstallmentsView } from "Components/Common/PlanInstallmentsView/PlanInstallmentsView";
import "./InstallmentsPreview.scss";

export const InstallmentsPreview = ({ installments, installmentCount }) => {
  return (
    <div className="installments-preview">
      <PlanInstallmentsView
        installments={installments}
        installmentCount={installmentCount}
      />
    </div>
  );
};
