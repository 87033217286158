import React, { useContext, useEffect, useState } from "react";
import { Row, Col, Alert, Button } from "reactstrap";
import { useFormContext } from "../../provider/utils/FormContext";
import { getString, FormattedString } from "Components/Common/FormattedString";
import { PreloaderWrap } from "Components/Common/Preloader/Preloader";
import { useSelector } from "react-redux";
import { installmentTypes } from "models/installmentPlans";
import { internalizeDate } from "helpers/utils";
import mapFormikValuesToDTO from "../../provider/utils/mapFormikValuesToDTO";
import { updateApplicationState } from "helpers/API/core-service/cs_backend_helper";
import { ApplicationDetailsContext } from "pages/Applications/ApplicationDetails/ApplicationDetails";
import { parseApplicationErrorToFormikErrors } from "../../provider/utils/parseApplicationErrorToFormikErrors";
import SummaryDetails from "./SummaryDetails";
import InstallmentForm from "./components/InstallmentForm";
import InstallmentPlanTile from "./components/InstallmentPlanTile";
import "./InstallmentsStep.scss";
import FormSwitch from "Components/Entity/FormSwitch";

const InstallmentsStep = () => {
  const { applicationData } = useContext(ApplicationDetailsContext);
  const PointOfSell = useSelector((rootState) => rootState.PointOfSell);
  const { activeBusinessUnit } = useSelector((rootState) => rootState.Profile);

  const pointOfSellOptions = PointOfSell.pointOfSellData.map((item) => ({
    value: item.id,
    label: item.name,
  }));

  const {
    formik,
    installmentsError,
    setInstallmentsError,
    setMaxInstallmentSum,
    parentProgram,
  } = useFormContext();
  const [loading, setLoading] = useState(true);
  const [summaryData, setSummaryData] = useState(null);
  const [advancedManagement, setAdvancedManagement] = useState(false);
  const [installmentPlans, setInstallmentPlans] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [showInstallmentForm, setShowInstallmentForm] = useState(
    applicationData.applicationInstallments.length > 0,
  );
  const [alertVisible, setAlertVisible] = useState(false);

  const addInstallment = () => {
    const lastInstallment =
      formik.values.installments[formik.values.installments.length - 1];
    const newInstallment = {
      price: "",
      dueDate: "",
      currency: activeBusinessUnit.currency || "",
      type: lastInstallment ? lastInstallment.type : "",
      pointOfSell: lastInstallment ? lastInstallment.pointOfSell : "",
    };

    formik.setFieldValue("installments", [
      ...formik.values.installments,
      newInstallment,
    ]);
  };

  const removeInstallment = (index) => {
    if (formik.values.installments.length > 1) {
      const updatedInstallments = formik.values.installments.filter(
        (_, i) => i !== index,
      );
      formik.setFieldValue("installments", updatedInstallments);
    }
  };

  useEffect(() => {
    const dto = mapFormikValuesToDTO(formik.values, 4, true);
    updateApplicationState(applicationData.id, dto)
      .then((response) => {
        const { installmentPlans, summary, discounts } = response;

        let finalInstallments = [];

        const plansToUse =
          installmentPlans && installmentPlans.length > 0
            ? installmentPlans
            : parentProgram.installmentPlans;

        if (
          !applicationData.applicationInstallments ||
          applicationData.applicationInstallments.length === 0
        ) {
          const sortedInstallmentPlans = plansToUse.sort(
            (a, b) => a.installmentCount - b.installmentCount,
          );

          setInstallmentPlans(sortedInstallmentPlans);
          setSummaryData({ summary, discounts });
          setLoading(false);

          if (response.summary) {
            setAlertVisible(true);
          }
        } else {
          const matchingInstallmentPlan = plansToUse.find(
            (plan) =>
              plan.installmentCount ===
              applicationData.applicationInstallments.length,
          );

          finalInstallments = applicationData.applicationInstallments.map(
            (installment, index) => {
              const matchingInstallment =
                matchingInstallmentPlan?.installments[index];

              const matchingProgramParentInstallment =
                parentProgram.installmentPlans[0].installments[index];

              return {
                price: installment.price,
                dueDate: internalizeDate(installment.dueDate),
                currency:
                  installment.currency ||
                  matchingInstallment?.currency ||
                  matchingProgramParentInstallment?.currency ||
                  activeBusinessUnit.currency,
                type: installment.type,
                pointOfSell:
                  installment.pointOfSellId ||
                  matchingInstallment?.pointOfSellId ||
                  matchingProgramParentInstallment?.pointOfSellId,
                isPaid: installment.isPaid,
                isOverDue: installment.isOverDue,
              };
            },
          );
        }
        formik.setFieldValue("installments", finalInstallments);
        setSummaryData({ summary, discounts });
        setLoading(false);
        if (installmentPlans[0].sum) {
          setMaxInstallmentSum(installmentPlans[0].sum);
        }
        if (response.summary) {
          setAlertVisible(true);
        }
      })
      .catch((error) => {
        parseApplicationErrorToFormikErrors(error.data, formik);
      });
  }, []);

  const toggleAdvancedManagement = () => {
    setAdvancedManagement(!advancedManagement);
  };

  const handleSelectPlan = (plan) => {
    const finalInstallments = plan.installments.map((installment, index) => ({
      price: installment.price,
      dueDate: internalizeDate(installment.dueDate),
      currency: installment?.currency || activeBusinessUnit.currency,
      type: installment.type,
      pointOfSell: installment?.pointOfSellId || pointOfSellOptions[0].value,
      isPaid: installment.isPaid,
      isOverDue: installment.isOverDue,
    }));

    formik.setFieldValue("installments", finalInstallments);
    setMaxInstallmentSum(plan.sum);
    setSelectedPlan(plan);
    setShowInstallmentForm(true);
  };

  return (
    <div className="installments-step">
      {alertVisible && (
        <Alert color="danger">
          <div className="d-flex align-items-center justify-content-center">
            <i
              className="ri-error-warning-line"
              style={{ marginRight: "4px" }}
            ></i>
            <FormattedString id="update_installment_plan_paragraph" />
          </div>
        </Alert>
      )}
      <Row className="mb-4 align-items-center">
        <Col>
          <h2
            className="text-left text-primary blue-header"
            style={{ marginBottom: 0 }}
          >
            <FormattedString id="installments" />
          </h2>
        </Col>
        <Col className="text-right">
          <FormSwitch
            label={getString("advanced_management")}
            name="advancedManagement"
            checked={advancedManagement}
            onChange={toggleAdvancedManagement}
            noMargin
          />
        </Col>
      </Row>
      {loading ? (
        <PreloaderWrap />
      ) : (
        <>
          {summaryData && (
            <SummaryDetails
              summary={summaryData.summary}
              discounts={summaryData.discounts}
            />
          )}
          {showInstallmentForm ? (
            <>
              {installmentPlans.length > 0 && (
                <Row className="mb-2">
                  <Col>
                    <Button
                      color="link"
                      className="select-other-plan-btn"
                      onClick={() => {
                        setShowInstallmentForm(false);
                        setInstallmentsError(null);
                        formik.setErrors({});
                      }}
                    >
                      <i className="ri-arrow-left-line"></i>{" "}
                      <FormattedString id="select_other_plan" />
                    </Button>
                  </Col>
                </Row>
              )}
              <InstallmentForm
                formik={formik}
                installmentTypes={installmentTypes}
                pointOfSellOptions={pointOfSellOptions}
                removeInstallment={removeInstallment}
                advancedManagement={advancedManagement}
                installmentsError={installmentsError}
                addInstallment={addInstallment}
              />
            </>
          ) : (
            <>
              <h3
                className="text-center mb-4 mt-4"
                style={{ color: "rgb(56, 151, 221)" }}
              >
                {getString("select_initial_installment_plan")}
              </h3>
              {installmentPlans.length > 0 ? (
                <Row className="installment-plans-grid">
                  {installmentPlans.map((plan) => (
                    <InstallmentPlanTile
                      key={plan.installmentPlanId}
                      plan={plan}
                      handleSelectPlan={handleSelectPlan}
                    />
                  ))}
                </Row>
              ) : (
                <Alert color="info" className="text-center">
                  <FormattedString id="no_installment_plans_to_show" />
                </Alert>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default InstallmentsStep;
