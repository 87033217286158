import React from "react";
import { Input, Label, FormFeedback } from "reactstrap";

const FormDateTimeField = ({ label, name, value, onChange, error }) => {
  return (
    <div className="mb-3">
      <Label htmlFor={name} className="form-label">
        {label}
      </Label>
      <Input
        type="datetime-local"
        className="form-control"
        id={name}
        name={name}
        onChange={(e) => onChange(e, name)}
        value={value || ""}
        invalid={!!error}
      />
      {error && <FormFeedback type="invalid">{error}</FormFeedback>}
    </div>
  );
};

export default FormDateTimeField;
