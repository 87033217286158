import { getString } from "Components/Common/FormattedString";
import { useContext } from "react";
import { ProgramDetailsContext } from "../../ProgramDetails";
import { calculateDateShift } from "helpers/utils";

export const ProgramInstallmentsPlanTooltipContent = ({
  installmentPlan,
  installments,
}) => {
  const { programData } = useContext(ProgramDetailsContext);

  return (
    <ul>
      <li>
        <strong>{getString("name")}</strong> - {installmentPlan?.name}
      </li>
      <li>
        <strong>{getString("availabilityDays")}</strong> -{" "}
        {installmentPlan?.availabilityDays}
      </li>
      <li>
        <strong>{getString("installmentCount")}</strong> -{" "}
        {installmentPlan?.installmentCount}
      </li>
      {installments.map((installment, index) => (
        <li key={installment.id}>
          <strong>{`${getString("installment")} ${index + 1}`}</strong> -{" "}
          {index !== 0 && (
            <>
              {calculateDateShift(programData.startDate, -installment.dueDays)},{" "}
            </>
          )}
          {installment.price} {installment.currency}
        </li>
      ))}
    </ul>
  );
};
