import {
  API_RESPONSE_SUCCESS,
  API_RESPONSE_ERROR,
  GET_PROGRAM_PACKETS_LIST_DATA,
  SET_CURRENT_PAGE,
  POST_PROGRAM_PACKET,
} from "./actionType";

export const programPacketsApiSuccess = (actionType, data) => ({
  type: API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

export const programPacketsApiError = (actionType, error) => ({
  type: API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getProgramPacketsListData = (
  page = 1,
  pageSize = 30,
  searchTerm = "",
  orderBy,
  orderDirection,
  filters,
) => ({
  type: GET_PROGRAM_PACKETS_LIST_DATA,
  payload: { page, pageSize, searchTerm, orderBy, orderDirection, filters },
});

export const setCurrentPage = (page) => ({
  type: SET_CURRENT_PAGE,
  payload: page,
});

export const postProgramPacket = (data, onSuccess) => ({
  type: POST_PROGRAM_PACKET,
  payload: { data, onSuccess },
});
