import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUpsellListData } from "../../store/upsells/action";
import { getAttachmentListData } from "store/attachments/action";
import TableContainer from "../../Components/Common/TableContainerReactTable";
import { filterListByType } from "../../helpers/entity/list_helper";
import ArchiveUpsell from "./ArchiveUpsell";
import EditUpsell from "./EditUpsell";
import ActionButtons from "../../Components/Entity/ActionButtons/ActionButtons";
import EntityListArchive from "../../Components/Entity/EntityListArchive";
import RestoreUpsell from "./RestoreUpsell";
import { FormattedString, getString } from "Components/Common/FormattedString";
import {
  currentLocation,
  getDefaultFilterType,
  getItemUuid,
  getLocation,
  isValidUuid,
  parentLocation,
} from "helpers/utils";
import { UpsellDetails } from "./UpsellDetails";
import { Badges } from "Components/Common/Badges/Badges";
import { AttachmentsSimplifiedCell } from "Components/Common/Cells/AttachmentsSimplifiedCell";
import UpsellAttachments from "./UpsellAttachments";

const UpsellsList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { upsellsData, loading, error } = useSelector(
    (rootState) => rootState.Upsells,
  );

  useEffect(() => {
    dispatch(getAttachmentListData());
    dispatch(getUpsellListData());
  }, [dispatch]);

  const [upsellAttachmentsModalVisible, setUpsellAttachmentsModalVisible] =
    useState(false);

  const [editModalVisible, setEditModalVisible] = useState(false);
  const [upsellData, setUpsellData] = useState();
  const [filterType, setFilterType] = useState(getDefaultFilterType());
  const [filteredData, setFilteredData] = useState(
    filterListByType(upsellsData, getDefaultFilterType()),
  );

  const toggleEditModal = (upsell, direction = false) => {
    setUpsellData(upsell);
    setEditModalVisible(direction);
  };

  const toggleAtachmentsModal = (upsell, direction = false) => {
    setUpsellData(upsell);
    setUpsellAttachmentsModalVisible(direction);
  };

  useEffect(() => {
    toggleFilter(filterType);
  }, [upsellsData]);

  const toggleFilter = (type) => {
    setFilteredData(filterListByType(upsellsData, type));
    setFilterType(type);
  };

  const [itemId, setModalItemId] = useState(getItemUuid());
  const [archiveModalVisible, setArchiveModalVisible] = useState(false);
  const toggleArchiveModal = (id) => {
    setModalItemId(id);
    setArchiveModalVisible((p) => !p);
  };

  const [restoreModalVisible, setRestoreModalVisible] = useState(false);
  const toggleRestoreModal = (id) => {
    setModalItemId(id);
    setRestoreModalVisible((p) => !p);
  };

  const [detailsModalVisible, setDetailsModalVisible] = useState(
    isValidUuid(getLocation(3)) || isValidUuid(getLocation(2)),
  );

  const toggleDetailsModal = (state, id) => {
    setDetailsModalVisible(state);
    state === false
      ? navigate(parentLocation())
      : navigate(`${currentLocation()}/${id}`);
    setModalItemId(id);
  };

  const columns = useMemo(
    () => [
      {
        Header: getString("name"),
        accessor: "name",
      },
      {
        Header: getString("description"),
        accessor: "description",
      },
      {
        Header: getString("price"),
        accessor: "price",
      },
      {
        Header: getString("type"),
        accessor: (cellProps) => cellProps.type,
        Cell: (cellProps) => <Badges feed={[cellProps.row.original.type]} />,
      },
      {
        Header: getString("installmentPosition"),
        accessor: (cellProps) => cellProps.installmentPosition,
        Cell: (cellProps) => (
          <Badges feed={[cellProps.row.original.installmentPosition]} />
        ),
      },
      {
        Header: getString("attachments"),
        accessor: (cellProps) => (
          <AttachmentsSimplifiedCell
            toggleAtachmentsModal={toggleAtachmentsModal}
            attachments={cellProps.attachments}
            upsell={cellProps}
          />
        ),
      },
      {
        id: "Actions",
        Header: () => (
          <div
            style={{
              textAlign: "right",
            }}
          >
            <FormattedString id="actions" />
          </div>
        ),
        disableSortBy: true,
        accessor: (cellProps) => (
          <ActionButtons
            onEdit={() => toggleEditModal(cellProps, true)}
            onArchive={() => toggleArchiveModal(cellProps.id)}
            isRestorable={cellProps.status === "archived"}
            onRestore={() => toggleRestoreModal(cellProps.id)}
            onShowDetails={() => toggleDetailsModal(true, cellProps.id)}
            itemId={cellProps.id}
            permissionPrefix="UPSELL"
          />
        ),
      },
    ],
    [],
  );

  return (
    <>
      <EntityListArchive toggleFilter={toggleFilter} />
      <TableContainer
        columns={columns || []}
        data={filteredData || []}
        isPagination={true}
        isGlobalFilter={true}
        isBordered={false}
        customPageSize={10}
        className="custom-header-css table align-middle table-nowrap"
        tableClassName="table-centered align-middle table-nowrap mb-0"
        theadClassName="text-muted table-light"
        SearchPlaceholder={getString("upsells_search_for")}
        error={error}
        loading={loading}
        onRetry={() => dispatch(getUpsellListData())}
        preventLoading={
          editModalVisible ||
          archiveModalVisible ||
          restoreModalVisible ||
          detailsModalVisible ||
          upsellAttachmentsModalVisible
        }
      />
      {editModalVisible && (
        <EditUpsell
          upsell={upsellData}
          visibility={editModalVisible}
          toggle={toggleEditModal}
        />
      )}
      {archiveModalVisible && (
        <ArchiveUpsell
          itemId={itemId}
          visibility={archiveModalVisible}
          toggle={toggleArchiveModal}
        />
      )}
      {restoreModalVisible && (
        <RestoreUpsell
          itemId={itemId}
          visibility={restoreModalVisible}
          toggle={toggleRestoreModal}
        />
      )}
      {detailsModalVisible && (
        <UpsellDetails
          itemId={itemId}
          toggle={toggleDetailsModal}
          visible={detailsModalVisible}
        />
      )}
      {upsellAttachmentsModalVisible && (
        <UpsellAttachments
          upsell={upsellsData.find((u) => u.id === upsellData.id)}
          attachments={
            upsellsData.find((u) => u.id === upsellData.id).attachments
          }
          visibility={upsellAttachmentsModalVisible}
          toggle={() => setUpsellAttachmentsModalVisible((p) => !p)}
        />
      )}
    </>
  );
};

export { UpsellsList };
