import React, { useCallback, useState } from "react";
import {
  deletePromoCode,
  getPromoCodesListData,
} from "../../store/promoCodes/action";
import { useDispatch } from "react-redux";

import { Modal } from "reactstrap";
import { getString } from "Components/Common/FormattedString";
import ConfirmDeleteBody from "Components/Entity/ConfirmDeleteBody";
import { toast_success } from "helpers/toast_helper";

const DeletePromoCode = ({ itemId, visibility, toggle }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const deletePromoCodeEntry = useCallback(() => {
    setLoading(true);
    dispatch(
      deletePromoCode(itemId, () => {
        toast_success(getString("promo_code_deleted_successfully"));
        dispatch(getPromoCodesListData());
        setLoading(false);
        toggle();
      }),
    );
  }, [dispatch, itemId, toggle]);

  return (
    <Modal
      isOpen={visibility}
      toggle={() => {
        if (!loading) {
          toggle();
        }
      }}
      backdrop={"static"}
      id="staticBackdrop"
      centered
    >
      <ConfirmDeleteBody
        entityName={getString("promo_code_sm")}
        toggle={toggle}
        loading={loading}
        deleteAction={deletePromoCodeEntry}
      />
    </Modal>
  );
};

export default DeletePromoCode;
