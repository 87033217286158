import { getStringSync } from "Components/Common/FormattedString";

export const applicationStatus = {
  AF_Participants_Data: "af_participants_data",
  AF_Participants_Upsells: "af_participant_upsells",
  AF_Billing_Data: "af_billing_data",
  AF_Summary: "af_summary",
  PreBooking: "pre_booking",
  PartlyPaid: "partly_paid",
  FullyPaid: "fully_paid",
  WaitList: "wait_list",
  NoPayment: "no_payment",
  Canceled: "cancelled",
  CanceledPaid: "cancelled_paid",
  NoShow: "no_show",
  Interrupted: "interrupted",
  Moved: "moved",
  Credit: "credit",
  CanceledCredit: "cancelled_credit",
};

export const statusOptions = Object.keys(applicationStatus)
  .filter(
    (key) =>
      !key.startsWith("AF") &&
      !["WaitList", "PartlyPaid", "FullyPaid"].includes(key),
  )
  .map((key) => ({
    value: applicationStatus[key],
    label: getStringSync(applicationStatus[key]),
  }));
