import React from "react";
import "./DiscountsShort.scss";
import { FormattedString } from "Components/Common/FormattedString";
import { splitNumbers } from "helpers/utils";

const DiscountsShort = ({ discounts }) => {
  const totalDiscount = discounts.reduce(
    (acc, discount) => acc + discount.sum,
    0,
  );

  return (
    <div className="discounts-short">
      {discounts.length > 0 ? (
        <div className="discounts-short__section">
          <div className="discounts-short__section__items">
            {discounts.map((discount, index) => (
              <div key={index} className="discounts-short__section__item">
                <div className="discounts-short__section__item__name">
                  <FormattedString id={discount.type} />
                </div>
                <div className="discounts-short__section__item__amount">
                  -{splitNumbers(discount.sum)} zł
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className="discounts-short__no-discounts">
          <FormattedString id="noDiscounts" />
        </div>
      )}
      <div className="discounts-short__sum">
        <div className="discounts-short__sum__name">
          <FormattedString id="sum" />
        </div>
        <div className="discounts-short__sum__value">
          -{splitNumbers(totalDiscount)} zł
        </div>
      </div>
    </div>
  );
};

export default DiscountsShort;
