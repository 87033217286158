import {
  API_RESPONSE_SUCCESS,
  API_RESPONSE_ERROR,
  SET_CURRENT_PAGE,
  GET_PROMO_CODE_LIST,
  POST_PROMO_CODE,
  UPDATE_PROMO_CODE,
  DELETE_PROMO_CODE,
  POST_PROMO_CODE_ERROR,
} from "./actionType";

export const promoCodesApiSuccess = (actionType, data) => ({
  type: API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

export const promoCodesApiError = (actionType, error) => ({
  type: API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getPromoCodesListData = (
  page = 1,
  searchTerm = "",
  pageSize = null,
) => ({
  type: GET_PROMO_CODE_LIST,
  payload: { page, searchTerm, pageSize },
});

export const getPromoCodesList = (
  page = 1,
  searchTerm = "",
  pageSize = null,
) => ({
  type: GET_PROMO_CODE_LIST,
  payload: { page, searchTerm, pageSize },
});

export const setCurrentPage = (page) => ({
  type: SET_CURRENT_PAGE,
  payload: page,
});

export const postPromoCode = (data, callback) => ({
  type: POST_PROMO_CODE,
  payload: { data, callback },
});

export const postPromoCodeSuccess = (response) => ({
  type: POST_PROMO_CODE_SUCCESS,
  payload: response,
});

export const postPromoCodeError = (error) => ({
  type: POST_PROMO_CODE_ERROR,
  payload: error,
});

export const updatePromoCode = (id, data, callback) => ({
  type: UPDATE_PROMO_CODE,
  payload: { data, id, callback },
});

export const deletePromoCode = (id, callback) => ({
  type: DELETE_PROMO_CODE,
  payload: { id, callback },
});
