// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.application-status-badge {
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 6px;
  text-align: center;
}
.application-status-badge__container {
  display: flex;
}
.application-status-badge--af_participants_data, .application-status-badge--af_participant_upsells, .application-status-badge--af_billing_data, .application-status-badge--af_summary {
  background: #efefef;
  color: #6c757d;
}
.application-status-badge--pre_booking {
  background: #ffcccc;
  color: #990000;
}
.application-status-badge--partly_paid {
  background: #ffe5b4;
  color: #cc7a00;
}
.application-status-badge--fully_paid {
  background: #d9ead3;
  color: #38761d;
}
.application-status-badge--wait_list, .application-status-badge--no_payment, .application-status-badge--cancelled, .application-status-badge--cancelled_paid, .application-status-badge--no_show, .application-status-badge--interrupted, .application-status-badge--moved, .application-status-badge--credit, .application-status-badge--cancelled_credit {
  background: #efefef;
  color: #6c757d;
}`, "",{"version":3,"sources":["webpack://./src/Components/Common/ApplicationStatusBadge/ApplicationStatusBadge.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,cAAA;EACA,kBAAA;AACF;AACE;EACE,aAAA;AACJ;AAEE;EAIE,mBAAA;EACA,cAAA;AAHJ;AAME;EACE,mBAAA;EACA,cAAA;AAJJ;AAOE;EACE,mBAAA;EACA,cAAA;AALJ;AAQE;EACE,mBAAA;EACA,cAAA;AANJ;AASE;EASE,mBAAA;EACA,cAAA;AAfJ","sourcesContent":[".application-status-badge {\n  border-radius: 4px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  padding: 0 6px;\n  text-align: center;\n\n  &__container {\n    display: flex;\n  }\n\n  &--af_participants_data,\n  &--af_participant_upsells,\n  &--af_billing_data,\n  &--af_summary {\n    background: #efefef;\n    color: #6c757d;\n  }\n\n  &--pre_booking {\n    background: #ffcccc;\n    color: #990000;\n  }\n\n  &--partly_paid {\n    background: #ffe5b4;\n    color: #cc7a00;\n  }\n\n  &--fully_paid {\n    background: #d9ead3;\n    color: #38761d;\n  }\n\n  &--wait_list,\n  &--no_payment,\n  &--cancelled,\n  &--cancelled_paid,\n  &--no_show,\n  &--interrupted,\n  &--moved,\n  &--credit,\n  &--cancelled_credit {\n    background: #efefef;\n    color: #6c757d;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
