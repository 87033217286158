import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { FormFeedback, Label, Input } from "reactstrap";
import { FormattedString } from "Components/Common/FormattedString";
import "./FormPriceConfigurationSelect.scss";

export const FormPriceConfigurationSelect = ({
  label = "",
  name,
  value = [],
  options,
  error = "",
  warning = "",
  "data-testid": dataTestId,
  onChange,
  ...rest
}) => {
  const handleSelectionChange = (templateId) => {
    const isSelected = value.some(
      (item) => item.programPacketTemplateId === templateId,
    );
    const newValue = isSelected
      ? value.filter((item) => item.programPacketTemplateId !== templateId)
      : [...value, { programPacketTemplateId: templateId, discount: 0 }];
    onChange(newValue);
  };

  const handleDiscountChange = (templateId, discountValue) => {
    const newValue = value.map((item) =>
      item.programPacketTemplateId === templateId
        ? { ...item, discount: Number(discountValue) }
        : item,
    );
    onChange(newValue);
  };

  const isSelected = (templateId) => {
    return value.some((item) => item.programPacketTemplateId === templateId);
  };

  const getDiscountValue = (templateId) => {
    const item = value.find(
      (item) => item.programPacketTemplateId === templateId,
    );
    return item ? item.discount : 0;
  };

  return (
    <div
      className={classNames("form-price-configuration-select", {
        "form-price-configuration-select--error": error && !warning,
        "form-price-configuration-select--warning": warning,
      })}
      data-testid={dataTestId || `price-configuration-select-${name}`}
    >
      {(label && (
        <Label
          htmlFor={name}
          className="form-price-configuration-select__label"
        >
          {label}
        </Label>
      )) ||
        (name && (
          <Label
            htmlFor={name}
            className="form-price-configuration-select__label"
          >
            {label || <FormattedString id={name} />}
          </Label>
        ))}

      <div className="form-price-configuration-select__options">
        {options.map((option) => {
          const selected = isSelected(option.value);
          return (
            <div
              key={option.value}
              className={classNames("form-price-configuration-select__option", {
                "form-price-configuration-select__option--selected": selected,
              })}
              onClick={() => handleSelectionChange(option.value)}
            >
              <div className="form-price-configuration-select__option-header">
                <input
                  type="checkbox"
                  id={`${name}-${option.value}`}
                  name={name}
                  value={option.value}
                  checked={selected}
                  onChange={() => handleSelectionChange(option.value)}
                  className={classNames(
                    "form-price-configuration-select__input",
                    {
                      "form-price-configuration-select__input--invalid":
                        !warning && error,
                      "form-price-configuration-select__input--warning":
                        warning,
                    },
                  )}
                  onClick={(e) => e.stopPropagation()}
                  {...rest}
                />
                <span className="form-price-configuration-select__option-label">
                  {option.label}
                </span>
              </div>

              {selected && (
                <div
                  className="form-price-configuration-select__discount-input"
                  onClick={(e) => e.stopPropagation()}
                >
                  <Label for={`${name}-${option.value}-discount`}>
                    Discount
                  </Label>
                  <Input
                    type="number"
                    id={`${name}-${option.value}-discount`}
                    value={getDiscountValue(option.value)}
                    onChange={(e) =>
                      handleDiscountChange(option.value, e.target.value)
                    }
                    min="0"
                  />
                </div>
              )}
            </div>
          );
        })}
      </div>

      {warning && (
        <FormFeedback className="form-price-configuration-select__feedback form-price-configuration-select__feedback--warning">
          <i className="ri-error-warning-line"></i>
          {warning}
        </FormFeedback>
      )}
      {!warning && error && (
        <FormFeedback className="form-price-configuration-select__feedback form-price-configuration-select__feedback--invalid">
          {error}
        </FormFeedback>
      )}
    </div>
  );
};

FormPriceConfigurationSelect.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.arrayOf(
    PropTypes.shape({
      programPacketTemplateId: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]).isRequired,
      discount: PropTypes.number.isRequired,
    }),
  ),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  error: PropTypes.string,
  warning: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  "data-testid": PropTypes.string,
};

export default FormPriceConfigurationSelect;
