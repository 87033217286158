import { useHasPermission } from "Components/Hooks/useHasPermission";
import { Link } from "react-router-dom";
import "./AttachmentsSimplifiedCell.scss";
import { getString } from "../FormattedString";

export const AttachmentsSimplifiedCell = ({
  attachments,
  upsell,
  toggleAtachmentsModal,
}) => {
  const matchStringForm = () => {
    if (attachments.length === 0) return getString("no_attachments");
    return attachments.length === 1
      ? `${attachments.length} attachment`
      : `${attachments.length} attachments`;
  };

  const hasUpsellAttachmentsPermission = useHasPermission("UPSELL_ATTACHMENTS");

  return (
    <Link
      className={`d-flex align-items-center gap-1 attachments-simplified-cell ${!hasUpsellAttachmentsPermission ? "attachments-simplified-cell--disabled" : ""}`}
      onClick={() =>
        hasUpsellAttachmentsPermission && toggleAtachmentsModal(upsell, true)
      }
    >
      {matchStringForm()} <i className="ri-attachment-line"></i>
    </Link>
  );
};
