import { combineReducers } from "redux";

// Front
import Layout from "./layouts/reducer";

// Authentication
import Login from "./auth/login/reducer";
import Account from "./auth/register/reducer";
import Profile from "./auth/profile/reducer";

// Dashboard CRM
import DashboardCRM from "./dashboardCRM/reducer";
import Hotels from "./hotels/reducer";
import Users from "./users/reducer";
import MeetingPoints from "./meetingPoints/reducer";
import Coordinators from "./coordinators/reducer";
import Programs from "./programs/reducer";
import Attachments from "./attachments/reducer";
import PaymentMethods from "./paymentMethods/reducer";
import Upsells from "./upsells/reducer";
import Applications from "./applications/reducer";
import PointOfSell from "./pointOfSell/reducer";
import ProgramPacketTemplates from "./programPacketTemplates/reducer";
import InstallmentPlans from "./installmentPlans/reducer";
import IpParticipants from "./ipParticipants/reducer";
import Customers from "./customers/reducer";
import IpApplications from "./ipApplications/reducer";

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  Profile,
  DashboardCRM,
  Hotels,
  Users,
  MeetingPoints,
  Coordinators,
  Programs,
  Attachments,
  PaymentMethods,
  Upsells,
  Applications,
  PointOfSell,
  ProgramPacketTemplates,
  InstallmentPlans,
  IpParticipants,
  Customers,
  IpApplications,
});

export default rootReducer;
