import { getStringSync } from "Components/Common/FormattedString";

export const PayerTypes = {
  PERSON: "person",
  COMPANY: "company",
};

export const payerTypeOptions = [
  { value: PayerTypes.PERSON, label: getStringSync("person") },
  { value: PayerTypes.COMPANY, label: getStringSync("company") },
];
