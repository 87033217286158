import React, { useState } from "react";
import { Col, Container, Row, Button, Spinner } from "reactstrap";
import { IpParticipantsList } from "./ListIpParticipants";
import AddIpParticipant from "./AddIpParticipant";
import EntityCardList from "../../Components/Entity/EntityCardList";
import { FormattedString, getString } from "Components/Common/FormattedString";
import { generateIpReport } from "helpers/API/core-service/cs_backend_helper";
import { downloadBlob } from "helpers/fileUtils";

const IpParticipants = () => {
  document.title = getString("ip_participants_page_title");
  const [ipParticipantAddVisible, setIpParticipantAddVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const toggleIpParticipantAdd = () => {
    setIpParticipantAddVisible((p) => !p);
  };

  const handleGenerateReport = () => {
    setLoading(true);
    generateIpReport()
      .then((response) => {
        const blob = new Blob([response.data], {
          type: "text/csv;charset=utf-8;",
        });
        downloadBlob(blob, response.headers, "ip_report.csv");
      })
      .catch((error) => {
        console.error("Error generating report:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col>
              <div className="h-100">
                <EntityCardList
                  title={getString("ip_participants_card_title")}
                  actionBtnLabel={getString("ip_participant")}
                  actionBtn={toggleIpParticipantAdd}
                  actionBtnPermission="IP_PARTICIPANT_CREATE"
                  headerButtons={
                    <Button
                      color="light"
                      className="generate-report-button text-primary"
                      onClick={handleGenerateReport}
                      disabled={loading}
                    >
                      <FormattedString id="generate_report" />
                      {loading && (
                        <Spinner size="sm" color="primary" className="ms-2" />
                      )}
                    </Button>
                  }
                >
                  <IpParticipantsList />
                </EntityCardList>
              </div>
            </Col>

            {ipParticipantAddVisible && (
              <AddIpParticipant
                visibility={ipParticipantAddVisible}
                toggle={toggleIpParticipantAdd}
              />
            )}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default IpParticipants;
