import { format, parse, parseISO } from "date-fns";
import { validate } from "uuid";

export const getValueFromLocalStorage = (key, defaultValue = null) =>
  localStorage.getItem(key) || defaultValue;

export const getLocation = (i = 1) => {
  const location = window.location;

  return location.pathname.split("/")[i];
};

export const isValidUuid = (uuid) => {
  return validate(uuid);
};

export const currentLocation = () => {
  const pathname = window.location.pathname;
  return pathname;
};

export const parentLocation = () => {
  const pathname = window.location.pathname;
  return pathname.substring(0, pathname.lastIndexOf("/"));
};

export const getItemUuid = () => {
  const uuid3 = getLocation(3);
  const uuid2 = getLocation(2);

  if (isValidUuid(uuid3)) return uuid3;
  if (isValidUuid(uuid2)) return uuid2;

  return null;
};

export const getDefaultFilterType = () => {
  return getLocation(2) === "archived" ? "archived" : "visible";
};

export const isObjectEmpty = (value) => {
  if (value === null || value === undefined) return true;
  if (typeof value === "string" || Array.isArray(value))
    return value.length === 0;
  if (typeof value === "object") return Object.keys(value).length === 0;
  return false;
};

export const formatDate = (dateString) => {
  const date = new Date(dateString);
  const formattedDate = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")}`;
  return formattedDate;
};

export const formatNumber = (num) => {
  return num.toLocaleString();
};

export const calculateDateShift = (date, shift) => {
  const startDate = new Date(date);
  startDate.setDate(startDate.getDate() + shift);
  return formatDate(startDate);
};

export const hasShiftedDatePassed = (date, shift) => {
  const now = new Date();
  const startDate = new Date(date);
  startDate.setDate(startDate.getDate() - shift);
  return startDate < now;
};

export const internalizeDate = (dateString) => {
  if (!dateString) return "";
  const date = parseISO(dateString);
  return format(date, "yyyy-MM-dd");
};

export const externalizeDate = (dateString) => {
  if (!dateString) return "";
  const date = parse(dateString, "yyyy-MM-dd", new Date());
  return format(date, "yyyy-MM-dd'T'HH:mm:ssxxx").slice(0, -6) + "+00:00";
};

export const splitNumbers = (number = "") => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};

export const calculatePercentage = (numerator, denominator) => {
  if (!numerator || !denominator || isNaN(numerator) || isNaN(denominator)) {
    return 0;
  }
  const percentage = (numerator / denominator) * 100;
  return Math.min(percentage, 100).toFixed(0);
};
