import {
  API_RESPONSE_SUCCESS,
  API_RESPONSE_ERROR,
  GET_PROMO_CODE_LIST,
  SET_CURRENT_PAGE,
  POST_PROMO_CODE,
  UPDATE_PROMO_CODE,
  DELETE_PROMO_CODE,
} from "./actionType";

export const INIT_STATE = {
  promoCodes: [],
  formValidationErrors: {},
  error: null,
  loading: false,
  count: 0,
  currentPage: 1,
  totalPages: 0,
  pageSize: 10,
  isPromoCodeAdded: false,
  isPromoCodeUpdated: false,
  isPromoCodeDeleted: false,
};

const PromoCodes = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PROMO_CODE_LIST:
    case POST_PROMO_CODE:
    case UPDATE_PROMO_CODE:
    case DELETE_PROMO_CODE:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case GET_PROMO_CODE_LIST:
          return {
            ...state,
            promoCodes: action.payload.data.data,
            count: action.payload.data.count,
            currentPage: action.payload.data.currentPage,
            totalPages: action.payload.data.totalPages,
            pageSize: action.payload.data.pageSize,
            loading: false,
            error: null,
            isPromoCodeAdded: false,
            isPromoCodeUpdated: false,
            isPromoCodeDeleted: false,
          };

        case POST_PROMO_CODE:
          return {
            ...state,
            loading: false,
            isPromoCodeAdded: true,
            error: null,
          };

        case UPDATE_PROMO_CODE:
          return {
            ...state,
            promoCodes: state.promoCodes.map((promoCode) =>
              promoCode.id === action.payload.data.id
                ? { ...promoCode, ...action.payload.data }
                : promoCode,
            ),
            loading: false,
            isPromoCodeUpdated: true,
            error: null,
          };

        case DELETE_PROMO_CODE:
          return {
            ...state,
            promoCodes: state.promoCodes.filter(
              (promoCode) => promoCode.id !== action.payload.data.id,
            ),
            count: state.count - 1,
            loading: false,
            isPromoCodeDeleted: true,
            error: null,
          };

        default:
          return state;
      }

    case API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case GET_PROMO_CODE_LIST:
          return {
            ...state,
            errorMsg: action.payload.data,
            error: action.payload.error,
            loading: false,
          };

        case POST_PROMO_CODE:
          return {
            ...state,
            error: action.payload.error,
            formValidationErrors: action.payload.error.data?.fields ?? null,
            loading: false,
            isPromoCodeAdded: false,
          };

        case UPDATE_PROMO_CODE:
          return {
            ...state,
            error: action.payload.error,
            loading: false,
            isPromoCodeUpdated: false,
          };

        case DELETE_PROMO_CODE:
          return {
            ...state,
            error: action.payload.error,
            loading: false,
            isPromoCodeDeleted: false,
          };

        default:
          return state;
      }

    case SET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.payload,
      };

    default:
      return state;
  }
};

export default PromoCodes;
