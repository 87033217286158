import React from "react";
import { getStringSync } from "Components/Common/FormattedString";
import "./ProgramStatusBadge.scss";
import classNames from "classnames";

const ProgramStatusBadge = ({ status, label }) => {
  const className =
    "program-status-badge--" +
    status
      .toLowerCase()
      .replace(/[\s()]/g, "_")
      .replace(/[^a-z0-9_]/g, "")
      .replace(/__+/g, "_")
      .replace(/_+$/, "");

  return (
    <div className="program-status-badge__container">
      <div className={classNames("program-status-badge", className)}>
        {label || getStringSync(status)}
      </div>
    </div>
  );
};

export default ProgramStatusBadge;
