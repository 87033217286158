import { FormattedString, getString } from "Components/Common/FormattedString";
import { SimplestTable } from "Components/Common/SimplestTable/SimplestTable";
import ActionButtons from "Components/Entity/ActionButtons/ActionButtons";
import { format } from "date-fns";
import { pl } from "date-fns/locale";
import { ProgramDetailsContext } from "pages/Programs/ProgramDetails/ProgramDetails";
import { useContext, useState } from "react";
import { DeleteProgramDiscountModal } from "../../modals/DeleteProgramDiscountModal";
import { AngloTooltip } from "Components/Common/AngloTooltip/AngloTooltip";
import { EditDiscountCanvas } from "../../modals/EditDiscountCanvas";

export const DiscountsView = () => {
  const { programData, canUpdateProgram } = useContext(ProgramDetailsContext);
  const [programDiscountToEdit, setProgramDiscountToEdit] = useState(null);

  const [editDiscountCanvasVisible, setEditDiscountCanvasVisible] =
    useState(false);

  const [
    deleteProgramDiscountModalVisible,
    setDeleteProgramDiscountModalVisible,
  ] = useState(false);

  return (
    <div className="discounts-view">
      <SimplestTable
        data={programData.programDiscounts}
        columns={[
          {
            header: getString("discount_name"),
            render: (discount) => (
              <div className="d-flex">
                <AngloTooltip
                  text={getString(discount.type)}
                  enabled={discount.type === "time_related"}
                >
                  <div
                    className="d-flex gap-1"
                    style={{ color: "var(--vz-heading-color)" }}
                  >
                    {discount.type === "time_related" ? (
                      <>
                        <i className="ri-information-fill" />
                        {discount.name}
                      </>
                    ) : (
                      <FormattedString id={discount.type} />
                    )}
                  </div>
                </AngloTooltip>
              </div>
            ),
            style: { fontWeight: "500" },
          },
          {
            header: getString("date_range"),
            render: (discount) =>
              discount.startDate && discount.endDate ? (
                <span>
                  {format(new Date(discount.startDate), "dd.MM.yyyy", {
                    locale: pl,
                  })}
                  <span className="text-primary">
                    {format(new Date(discount.startDate), ", HH:mm", {
                      locale: pl,
                    })}
                  </span>
                  <br />
                  {format(new Date(discount.endDate), "dd.MM.yyyy", {
                    locale: pl,
                  })}
                  <span className="text-primary">
                    {format(new Date(discount.endDate), ", HH:mm", {
                      locale: pl,
                    })}
                  </span>
                </span>
              ) : (
                "-"
              ),
          },
          {
            header: getString("discount_value"),
            render: (discount) => (
              <div className="d-flex flex-column">
                <div className="d-flex">
                  <AngloTooltip text={getString("activeAmount")}>
                    <strong className="text-success">
                      {discount.activeAmount}
                    </strong>
                  </AngloTooltip>
                </div>

                {discount.passiveAmount && (
                  <div className="d-flex">
                    <AngloTooltip text={getString("passiveAmount")}>
                      <strong className="text-warning">
                        {discount.passiveAmount}
                      </strong>
                    </AngloTooltip>
                  </div>
                )}
              </div>
            ),
          },
          {
            header: canUpdateProgram && (
              <div
                style={{
                  textAlign: "right",
                }}
              >
                <FormattedString id="actions" />
              </div>
            ),
            render: (discount) =>
              canUpdateProgram && (
                <ActionButtons
                  onEdit={() => {
                    setProgramDiscountToEdit(discount.id);
                    setEditDiscountCanvasVisible(true);
                  }}
                  onDelete={() => {
                    setProgramDiscountToEdit(discount.id);
                    setDeleteProgramDiscountModalVisible(true);
                  }}
                  itemId={discount.id}
                />
              ),
          },
        ]}
        className="table table-striped table-hover"
      />

      {editDiscountCanvasVisible && (
        <EditDiscountCanvas
          programDiscountToEdit={programDiscountToEdit}
          visible={editDiscountCanvasVisible}
          setVisible={setEditDiscountCanvasVisible}
        />
      )}

      {deleteProgramDiscountModalVisible && (
        <DeleteProgramDiscountModal
          itemId={programDiscountToEdit}
          visibility={true}
          toggle={() => setDeleteProgramDiscountModalVisible(false)}
        />
      )}
    </div>
  );
};
