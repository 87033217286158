import React from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import { PayerTypes } from "models/payerTypes";
import { FormattedString } from "Components/Common/FormattedString";

const PayerDetailsAdder = ({
  formik,
  existingPayerDetails,
  setShowPayerDetailsAdder,
}) => {
  const addNewPayerDetails = () => {
    formik.setFieldValue("payerDetails", {
      id: uuidv4(),
      payerType: PayerTypes.PERSON,
      firstName: "",
      lastName: "",
      companyName: "",
      taxNumber: "",
      address: "",
      zipCode: "",
      city: "",
    });
    formik.setErrors({});
    setShowPayerDetailsAdder(false);
  };

  const addExistingPayerDetails = (payerDetails) => {
    const updatedPayerDetails = {
      ...payerDetails,
      payerType: payerDetails.type,
    };

    Object.keys(updatedPayerDetails).forEach((key) => {
      if (updatedPayerDetails[key] === null) {
        updatedPayerDetails[key] = "";
      }
    });
    formik.setFieldValue("payerDetails", updatedPayerDetails);
    formik.setErrors({});
    setShowPayerDetailsAdder(false);
  };

  return (
    <Card className="mt-4">
      <CardBody className="summary-card">
        <p className="summary-title">
          <FormattedString id="choose_an_option" />
        </p>
        <Row className="installment-list">
          {existingPayerDetails.map((payerDetails, idx) => (
            <Col key={idx} md={12}>
              <div
                className="add-installment-btn"
                onClick={() => addExistingPayerDetails(payerDetails)}
              >
                <i className="ri-user-add-line add-installment-btn__icon"></i>
                <FormattedString id="add" /> {payerDetails.firstName}{" "}
                {payerDetails.lastName}
              </div>
            </Col>
          ))}
          <Col md={12}>
            <div
              className="add-installment-btn create-new-payer"
              onClick={addNewPayerDetails}
            >
              <i className="ri-add-line add-installment-btn__icon"></i>
              <FormattedString id="create_new_payer" />
            </div>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default PayerDetailsAdder;
