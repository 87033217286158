// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pp-summary-cell {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
}
.pp-summary-cell__equation {
  display: flex;
}
.pp-summary-cell__separator {
  color: #6c757d;
  margin: 0 2px;
}
.pp-summary-cell__bracket {
  color: black;
  margin: 0 2px;
}
.pp-summary-cell__val {
  font-weight: 400;
}
.pp-summary-cell__val--highlight {
  font-weight: 700;
}
.pp-summary-cell__val--af-summary {
  color: #808080;
}
.pp-summary-cell__val--wait-list {
  color: #808080;
  font-weight: 700;
}
.pp-summary-cell__val--pre-booking {
  color: #ff0000;
}
.pp-summary-cell__val--partly-paid {
  color: #ffa500;
}
.pp-summary-cell__val--fully-paid {
  color: #2ca02c;
}
.pp-summary-cell__sum {
  color: #299cdb;
  font-weight: 700;
}
.pp-summary-cell__total {
  color: black;
}

.pp-summary-cell__equation--muted {
  color: #6c757d;
  filter: grayscale(1);
  opacity: 0.6;
}`, "",{"version":3,"sources":["webpack://./src/pages/ProgramPackets/cells/PpSummaryCell/PpSummaryCell.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,yBAAA;EACA,QAAA;AACF;AACE;EACE,aAAA;AACJ;AAEE;EACE,cAAA;EACA,aAAA;AAAJ;AAGE;EACE,YAAA;EACA,aAAA;AADJ;AAIE;EACE,gBAAA;AAFJ;AAII;EACE,gBAAA;AAFN;AAKI;EACE,cAAA;AAHN;AAMI;EACE,cAAA;EACA,gBAAA;AAJN;AAOI;EACE,cAAA;AALN;AAQI;EACE,cAAA;AANN;AASI;EACE,cAAA;AAPN;AAWE;EACE,cAAA;EACA,gBAAA;AATJ;AAYE;EACE,YAAA;AAVJ;;AAcA;EACE,cAAA;EACA,oBAAA;EACA,YAAA;AAXF","sourcesContent":[".pp-summary-cell {\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n  gap: 8px;\n\n  &__equation {\n    display: flex;\n  }\n\n  &__separator {\n    color: #6c757d;\n    margin: 0 2px;\n  }\n\n  &__bracket {\n    color: black;\n    margin: 0 2px;\n  }\n\n  &__val {\n    font-weight: 400;\n\n    &--highlight {\n      font-weight: 700;\n    }\n\n    &--af-summary {\n      color: #808080;\n    }\n\n    &--wait-list {\n      color: #808080;\n      font-weight: 700;\n    }\n\n    &--pre-booking {\n      color: #ff0000;\n    }\n\n    &--partly-paid {\n      color: #ffa500;\n    }\n\n    &--fully-paid {\n      color: #2ca02c;\n    }\n  }\n\n  &__sum {\n    color: #299cdb;\n    font-weight: 700;\n  }\n\n  &__total {\n    color: black;\n  }\n}\n\n.pp-summary-cell__equation--muted {\n  color: #6c757d;\n  filter: grayscale(1);\n  opacity: 0.6;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
