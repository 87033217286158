import { getStringSync } from "Components/Common/FormattedString";
import * as Yup from "yup";

const installmentPositions = [
  { value: "first", label: getStringSync("installment_first") },
  { value: "last", label: getStringSync("installment_last") },
];

const createPromoCodeSchema = Yup.object().shape({
  code: Yup.string()
    .required(getStringSync("promo_code_required"))
    .max(50, getStringSync("promo_code_max_length")),
  usageLimit: Yup.number()
    .nullable()
    .transform((value, originalValue) => (originalValue === "" ? null : value))
    .min(1, getStringSync("usage_limit_min"))
    .integer(getStringSync("usage_limit_integer")),
  isExternal: Yup.boolean(),
  isGlobal: Yup.boolean(),
  endDate: Yup.string().nullable(),
  startDate: Yup.string().nullable(),
  email: Yup.string().email(getStringSync("email_invalid")),
  installmentPosition: Yup.string()
    .oneOf(
      installmentPositions.map((pos) => pos.value),
      getStringSync("installment_position_invalid"),
    )
    .required(getStringSync("installment_position_required")),
  pricesConfiguration: Yup.array()
    .of(
      Yup.object().shape({
        programPacketTemplateId: Yup.string().required(
          getStringSync("program_packet_template_id_required"),
        ),
        discount: Yup.number()
          .required(getStringSync("discount_required"))
          .min(0, getStringSync("discount_min")),
      }),
    )
    .test(
      "prices-configuration-required",
      getStringSync("prices_configuration_required"),
      function (value) {
        const { isGlobal } = this.parent;
        if (!isGlobal) {
          return value && value.length > 0;
        }
        return true;
      },
    ),
  globalDiscount: Yup.number()
    .nullable()
    .transform((value, originalValue) => (originalValue === "" ? null : value))
    .test(
      "global-discount-required",
      getStringSync("global_discount_required"),
      function (value) {
        const { isGlobal } = this.parent;
        if (isGlobal) {
          return value !== null && value !== undefined && value > 0;
        }
        return true;
      },
    )
    .integer(getStringSync("global_discount_integer")),
});

export { createPromoCodeSchema, installmentPositions };
