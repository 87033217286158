// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.application-status-changer {
  display: flex;
  flex-direction: column;
  width: 300px;
  right: 24px;
  top: 225px;
  z-index: 5;
}
.application-status-changer label {
  color: rgba(255, 255, 255, 0.8);
  position: absolute;
  top: -24px;
}
.application-status-changer .mb-3 {
  margin-bottom: 0 !important;
  position: relative;
}`, "",{"version":3,"sources":["webpack://./src/pages/Applications/ApplicationDetails/partials/components/ApplicationStatusChanger/ApplicationStatusChanger.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,YAAA;EACA,WAAA;EACA,UAAA;EACA,UAAA;AACF;AACE;EACE,+BAAA;EACA,kBAAA;EACA,UAAA;AACJ;AAEE;EACE,2BAAA;EACA,kBAAA;AAAJ","sourcesContent":[".application-status-changer {\n  display: flex;\n  flex-direction: column;\n  width: 300px;\n  right: 24px;\n  top: 225px;\n  z-index: 5;\n\n  label {\n    color: rgba(255, 255, 255, 0.8);\n    position: absolute;\n    top: -24px;\n  }\n\n  .mb-3 {\n    margin-bottom: 0 !important;\n    position: relative;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
