import React, { useMemo, useState, useEffect } from "react";
import { format } from "date-fns";
import { FormattedString, getString } from "Components/Common/FormattedString";
import TableContainer from "Components/Common/TableContainerReactTable";
import AttachmentActionButtons from "pages/IpParticipants/components/AttachmentActionButtons/AttachmentActionButtons";

import EntityListArchive from "Components/Entity/EntityListArchive";
import { filterListByType } from "helpers/entity/list_helper";
import { EditDocumentCanvas } from "../../modals/EditDocumentCanvas";
import ArchiveDocument from "../../modals/ArchiveDocument";
import RestoreDocument from "../../modals/RestoreDocument";

export const IpDocumentList = ({ documents, internationalParticipantId }) => {
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const [filterType, setFilterType] = useState("visible");

  const [documentId, setDocumentId] = useState(null);
  const [archiveModalVisible, setArchiveModalVisible] = useState(false);
  const [restoreModalVisible, setRestoreModalVisible] = useState(false);

  useEffect(() => {
    toggleFilter(filterType);
  }, [documents]);

  const handleEditClick = (document) => {
    setSelectedDocument(document);
    setEditModalVisible(true);
  };

  const toggleArchiveModal = (id) => {
    setDocumentId(id);
    setArchiveModalVisible((prev) => !prev);
  };

  const toggleRestoreModal = (id) => {
    setDocumentId(id);
    setRestoreModalVisible((prev) => !prev);
  };

  const toggleFilter = (type) => {
    setFilteredData(filterListByType(documents, type));
    setFilterType(type);
  };

  const columns = useMemo(
    () => [
      {
        Header: getString("description"),
        accessor: "description",
        Cell: ({ value }) => (
          <span className="text-decoration-none">{value || "N/A"}</span>
        ),
      },
      {
        Header: getString("type"),
        accessor: "type",
        Cell: ({ value }) => (
          <span
            className="text-decoration-none"
            style={{
              backgroundColor: "#f0f0f0",
              padding: "5px",
              borderRadius: "3px",
            }}
          >
            {value ? <FormattedString id={value} /> : "N/A"}
          </span>
        ),
      },
      {
        Header: getString("expires_at"),
        accessor: "expirationDate",
        Cell: ({ value }) => (
          <div className="text-nowrap">
            {value ? format(new Date(value), "yyyy-MM-dd") : "N/A"}
          </div>
        ),
      },
      {
        Header: () => (
          <div
            style={{
              textAlign: "right",
            }}
          >
            <FormattedString id="attachment" />
          </div>
        ),
        accessor: "filePath",
        Cell: ({ row }) => (
          <div className="d-flex justify-content-end">
            <AttachmentActionButtons
              itemId={row.original.id}
              filePath={row.original.filePath}
              fileType={row.original.filePath.split(".").pop()}
              onPreview={() => {}}
              onDownload={() => {}}
              onEdit={() => handleEditClick(row.original)}
              onArchive={
                filterType === "visible"
                  ? () => toggleArchiveModal(row.original.id)
                  : undefined
              }
              onRestore={
                filterType === "archived"
                  ? () => toggleRestoreModal(row.original.id)
                  : undefined
              }
            />
          </div>
        ),
      },
    ],
    [filterType],
  );

  return (
    <div className="ip-document-list">
      <EntityListArchive toggleFilter={toggleFilter} useLocationState={false} />
      <TableContainer
        columns={columns}
        data={filteredData || []}
        isPagination={true}
        excludeUrlHandling
        isGlobalFilter={true}
        isBordered={true}
        customPageSize={10}
        className="custom-header-css table align-middle table-nowrap"
        tableClassName="table-centered align-middle table-nowrap mb-0"
        theadClassName="text-muted table-light"
        SearchPlaceholder={getString("documents_search_for")}
      />
      {editModalVisible && (
        <EditDocumentCanvas
          visible={editModalVisible}
          setVisible={setEditModalVisible}
          documentData={selectedDocument}
        />
      )}
      {archiveModalVisible && (
        <ArchiveDocument
          documentId={documentId}
          internationalParticipantId={internationalParticipantId}
          visibility={archiveModalVisible}
          toggle={toggleArchiveModal}
        />
      )}
      {restoreModalVisible && (
        <RestoreDocument
          documentId={documentId}
          internationalParticipantId={internationalParticipantId}
          visibility={restoreModalVisible}
          toggle={toggleRestoreModal}
        />
      )}
    </div>
  );
};
