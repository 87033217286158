import React, { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getPromoCodesList } from "../../store/promoCodes/action";
import { getProgramPacketTemplateListData } from "../../store/programPacketTemplates/action";
import TableContainerExternalPagination from "Components/Common/TableContainerExternalPagination";
import "./PromoCodesList.scss";
import { FormattedString, getString } from "Components/Common/FormattedString";
import { handleCopyToClipboard, splitNumbers } from "helpers/utils";
import { format } from "date-fns";
import ActionButtons from "../../Components/Entity/ActionButtons/ActionButtons";
import EditPromoCode from "./EditPromoCode";
import DeletePromoCode from "./DeletePromoCode";
import { AngloTooltip } from "Components/Common/AngloTooltip/AngloTooltip";
import PackageBadge from "Components/Common/PackageBadge/PackageBadge";

const PromoCodesList = () => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const isInitialRender = useRef(true);

  const {
    promoCodes,
    count,
    currentPage,
    totalPages,
    pageSize,
    loading,
    error,
  } = useSelector((rootState) => rootState.PromoCodes);

  const { programPacketTemplatesData } = useSelector(
    (state) => state.ProgramPacketTemplates,
  );

  const pageNumber = searchParams.get("page") || "1";
  const globalFilter = searchParams.get("searchTerm") || "";

  const [editModalVisible, setEditModalVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [selectedPromoCode, setSelectedPromoCode] = useState(null);

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
    }
    dispatch(getPromoCodesList(pageNumber, globalFilter));
    dispatch(getProgramPacketTemplateListData());
  }, [searchParams, dispatch]);

  const handleEdit = (promoCode) => {
    setSelectedPromoCode(promoCode);
    setEditModalVisible(true);
  };

  const handleDelete = (promoCode) => {
    setSelectedPromoCode(promoCode);
    setDeleteModalVisible(true);
  };

  const toggleEditModal = () => {
    setEditModalVisible((prev) => !prev);
    setSelectedPromoCode(null);
  };

  const toggleDeleteModal = () => {
    setDeleteModalVisible((prev) => !prev);
    setSelectedPromoCode(null);
  };

  const getProgramPacketTemplateName = (id) => {
    const template = programPacketTemplatesData.find((pp) => pp.id === id);
    return template ? template.label : getString("unknown");
  };

  const columns = [
    {
      id: "promo_code",
      Header: getString("promo_code"),
      accessor: (row) => (
        <div className="promo-code-cell">
          <span
            className="promo-code-text"
            onClick={() => handleCopyToClipboard(row.code)}
          >
            {row.code}
            <i className="ri-clipboard-line clipboard-icon" />
          </span>
        </div>
      ),
    },
    {
      id: "email",
      Header: getString("email"),
      accessor: (row) => (
        <div
          className="cell-email d-flex align-items-center"
          style={{ gap: "8px", color: "#6c757d" }}
        >
          {row.email ? (
            <>
              <i
                className="ri-clipboard-line"
                style={{ cursor: "pointer", color: "inherit" }}
                onClick={() => handleCopyToClipboard(row.email)}
              ></i>
              <span
                style={{ cursor: "pointer", color: "inherit" }}
                onClick={() => handleCopyToClipboard(row.email)}
              >
                {row.email}
              </span>
            </>
          ) : (
            <span>-</span>
          )}
        </div>
      ),
    },
    {
      id: "global_discount",
      Header: getString("global_discount"),
      accessor: (row) => (
        <div
          className="d-flex align-items-center"
          style={{
            fontWeight: row.globalDiscount ? "600" : "400",
            color: row.globalDiscount ? "#495057" : "#6c757d",
          }}
        >
          {row.globalDiscount ? (
            <span className="badge bg-success bg-opacity-10 text-success p-2">
              {splitNumbers(row.globalDiscount)} zł
            </span>
          ) : (
            <span className="text-muted">{getString("none")}</span>
          )}
        </div>
      ),
    },
    {
      id: "discount_values",
      Header: getString("discount_values"),
      accessor: (row) => (
        <div className="d-flex flex-wrap gap-2">
          {row.discountValues && row.discountValues.length > 0 ? (
            row.discountValues.map((v, index) => (
              <AngloTooltip
                key={index}
                text={getProgramPacketTemplateName(v.id)}
              >
                <PackageBadge
                  packageLabel={getProgramPacketTemplateName(v.id)}
                  packageText={`${splitNumbers(v.discount)} zł`}
                />
              </AngloTooltip>
            ))
          ) : (
            <span className="text-muted">{getString("none")}</span>
          )}
        </div>
      ),
    },
    {
      id: "usage_limit",
      Header: getString("usage_limit"),
      accessor: (row) => (
        <div className="d-flex align-items-center">
          {row.usageLimit ? (
            <span
              className="badge bg-info bg-opacity-10 text-info p-2"
              style={{ minWidth: "50px", textAlign: "center" }}
            >
              {row.usageLimit}
            </span>
          ) : (
            <span className="badge bg-secondary bg-opacity-10 text-secondary p-2">
              {getString("unlimited")}
            </span>
          )}
        </div>
      ),
    },
    {
      id: "start_date",
      Header: getString("start_date"),
      accessor: (row) => (
        <div className="cell-start-date">
          {row.startDate ? format(new Date(row.startDate), "yyyy-MM-dd") : "-"}
        </div>
      ),
    },
    {
      id: "end_date",
      Header: getString("end_date"),
      accessor: (row) => (
        <div className="cell-end-date">
          {row.endDate ? format(new Date(row.endDate), "yyyy-MM-dd") : "-"}
        </div>
      ),
    },
    {
      id: "is_external",
      Header: getString("is_external"),
      accessor: (row) => (
        <div className="d-flex align-items-center">
          <span
            className={`badge p-2 ${
              row.isExternal
                ? "bg-info bg-opacity-10 text-info"
                : "bg-secondary bg-opacity-10 text-secondary"
            }`}
          >
            {row.isExternal ? getString("yes") : getString("no")}
          </span>
        </div>
      ),
    },
    {
      id: "Actions",
      Header: () => (
        <div style={{ textAlign: "right" }}>
          <FormattedString id="actions" />
        </div>
      ),
      disableSortBy: true,
      accessor: (row) => (
        <ActionButtons
          onEdit={() => handleEdit(row)}
          onDelete={() => handleDelete(row)}
        />
      ),
    },
  ];

  const customSetGlobalFilter = (value) => {
    const newSearchParams = new URLSearchParams(searchParams);
    if (value) {
      newSearchParams.set("searchTerm", value);
    } else {
      newSearchParams.delete("searchTerm");
    }
    newSearchParams.set("page", "1");
    setSearchParams(newSearchParams);
  };

  const handlePageChange = (page) => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set("page", page.toString());
    setSearchParams(newSearchParams);
  };

  return (
    <div className="promo-codes-list">
      <TableContainerExternalPagination
        columns={columns || []}
        count={count}
        currentPage={currentPage}
        totalPages={totalPages}
        pageSize={pageSize}
        loading={loading}
        globalFilter={globalFilter}
        setGlobalFilter={customSetGlobalFilter}
        onPageChange={handlePageChange}
        data={promoCodes || []}
        isBordered={false}
        isGlobalFilter={true}
        className="custom-header-css table align-middle table-nowrap"
        tableClassName="table-centered align-middle table-nowrap mb-0"
        theadClassName="text-muted table-light"
        SearchPlaceholder={getString("promo_codes_search_for")}
        error={error}
        onRetry={() => dispatch(getPromoCodesList(pageNumber, globalFilter))}
        preventLoading={false}
      />
      {editModalVisible && (
        <EditPromoCode
          promoCode={selectedPromoCode}
          toggle={toggleEditModal}
          visibility={editModalVisible}
        />
      )}
      {deleteModalVisible && (
        <DeletePromoCode
          itemId={selectedPromoCode?.id}
          toggle={toggleDeleteModal}
          visibility={deleteModalVisible}
        />
      )}
    </div>
  );
};

export { PromoCodesList };
