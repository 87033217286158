import React, { useContext, useEffect } from "react";
import PayerTile from "./PayerTile";
import "./PayerTiles.scss";
import { ApplicationDetailsContext } from "pages/Applications/ApplicationDetails/ApplicationDetails";
import { FormattedString } from "Components/Common/FormattedString";
import { applicationStatus } from "models/applicationStatuses";
import { useHasPermission } from "Components/Hooks/useHasPermission";

const PayerTiles = ({
  existingPayerDetails,
  formik,
  setIsEditing,
  onEditPayer,
}) => {
  const { applicationData } = useContext(ApplicationDetailsContext);

  const hasPaidInstallment =
    applicationData?.status === applicationStatus.PartlyPaid ||
    applicationData?.status === applicationStatus.FullyPaid;

  const canModifyPayerAnyway = useHasPermission("PP_APPLICATION_ACCOUNTING");

  const handleTileClick = (payer) => {
    if (hasPaidInstallment && !canModifyPayerAnyway) {
      return;
    }
    formik.setFieldValue("selectedPayer", payer);
    formik.setFieldError("selectedPayer", null);
  };

  const handleEditClick = (payer) => {
    if (hasPaidInstallment && !canModifyPayerAnyway) {
      return;
    }
    onEditPayer(payer);
  };

  const handleAddNewPayer = () => {
    if (hasPaidInstallment && !canModifyPayerAnyway) {
      return;
    }

    formik.setFieldValue("payerDetails", {
      id: null,
      customId: "",
      type: "person",
      companyName: null,
      taxNumber: null,
      firstName: "",
      lastName: "",
      address: "",
      zipCode: "",
      city: "",
      updatedAt: null,
    });
    formik.setFieldValue("selectedPayer", null);
    formik.setErrors({});
    setIsEditing(true);
  };

  useEffect(() => {
    if (!formik.values.selectedPayer && formik.values.payerDetails?.firstName) {
      const matchingPayer = existingPayerDetails.find((payer) => {
        return (
          payer.firstName === formik.values.payerDetails.firstName &&
          payer.lastName === formik.values.payerDetails.lastName &&
          payer.address === formik.values.payerDetails.address &&
          payer.zipCode === formik.values.payerDetails.zipCode &&
          payer.city === formik.values.payerDetails.city
        );
      });

      if (matchingPayer) {
        formik.setFieldValue("selectedPayer", matchingPayer);
      } else {
        formik.setFieldValue("selectedPayer", formik.values.payerDetails);
      }
    }
  }, [
    formik.values.payerDetails,
    formik.values.selectedPayer,
    existingPayerDetails,
  ]);

  const shouldShowPayerDetailsTile = () => {
    if (!formik.values.payerDetails?.firstName) {
      return false;
    }

    const hasExactMatch = existingPayerDetails.some(
      (payer) =>
        payer.firstName === formik.values.payerDetails.firstName &&
        payer.lastName === formik.values.payerDetails.lastName &&
        payer.address === formik.values.payerDetails.address &&
        payer.zipCode === formik.values.payerDetails.zipCode &&
        payer.city === formik.values.payerDetails.city,
    );

    return !hasExactMatch;
  };

  return (
    <div className="payer-tiles">
      {shouldShowPayerDetailsTile() && (
        <PayerTile
          payer={formik.values.payerDetails}
          isSelected={
            formik.values.selectedPayer === formik.values.payerDetails
          }
          onClick={handleTileClick}
          onEdit={handleEditClick}
          disabled={hasPaidInstallment && !canModifyPayerAnyway}
        />
      )}

      {existingPayerDetails.map((payer, index) => (
        <PayerTile
          key={payer.id || index}
          payer={payer}
          isSelected={formik.values.selectedPayer?.id === payer.id}
          onClick={handleTileClick}
          onEdit={handleEditClick}
          disabled={hasPaidInstallment && !canModifyPayerAnyway}
        />
      ))}

      <div
        className={`payer-tile add-new-tile ${
          hasPaidInstallment && !canModifyPayerAnyway ? "disabled" : ""
        }`}
        onClick={handleAddNewPayer}
      >
        <div className="payer-tile__avatar">
          <i className="ri-add-line"></i>
        </div>
        <div className="payer-tile__content">
          <div className="payer-tile__name">
            <FormattedString id="add_new_payer" />
          </div>
        </div>
      </div>

      {hasPaidInstallment && (
        <div
          className={`payer-tiles__disabled-notice ${
            canModifyPayerAnyway
              ? "payer-tiles__disabled-notice--editable"
              : "payer-tiles__disabled-notice--non-editable"
          }`}
        >
          <i className="ri-error-warning-line"></i>
          <span>
            {canModifyPayerAnyway ? (
              <FormattedString id="warning_paid_installments_edit_allowed" />
            ) : (
              <FormattedString id="cannot_change_payer_after_payment" />
            )}
          </span>
        </div>
      )}
    </div>
  );
};

export default PayerTiles;
