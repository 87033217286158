import "./FormCheckboxGroup.scss";
import { FormattedString } from "Components/Common/FormattedString";
import classNames from "classnames";
import { FormFeedback, Label, Input, FormGroup } from "reactstrap";

export const FormCheckboxGroup = ({
  label,
  name,
  values,
  options,
  onChange,
  error,
  ...rest
}) => {
  return (
    <div
      className="mb-3 form-checkbox-group"
      data-testid={`checkbox-group-${name}`}
    >
      {(label && (
        <Label htmlFor={name} className="form-label">
          {label}
        </Label>
      )) ||
        (name && (
          <Label htmlFor={name} className="form-label">
            {label || <FormattedString id={name} />}
          </Label>
        ))}
      {options.map((group) => (
        <div key={group.label} className="checkbox-group mb-3">
          <Label className="checkbox-group-label mb-2">
            <FormattedString id={group.label} />
          </Label>
          {group.options.map((option) => (
            <FormGroup check key={option.value} className="form-check">
              <Label
                check
                className={classNames("checkbox-label", {
                  "checked-label": values.includes(option.value),
                })}
              >
                <Input
                  type="checkbox"
                  name={name}
                  checked={values.includes(option.value)}
                  onChange={() => onChange(option.value)}
                  className={classNames([
                    "form-check-input",
                    "custom-checkbox",
                    error && "is-invalid",
                  ])}
                  {...rest}
                />
                {option.label}
              </Label>
            </FormGroup>
          ))}
        </div>
      ))}
      {error && <FormFeedback type="invalid">{error}</FormFeedback>}
    </div>
  );
};
