// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.payer-tile--disabled {
  opacity: 0.6;
  pointer-events: none;
  cursor: not-allowed;
}

.payer-tile--disabled .payer-tile__edit {
  display: none;
}

.payer-tile__disabled-message {
  color: #ff4d4f;
  font-size: 0.8em;
  margin-top: 5px;
}

.payer-tile.disabled,
.add-new-tile.disabled {
  opacity: 0.6;
  pointer-events: none;
  cursor: not-allowed;
}

.disabled-message {
  color: #ff4d4f;
  font-size: 0.8em;
  margin-top: 5px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Applications/ApplicationDetails/partials/ApplicationForm/steps/PayerDetailsStep/PayerTile.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,oBAAA;EACA,mBAAA;AACF;;AAEA;EACE,aAAA;AACF;;AAEA;EACE,cAAA;EACA,gBAAA;EACA,eAAA;AACF;;AAEA;;EAEE,YAAA;EACA,oBAAA;EACA,mBAAA;AACF;;AAEA;EACE,cAAA;EACA,gBAAA;EACA,eAAA;AACF","sourcesContent":[".payer-tile--disabled {\n  opacity: 0.6;\n  pointer-events: none;\n  cursor: not-allowed;\n}\n\n.payer-tile--disabled .payer-tile__edit {\n  display: none;\n}\n\n.payer-tile__disabled-message {\n  color: #ff4d4f;\n  font-size: 0.8em;\n  margin-top: 5px;\n}\n\n.payer-tile.disabled,\n.add-new-tile.disabled {\n  opacity: 0.6;\n  pointer-events: none;\n  cursor: not-allowed;\n}\n\n.disabled-message {\n  color: #ff4d4f;\n  font-size: 0.8em;\n  margin-top: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
