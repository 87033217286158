import React, { useState } from "react";
import { Col, Container, Row } from "reactstrap";
import { CustomersList } from "./CustomersList";
import EntityCardList from "../../Components/Entity/EntityCardList";
import { getString } from "Components/Common/FormattedString";
import AddApplication from "pages/Applications/AddApplication";

const Customers = () => {
  document.title = getString("customers_page_title");
  const [applicationAddVisible, setApplicationAddVisible] = useState(false);
  const toggleApplicationAdd = () => {
    setApplicationAddVisible((prev) => !prev);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col>
              <div className="h-100">
                <EntityCardList
                  title={getString("customers_list")}
                  actionBtn={toggleApplicationAdd}
                  actionBtnLabel={getString("application")}
                >
                  <CustomersList />
                </EntityCardList>
              </div>
            </Col>
            {applicationAddVisible && (
              <AddApplication
                visibility={applicationAddVisible}
                toggle={toggleApplicationAdd}
              />
            )}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Customers;
