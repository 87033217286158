// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.upsells-overview .single-overview .custom-progress {
  width: 120px;
}
.upsells-overview .single-overview .upsell-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.upsells-overview .single-overview .upsell-row .delete-btn {
  background-color: transparent;
  color: red;
  border: none;
  padding: 0;
  cursor: pointer;
  transition: color 0.15s;
}
.upsells-overview .single-overview .upsell-row .delete-btn:hover {
  color: darkred;
}
.upsells-overview .single-overview .upsell-row .delete-btn i {
  color: red;
  transition: color 0.15s;
}
.upsells-overview .single-overview .upsell-row .delete-btn i:hover {
  color: darkred;
}`, "",{"version":3,"sources":["webpack://./src/pages/Programs/ProgramDetails/partials/components/UpsellsOverview/UpsellsOverview.scss"],"names":[],"mappings":"AAEI;EACE,YAAA;AADN;AAGI;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;AADN;AAGM;EACE,6BAAA;EACA,UAAA;EACA,YAAA;EACA,UAAA;EACA,eAAA;EACA,uBAAA;AADR;AAGQ;EACE,cAAA;AADV;AAIQ;EACE,UAAA;EACA,uBAAA;AAFV;AAIU;EACE,cAAA;AAFZ","sourcesContent":[".upsells-overview {\n  .single-overview {\n    .custom-progress {\n      width: 120px;\n    }\n    .upsell-row {\n      display: flex;\n      align-items: center;\n      justify-content: space-between;\n\n      .delete-btn {\n        background-color: transparent;\n        color: red;\n        border: none;\n        padding: 0;\n        cursor: pointer;\n        transition: color 0.15s;\n\n        &:hover {\n          color: darkred;\n        }\n\n        i {\n          color: red;\n          transition: color 0.15s;\n\n          &:hover {\n            color: darkred;\n          }\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
