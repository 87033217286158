import React, { forwardRef, useImperativeHandle } from "react";
import { Row, Col, Button } from "reactstrap";
import { useFormik } from "formik";
import * as yup from "yup";
import { v4 as uuidv4 } from "uuid";
import FormTextField from "Components/Entity/FormTextField";
import FormRadioSelect from "Components/Entity/FormRadioSelect";
import FormNumberField from "Components/Entity/FormNumberField";
import { payerTypeOptions, PayerTypes } from "models/payerTypes";
import {
  FormattedString,
  getString,
  getStringSync,
} from "Components/Common/FormattedString";
import { useFormContext } from "../../provider/utils/FormContext";
import { handleInputChange, isValidNIP } from "helpers/validation_helper";

const getValidationSchema = (payerType) =>
  yup.object({
    payerDetails: yup.object({
      id: yup.string().nullable(),
      type: yup.string().required(getStringSync("payer_type_required")),
      companyName:
        payerType === PayerTypes.COMPANY
          ? yup.string().required(getStringSync("company_name_required"))
          : yup.string().nullable(),
      taxNumber:
        payerType === PayerTypes.COMPANY
          ? yup
              .string()
              .required(getStringSync("tax_number_required"))
              .test(
                "is-valid-nip",
                getStringSync("invalid_tax_number"),
                (value) => isValidNIP(value),
              )
          : yup.string().nullable(),
      firstName:
        payerType === PayerTypes.PERSON
          ? yup.string().required(getStringSync("first_name_required"))
          : yup.string().nullable(),
      lastName:
        payerType === PayerTypes.PERSON
          ? yup.string().required(getStringSync("last_name_required"))
          : yup.string().nullable(),
      city: yup.string().required(getStringSync("city_required")),
      zipCode: yup.string().required(getStringSync("zip_code_required")),
      address: yup.string().required(getStringSync("address_required")),
    }),
  });

const defaultInitialValues = {
  payerDetails: {
    id: uuidv4(),
    type: PayerTypes.PERSON,
    companyName: "",
    taxNumber: "",
    firstName: "",
    lastName: "",
    city: "",
    zipCode: "",
    address: "",
  },
};

const PayerForm = forwardRef(
  ({ handleBack, initialValues = defaultInitialValues }, ref) => {
    const { formik: parentFormik, handleStepChange } = useFormContext();

    const validation = useFormik({
      enableReinitialize: true,
      initialValues,
      validationSchema: yup.lazy((values) =>
        getValidationSchema(values.payerDetails.type),
      ),
      onSubmit: (values) => {
        const updatedPayerDetails = {
          ...values.payerDetails,
          taxNumber: values.payerDetails.taxNumber
            ? String(values.payerDetails.taxNumber)
            : null,
        };

        parentFormik
          .setValues({
            ...parentFormik.values,
            payerDetails: updatedPayerDetails,
          })
          .then(() => {
            handleStepChange(1);
          });
      },
    });

    useImperativeHandle(ref, () => ({
      submit: validation.handleSubmit,
    }));

    const handleTypeChange = (selectedType) => {
      validation.setFieldValue("payerDetails.type", selectedType, false);
      if (selectedType === PayerTypes.PERSON) {
        validation.setFieldValue("payerDetails.companyName", "", false);
        validation.setFieldValue("payerDetails.taxNumber", "", false);
      } else if (selectedType === PayerTypes.COMPANY) {
        validation.setFieldValue("payerDetails.firstName", "", false);
        validation.setFieldValue("payerDetails.lastName", "", false);
      }
    };

    return (
      <form onSubmit={validation.handleSubmit}>
        <Row className="align-items-center mb-4">
          <Col className="text-left">
            <h5 className="text-primary mb-0">
              {validation.values.payerDetails.id
                ? parentFormik.values.selectedPayer.type === PayerTypes.PERSON
                  ? `${getString("edit")} ${parentFormik.values.selectedPayer.firstName} ${parentFormik.values.selectedPayer.lastName} ${getString("details")}`
                  : `${getString("edit")} ${parentFormik.values.selectedPayer.companyName} ${getString("details")}`
                : getString("add_new_payer")}
            </h5>
          </Col>
          <Col xs="auto" className="text-right">
            <Button color="link" onClick={handleBack}>
              <FormattedString id="back_to_selection" />{" "}
              <i className="ri-arrow-right-line"></i>
            </Button>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormRadioSelect
              name="payerDetails.type"
              label={getString("payer_type")}
              options={payerTypeOptions}
              value={validation.values.payerDetails.type}
              onChange={handleTypeChange}
              error={validation.errors.payerDetails?.type}
            />
            {validation.values.payerDetails.type === PayerTypes.PERSON && (
              <>
                <FormTextField
                  label={getString("first_name")}
                  name="payerDetails.firstName"
                  value={validation.values.payerDetails.firstName || ""}
                  onChange={(e) =>
                    handleInputChange(validation, e, "payerDetails.firstName")
                  }
                  error={validation.errors.payerDetails?.firstName}
                />
                <FormTextField
                  label={getString("last_name")}
                  name="payerDetails.lastName"
                  value={validation.values.payerDetails.lastName || ""}
                  onChange={(e) =>
                    handleInputChange(validation, e, "payerDetails.lastName")
                  }
                  error={validation.errors.payerDetails?.lastName}
                />
              </>
            )}
            {validation.values.payerDetails.type === PayerTypes.COMPANY && (
              <>
                <FormTextField
                  label={getString("company_name")}
                  name="payerDetails.companyName"
                  value={validation.values.payerDetails.companyName || ""}
                  onChange={(e) =>
                    handleInputChange(validation, e, "payerDetails.companyName")
                  }
                  error={validation.errors.payerDetails?.companyName}
                />
                <FormNumberField
                  label={getString("tax_number_nip")}
                  name="payerDetails.taxNumber"
                  value={validation.values.payerDetails.taxNumber || ""}
                  onChange={(e) =>
                    handleInputChange(validation, e, "payerDetails.taxNumber")
                  }
                  error={validation.errors.payerDetails?.taxNumber}
                />
              </>
            )}
          </Col>
          <Col md={6}>
            <FormTextField
              label={getString("address")}
              name="payerDetails.address"
              value={validation.values.payerDetails.address || ""}
              onChange={(e) =>
                handleInputChange(validation, e, "payerDetails.address")
              }
              error={validation.errors.payerDetails?.address}
            />
            <FormTextField
              label={getString("zip_code")}
              name="payerDetails.zipCode"
              value={validation.values.payerDetails.zipCode || ""}
              onChange={(e) =>
                handleInputChange(validation, e, "payerDetails.zipCode")
              }
              error={validation.errors.payerDetails?.zipCode}
            />
            <FormTextField
              label={getString("city")}
              name="payerDetails.city"
              value={validation.values.payerDetails.city || ""}
              onChange={(e) =>
                handleInputChange(validation, e, "payerDetails.city")
              }
              error={validation.errors.payerDetails?.city}
            />
          </Col>
        </Row>
      </form>
    );
  },
);

export default PayerForm;
