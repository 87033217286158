import React from "react";
import { Col, Container, Row } from "reactstrap";
import EntityCardList from "../../Components/Entity/EntityCardList";
import { getString } from "Components/Common/FormattedString";
import { PromoCodesUsageList } from "./PromoCodesUsageList";

const PromoCodesUsage = () => {
  document.title = getString("promo_codes_usage_page_title");
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col>
              <div className="h-100">
                <EntityCardList title={getString("promo_codes_usage_list")}>
                  <PromoCodesUsageList />
                </EntityCardList>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default PromoCodesUsage;
