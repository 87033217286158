import React, { useContext } from "react";
import { Card, CardBody, CardTitle, CardText, Row, Col } from "reactstrap";
import "./SummaryDetails.scss";
import { useSelector } from "react-redux";
import { ApplicationDetailsContext } from "pages/Applications/ApplicationDetails/ApplicationDetails";
import { FormattedString, getString } from "Components/Common/FormattedString";
import { useFormContext } from "../../provider/utils/FormContext";
import { splitNumbers } from "helpers/utils";

const SummaryDetails = ({ summary = [], discounts = [] }) => {
  const { applicationData } = useContext(ApplicationDetailsContext);
  const { activeBusinessUnit } = useSelector((rootState) => rootState.Profile);
  const { maxInstallmentSum } = useFormContext();
  const currency = activeBusinessUnit.currency || "";

  const fallbackSummary = applicationData.participants.map((participant) => ({
    tempId: participant.participantId,
    participantName: `${participant.firstName} ${participant.lastName}`,
    programPacketName: participant.programPacketName,
    programPacketPrice: participant.programPacketPrice,
    programMeetingPrice: participant.transportPrice,
    upsells: [
      {
        name: participant.dietName,
        type: "diet",
        price: participant.dietPrice,
      },
      {
        name: participant.insuranceName,
        type: "insurance",
        price: participant.insurancePrice,
      },
    ],
  }));

  const fallbackDiscounts = applicationData.discounts.map(
    (discount, index) => ({
      id: `${discount.type}-${index}`,
      type: discount.type,
      amount: discount.amount,
      sum: discount.sum,
      count: discount.count,
    }),
  );

  const finalSummary = summary.length > 0 ? summary : fallbackSummary;
  const finalDiscounts = discounts.length > 0 ? discounts : fallbackDiscounts;

  return (
    <Card className="summary-card mb-4">
      <CardBody>
        <Row>
          <Col md={5}>
            <CardTitle tag="h5" className="summary-title text-primary">
              <FormattedString id="summary" />
            </CardTitle>
            {finalSummary.map((item, index) => (
              <div
                key={`${item.tempId}-${index}`}
                className="summary-item mb-3"
              >
                <CardText tag="h6" className="participant-name">
                  {item.participantName || getString("no_participant_name")}
                </CardText>
                <CardText>
                  <strong className="custom-muted">
                    <FormattedString id="program_packet" />:
                  </strong>{" "}
                  {item.programPacketName || getString("no_program_packet")} -{" "}
                  {splitNumbers(item.programPacketPrice) ||
                    getString("no_price")}{" "}
                  {currency}
                </CardText>
                <CardText>
                  <strong className="custom-muted">
                    <FormattedString id="meeting_price" />:
                  </strong>{" "}
                  {splitNumbers(item.programMeetingPrice) ||
                    getString("no_meeting_price")}{" "}
                  {currency}
                </CardText>
                <div>
                  <strong className="custom-muted">
                    <FormattedString id="upsells" />:
                  </strong>
                  <ul className="upsells-list">
                    {item.upsells.length > 0 ? (
                      item.upsells.map((upsell, index) => (
                        <li key={`${item.tempId}-upsell-${index}`}>
                          <span className="upsell-name">
                            {upsell.name || getString("no_upsell_name")}
                          </span>{" "}
                          ({upsell.type || getString("no_type")}) -{" "}
                          {splitNumbers(upsell.price) || getString("no_price")}{" "}
                          {currency}
                        </li>
                      ))
                    ) : (
                      <li>
                        <FormattedString id="no_upsells" />
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            ))}
          </Col>
          <Col md={1} className="separator">
            <div className="vertical-line"></div>
          </Col>
          <Col md={6}>
            <CardTitle tag="h5" className="discounts-title text-primary">
              <FormattedString id="discounts" />
            </CardTitle>
            {finalDiscounts.length > 0 ? (
              finalDiscounts.map((discount, index) => (
                <CardText key={`${discount.type}-${index}`}>
                  <strong className="custom-muted">
                    <FormattedString id={discount.type || "no_discount_type"} />
                    :
                  </strong>{" "}
                  {discount.count || getString("no_count")} x{" "}
                  {splitNumbers(discount.amount) || getString("no_amount")}{" "}
                  {currency} (Total:{" "}
                  {splitNumbers(discount.sum) || getString("no_total")}{" "}
                  {currency})
                </CardText>
              ))
            ) : (
              <CardText>
                <FormattedString id="no_discounts_available" />
              </CardText>
            )}
            <CardTitle tag="h5" className="total-price-title text-primary mt-4">
              <FormattedString id="total_price" />
            </CardTitle>
            <CardText>
              <strong className="custom-muted">
                <FormattedString id="total_price" />:
              </strong>{" "}
              {splitNumbers(maxInstallmentSum)} {currency}
            </CardText>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default SummaryDetails;
