import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { createSelector } from "reselect";
import {
  handleInputChange,
  setValidationErrors,
} from "../../helpers/validation_helper";
import {
  getPromoCodesListData,
  updatePromoCode,
} from "../../store/promoCodes/action";
import { getProgramPacketTemplateListData } from "../../store/programPacketTemplates/action";
import { createPromoCodeSchema } from "../../models/promoCodes";
import OffcanvasRight from "../../Components/Entity/OffcanvasRight";
import FormTextField from "../../Components/Entity/FormTextField";
import FormNumberField from "../../Components/Entity/FormNumberField";
import { FormRadioSelect } from "../../Components/Entity/FormRadioSelect";
import FormDateField from "../../Components/Entity/FormDateField";
import FormSwitch from "../../Components/Entity/FormSwitch";
import FormPriceConfigurationSelect from "./components/FormPriceConfigurationSelect/FormPriceConfigurationSelect";
import { externalizeDate, internalizeDate } from "helpers/utils";
import { getString } from "Components/Common/FormattedString";
import { installmentPositions } from "models/installmentPositions";
import { Card, CardBody, Col, Form, Row, Spinner } from "reactstrap";
import { toast_success } from "helpers/toast_helper";

const EditPromoCode = ({ promoCode, toggle, visibility }) => {
  const dispatch = useDispatch();

  const { programPacketTemplatesData, loading: templatesLoading } = useSelector(
    (state) => state.ProgramPacketTemplates,
  );

  const selectPromoCodesState = (state) => state.PromoCodes;
  const selectPromoCodesProperties = createSelector(
    selectPromoCodesState,
    (state) => ({
      isPromoCodeUpdated: state.isPromoCodeUpdated,
      error: state.error,
      loading: state.loading,
      formValidationErrors: state.formValidationErrors,
    }),
  );
  const { loading, formValidationErrors } = useSelector(
    selectPromoCodesProperties,
  );

  useEffect(() => {
    dispatch(getProgramPacketTemplateListData());
  }, [dispatch]);

  const programPacketTemplateOptions = programPacketTemplatesData.map((pp) => ({
    value: pp.id,
    label: pp.label,
  }));

  const validation = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    initialValues: {
      code: promoCode?.code || "",
      usageLimit: promoCode?.usageLimit || "",
      isExternal: promoCode?.isExternal || false,
      startDate: internalizeDate(promoCode?.startDate) || "",
      endDate: internalizeDate(promoCode?.endDate) || "",
      email: promoCode?.email || "",
      installmentPosition: promoCode?.installmentPosition || "",
      pricesConfiguration: Array.isArray(promoCode?.discountValues)
        ? promoCode.discountValues.map((d) => ({
            programPacketTemplateId: d.id,
            discount: d.discount,
          }))
        : [],
      isGlobal:
        !Array.isArray(promoCode.discountValues) ||
        promoCode.discountValues.length === 0,
      globalDiscount: promoCode?.globalDiscount || "",
    },
    validationSchema: createPromoCodeSchema,
    onSubmit: async (values) => {
      const {
        isGlobal,
        pricesConfiguration,
        usageLimit,
        globalDiscount,
        ...data
      } = values;
      const formattedData = {
        ...data,
        ...(usageLimit !== "" && { usageLimit }),
        ...(isGlobal && globalDiscount !== "" && { globalDiscount }),
        startDate: externalizeDate(values.startDate),
        endDate: externalizeDate(values.endDate),
        ...(pricesConfiguration.length > 0 && { pricesConfiguration }),
      };

      dispatch(
        updatePromoCode(promoCode.id, formattedData, () => {
          dispatch(getPromoCodesListData());
          toggle(false);
          toast_success(getString("promo_code_updated"));
        }),
      );
    },
  });

  const [prevValidationErrors, setPrevValidationErrors] = useState(null);
  useEffect(() => {
    if (formValidationErrors === null) {
      return;
    }
    if (prevValidationErrors !== null) {
      if (prevValidationErrors !== formValidationErrors) {
        setValidationErrors(validation, formValidationErrors);
      }
    }
    setPrevValidationErrors(formValidationErrors);
  }, [formValidationErrors, prevValidationErrors]);

  useEffect(() => {
    if (validation.touched.installmentPosition) {
      validation.validateField("installmentPosition");
    }
  }, [
    validation.values.installmentPosition,
    validation.touched.installmentPosition,
  ]);

  const handleGlobalToggle = (e) => {
    const isGlobal = e.target.checked;
    validation.setFieldValue("isGlobal", isGlobal);

    if (isGlobal) {
      validation.setFieldValue("pricesConfiguration", []);
      validation.setFieldError("pricesConfiguration", undefined);
    } else {
      validation.setFieldValue("globalDiscount", "");
      validation.setFieldError("globalDiscount", undefined);
    }
  };

  return (
    <React.Fragment>
      <OffcanvasRight
        isOpen={visibility}
        toggle={toggle}
        title={getString("promo_codes_edit_promo_code")}
        formId="edit-promo-code"
        validationRule={validation.isValid}
        loading={loading}
        buttonLabel={getString("save")}
      >
        <Card>
          <CardBody className="card-body">
            {templatesLoading ? (
              <div className="d-flex justify-content-center align-items-center">
                <Spinner color="primary" />
              </div>
            ) : (
              <Form
                id="edit-promo-code"
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
                action="#"
              >
                <FormTextField
                  name="code"
                  label={getString("promo_code")}
                  value={validation.values.code}
                  onChange={(e) => handleInputChange(validation, e, "code")}
                  error={validation.errors["code"]}
                  touched={validation.touched.code}
                />
                <FormNumberField
                  name="usageLimit"
                  label={getString("usage_limit")}
                  value={validation.values.usageLimit}
                  onChange={(e) =>
                    handleInputChange(validation, e, "usageLimit")
                  }
                  error={validation.errors["usageLimit"]}
                  touched={validation.touched.usageLimit}
                />
                <FormSwitch
                  label={getString("is_external")}
                  noPadding
                  name="isExternal"
                  checked={validation.values.isExternal}
                  onChange={(e) =>
                    validation.setFieldValue("isExternal", e.target.checked)
                  }
                  error={validation.errors.isExternal}
                />
                <FormDateField
                  name="startDate"
                  label={getString("start_date")}
                  value={validation.values.startDate}
                  onChange={(e) =>
                    handleInputChange(validation, e, "startDate")
                  }
                  error={validation.errors["startDate"]}
                  touched={validation.touched.startDate}
                  clearable
                />
                <FormDateField
                  name="endDate"
                  label={getString("end_date")}
                  value={validation.values.endDate}
                  onChange={(e) => handleInputChange(validation, e, "endDate")}
                  error={validation.errors["endDate"]}
                  touched={validation.touched.endDate}
                  clearable
                />
                <FormTextField
                  name="email"
                  label={getString("email")}
                  type="email"
                  value={validation.values.email}
                  onChange={(e) => handleInputChange(validation, e, "email")}
                  error={validation.errors["email"]}
                  touched={validation.touched.email}
                />
                <FormRadioSelect
                  name="installmentPosition"
                  id="installmentPosition"
                  label={getString("installment_position")}
                  isMulti={false}
                  options={installmentPositions}
                  value={validation.values.installmentPosition}
                  onChange={(selectedValue) => {
                    validation.setFieldValue(
                      "installmentPosition",
                      selectedValue,
                    );
                  }}
                  error={validation.errors["installmentPosition"]}
                />
                <Row className="mb-3 align-items-center">
                  <Col>
                    <FormSwitch
                      label={getString("apply_globally")}
                      name="isGlobal"
                      noPadding
                      noMargin
                      checked={validation.values.isGlobal}
                      onChange={handleGlobalToggle}
                    />
                  </Col>
                </Row>

                {validation.values.isGlobal && (
                  <FormNumberField
                    name="globalDiscount"
                    label={getString("global_discount")}
                    value={validation.values.globalDiscount}
                    onChange={(e) =>
                      handleInputChange(validation, e, "globalDiscount")
                    }
                    error={validation.errors["globalDiscount"]}
                    touched={validation.touched.globalDiscount}
                  />
                )}

                {!validation.values.isGlobal && (
                  <FormPriceConfigurationSelect
                    name="pricesConfiguration"
                    label={getString("prices_configuration")}
                    value={validation.values.pricesConfiguration}
                    options={programPacketTemplateOptions}
                    onChange={(value) =>
                      validation.setFieldValue("pricesConfiguration", value)
                    }
                    error={validation.errors.pricesConfiguration}
                  />
                )}
              </Form>
            )}
          </CardBody>
        </Card>
      </OffcanvasRight>
    </React.Fragment>
  );
};

export default EditPromoCode;
