import * as Yup from "yup";
import { getStringSync } from "Components/Common/FormattedString";

const createIpApplicationSchema = Yup.object({
  programId: Yup.string().required(getStringSync("select_program")),
  customerId: Yup.string().nullable(),
  phonePrefix: Yup.string().test(
    "phonePrefix-required",
    getStringSync("phone_prefix_required"),
    function (value) {
      const { customerId } = this.parent;
      return customerId || value;
    },
  ),
  phoneNumber: Yup.string().test(
    "phoneNumber-required",
    getStringSync("phone_number_required"),
    function (value) {
      const { customerId } = this.parent;
      return customerId || value;
    },
  ),
  email: Yup.string()
    .email(getStringSync("invalid_email"))
    .test("email-required", getStringSync("email_required"), function (value) {
      const { customerId } = this.parent;
      return customerId || value;
    }),
  firstName: Yup.string().test(
    "firstName-required",
    getStringSync("first_name_required"),
    function (value) {
      const { customerId } = this.parent;
      return customerId || value;
    },
  ),
  lastName: Yup.string().test(
    "lastName-required",
    getStringSync("last_name_required"),
    function (value) {
      const { customerId } = this.parent;
      return customerId || value;
    },
  ),
});

const getIpStatusBadgeClass = (status) => {
  switch (status) {
    case "invited":
      return "bg-primary text-white";
    case "wait_list":
      return "bg-warning text-dark";
    case "awaiting_travel_details":
      return "bg-info text-white";
    case "confirmed":
      return "bg-success text-white";
    case "cancelled":
      return "bg-danger text-white";
    case "no_show":
      return "bg-dark text-white";
    case "no_response":
      return "bg-secondary text-white";
    case "partly_confirmed":
      return "bg-light text-dark";
    case "moved":
      return "bg-primary text-white";
    case "dropped_out":
      return "bg-danger text-white";
    case "uninvited":
      return "bg-warning text-dark";
    case "14_day_remorse":
      return "bg-info text-white";
    case "early_cancelled":
      return "bg-danger text-white";
    case "last_min_cancelled":
      return "bg-danger text-white";
    case "annulled_programme":
      return "bg-dark text-white";
    case "suspended":
      return "bg-warning text-dark";
    case "expelled":
      return "bg-danger text-white";
    case "overbooked":
      return "bg-secondary text-white";
    default:
      return "bg-secondary text-white";
  }
};

export { createIpApplicationSchema, getIpStatusBadgeClass };
