import React, { Fragment, useEffect, useRef, useState } from "react";
import {
  useTable,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
  useRowSelect,
} from "react-table";
import { Table, Row, Col, CardBody } from "reactstrap";
import { DefaultColumnFilter } from "./filters";
import { Link, useSearchParams } from "react-router-dom";
import DataLoadError from "./DataLoadError/DataLoadError";
import classNames from "classnames";
import { getString, FormattedString } from "Components/Common/FormattedString";

function GlobalFilter({ globalFilter, setGlobalFilter, SearchPlaceholder }) {
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <React.Fragment>
      <CardBody>
        <form onSubmit={handleSubmit}>
          <Row className="g-3">
            <Col>
              <div className="search-box me-2 mb-2 d-inline-block col-12">
                <input
                  onChange={(e) => {
                    setValue(e.target.value);
                    onChange(e.target.value);
                  }}
                  id="search-bar-0"
                  type="text"
                  className="form-control search"
                  placeholder={SearchPlaceholder}
                  value={value || ""}
                />
                <i className="bx bx-search-alt search-icon"></i>
              </div>
            </Col>
          </Row>
        </form>
      </CardBody>
    </React.Fragment>
  );
}

const TableContainer = ({
  columns,
  data,
  isPagination,
  isGlobalSearch,
  isGlobalFilter,
  customPageSize,
  tableClass,
  theadClass,
  trClass,
  thClass,
  divClass,
  SearchPlaceholder,
  error,
  loading,
  onRetry,
  preventLoading = false,
  excludeUrlHandling = false,
  disableDataInvertion = false,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const isInitialRender = useRef(true);
  const [globalFilter, setGlobalFilterState] = useState(
    searchParams.get("searchTerm") || "",
  );
  const pageNumber = searchParams.get("page") || "1";

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    setPageSize,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,

      defaultColumn: { Filter: DefaultColumnFilter },
      initialState: {
        pageIndex: pageNumber - 1,
        pageSize: customPageSize,
        selectedRowIds: 0,
        sortBy: disableDataInvertion
          ? []
          : [
              {
                desc: true,
              },
            ],
        globalFilter,
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
  );

  useEffect(() => {
    if (!excludeUrlHandling) {
      if (isInitialRender.current) {
        isInitialRender.current = false;
      } else {
        const newSearchParams = new URLSearchParams(searchParams);
        if (pageIndex > 0) {
          newSearchParams.set("page", pageIndex + 1);
        } else {
          newSearchParams.delete("page");
        }
        setSearchParams(newSearchParams);
      }
    }
  }, [pageIndex, excludeUrlHandling]);

  const generateSortingIndicator = (column) => {
    return column.isSorted ? (
      column.isSortedDesc ? (
        <span>&#8593;</span>
      ) : (
        <span>&#8595;</span>
      )
    ) : (
      ""
    );
  };

  const customSetGlobalFilter = (value) => {
    if (!excludeUrlHandling) {
      const newSearchParams = new URLSearchParams(searchParams);
      if (value) {
        newSearchParams.set("searchTerm", value);
      } else {
        newSearchParams.delete("searchTerm");
      }
      newSearchParams.set("page", "1");
      setSearchParams(newSearchParams);
    }
    setGlobalFilterState(value);
    setGlobalFilter(value);
  };

  const handlePageChange = (page) => {
    if (!excludeUrlHandling) {
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.set("page", page.toString());
      setSearchParams(newSearchParams);
    }
    gotoPage(page - 1);
  };

  const onChangeInSelect = (event) => {
    setPageSize(Number(event.target.value));
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const maxPagesToShow = 11;
    const halfMaxPagesToShow = Math.floor(maxPagesToShow / 2);

    if (pageOptions.length <= maxPagesToShow) {
      for (let i = 1; i <= pageOptions.length; i++) {
        pageNumbers.push(i);
      }
    } else {
      const startPage = Math.max(1, pageIndex + 1 - halfMaxPagesToShow);
      const endPage = Math.min(
        pageOptions.length,
        pageIndex + 1 + halfMaxPagesToShow,
      );

      if (startPage > 1) {
        pageNumbers.push(1);
        if (startPage > 2) {
          pageNumbers.push("...");
        }
      }

      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
      }

      if (endPage < pageOptions.length) {
        if (endPage < pageOptions.length - 1) {
          pageNumbers.push("...");
        }
        pageNumbers.push(pageOptions.length);
      }
    }

    return pageNumbers.map((page, index) => (
      <li key={index} className="page-item">
        {page === "..." ? (
          <span className="page-link">...</span>
        ) : (
          <Link
            to="#"
            className={classNames("page-link", {
              active: pageIndex + 1 === page,
              disabled: loading && pageIndex + 1 !== page,
            })}
            onClick={(e) => {
              e.preventDefault();
              handlePageChange(page);
            }}
          >
            {page}
          </Link>
        )}
      </li>
    ));
  };

  return (
    <Fragment>
      {(isGlobalSearch || isGlobalFilter) && (
        <Row className="mb-3">
          {isGlobalSearch && (
            <Col md={1}>
              <select
                className="form-select"
                value={pageSize}
                onChange={onChangeInSelect}
              >
                {[10, 20, 30, 40, 50].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    <FormattedString id={getString("show")} /> {pageSize}
                  </option>
                ))}
              </select>
            </Col>
          )}
          {isGlobalFilter && (
            <GlobalFilter
              preGlobalFilteredRows={preGlobalFilteredRows}
              globalFilter={globalFilter}
              setGlobalFilter={customSetGlobalFilter}
              SearchPlaceholder={SearchPlaceholder}
            />
          )}
        </Row>
      )}

      <div className={divClass}>
        <Table hover {...getTableProps()} className={tableClass}>
          <thead className={theadClass}>
            {headerGroups.map((headerGroup) => (
              <tr
                className={trClass}
                key={headerGroup.id}
                {...headerGroup.getHeaderGroupProps()}
              >
                {headerGroup.headers.map((column) => (
                  <th
                    key={column.id}
                    className={thClass}
                    {...column.getSortByToggleProps()}
                  >
                    {column.render("Header")}
                    {generateSortingIndicator(column)}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {error || (loading && !preventLoading) ? (
              <tr>
                <td colSpan={columns.length}>
                  <DataLoadError
                    errorMessage={error}
                    loading={loading}
                    onRetry={onRetry}
                  />
                </td>
              </tr>
            ) : (
              page.map((row) => {
                prepareRow(row);
                return (
                  <Fragment key={row.getRowProps().key}>
                    <tr data-testid={row.original.id}>
                      {row.cells.map((cell) => {
                        return (
                          <td key={cell.id} {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  </Fragment>
                );
              })
            )}
          </tbody>
        </Table>
      </div>

      {isPagination && (
        <Row className="align-items-center mt-2 g-3 text-center text-sm-start">
          <div className="col-sm">
            <div className="text-muted">
              <FormattedString id={getString("showing")} />
              <span className="fw-semibold ms-1">{page.length}</span>{" "}
              <FormattedString id={getString("of")} />{" "}
              <span className="fw-semibold">{data.length}</span>{" "}
              <FormattedString id={getString("results")} />
            </div>
          </div>
          <div className="col-sm-auto">
            <ul className="pagination pagination-separated pagination-md justify-content-center justify-content-sm-start mb-0">
              <li
                className={
                  !canPreviousPage ? "page-item disabled" : "page-item"
                }
              >
                <Link
                  to="#"
                  className="page-link"
                  onClick={(e) => {
                    e.preventDefault();
                    handlePageChange(pageIndex);
                  }}
                >
                  <FormattedString id={getString("previous")} />
                </Link>
              </li>
              {renderPageNumbers()}
              <li className={!canNextPage ? "page-item disabled" : "page-item"}>
                <Link
                  to="#"
                  className="page-link"
                  onClick={(e) => {
                    e.preventDefault();
                    handlePageChange(pageIndex + 2);
                  }}
                >
                  <FormattedString id={getString("next")} />
                </Link>
              </li>
            </ul>
          </div>
        </Row>
      )}
    </Fragment>
  );
};

export default TableContainer;
