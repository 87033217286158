import { call, put, all, fork, takeLatest } from "redux-saga/effects";

import {
  GET_PROMO_CODE_LIST,
  POST_PROMO_CODE,
  DELETE_PROMO_CODE,
  UPDATE_PROMO_CODE,
} from "./actionType";
import {
  promoCodesApiSuccess,
  promoCodesApiError,
  postPromoCodeSuccess,
  postPromoCodeError,
} from "./action";

import {
  getPromoCodesList,
  postPromoCode,
  deletePromoCode,
  updatePromoCode,
} from "../../helpers/API/core-service/cs_backend_helper";

function* getPromoCodesListData({ payload: data }) {
  try {
    const { page, searchTerm, pageSize } = data;
    const response = yield call(getPromoCodesList, page, searchTerm, pageSize);

    yield put(promoCodesApiSuccess(GET_PROMO_CODE_LIST, response));
  } catch (error) {
    yield put(promoCodesApiError(GET_PROMO_CODE_LIST, error));
  }
}

function* postPromoCodeData({ payload: { data, callback } }) {
  try {
    const response = yield call(postPromoCode, data, callback);
    yield put(postPromoCodeSuccess(response));

    if (callback) {
      callback();
    }
  } catch (error) {
    yield put(postPromoCodeError(error));
  }
}

function* deletePromoCodeData({ payload: { id, callback } }) {
  try {
    const response = yield call(deletePromoCode, id);
    yield put(promoCodesApiSuccess(DELETE_PROMO_CODE, response));

    if (callback) {
      callback();
    }
  } catch (error) {
    yield put(promoCodesApiError(DELETE_PROMO_CODE, error));
  }
}

function* updatePromoCodeData({ payload: { id, data, callback } }) {
  try {
    const response = yield call(updatePromoCode, id, data);
    yield put(promoCodesApiSuccess(UPDATE_PROMO_CODE, response));

    if (callback) {
      callback();
    }
  } catch (error) {
    yield put(promoCodesApiError(UPDATE_PROMO_CODE, error));
  }
}

export function* watchGetPromoCodesListData() {
  yield takeLatest(GET_PROMO_CODE_LIST, getPromoCodesListData);
}

export function* watchPostPromoCodeData() {
  yield takeLatest(POST_PROMO_CODE, postPromoCodeData);
}

export function* watchDeletePromoCodeData() {
  yield takeLatest(DELETE_PROMO_CODE, deletePromoCodeData);
}

export function* watchUpdatePromoCodeData() {
  yield takeLatest(UPDATE_PROMO_CODE, updatePromoCodeData);
}

function* promoCodesSaga() {
  yield all([
    fork(watchGetPromoCodesListData),
    fork(watchPostPromoCodeData),
    fork(watchDeletePromoCodeData),
    fork(watchUpdatePromoCodeData),
  ]);
}

export default promoCodesSaga;
