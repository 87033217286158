import React, { useContext, useEffect, useState } from "react";
import { Card, CardBody, Form, Spinner } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import * as Yup from "yup";
import OffcanvasRight from "Components/Entity/OffcanvasRight";
import { FormSelect } from "Components/Entity/FormSelect";
import { getProgramListData } from "store/programs/action";
import { setValidationErrors } from "helpers/validation_helper";
import { getProgram } from "helpers/API/core-service/cs_backend_helper";
import { programStatus } from "models/programStatuses";
import { useFormContext } from "../../../provider/utils/FormContext";
import { useFormik } from "formik";
import { getString } from "Components/Common/FormattedString";
import { ApplicationDetailsContext } from "pages/Applications/ApplicationDetails/ApplicationDetails";
import { useHasPermission } from "Components/Hooks/useHasPermission";
import { PreloaderWrap } from "Components/Common/Preloader/Preloader";

const EditProgramOffcanvas = ({ isOpen, toggle }) => {
  const { applicationData, parentProgram } = useContext(
    ApplicationDetailsContext,
  );

  const canMoveToSameProgram = useHasPermission("PP_APPLICATION_ACCOUNTING");

  const dispatch = useDispatch();
  const { formik, targetProgram, setTargetProgram } = useFormContext();
  const selectProgramState = (state) => state.Programs;
  const selectProgramProperties = createSelector(
    selectProgramState,
    (state) => ({
      isProgramEdited: state.isProgramEdited,
      error: state.error,
      loading: state.loading,
      formValidationErrors: state.formValidationErrors,
      programsData: state.programsData,
    }),
  );
  const { formValidationErrors, programsData } = useSelector(
    selectProgramProperties,
  );

  const [loadingProgram, setLoadingProgram] = useState(false);

  const shouldSetEmptyString = applicationData.programId === targetProgram.id;

  const validation = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    initialValues: {
      program: shouldSetEmptyString ? "" : formik.values.program || "",
    },
    validationSchema: Yup.object({
      program: Yup.string().required("Program is required"),
    }),
    onSubmit: (values) => {
      setLoadingProgram(true);
      formik.setFieldValue("program", values.program);
      formik.setFieldError("program", "");
      getProgram(values.program).then((res) => {
        if (res) {
          const validProgramPacketIds = res.programPackets.map(
            (packet) => packet.id,
          );
          const validDietIds = res.participantUpsells
            .filter((upsell) => upsell.type === "diet")
            .map((upsell) => upsell.id);
          const validInsuranceIds = res.participantUpsells
            .filter((upsell) => upsell.type === "insurance")
            .map((upsell) => upsell.id);
          const validProgramMeetingIds = res.programMeetingsPP.map(
            (meeting) => meeting.id,
          );
          const validUpsellIds = res.participantUpsells.map(
            (upsell) => upsell.id,
          );

          formik.values.participants.forEach((participant, index) => {
            if (!validProgramPacketIds.includes(participant.programPacket)) {
              formik.setFieldValue(`participants[${index}].programPacket`, "");
            }
            if (!validDietIds.includes(participant.upsells.diet)) {
              formik.setFieldValue(`participants[${index}].upsells.diet`, "");
            }
            if (!validInsuranceIds.includes(participant.upsells.insurance)) {
              formik.setFieldValue(
                `participants[${index}].upsells.insurance`,
                "",
              );
            }
            const filteredUpsells = participant.upsells.upsells?.filter(
              (upsell) => validUpsellIds.includes(upsell),
            );
            formik.setFieldValue(
              `participants[${index}].upsells.upsells`,
              filteredUpsells,
            );
          });

          if (!validProgramMeetingIds.includes(formik.values.programMeeting)) {
            formik.setFieldValue("programMeeting", "");
          }
          if (res.programPackets.length === 1) {
            formik.values.participants.forEach((participant, index) => {
              formik.setFieldValue(
                `participants[${index}].programPacket`,
                res.programPackets[0].id,
              );
            });
          }
          if (validDietIds.length === 1) {
            formik.values.participants.forEach((participant, index) => {
              formik.setFieldValue(
                `participants[${index}].upsells.diet`,
                validDietIds[0],
              );
            });
          }
          if (validInsuranceIds.length === 1) {
            formik.values.participants.forEach((participant, index) => {
              formik.setFieldValue(
                `participants[${index}].upsells.insurance`,
                validInsuranceIds[0],
              );
            });
          }

          setTargetProgram(res);
          setLoadingProgram(false);
          toggle(false);
        }
      });
    },
  });

  const handleProgramChange = (selectedOption) => {
    validation.setFieldValue("program", selectedOption.id);
  };

  useEffect(() => {
    dispatch(getProgramListData());
  }, [dispatch]);

  const programOptions = programsData
    .filter((program) => {
      const isParentProgram = program.id === parentProgram?.id;
      return (
        [programStatus.on_sale].includes(program.status) &&
        (canMoveToSameProgram || !isParentProgram)
      );
    })
    .map((program) => ({
      value: program.id,
      id: program.id,
      label: `#${program.customId} ${program.name} ${new Date(
        program.startDate,
      ).toLocaleDateString()} - ${new Date(program.endDate).toLocaleDateString()}`,
    }));

  const [prevValidationErrors, setPrevValidationErrors] = useState(null);
  useEffect(() => {
    if (formValidationErrors === null) {
      return;
    }
    if (prevValidationErrors !== null) {
      if (prevValidationErrors !== formValidationErrors) {
        setValidationErrors(validation, formValidationErrors);
      }
    }
    setPrevValidationErrors(formValidationErrors);
  }, [formValidationErrors, prevValidationErrors]);

  return (
    <OffcanvasRight
      isOpen={isOpen}
      toggle={toggle}
      title={getString("select_program")}
      formId="edit-program"
      validationRule={true}
      loading={loadingProgram}
      buttonLabel="Save"
    >
      <Form
        id="edit-program"
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
          return false;
        }}
        action="#"
      >
        <Card>
          <CardBody>
            <div className="position-relative">
              {programsData.length > 0 ? (
                <FormSelect
                  name="program"
                  id="program"
                  label="Program"
                  isMulti={false}
                  options={programOptions}
                  defaultValue={programOptions.find(
                    (o) => o.id === validation.values.program,
                  )}
                  value={validation.values.program}
                  onChange={handleProgramChange}
                  error={validation.errors.program}
                  disabled={loadingProgram}
                />
              ) : (
                <PreloaderWrap />
              )}
              {loadingProgram && (
                <Spinner
                  size="sm"
                  color="primary"
                  className="position-absolute"
                  style={{
                    top: "38px",
                    right: "10px",
                    backgroundColor: "white",
                  }}
                />
              )}
            </div>
          </CardBody>
        </Card>
      </Form>
    </OffcanvasRight>
  );
};

export default EditProgramOffcanvas;
