import React from "react";
import { Form, Row, Col } from "reactstrap";
import InstallmentList from "./InstallmentList";
import ApplicationStepNavigation from "../../../components/ApplicationStepNavigation/ApplicationStepNavigation";
import { getString } from "Components/Common/FormattedString";

const InstallmentForm = ({
  formik,
  installmentTypes,
  pointOfSellOptions,
  removeInstallment,
  advancedManagement,
  installmentsError,
  addInstallment,
}) => (
  <Form
    onSubmit={(e) => {
      e.preventDefault();
      formik.handleSubmit();
      return false;
    }}
  >
    <InstallmentList
      installments={formik.values.installments}
      formik={formik}
      installmentTypes={installmentTypes}
      pointOfSellOptions={pointOfSellOptions}
      removeInstallment={removeInstallment}
      advancedManagement={advancedManagement}
    />
    {installmentsError && (
      <Row className="mb-3">
        <Col>
          <div className="text-danger">{JSON.stringify(installmentsError)}</div>
        </Col>
      </Row>
    )}
    <Row className="mb-3">
      <Col>
        <div className="add-installment-btn" onClick={addInstallment}>
          <i className="ri-add-line add-installment-btn__icon"></i>{" "}
          {getString("add_installment")}
        </div>
      </Col>
    </Row>
    <ApplicationStepNavigation />
  </Form>
);

export default InstallmentForm;
